import React, { useEffect, useState } from "react";
import { Checkbox, DatePicker, message, Table } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Select } from "antd";
import dayjs from "dayjs";
import {
  businessUnitCategories,
  businessUnitSubCategories,
  getTitleLogsApi,
  getTitleLogsMonthlyApi,
  proposalsListingApi,
} from "../../../services/Collections";
import { useDispatch, useSelector } from "react-redux";
import {
  finalReportData,
  finalReportFilter,
} from "../../exceltable/ExcelStore";
import Watermark from "../../../components/Watermark";
import LoadingBlock from "../../../components/LoadingBlock";
import { months, TableValIni, TableValuesKeys } from "./data";
import { FilterTabsWrapper, FinalReportWrapper } from "./style";
import { ApplyFilter } from "../../dynamicBudgetSheet/YearSheet";
import {
  checkForInfinity,
  formatInteger,
  formatNumberDecimal,
} from "../../dynamicBudgetSheet/Year/commonFuc";

export default function index({ filter, activeTabInReport }) {
  const dispatch = useDispatch();
  const { loginAuth, dynamicBudgetSheet } = useSelector((state) => state);
  const {
    data: loginData,
    summarySectionFilter: clientFilter,
    prefilledFilters,
  } = loginAuth;
  const { yearSheetFilters, finalReport } = dynamicBudgetSheet;

  const clientKey =
    clientFilter?.selectedClient?.key || prefilledFilters?.selectedClient?.key;

  const data = activeTabInReport
    ? finalReport?.predictedData
    : finalReport?.currentData;

  const activeFilter = activeTabInReport
    ? finalReport?.predictedFilter
    : finalReport?.currentFilter;

  const uniqueSelectedMonths = yearSheetFilters?.uniqueMonths;

  const [tableData, setTableData] = useState(data || []);
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState(activeFilter?.month || null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [categoriesListing, setCategoriesListing] = useState([]);
  const [catLoading, setCatLoading] = useState(false);
  const [subCategoriesListing, setSubCategoriesListing] = useState([]);
  const [subCatLoading, setSubCatLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(
    activeFilter?.category || []
  );
  const [selectedSubCategories, setSelectedSubCategories] = useState(
    activeFilter?.subCategory || []
  );
  const [newCustomer, setNewCustomer] = useState(
    activeFilter?.newCustomer || false
  );
  const [titleOption, setTitleOption] = useState(null);
  const [noDataFound, setNoDataFound] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(
    finalReport?.predictedFilter?.title || null
  );
  const [titleLoading, setTitleLoading] = useState(false);

  const onYearChange = (year) => {
    if (!year) {
      setSelectedYear(null);
      return;
    }
    setSelectedYear(Number(year));
  };

  const onMonthChange = (date) => {
    if (date == null || date == undefined) {
      setDate(null);
      return;
    }
    setDate(date);
  };

  const getCategoriesListing = async () => {
    try {
      setCatLoading(true);
      let res = await businessUnitCategories(clientKey);
      if (res?.status === 200) {
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.name,
          label: ele?.name,
        }));
        setCategoriesListing(updateData);
        setCatLoading(false);
      } else {
        message.error("Something went wrong");
        setCatLoading(false);
      }
    } catch (error) {
      setCatLoading(false);
      console.log("error in businessUnitCategories", error);
    }
  };

  const getSubCategoriesListing = async () => {
    try {
      setSubCatLoading(true);
      let res = await businessUnitSubCategories(clientKey);
      if (res?.status === 200) {
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.name,
          label: ele?.name,
        }));
        setSubCategoriesListing(updateData);
        setSubCatLoading(false);
      } else {
        message.error("Something went wrong");
        setSubCatLoading(false);
      }
    } catch (error) {
      setSubCatLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  const getTitleListing = async () => {
    try {
      let params = new URLSearchParams();
      params.append("user_id", loginData?.id);

      setTitleLoading(true);
      let res = await getTitleLogsApi(clientKey, params);
      if (res?.status === 200) {
        if (res?.data?.length == 0) {
          setTitleLoading(false);
          dispatch(
            finalReportFilter({
              filter: null,
              type: "pre",
            })
          );
          dispatch(finalReportData({ data: null, type: "pre" }));
          return;
        }
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.id,
          label: ele?.title,
        }));
        setTitleOption(updateData);
        setTitleLoading(false);
      } else {
        setTitleOption([]);
        setTitleLoading(false);
      }
    } catch (error) {
      setTitleLoading(false);
      console.log("error in title", error);
    }
  };

  const returnDataWithTotal = (res) => {
    let modified = res.filter(
      (ele) => ele?.budget != 0 || ele?.sales != 0 || ele?.roas != 0
    );

    let Total = { ...TableValIni };

    modified.forEach((ele) => {
      TableValuesKeys.forEach((key) => {
        Total[key] += Number(ele?.[key]) || 0;
      });
    });

    return [...modified, Total];
  };

  const getDataOFTitle = async () => {
    if (!selectedTitle?.id) {
      message.error("Please select the Predicted Title");
      return;
    }
    try {
      setLoader(true);
      let res = await getTitleLogsMonthlyApi(clientKey, selectedTitle?.id);
      dispatch(
        finalReportFilter({
          filter: {
            title: selectedTitle,
            month: selectedTitle?.month,
            category: selectedTitle?.bu_category,
            subCategory: selectedTitle?.bu_subcategory,
            newCustomer: selectedTitle?.customer_type !== "all",
          },
          type: "pre",
        })
      );

      if (res?.status === 200) {
        if (res.data?.length === 0) {
          setNoDataFound(true);
          setLoader(false);
          setTableData([]);
          setDate(null);
          return;
        }
        let final = returnDataWithTotal(res?.data);
        setTableData(final);
        dispatch(finalReportData({ data: final, type: "pre" }));
        setLoader(false);
      } else {
        message.error("Something went wrong");
        setLoader(false);
        selectedTitle(null);
      }
    } catch (error) {
      setLoader(false);
      console.log("error in finalreport", error);
    }
  };

  const handleChangeCategory = (value) => {
    setSelectedCategories(value);
  };

  const handleChangeSubCategory = (value) => {
    setSelectedSubCategories(value);
  };

  const handleTitleOnChange = (_, selectedObj) => {
    setDate(selectedObj?.month || null);
    setSelectedCategories(selectedObj?.bu_category || []);
    setSelectedSubCategories(selectedObj?.bu_subcategory || []);
    setNewCustomer(selectedObj ? selectedObj?.customer_type !== "all" : false);

    setSelectedTitle(selectedObj);
  };

  const handleFilter = async () => {
    if (!selectedYear) {
      message.error("Please select the Year & Month");
      return;
    }

    try {
      setLoader(true);
      let params = new URLSearchParams();

      selectedCategories?.length > 0 &&
        params.append("bu_category", selectedCategories);
      selectedSubCategories?.length > 0 &&
        params.append("bu_subcategory", selectedSubCategories);
      (date || date == 0) && params.append("month", date);
      params.append("year", selectedYear);
      params.append("client_key", clientKey);
      params.append("user_id", loginData?.id);
      params.append("customer_type", newCustomer ? "new" : "all");

      dispatch(
        finalReportFilter({
          filter: {
            month: date,
            category: selectedCategories,
            subCategory: selectedSubCategories,
            newCustomer: newCustomer,
          },
          type: "cur",
        })
      );

      let res = await proposalsListingApi(clientKey, params);
      if (res?.status === 200) {
        if (res?.data?.length === 0 || res?.data?.current?.length === 0) {
          setNoDataFound(true);
          setLoader(false);
          setTableData([]);
          dispatch(finalReportData({ data: [], type: "cur" }));
          return;
        }
        let final = returnDataWithTotal(res?.data?.current);
        setTableData(final);
        dispatch(finalReportData({ data: final, type: "cur" }));
        setLoader(false);
      } else {
        message.error("Something went wrong");
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log("error in finalreport", error);
    }
  };

  useEffect(() => {
    activeTabInReport && setSelectedTitle(finalReport?.predictedFilter?.title);
    setTableData(data || []);
    setDate(activeFilter?.month || null);
    setSelectedCategories(activeFilter?.category || []);
    setSelectedSubCategories(activeFilter?.subCategory || []);
    setNewCustomer(activeFilter?.newCustomer || false);
  }, [activeTabInReport, activeFilter]);

  useEffect(() => {
    setCategoriesListing([]);
    setSubCategoriesListing([]);
    setTitleOption(null);

    if (clientKey) {
      getCategoriesListing();
      getSubCategoriesListing();
      getTitleListing();
    }
  }, [clientKey]);

  const columns = [
    {
      title: "Category",
      dataIndex: "category_name",
      width: 160,
      fixed: "left",
      render: (val) => <TableText val={val} weight={val == "Total" && 600} />,
    },
    {
      title: "Budget",
      dataIndex: "budget",
      width: 120,
      render: (val, record) => (
        <TableText
          val={"$" + formatInteger(val)}
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "Revenue",
      dataIndex: "sales",
      width: 120,
      render: (val, record) => (
        <TableText
          val={"$" + formatInteger(val)}
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "ROAS",
      dataIndex: "roas",
      width: 80,
      render: (_, record) => (
        <TableText
          val={formatNumberDecimal(
            checkForInfinity(record?.sales / record?.budget)
          )}
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "Leads",
      dataIndex: "leads",
      width: 70,
      render: (val, record) => (
        <TableText
          val={formatInteger(val)}
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "CPL",
      dataIndex: "cpl",
      width: 100,
      render: (_, record) => (
        <TableText
          val={
            "$" +
            formatInteger(checkForInfinity(record?.budget / record?.leads))
          }
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "Appts",
      dataIndex: "appts",
      width: 80,
      render: (val, record) => (
        <TableText
          val={formatInteger(val)}
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "CPA",
      dataIndex: "cpa",
      width: 120,
      render: (_, record) => (
        <TableText
          val={
            "$" +
            formatInteger(checkForInfinity(record?.budget / record?.appts))
          }
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "Conv %",
      dataIndex: "con_per",
      width: 80,
      render: (_, record) => (
        <TableText
          val={
            formatInteger(
              checkForInfinity(record?.appts / record?.leads) * 100
            ) + "%"
          }
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "jobs",
      dataIndex: "jobs",
      width: 80,
      render: (val, record) => (
        <TableText
          val={formatInteger(val)}
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "CPJ",
      dataIndex: "cpj",
      width: 100,
      render: (_, record) => (
        <TableText
          val={
            "$" + formatInteger(checkForInfinity(record?.budget / record?.jobs))
          }
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "Close %",
      dataIndex: "close_per",
      width: 80,
      render: (_, record) => (
        <TableText
          val={
            formatInteger(
              checkForInfinity(record?.jobs / record?.appts) * 100
            ) + "%"
          }
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "Avg Ticket",
      dataIndex: "avg_ticket",
      width: 120,
      render: (_, record) => (
        <TableText
          val={
            "$" + formatInteger(checkForInfinity(record?.sales / record?.jobs))
          }
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
  ];

  return (
    <FinalReportWrapper size={filter}>
      <FilterTabsWrapper position={filter}>
        {filter && (
          <div className="wrap">
            {activeTabInReport && (
              <section>
                <div className="label">Predicted Title</div>
                <Select
                  placeholder="Please select"
                  prefixCls="inner-select2"
                  className="select"
                  loading={titleLoading}
                  onChange={handleTitleOnChange}
                  options={titleOption}
                  value={titleLoading || selectedTitle?.id}
                  size="middle"
                  allowClear
                />
              </section>
            )}
            <section>
              <div className="label">Select Year & Month</div>
              <div className="flex">
                <DatePicker
                  disabled={activeTabInReport}
                  picker="year"
                  value={
                    activeTabInReport
                      ? selectedTitle?.year
                        ? dayjs().year(selectedTitle?.year)
                        : null
                      : selectedYear
                      ? dayjs().year(selectedYear)
                      : null
                  }
                  onChange={(_, dateString) => onYearChange(dateString)}
                  disabledDate={(date) =>
                    date.year() > dayjs().year() || date.year() < dayjs().year()
                  }
                />
                <Select
                  suffixIcon={activeTabInReport || <DownOutlined />}
                  disabled={activeTabInReport}
                  placeholder="Select month"
                  prefixCls="inner-select2"
                  className="select"
                  onChange={onMonthChange}
                  value={date}
                  size="middle"
                  allowClear
                >
                  {months?.map((item) => (
                    <Option key={item?.value} value={item?.value}>
                      <span
                        style={{
                          color:
                            uniqueSelectedMonths?.includes(item?.value) &&
                            item?.value != date &&
                            "green",
                        }}
                      >
                        {item?.label}
                      </span>
                    </Option>
                  ))}
                </Select>
              </div>
            </section>

            <section>
              <div className="label">Category</div>
              <Select
                suffixIcon={activeTabInReport || <DownOutlined />}
                disabled={activeTabInReport}
                placeholder="Please select"
                prefixCls="inner-select2"
                className="select"
                loading={catLoading}
                onChange={handleChangeCategory}
                options={categoriesListing}
                value={selectedCategories || []}
                mode="multiple"
                size="middle"
                maxTagCount={2}
              />
            </section>
            <section>
              <div className="label">Sub-Category</div>
              <Select
                suffixIcon={activeTabInReport || <DownOutlined />}
                disabled={activeTabInReport}
                placeholder="Please select"
                prefixCls="inner-select2"
                className="select"
                loading={subCatLoading}
                onChange={handleChangeSubCategory}
                options={subCategoriesListing}
                value={selectedSubCategories || []}
                mode="multiple"
                maxTagCount={2}
              />
            </section>

            <section className="new-customers">
              <div className="label">New Customers</div>
              <Checkbox
                disabled={activeTabInReport}
                checked={newCustomer}
                style={{ height: "32px" }}
                onChange={(e) => setNewCustomer(e?.target?.checked)}
              />
            </section>

            <button
              className="saveBtn submit"
              onClick={() =>
                !loader &&
                (activeTabInReport ? getDataOFTitle() : handleFilter())
              }
            >
              Submit
            </button>
          </div>
        )}
      </FilterTabsWrapper>
      <div className="Wrap">
        {loader ? (
          <LoadingBlock height={"calc(100vh - 210px)"} size={28} />
        ) : tableData?.length > 0 ? (
          <Table
            prefixCls="custom-final-table-ui"
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        ) : (
          <ApplyFilter
            title="title"
            yearSheetFilter={true}
            message={
              noDataFound &&
              "The prediction data for the specified period does not exist. Please set up the prediction data for the given period."
            }
          />
        )}
      </div>
      {tableData?.length > 0 && !loader && (
        <Watermark
          style={{ paddingBottom: "6px" }}
          background={"transparent"}
        />
      )}
    </FinalReportWrapper>
  );
}
