import { InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatToCurrencyWithDecimal } from "../../exceltable/commonFun";
import {
  calculateTotals,
  checkForInfinity,
  formatInteger,
  formatNumberDecimal,
} from "./commonFuc";
import { useSelector } from "react-redux";

const tabs = ["Goal", "Pacing", "New Goal"];
const labels = [
  "Budget",
  "Revenue",
  "ROAS",
  "Leads",
  "Appts",
  "CPA",
  "Jobs",
  "CPJ",
  "CPL",
  "Conv%",
  "Close%",
  "Avg. Ticket",
];
export default function TotalComponent({
  data,
  activeTab,
  setActiveTab,
  name,
}) {
  const goalPrediction = useSelector((state) => state?.dynamicBudgetSheet?.GoalPrediction);
  const prevDBS = useSelector((state) => state?.dynamicBudgetSheet?.yearData?.prev);
  const [totalData, setTotalData] = useState({
    prev: null,
    current: null,
    duplicate: null,
  });
  const { prev, current, duplicate } = totalData;

  console.log(totalData, "totalData", prevDBS)

  useEffect(() => {
    if (prevDBS?.length > 0) {
      let prevTotal = null;
      if (prevDBS?.length === 1) {
        prevTotal = prevDBS?.[0];
      } else {
        prevTotal = calculateTotals(prevDBS);
      }
      setTotalData((pr) => ({ ...pr, prev: { ...prevTotal } }));
    }
  }, [prevDBS]);
  
  useEffect(() => {
    if (data && data?.length > 0) {
      const parentArray = data?.filter((cat) => cat?.isParent);
      const parentTotal = calculateTotals(parentArray);
      let category = null;
      let duplicateArray = [];

      data?.forEach((cat, index) => {
        if (cat?.isChecked || cat?.isParent) {
          if (category && cat?.category_name !== category?.category_name) {
            duplicateArray.push(category);
          }
          category = cat;
        }
        if (index === data.length - 1 && category) {
          duplicateArray.push(category);
        }
      });
      const duplicateTotal = calculateTotals(duplicateArray);
      console.log(duplicateTotal, "duplicateTotal")
      setTotalData((pr) => ({ ...pr, current: parentTotal, duplicate: duplicateTotal }));
    }
  }, [data]);

  return (
    <TotalWrapper>
      <div className="all-tabs">
        {tabs?.map((el) => (
          <button type="button" className="active">
            {el}
          </button>
        ))}
      </div>

      <Container>
        {/* For Labels */}
        <AdditionalMetrics onlyLabel>
          {labels?.map((el) => (
            <Metric onlyLabel>
              <MetricLabel>{el}</MetricLabel>
            </Metric>
          ))}
        </AdditionalMetrics>

        {/* For Goals */}
        <AdditionalMetrics>
          <Metric>
            {/* <MetricLabel>Budget</MetricLabel> */}
            <MetricValue>${formatInteger(prev?.budget)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>Revenue</MetricLabel> */}
            <MetricValue>${formatInteger(prev?.sales)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>ROAS</MetricLabel> */}
            <MetricValue>
              {formatNumberDecimal(
                checkForInfinity(prev?.sales / prev?.budget)
              )}
            </MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>Leads</MetricLabel> */}
            <MetricValue>{formatInteger(prev?.leads)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>Appts</MetricLabel> */}
            <MetricValue>{formatInteger(prev?.appts)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>CPA</MetricLabel> */}
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(prev?.budget / prev?.appts)
              )}
            </MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>Jobs</MetricLabel> */}
            <MetricValue>{formatInteger(prev?.jobs)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>CPJ</MetricLabel> */}
            <MetricValue>
              $
              {formatInteger(checkForInfinity(prev?.budget / prev?.jobs))}
            </MetricValue>
          </Metric>

          <Metric>
            {/* <MetricLabel>CPL</MetricLabel> */}
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(prev?.budget / prev?.leads)
              )}
            </MetricValue>
          </Metric>

          <Metric>
            {/* <MetricLabel>Conv%</MetricLabel> */}
            <MetricValue>
              {formatInteger(
                checkForInfinity(prev?.appts / prev?.leads) * 100
              )}
              %
            </MetricValue>
          </Metric>

          <Metric>
            {/* <MetricLabel>Close%</MetricLabel> */}
            <MetricValue>
              {formatInteger(
                checkForInfinity(prev?.jobs / prev?.appts) * 100
              )}
              %
            </MetricValue>
          </Metric>

          <Metric>
            {/* <MetricLabel>Avg. Ticket</MetricLabel> */}
            <MetricValue>
              ${formatInteger(checkForInfinity(prev?.sales / prev?.jobs))}
            </MetricValue>
          </Metric>
        </AdditionalMetrics>

        <AdditionalMetrics>
          <Metric>
            {/* <MetricLabel>Budget</MetricLabel> */}
            <MetricValue>${formatInteger(current?.budget)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>Revenue</MetricLabel> */}
            <MetricValue>${formatInteger(current?.sales)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>ROAS</MetricLabel> */}
            <MetricValue>
              {formatNumberDecimal(
                checkForInfinity(current?.sales / current?.budget)
              )}
            </MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>Leads</MetricLabel> */}
            <MetricValue>{formatInteger(current?.leads)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>Appts</MetricLabel> */}
            <MetricValue>{formatInteger(current?.appts)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>CPA</MetricLabel> */}
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(current?.budget / current?.appts)
              )}
            </MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>Jobs</MetricLabel> */}
            <MetricValue>{formatInteger(current?.jobs)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>CPJ</MetricLabel> */}
            <MetricValue>
              $
              {formatInteger(checkForInfinity(current?.budget / current?.jobs))}
            </MetricValue>
          </Metric>

          <Metric>
            {/* <MetricLabel>CPL</MetricLabel> */}
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(current?.budget / current?.leads)
              )}
            </MetricValue>
          </Metric>

          <Metric>
            {/* <MetricLabel>Conv%</MetricLabel> */}
            <MetricValue>
              {formatInteger(
                checkForInfinity(current?.appts / current?.leads) * 100
              )}
              %
            </MetricValue>
          </Metric>

          <Metric>
            {/* <MetricLabel>Close%</MetricLabel> */}
            <MetricValue>
              {formatInteger(
                checkForInfinity(current?.jobs / current?.appts) * 100
              )}
              %
            </MetricValue>
          </Metric>

          <Metric>
            {/* <MetricLabel>Avg. Ticket</MetricLabel> */}
            <MetricValue>
              ${formatInteger(checkForInfinity(current?.sales / current?.jobs))}
            </MetricValue>
          </Metric>
        </AdditionalMetrics>

        <AdditionalMetrics>
          <Metric>
            {/* <MetricLabel>Budget</MetricLabel> */}
            <MetricValue>${formatInteger(duplicate?.budget)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>Revenue</MetricLabel> */}
            <MetricValue>${formatInteger(duplicate?.sales)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>ROAS</MetricLabel> */}
            <MetricValue>
              {formatNumberDecimal(
                checkForInfinity(duplicate?.sales / duplicate?.budget)
              )}
            </MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>Leads</MetricLabel> */}
            <MetricValue>{formatInteger(duplicate?.leads)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>Appts</MetricLabel> */}
            <MetricValue>{formatInteger(duplicate?.appts)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>CPA</MetricLabel> */}
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(duplicate?.budget / duplicate?.appts)
              )}
            </MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>Jobs</MetricLabel> */}
            <MetricValue>{formatInteger(duplicate?.jobs)}</MetricValue>
          </Metric>
          <Metric>
            {/* <MetricLabel>CPJ</MetricLabel> */}
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(duplicate?.budget / duplicate?.jobs)
              )}
            </MetricValue>
          </Metric>

          <Metric>
            {/* <MetricLabel>CPL</MetricLabel> */}
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(duplicate?.budget / duplicate?.leads)
              )}
            </MetricValue>
          </Metric>

          <Metric>
            {/* <MetricLabel>Conv%</MetricLabel> */}
            <MetricValue>
              {formatInteger(
                checkForInfinity(duplicate?.appts / duplicate?.leads) * 100
              )}
              %
            </MetricValue>
          </Metric>

          <Metric>
            {/* <MetricLabel>Close%</MetricLabel> */}
            <MetricValue>
              {formatInteger(
                checkForInfinity(duplicate?.jobs / duplicate?.appts) * 100
              )}
              %
            </MetricValue>
          </Metric>

          <Metric>
            {/* <MetricLabel>Avg. Ticket</MetricLabel> */}
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(duplicate?.sales / duplicate?.jobs)
              )}
            </MetricValue>
          </Metric>
        </AdditionalMetrics>
      </Container>
    </TotalWrapper>
  );
}

const TotalWrapper = styled.div`
  background-color: #373737;
  padding: 10px 12px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
  margin-bottom: 10px;

  .all-tabs {
    width: calc(100% - 90px);
    margin-left: 90px;
    border: 1px solid #d2d4de;
    border-radius: 6px;
    padding: 2px;
    display: flex;
    gap: 5px;
    background: white;
    margin-bottom: 10px;

    button {
      background: #f6f9fc;
      width: 100%;
      text-align: center;
      padding: 5px 20px;
      color: #000;
      border: none;
      border-left: 2px solid white;
      height: 33px;
      font-size: 14px;
      font-family: Poppins;
      font-style: normal;
      border-radius: 6px;
      transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease;
      text-transform: capitalize;
       white-space: nowrap;
      @media (max-width: 1675px) {
        padding: 5px 10px;
      }
    }

    .active {
      background: #979797;
      color: #fff;
      transition: background 0.3s ease, color 0.3s ease;
    }
  }
`;

const Container = styled.div`
  display: flex;
  // flex-direction: column;
  gap: 10px;

  @media (max-width: 1500px) {
    gap: 6px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CategoryName = styled.h2`
  color: #fff;
  margin: 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
`;

const MetricsContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 9px;
  background-color: #f4f4f41a;
`;

const MetricInput = styled.input`
  border: none;
  background-color: #ffffff1f;
  color: #fff;
  width: 150px;

  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  padding: 9px 10px;
  border-radius: 6px;
`;

const Label = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #fff;
  padding-right: 4px;
`;

const AdditionalMetrics = styled.div`
  width: ${({ onlyLabel }) => (onlyLabel ? "320px" : "100%")};
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: transparent;
  border-radius: 8px;
`;

const Metric = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ onlyLabel }) => (onlyLabel ? "11px 10px" : "8px 10px")};
  gap: 0px;
  border-radius: 12px;
  background: ${({ onlyLabel }) => (onlyLabel ? "transparent" : "#ffffff1f")};
  box-shadow: ${({ onlyLabel }) =>
    onlyLabel ? "none" : "0 1px 3px rgba(0, 0, 0, 0.1)"};
  transition: transform 0.2s ease, box-shadow 0.3s ease;
  white-space: nowrap;
  &:hover {
    transform: ${({ onlyLabel }) => (onlyLabel ? "none" : "scale(1.05)")};
    box-shadow: ${({ onlyLabel }) =>
    onlyLabel ? "none" : "0 4px 8px rgba(0, 0, 0, 0.2)"};
  }

  @media (min-height: 1080px) {
    padding: ${({ onlyLabel }) => (onlyLabel ? "15px 10px" : "12px 10px")};
  }
  // @media (max-width: 1504px) {
  //   padding:8px 5px;
  // }
`;

const MetricLabel = styled.span`
  color: #fff;
  // margin-bottom: 5px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
`;

const MetricValue = styled.span`
  color: #fff;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  text-align: center;

  @media (max-width: 1675px) {
    font-size: 12px;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledInputNumber = styled(InputNumber)`
  margin-bottom: 0;
  .ant-input-number {
    border: none;
    background-color: red;
    width: 100px;
    .ant-input-number-input {
      color: #2863e3;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      padding: 9px 10px;
      border: 1px solid #d2d4de;
      border-radius: 6px;
    }
  }
`;
