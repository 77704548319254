import React from "react";
import styled from "styled-components";

export default function Watermark({ title = "Data Source:", sub = "n^sight", style={}, background }) {
  return (
    <div>
      <PoweredBy style={style} background={background}>
        {title} {sub}
      </PoweredBy>
    </div>
  );
}

export const PoweredBy = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  color: rgb(0 0 0 / 60%);
  font-size: 15px;
  gap: 20px;
  align-items: center;
  /* padding:0 5px; */
  background: ${({ background }) => background || "rgb(243, 243, 243)"};

  img {
    opacity: 0.6;
    height: 32px;
  }

  @media(max-width: 1440px){
    font-size: 12px;
  }
`;
