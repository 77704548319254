import React, { useEffect, useState } from "react";
import ComposedGraphComponent from "../../components/ComposedGraph";
import { Skeleton } from "antd";
import { useSelector } from "react-redux";
import { getCampaignVisualizationData } from "../../services/Collections";
import { GraphLoader } from "../../utils/Icons/SvgIcons";
import { SubHeader } from "./Visualization";
import styled from "styled-components";
import moment from "moment";

const colrs = {
  skyBlue: "rgb(44, 175, 254)",
  navyBlue: "rgb(84, 79, 197)",
  green: "rgb(0, 226, 114)",
  orange: "rgb(254, 106, 53)",
  grey: "rgb(107, 138, 188)",
  pink: "rgb(213, 104, 251)",
  seaGreen: "rgb(46, 224, 202)",
  red: "rgb(250, 75, 66)",
  orangeYellow: "rgb(254, 181, 106)",
};

const graphListing = [
  { name: "Existing Customer Rev", color: colrs.skyBlue }, //skyblue
  { name: "Existing Customer Job", color: colrs.skyBlue, disable: true },
  {
    name: "Existing Customer Avg Rev",
    type: "line",
    color: colrs.skyBlue,
    yAxis: "right",
  },
  { name: "Customer Spent", color: colrs.orangeYellow },
  { name: "New Customer Rev", color: colrs.seaGreen },
  { name: "New Customer Job", color: colrs.seaGreen, disable: true },
  {
    name: "New Customer Avg Rev",
    type: "line",
    color: colrs.seaGreen,
    yAxis: "right",
  },
  // { name: "New Customer Spent", color: colrs.navyBlue },
];

const VisualizationCampaignUnit = ({ campaignData, init }) => {
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const [campaignUnit, setCampaignUnit] = useState([]);
  const [loading, setLoading] = useState(false);

  const checkCategory =
    campaignData?.listofsource == "Paid Search" ||
    campaignData?.listofsource == "Paid Social";
  const graphListingNew = checkCategory
    ? graphListing
    : graphListing?.filter((ele) => !ele?.name?.includes("Spent"));

  const getVisualizationDataListing = async (init) => {
    setLoading(true);
    const {
      selectedClient,
      date_start,
      date_end,
      customer_type = "all",
      job_types = [],
    } = init;
    let params = new URLSearchParams();
    params.append("date_start", moment(date_start).format("YYYY-MM-DD"));
    params.append(
      "date_end",
      moment(date_end).endOf("month").format("YYYY-MM-DD")
    );
    params.append("customer_type", customer_type);
    params.append("state", "NY");
    job_types?.map((list) => params.append("job_types", list));
    params.append("campaigns", [campaignData?.listofsource]);

    let res = await getCampaignVisualizationData(selectedClient?.key, params);

    if (res?.status === 200) {
      const { categories, existing_cus, existing_cus_mkt, new_cus } =
        res?.data || {};
      let customize_data = categories?.map((element, index) => {
        let obj = {
          name: element,
          "Existing Customer Rev": existing_cus?.rev[index],
          "Existing Customer Job": existing_cus?.job[index],
          "Existing Customer Avg Rev": existing_cus?.avg_rev[index],
          "Existing Customer with Marketing Rev": existing_cus_mkt?.rev[index],
          "Existing Customer with Marketing Job": existing_cus_mkt?.job[index],
          "Existing Customer with Marketing Avg Rev":
            existing_cus_mkt?.avg_rev[index],
          "New Customer Rev": new_cus?.rev[index],
          "New Customer Job": new_cus?.job[index],
          "New Customer Avg Rev": new_cus?.avg_rev[index],
        };
        if (checkCategory) {
          obj["Customer Spent"] = existing_cus?.spent[index];
        }

        return obj;
      });

      setCampaignUnit(customize_data);
      setLoading(false);
    } else {
      // toast.error(res?.message || res?.error || "Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    getVisualizationDataListing(init);
  }, []);

  return (
    <>
      {loading ? (
        <MapsLoaderWrapper>
          <Skeleton.Node
            active={true}
            style={{ width: "100%", height: "450px", margin: "auto" }}
          >
            <GraphLoader />
          </Skeleton.Node>
        </MapsLoaderWrapper>
      ) : (
        <>
          <SubHeader selectedThemeColors={selectedThemeColors}>
            <div className="filterBtn-Box">
              <h4>
                Campaign : {campaignData?.name || campaignData?.listofsource}
              </h4>
            </div>
          </SubHeader>
          <ComposedGraphComponent
            data={campaignUnit}
            graphListing={graphListingNew}
            yAxis_left_label="REV"
            yAxis_right_label="AVG REV"
            label_prefix="$"
          />
        </>
      )}
    </>
  );
};

export default VisualizationCampaignUnit;

const MapsLoaderWrapper = styled.div`
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 40px 0;
`;
