import {
  Drawer,
  Modal,
  Pagination,
  Select,
  Spin,
  Switch,
  Table,
  Tooltip,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import {
  adminUserList,
  deleteAdminUser,
  deleteSettingsMapping,
  getAllClients,
  getCampaignMapping,
  logoutUsers,
} from "../../services/Collections";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  BillingEditIcon,
  DeleteIcon,
  DeleteNewIcon,
  EditIcon,
  EditNewicon,
  Logouticon,
} from "../../utils/Icons/SvgIcons";
// import EditSettings from "./EditSettings";
import { DeleteModal } from "../../components/DeleteModal";
import {
  reset_loginAuth,
  updateSettingData,
} from "../../app/Auth/signIn/LogInSlice";
import CreateAdminUser from "./CreateAdmin";
import { PermissionModal } from "./PermissionModal";
import { debounce } from "../../utils/common/commonFunction";
import { TitleWrapper } from "../manageYelpUser/ManageYelpUser";
import { Sort, SortAsc, SortDesc } from "../../utils/Images/Images";
import { reset_AiBookingSlice } from "../AIBooking/AiBookingSlice";
import { resetCampaignslice } from "../campaignTable/CampaignSlice";
import { resetLsaPerformance } from "../PaidSearch/LsaPerformanceStore";
import { useNavigate } from "react-router-dom";
import { resetDynamicBudgetSlice } from "../exceltable/ExcelStore";
import Header from "../../components/Header";
import LoadingBlock from "../../components/LoadingBlock";
import { resetCapacitySlice } from "../capacityMarketing/CapacitySlice";
import { resetQualitySlice } from "../qualityAssurance/QualityAssSlice";
import { resetBudgetTrckerSlice } from "../BudgetTracker/BudgetTrckerSlice";
import { resetNotificationSlice } from "../NotificationCenter/NotificationSlice";

const permissionOptions = [
  {
    label: "Dashboard",
    value: "dashboard",
  },
  {
    label: "Marketing Tracker",
    value: "data-table",
  },

  {
    label: "Attribution Analyzer",
    value: "campaign-table",
  },
  {
    label: "Performance Metrics",
    value: "report-table",
  },
  {
    label: "Visualization",
    value: "visualization",
  },
  {
    label: "Call classification",
    value: "call-classification",
  },
  // {
  //   label: "Dynamic Budget Sheet",
  //   value: "dynamic-budget-sheet",
  // },
  {
    label: "Paid + LSA Perf.",
    value: "paid-search",
  },

  {
    label: "Get Leverage",
    value: "ai-booking-stats",
  },
  {
    label: "Admin Users",
    value: "admin-users",
  },
  {
    label: "Settings",
    value: "settings",
  },
  {
    label: "Quality Assurance",
    value: "quality-assurance",
  },
  {
    label: "Capacity Marketing",
    value: "capacity-marketing",
  },
  {
    label: "Notification Center",
    value: "notification-center",
  },
  {
    label: "Budget Tracker",
    value: "budget-tracker",
  },
  // {
  //   label: "Booking Buddy Settings",
  //   value: "booking_buddy_settings",
  // },
];

const AdminList = () => {
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [allLogoutUser, setAllLogoutUser] = useState(false);
  const userDetails = useSelector((state) => state?.loginAuth?.data);
  const filterData = useSelector((state) => state?.loginAuth?.settingData);
  const [defaultValue, setDefaultValue] = useState(
    filterData ? filterData : prefilledData?.selectedClient
  );
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [clientOptions, setclientOptions] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [editDetails, setEditDetails] = useState(null);
  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [permissionData, setPermissionData] = useState(null);
  const [openDbModal, setOpenDbModal] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [extraData, setExtraData] = useState(0);
  const [searchWord, setSearchWord] = useState("");
  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
  });

  const [selectedPermission, setSelectedPermission] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 40,
      }}
      spin
    />
  );

  const getLabelFromClients = (allocateddb) => {
    return (clientOptions ?? [])
      ?.filter((item) => allocateddb?.includes(item?.db_name))
      ?.map((item) => item?.label);
  };

  const handleCloseDrawer = () => {
    setOpenFilterDrawer(false);
    setDefaultValue(filterData ? filterData : prefilledData?.selectedClient);
  };

  const getSortParam = (sortBasis) => {
    setPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "desc" ? "asc" : "desc",
        sortBasis,
      });
    } else {
      setSortParam({
        sortType: "desc",
        sortBasis,
      });
    }
  };

  const srcSortImage = (Basis) => {
    if (Basis === sortParam.sortBasis) {
      if (sortParam.sortType === "asc") {
        return SortAsc;
      }
      return SortDesc;
    }
    return Sort;
  };

  const columns = [
    {
      title: "Sr No.",
      dataIndex: "index",
      width: 100,
      fixed: "left",
      render: (val) => (
        <p style={{ margin: 0, textTransform: "capitalize" }}>{val}.</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 100,
      render: (val) => <p style={{ margin: 0, whiteSpace: "nowrap" }}>{val}</p>,
    },
    {
      title: "Clients",
      dataIndex: "allocated_db",
      width: 100,
      render: (val) => (
        <p
          style={{
            cursor: "pointer",
            margin: "0px",
            whiteSpace: "nowrap",
          }}
          onClick={() => {
            if (val?.length > 0) {
              setOpenDbModal(true);
              setPermissionData(
                getLabelFromClients(val)?.length > 0
                  ? getLabelFromClients(val)
                  : []
              );
            }
          }}
        >{`${
          getLabelFromClients(val)?.[0] ? getLabelFromClients(val)?.[0] : ""
        } ${
          getLabelFromClients(val)?.length > 1
            ? ` + ${getLabelFromClients(val)?.length - 1}`
            : ""
        }`}</p>
      ),
    },
    {
      title: "Permission",
      dataIndex: "allocated_boards",
      width: 100,
      render: (val) => (
        <p
          style={{
            cursor: "pointer",
            margin: "0px",
          }}
          onClick={() => {
            if (val?.length > 0) {
              setOpenPermissionModal(true);
              setPermissionData(getMatchedLabels(permissionOptions, val));
            }
          }}
        >{`${
          getMatchedLabels(permissionOptions, val)?.[0]
            ? getMatchedLabels(permissionOptions, val)?.[0]
            : ""
        } ${
          getMatchedLabels(permissionOptions, val)?.length > 1
            ? `, +${getMatchedLabels(permissionOptions, val)?.length - 1}`
            : ""
        }`}</p>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <span>Status</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "active" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("active")}
            onClick={() => getSortParam("active")}
          />
        </TitleWrapper>
      ),
      dataIndex: "active",
      width: 100,
      render: (val, data) => (
        <div>
          {/* <ConfigProvider
            theme={{
              components: {
                Switch: {
                  colorPrimary: "#296eff",
                  handleBg: "#fff",
                  colorPrimaryHover: "#296eff",
                  colorTextQuaternary: "#2c2c2c",
                  colorTextTertiary: "#2c2c2c",
                },
              },
            }}
          >
            <Switch
              prefixCls="custom-switch"
              style={{ width: "50px" }}
              value={val}
              // onChange={(e) =>
              //   handleActiveDeactiveUser(e?.target?.checked, data)
              // }
            />
          </ConfigProvider> */}
          <p style={{ margin: 0 }}>{val ? "Active" : "Deactive"}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, data) => (
        <div style={{ whiteSpace: "nowrap", display: "flex", gap: "20px" }}>
          <Tooltip title="Edit">
            <span
              onClick={() => {
                setEditUserModal(true);
                setEditDetails(data);
              }}
              style={{ cursor: "pointer" }}
            >
              <EditNewicon />
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <span
              onClick={() => {
                setDeleteModal(true);
                setEditDetails(data);
              }}
              style={{ cursor: "pointer" }}
            >
              <DeleteNewIcon />
            </span>
          </Tooltip>
          <Tooltip title="Logout">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setLogoutModal(true);
                setEditDetails(data);
              }}
            >
              <Logouticon />
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleClientOptions = async () => {
    // setOptionsLoading(true);
    let res = await getAllClients();
    if (res?.status === 200) {
      let arr = [];
      res?.data?.length > 0 &&
        // eslint-disable-next-line array-callback-return
        res?.data?.map((ele) => {
          let obj = {
            ...ele,
            label: ele?.name,
            value: ele?.db_name,
          };
          arr.push(obj);
        });
      setclientOptions(arr);
      // setOptionsLoading(false);
    } else {
      console.log(res);
      // setOptionsLoading(false);
    }
  };

  const onChangePagination = (e, limit) => {
    setPage(e);
    setPageSize(limit);
    handleList(searchWord, e, limit);
  };

  const getMatchedLabels = (permissionOptions, apiValues) => {
    return apiValues?.length > 0
      ? apiValues
          ?.map((value) => {
            const matchedOption = permissionOptions?.find(
              (option) => option?.value === value
            );
            return matchedOption ? matchedOption?.label : null;
          })
          .filter(Boolean)
      : [];
  };

  const FilterComponent = () => {
    return (
      <div className="campaign-filters">
        <div className="title">
          <h4 style={{ color: "rgba(25, 25, 25, 1)" }}>Filters</h4>
        </div>

        <div className="content">
          <div className="datepicker-box">
            <div style={{ marginTop: "20px" }} className="input-box">
              <label>
                <strong>Client</strong>
              </label>
              <br />
              <Select
                size={"large"}
                prefixCls="inner-select2"
                value={defaultValue}
                onChange={(_, data) => setDefaultValue(data)}
                style={{
                  width: "100%",
                  height: "48px",
                  marginTop: "6px",
                }}
                maxTagCount={1}
                placeholder="Please select"
                options={clientOptions}
              />
            </div>

            <div className="button-wrap">
              <button
                onClick={() => {
                  dispatch(updateSettingData(defaultValue));
                  handleList();
                  setOpenFilterDrawer(false);
                }}
              >
                <b>Apply</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleLogout = async () => {
    // requestNotificationPermission(userDetails);
    navigate("/");
    dispatch(resetLsaPerformance());
    dispatch(resetDynamicBudgetSlice());
    dispatch(resetCampaignslice());
    dispatch(reset_AiBookingSlice());
    dispatch(reset_loginAuth());
    dispatch(resetCapacitySlice());
    dispatch(resetQualitySlice());
    dispatch(resetBudgetTrckerSlice());
    dispatch(resetNotificationSlice());
  };

  const handleList = async (search, currentPage, currentLimit, reset) => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("include_inactive", true);
    params.append("page", currentPage || page);
    params.append("page_size", currentLimit || pageSize);
    sortParam?.sortBasis && params.append("order_by", sortParam?.sortBasis);
    sortParam?.sortType && params.append("order_by_dir", sortParam?.sortType);

    if (search !== null || search !== "") {
      if (search === undefined) {
        if (searchWord) params.append("search", searchWord);
      } else {
        search && params.append("search", search);
      }
    }
    if (selectedPermission?.length > 0 && !reset) {
      selectedPermission?.forEach((ele) => {
        params.append("boards", ele?.value);
      });
    }

    let res = await adminUserList(params?.toString());
    if (res.status == 200) {
      setExtraData(res?.total);
      setLoading(false);
      setIsDrawerOpen(false);
      let arr = [];
      res?.data?.length > 0 &&
        res?.data?.map((el) => {
          let obj = {
            index:
              (currentLimit || pageSize) * ((currentPage || page) - 1) +
              (1 + arr?.length),
            ...el,
          };
          arr.push(obj);
        });
      setTableData(arr);
    } else {
      if (res?.response?.status == 401) {
        handleLogout();
        navigate("/");
        setLoading(false);
      }

      setLoading(false);
      setIsDrawerOpen(false);
    }
  };

  const handleSearchListing = useCallback(debounce(handleList, 1000), [
    selectedPermission,
    sortParam,
  ]);

  const handleSearch = (value) => {
    setSearchWord(value);
    handleSearchListing(value, 1, pageSize);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    let res = await deleteAdminUser(editDetails?.id);
    if (res?.status == 200 || res?.status == 201) {
      toast.success("Deleted Successfully");
      setDeleteLoading(false);
      setDeleteModal(false);
      handleList();
    } else {
      setDeleteLoading(false);
      // toast.error(
      //   res?.response?.data?.message ||
      //     res?.message ||
      //     res?.error ||
      //     "Something went wrong"
      // );
    }
  };

  const handleLogoutUsers = async (type) => {
    setLogoutLoading(true);
    let payload = {
      user_id: editDetails?.id,
    };
    let res = await logoutUsers(!allLogoutUser && payload);
    if (res?.status === 200) {
      if (!type) {
        toast.success("Logged out successfully");
      }

      setLogoutModal(false);
      setLogoutLoading(false);
      if (editDetails?.id === userDetails?.id) {
        handleLogout();
      }
      if (allLogoutUser) {
        handleLogout();
      }
    } else {
      setLogoutLoading(false);
      // toast.error(
      //   res?.response?.data?.message || res?.response || "Something went wrong"
      // );
    }
  };

  const handleFilterSubmit = () => {
    setPage(1);
    setSearchWord("");
    handleList("", 1, pageSize);
  };
  const handleFilterReset = () => {
    setPage(1);
    setSearchWord("");
    setSelectedPermission([]);
    setIsDrawerOpen(false);
    handleList("", 1, pageSize, true);
  };

  useEffect(() => {
    handleClientOptions();
  }, []);

  useEffect(() => {
    handleList();
  }, [sortParam]);

  return (
    <>
      <Header
        title="Admin Users"
        isFilter={true}
        openFilter={() => setIsDrawerOpen(!isDrawerOpen)}
        // clientInfo={activeClient}
        // openFilter={() => setOpenDrawer(true)}
      />
      <TableWrapper selectedThemeColors={selectedThemeColors}>
        {deleteModal && (
          <DeleteModal
            handleClose={() => {
              setDeleteModal(false);
              setAllLogoutUser(false);
            }}
            open={deleteModal}
            title="Are you sure you want to Delete ?"
            description=""
            button="Delete"
            btnColor="red"
            handleSubmit={handleDelete}
            isLoading={deleteLoading}
          />
        )}

        {logoutModal && (
          <DeleteModal
            handleClose={() => {
              setLogoutModal(false);
              setAllLogoutUser(false);
            }}
            open={logoutModal}
            title={`Are you sure you want to Logout ${
              allLogoutUser ? "All Users" : editDetails?.email
            } ?`}
            description={`Logging out will sign out ${
              allLogoutUser ? "all users" : "this user"
            } from all logged-in devices.`}
            button="YES"
            btnColor="red"
            handleSubmit={handleLogoutUsers}
            isLoading={logoutLoading}
          />
        )}

        {openPermissionModal && (
          <PermissionModal
            isVisible={openPermissionModal}
            onClose={() => setOpenPermissionModal(false)}
            scheduleData={permissionData}
            type="Permissions :"
          />
        )}
        {openDbModal && (
          <PermissionModal
            isVisible={openDbModal}
            onClose={() => setOpenDbModal(false)}
            scheduleData={permissionData}
            type="Clients :"
          />
        )}

        {createUserModal && (
          <CreateAdminUser
            open={createUserModal}
            handleClose={() => setCreateUserModal(false)}
            modalType="add"
            listApi={handleList}
          />
        )}

        {editUserModal && (
          <CreateAdminUser
            open={editUserModal}
            handleClose={() => setEditUserModal(false)}
            modalType="edit"
            editDetails={editDetails}
            listApi={handleList}
            handleLogoutUsers={handleLogoutUsers}
          />
        )}
        {/* {editDrawer && (
        <Drawer
          className="filter-drawer"
          placement="right"
          closable={false}
          onClose={() => setEditDrawer(false)}
          open={editDrawer}
          width={"600px"}
          key="bottom"
        >
          <EditSettings
            handleListing={handleList}
            setFilterDrawer={setEditDrawer}
            editData={editData}
          />
        </Drawer>
      )} */}
        {isDrawerOpen && (
          <Drawer
            className="filter-drawer"
            placement="right"
            closable={false}
            onClose={() => setIsDrawerOpen(false)}
            open={isDrawerOpen}
            width={"400px"}
            key="bottom"
          >
            <FilterStyle selectedThemeColors={selectedThemeColors}>
              <div className="title">
                <h3>Filters</h3>
              </div>
              <div className="content">
                <div style={{ margin: "20px 0" }} className="select-wrap">
                  <label>
                    <strong>Permissions</strong>
                  </label>
                  <br />
                  <Select
                    mode="multiple"
                    prefixCls="inner-select2"
                    allowClear
                    maxTagCount={1}
                    style={{ width: "100%", height: "46px", marginTop: "10px" }}
                    value={selectedPermission}
                    onChange={(_, data) => setSelectedPermission(data)}
                    placeholder="Please select"
                    options={permissionOptions}
                  />
                </div>

                {loading ? (
                  <LoadingButton selectedThemeColors={selectedThemeColors}>
                    <LoadingBlock
                      height="fit-content"
                      size={28}
                      onlySpinner={false}
                      color={"#fff"}
                    />
                  </LoadingButton>
                ) : (
                  <div className="wrap-btns">
                    <button
                      style={{ background: "#000" }}
                      className="btn-wrap"
                      onClick={handleFilterReset}
                    >
                      <b>Reset</b>
                    </button>
                    <button className="btn-wrap" onClick={handleFilterSubmit}>
                      <b>Submit</b>
                    </button>
                  </div>
                )}
              </div>
            </FilterStyle>
          </Drawer>
        )}

        {openFilterDrawer && (
          <Drawer
            className="filter-drawer"
            placement="right"
            closable={false}
            onClose={() => handleCloseDrawer()}
            open={openFilterDrawer}
            width={"400px"}
            key="bottom"
          >
            <FilterComponent />
          </Drawer>
        )}

        {viewModal && (
          <Modal
            open={viewModal}
            centered
            width={448}
            footer={false}
            onCancel={() => setViewModal(false)}
          >
            <div className="viewModal">
              {viewData?.length > 0 &&
                viewData.map((el, index) => (
                  <div>
                    <span>
                      {index + 1}. {el}
                    </span>
                    <br />
                  </div>
                ))}
            </div>
          </Modal>
        )}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div></div>
          {/* <h3 className="client-name">Client Name : {defaultValue?.value}</h3> */}
          <div className="filterBtn-Box">
            <div>
              <form autoComplete="off" onSubmit={(e) => e?.preventDefault()}>
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    e?.preventDefault();
                    setPage(1);
                    handleSearch(e?.target?.value);
                  }}
                  value={searchWord}
                  autoComplete="off"
                  disabled={loading}
                  style={{ cursor: loading && "not-allowed" }}
                />
              </form>
            </div>
            <button
              onClick={() => {
                setLogoutModal(true);
                setAllLogoutUser(true);
              }}
            >
              Logout All Users
            </button>
            <button onClick={() => setCreateUserModal(true)}>Create</button>
            {/* <button onClick={() => setOpenFilterDrawer(true)}>Filters</button> */}
          </div>
        </div>

        {loading ? (
          <LoadingBlock height={"calc(100vh - 200px)"} size={28} />
        ) : (
          <div className="">
            {/* {openDrawer && (
            <Drawer
              className="filter-drawer"
              placement="right"
              closable={false}
              onClose={() => setFilterDrawer(false)}
              open={openDrawer}
              width={"600px"}
              key="bottom"
            >
              <AddSettings
                handleListing={handleList}
                setFilterDrawer={setFilterDrawer}
              />
            </Drawer>
          )} */}

            <Table
              prefixCls="custom-ui-table"
              columns={columns}
              dataSource={tableData}
              pagination={false}
              scroll={{
                x: "100%",
              }}
            />
            {!loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "10px",
                }}
              >
                {/* <Pagination
                // prefixCls="interaction-pagination-night"
                current={page}
                onChange={onChangePagination}
                total={extraData}
                defaultPageSize={pageSize}
                className="pagination"
                showSizeChanger
              /> */}
                <Pagination
                  hideOnSinglePage
                  page={page}
                  limit={pageSize}
                  current={page}
                  onChange={onChangePagination}
                  total={extraData}
                  defaultPageSize={pageSize}
                  className="pagination"
                  showSizeChanger={true}
                  style={{ paddingBottom: "20px" }}
                />
              </div>
            )}
          </div>
        )}
      </TableWrapper>
    </>
  );
};

export default AdminList;

const TableWrapper = styled.div`
  background: ${({ selectedThemeColors }) =>
    selectedThemeColors?.bgMainColor} !important;
  width: 100%;
  height: calc(100vh - 82px);
  padding: 20px !important;

  .client-name {
    color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.textPrimaryColor};
  }

  .filterBtn-Box {
    button {
      background: ${({ selectedThemeColors }) =>
        selectedThemeColors?.headerColor};
      height: 33px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      border-radius: 7px;
      color: white;
      font-weight: 400;
      padding: 4px 11px;
      border-style: none;
    }

    input {
      height: 33px;
      width: 100%;
      background: #ffff;
      border-radius: 7px;
      border: 1px solid #cecece;
      padding: 0 10px;
      color: #000;
      font-family: "Poppins", Arial, sans-serif;

      ::-webkit-input-placeholder {
        color: #000 !important;
      }

      &::placeholder {
        color: #cecece !important;
      }

      &:focus {
        outline: none;
        border: 1px solid #000;
      }
    }
  }

  .campaignTable {
    .campaignTable-thead {
      .campaignTable-cell {
        background: ${({ selectedThemeColors }) =>
          selectedThemeColors?.headerColor} !important;
      }
    }
    .campaignTable-tbody {
      .campaignTable-cell {
        border-bottom: 1px solid rgba(232, 232, 237, 1) !important;
        background: ${({ selectedThemeColors }) =>
          selectedThemeColors?.CardsColor} !important;
        color: ${({ selectedThemeColors }) =>
          selectedThemeColors?.textPrimaryColor} !important;
      }
      .campaignTable-cell-row-hover {
        background: rgba(241, 241, 241, 1) !important;
      }
    }
    .campaignTable-container {
      max-height: calc(100vh - 200px) !important;
      overflow: auto !important;
    }
    .campaignTable-thead {
      position: sticky !important;
      top: 0 !important;
      z-index: 99 !important;
    }
  }
  .pagination {
    .ant-pagination-item {
      color: ${({ selectedThemeColors }) =>
        selectedThemeColors?.textPrimaryColor} !important;
    }
    .ant-pagination-item-active {
      border-color: ${({ selectedThemeColors }) =>
        selectedThemeColors?.textPrimaryColor};
    }
    .anticon-double-right {
      color: #fff !important;
    }
    .ant-select-selection-item {
      background: ${({ selectedThemeColors }) =>
        selectedThemeColors?.headerColor} !important;
      color: white !important;
    }
    .ant-select-item-option-selected {
      background: rgb(207 199 199) !important;
      .ant-select-item-option-content {
        background: transparent !important;
      }
    }
  }
`;

const FilterStyle = styled.div`
  width: 100%;
  height: 100%;

  .wrap-btns {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .btn-wrap {
    width: 100%;
    border-style: none;
    background-color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.buttonColor};
    border-radius: 7px;
    padding: 10px 15px;
    color: #fff;
  }
  .title {
    width: 100%;
    margin-bottom: 20px;

    h3 {
      font-size: 24px;
      color: rgba(25, 25, 25, 1);
    }
  }
  .content {
    width: 100%;
    padding-bottom: 20px;

    label {
      color: ${({ selectedThemeColors }) => selectedThemeColors?.lightText};
      font-size: 15px;
    }
    .select-wrap {
      margin-top: 10px;
    }
  }
`;
const LoadingButton = styled.p`
  margin-top: 30px;
  width: 100%;
  border-style: none;
  background-color: ${({ selectedThemeColors }) =>
    selectedThemeColors?.buttonColor};
  border-radius: 7px;
  padding: 10px 15px;
  color: #fff;

  text-align: center;
`;
