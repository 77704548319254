import { message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ModalLoader } from "../../../components/Loader";
import {
  getListingGoalHistoryApi,
  goalPredictionApi,
  setMarkAsDefaultGoalApi,
} from "../../../services/Collections";
import { IconWrapper } from "../YearHistory";
import { Calendar, Clock } from "lucide-react";
import { dateFunc, timeFunc } from "../../../utils/common/commonFunction";
import {
  activeGoalHistory,
  updateGoalFilters,
} from "../../exceltable/ExcelStore";
import { toast } from "react-toastify";
import { GoalHistoryCrossIcon } from "../../../utils/Icons/SvgIcons";
import LoadingBlock from "../../../components/LoadingBlock";

const GoalsHistory = ({ closeDrawer }) => {
  const dispatch = useDispatch();
  const newFilters = useSelector(
    (state) => state?.loginAuth?.summarySectionFilter?.selectedClient
  );
  const prefilled = useSelector(
    (state) => state?.loginAuth?.prefilledFilters?.selectedClient
  );
  const activeClient = newFilters ? newFilters : prefilled;
  const activeHistory = useSelector((state) => state?.dynamicBudgetSheet);
  const loginAuth = useSelector((state) => state?.loginAuth);

  const [sheetHistory, setSheetHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultModal, setDefaultModal] = useState(false);
  const [user, setUser] = useState(null);

  const [selectedBox, setSelectedBox] = useState(
    activeHistory?.selectedGoalHistory
  );

  const handleBoxClick = (id, data) => {
    if (id === selectedBox) {
      setSelectedBox(null);
      dispatch(updateGoalFilters({}));
      dispatch(activeGoalHistory(null));
    } else {
      setSelectedBox(id);
      dispatch(updateGoalFilters(data));
      dispatch(activeGoalHistory(id));
      closeDrawer(false)
    
    }
  };

  const handleDefault = (e, user) => {
    e.stopPropagation();
    setDefaultModal(true);
    setUser(user);
  };
  
  const handleMark = () => {
    setDefaultModal(false);
    handleMarkAsDefault();
  };

  const handleMarkAsDefault = async () => {
    try {
      setLoading(true);
      let params = new URLSearchParams();
      params.append("history", user?.id);
      params.append("client_key", activeClient?.key);
      let res = await setMarkAsDefaultGoalApi(
        activeClient?.key,
        user?.id,
        params
      );
      if (res?.status === 200) {
        toast.success("Mark as default updated successfully");
        // handleBoxClick(user?.id, user?.current_budget_sheet);
        handleSheetListing();
        handleSaveSheet(user);
      } else {
        setLoading(false);
        message.error("Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  const handleSaveSheet = async (history) => {
    try {
      let payload = {
        year: history?.current_budget_sheet?.year,
        user_id: loginAuth?.data?.id,
        current_budget_sheet: history?.current_budget_sheet,
        current_budget_per: 0,
        current_budget: 0,
        current_revenue: 0,
        expected_budget_sheet: history?.expected_budget_sheet,
        expected_budget_per: 0,
        expected_budget: 0,
        expected_revenue_goal: 0,
        expected_increase_revenue: 0,
      };

      let params = new URLSearchParams();
      params.append("client_key", activeClient?.key);

      let predictionRes = await goalPredictionApi(
        activeClient?.key,
        params,
        payload
      );
      setUser(null);
      if (predictionRes?.status === 200) {
        dispatch(updateGoalFilters(history?.current_budget_sheet));
        closeDrawer(false);
        // message.success("Saved successfully");
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.log("error in businessUnitSubCategories", error);
    }
  };

  const handleSheetListing = async () => {
    try {
      setLoading(true);
      let params = new URLSearchParams();
      params.append("user_id", loginAuth?.data?.id);
      params.append("default", 0);
      params.append("client_key", activeClient?.key);
      let res = await getListingGoalHistoryApi(activeClient?.key, params);
      if (res?.status === 200) {
        setSheetHistory(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        message.error("Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  useEffect(() => {
    handleSheetListing();
  }, []);

  return (
    <HistoryStyle>
      {defaultModal && (
        <Modal open={defaultModal} footer={false} prefixCls="goalHistory" closable={false}>
          <ModalWrapper>
            <p>Are you sure you want to mark this record as a default ?</p>
            <div className="btnWrapper">
              <button className="cancel" onClick={() => setDefaultModal(false)}>
                No
              </button>
              <button className="submit" onClick={handleMark}>
                Yes
              </button>
            </div>
          </ModalWrapper>
        </Modal>
      )}
      <div className="history">
        <div className="header">
          <div className="title">Goal History</div>
          <i style={{ cursor: "pointer" }} onClick={() => closeDrawer(false)}>
            <GoalHistoryCrossIcon />
          </i>
        </div>
        {loading ? (
          <div className="loadingWrapper">
              <LoadingBlock height={"fit-content"} size={28} />
          </div>
        ) : (
          <Container>
            {sheetHistory?.length > 0 ? (
              sheetHistory?.map((user, i) => (
                <>
                  {i == 0 && <hr></hr>}
                  <Box
                    active={selectedBox == user?.id}
                    onClick={() =>
                      handleBoxClick(user?.id, user?.current_budget_sheet)
                    }
                    key={user?.id}
                  >
                    <UserName>{user?.name}</UserName>
                    <Email>{user?.user?.email}</Email>
                    <div className="defaultSection">
                      <div className="dateTimeWrap gap">
                        <div className="dateTimeWrap">
                          <IconWrapper>
                            <Calendar color="#7A7B7A" size={18} />
                          </IconWrapper>
                          <Email style={{ color: "#7A7B7A" }}>
                            {dateFunc(user.save_date)}
                          </Email>
                        </div>
                        <div className="dateTimeWrap">
                          <IconWrapper>
                            <Clock color="#7A7B7A" size={18} />
                          </IconWrapper>
                          <Email style={{ color: "#7A7B7A" }}>
                            {timeFunc(user.save_date)}
                          </Email>
                        </div>
                      </div>

                      <DefaultButton
                        active={user?.mark_as_default == 0 ? false : true}
                        onClick={(e) =>
                          user?.mark_as_default == 0 && handleDefault(e, user)
                        }
                      >
                        {user?.mark_as_default ? "Default" : "Set as Default"}{" "}
                      </DefaultButton>
                    </div>
                  </Box>
                  <hr></hr>
                </>
              ))
            ) : (
              <div className="noData">No Data Found</div>
            )}
          </Container>
        )}
      </div>
    </HistoryStyle>
  );
};

export default GoalsHistory;

export const ModalWrapper = styled.div`
  margin: 10px 0;
  p {
    text-align: center;
    margin: 0 0 5px 0;
    font-size: 16px;
    font-weight: 600;
  }
  .btnWrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;

    button {
      background: #ffffff;
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.88);
      border: 1px solid transparent;
      padding: 5px 40px;
      border-radius: 10px;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
      color: white;
    }
    .cancel {
      background: black;
      }
      .submit {
        background: rgb(216, 12, 12);
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  hr {
    margin: 0;
  }
`;

export const Box = styled.div`
  padding: 12px;
  border-left: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  border: ${({ active }) => active && "1px solid black"};
`;

export const UserName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 6px;
  color: #0f1011;
`;

export const Email = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
`;

export const DefaultButton = styled.div`
  width: fit-content;
  height: 24px;
  padding: 5px 12px;
  gap: 6px;
  border-radius: 64px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  cursor: pointer;
  color: ${({ active }) => (active ? "#544fc5" : "#00A1E6")};
  text-decoration: ${({ active }) => (active ? "none" : "underline")};
  background: ${({ active }) => (active ? "#E5E3FF" : "transparent")};
`;

export const HistoryStyle = styled.div`

  position: relative;
  z-index: 9999;

  .loadingWrapper {
    width: 100%;
    height: calc(100vh - 110px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .historyWrapper {
    display: flex;
    gap: 10px;
  }

  .title {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 400px;
    line-height: 16px;
    height: 35px;
  }

  .nameField {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .name {
    width: 35px;
    height: 35px;
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: white;
    font-size: 18px;
  }

  .saveBtn {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-style: none;
    font-size: 13px;
    border-radius: 10px;
    cursor: pointer;
    font-family: Poppins;
    width: 100%;
    border-radius: 0px;
    border-bottom: 1px solid gray;
    margin-bottom: 5px;

    span {
      font-size: 16px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: 5px;
      height: 35px;
    }
  }

  .historyWrapper {
    display: flex;
    gap: 10px;
  }

  .history {
    min-width: 160px;
    overflow: auto;
    background: white;
  }

  .noData {
    color: #777070;
    font-size: 18px;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .defaultSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    gap: 16px;
  }
  .dateTimeWrap {
    display: flex;
    align-items: center;
  }
  .gap {
    gap: 16px;
  }
`;
