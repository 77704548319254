import {
  deleteApiExternal,
  getApiExternal,
  patchApiExternal,
  postApiExternal,
  putApiExternal,
} from "./ExternalCxcTeam/ApiMethodForExternal";
import ExternalApi from "./ExternalCxcTeam/Interceptor";

const FILTERS = "clients";
const CAMPAIGN_LIST = "/clients";
const CAMPAIGN_TABLE = "custom/callrail/servicetitan-mapping";
const CAMPAIGN_TABLE_EXPORT = "custom/callrail/servicetitan-mapping/export";
const GET_CAMPAIGN_LIST = "/settings/mapping/campaigns/servicetitan";
const GET_BUSINESS_UNIT_LIST = "/settings/mapping/businesses/servicetitan";
const GET_CAMPAIGN_LIST_BY_PLATFORM = "/settings/mapping/campaigns/platforms";
const ADD_CAMPAIGN_MAPPING = "/settings/mapping";
const GET_CAMPAIGN_LIST_MAPPING = "/settings/mapping";
const DELETE_SETTINGS_MAPPING_LIST = "/settings/mapping";
const INBOUNDED_CALLS_CLASSIFICATION =
  "custom/callrail/inbound-call-classification";
const AI_CALL_CLASSIFICATION = "custom/callrail/ai-call-classification";
const WEEK_OVER_WEEK = "custom/callrail/weekly-report";
const TOTAL_BOOKING = "custom/callrail/total-booking";
const DATA2_LIST = "report";
const GET_PAID_SEARCH_PERFORMANCE = "report/get-paid-search-performance";
const UPDATE_PAID_SEARCH = "report/update-paid-search-performance";
const GET_TOTAL_SPEND_DATE = "report/get-total-spend-by-date";
const GET_EXPIRED_COMPANY_LIST = "start_dates";
const GET_EXPORT_USERS = "export";

// Admin User Api's
const CREATE_ADMIN_USER = "users";
const UPDATE_ADMIN_USER = "users";

// Auth Apis
const LOGIN_USER_NEW = "users/login";
const CHANGE_PASSWORD_NEW = "users/change-password";
const RESET_PASSWORD_NEW = "users/auth/forgot-password";
const SET_NEW_PASSWORD = "users/auth/reset-password";
const GOOGLE_AUTH_LOGIN = "users/google-login";
const IS_USER_DELETED = "users";

//budget sheet
const BUSINESS_UINT_CATEGORIES =
  "dbs/settings/business-units/servicetitan/categories";
const BUSINESS_UINT_SUBCATEGORIES =
  "dbs/settings/business-units/servicetitan/sub-categories";
const PROPOSALS = "dbs/budgets/monthly";
const PROPOSALSHISTORY = "dbs/budgets/history";
const GOALHISTORY = "dbs/goals/history";
const GOALMARKASDEFAULT = "as-default";
const GOALPREDICTION = "dbs/goals/prediction";
const STATICDATA = "dbs/static-data";
const GETGOALS = "dbs/goals";

const SOURCE_LIST = "/settings/mapping/campaigns/sources";
const AI_COMPANT_LIST = "aibookingbuddy/company";
const AI_COMPANT_STATISTICS = "aibookingbuddy/statistics";
const AI_GET_MESSAGES = "aibookingbuddy/messaging";
const GET_YELP_USER_LIST = "/aibookingbuddy/yelp/users";
const BOOKING_LIST = "booking";
const GET_USERS_LIST = "users";
const AI_GET_USERS_LIST = "aibookingbuddy/users";
const PERFORMACE_LIST = "report/clients/performance/metrics";
const GET_ALL_CLIENTS = "common/clients";
const GET_CONVERSATION_DETAILS = "aibookingbuddy/messaging";
const LOGOUT_API = "users/logout";
const LOGOUT_USERS_API = "users/logout/all";
const GET_CLIENTS_OF_USERS = "users/";
const BUSINESS_UNITS_LIST = "dbs/settings/business-units";
const GET_CAMPAIGN_UNITS = "dbs/settings/campaigns";
const GETBUDGETS = "dbs/budgets/inputs";
const AddCategories = "dbs/budgets/inputs";
const GETCOMPAIGN = "dbs/settings/campaigns/categories";
const ChannelPerfMonthly = "p/channel-perf-monthly";
const SettingCampaingsNew = "settings/mapping/campaigns/servicetitan";
const MediumTransformation = "settings/medium-transformation-rules";
const GET_CATEGORY = "settings/paid-search-performance/categories";
const getPhoneAutocheck = "qa/phone-autocheck";
const quality_assurance = "qa/phone-autocheck";
const CAPACITY_MARKETING = "capacity-marketing/seven-day-capacity";
const DOWNLOAD_UNBOOKED_USERINFO = "/custom/callrail/unbooked-calls/export?";

export const getCampaignListing = (id) => {
  return getApiExternal(`${CAMPAIGN_LIST}/${id}/campaigns`);
};

export const getDataFromFilters = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/data`, payload);
};

export const getCampaignTableData = (payload) => {
  return getApiExternal(`${CAMPAIGN_TABLE}?${payload}`);
};

export const postCampaignTableData = (db, payload) => {
  return postApiExternal(`${CAMPAIGN_TABLE}?${db}`, payload);
};

export const getCampaignTableExportData = (payload) => {
  return getApiExternal(`${CAMPAIGN_TABLE_EXPORT}?${payload}`);
};

export const getCampaignDataFromST = (db) => {
  return getApiExternal(`${GET_CAMPAIGN_LIST}?db=${db}`);
};

export const getBusinessUnitListFromST = (db) => {
  return getApiExternal(`${GET_BUSINESS_UNIT_LIST}?db=${db}`);
};

export const getCampaignByPlatform = (payload) => {
  return getApiExternal(`${GET_CAMPAIGN_LIST_BY_PLATFORM}?${payload}`);
};

export const addCampaignAndBusinessUnit = (db, payload) => {
  return postApiExternal(`${ADD_CAMPAIGN_MAPPING}?db=${db}`, payload);
};

export const getCampaignMapping = (db) => {
  return getApiExternal(`${GET_CAMPAIGN_LIST_MAPPING}?db=${db}`);
};

export const deleteSettingsMapping = (id, db) => {
  return deleteApiExternal(`${DELETE_SETTINGS_MAPPING_LIST}/${id}?db=${db}`);
};

export const getTotalBooking = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/total-booking`, payload);
};

export const getAbandonedRate = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/abandoned-rate`, payload);
};

export const getAVGTickets = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/avg-ticket`, payload);
};

export const getCancelation = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/cancelation`, payload);
};

export const getClosing = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/closing`, payload);
};

export const getCostPerJob = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/cost-per-job`, payload);
};

export const getCostPerLead = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/cost-per-lead`, payload);
};

export const getExcusedRate = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/excused-rate`, payload);
};

export const getIndicator = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/indicator`, payload);
};

export const getLeadCalls = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/lead-calls`, payload);
};

export const getUnbookedCalls = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/unbooked-calls`, payload);
};

export const getGoogleData = (id, payload) => {
  return postApiExternal(
    `${FILTERS}/${id}/kpis/ad-performance/google`,
    payload
  );
};

export const getBindData = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/ad-performance/bing`, payload);
};

export const getFacebookData = (id, payload) => {
  return postApiExternal(
    `${FILTERS}/${id}/kpis/ad-performance/facebook`,
    payload
  );
};

export const getYelpData = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/ad-performance/yelp`, payload);
};

export const getIsaData = (id, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/ad-performance/lsa`, payload);
};

export const getDashBoardContent = (id, name, payload) => {
  return postApiExternal(`${FILTERS}/${id}/kpis/${name}`, payload);
};

export const inboundedcallclassification = (startDate, endDate, dbName) => {
  return getApiExternal(
    `${INBOUNDED_CALLS_CLASSIFICATION}?start_date=${startDate}&end_date=${endDate}&db=${dbName}`
  );
};

export const getDataRoasPerformance = (id, queryParams) => {
  return getApiExternal(`${DATA2_LIST}/${id}/performance/roas?` + queryParams);
};

export const getJobTypesListing = (id) => {
  return getApiExternal(`${DATA2_LIST}/${id}/job-types`);
};

export const getBussinessUnitsListing = (id) => {
  return getApiExternal(`${DATA2_LIST}/${id}/business-units`);
};

export const getVisualizationData = (id, queryParams) => {
  return getApiExternal(`${DATA2_LIST}/${id}/visualization?` + queryParams);
};

const CampaignsVisualization = "campaigns/visualization";
export const getCampaignVisualizationData = (key, queryParams) => {
  return getApiExternal(
    `report/${key}/${CampaignsVisualization}?${queryParams}`
  );
};
const CampaignsCategories = "dbs/settings/campaigns/categories";
export const getCampaignsCategories = (key, queryParams) => {
  return getApiExternal(`clients/${key}/${CampaignsCategories}?${queryParams}`);
};

export const getAICallClassification = (queryParams) => {
  return getApiExternal(`${AI_CALL_CLASSIFICATION}?` + queryParams);
};

export const getWeekOverWeek = (queryParams) => {
  return getApiExternal(`${WEEK_OVER_WEEK}?` + queryParams);
};

export const getSourceListForSetting = (payload) => {
  return getApiExternal(`${SOURCE_LIST}?${payload}`);
};

export const getBudgetCategories = (id) => {
  return getApiExternal(`${DATA2_LIST}/${id}/budget-bu-categories`);
};

export const getpaidsearchperformance = (payload) => {
  return getApiExternal(`${GET_PAID_SEARCH_PERFORMANCE}?${payload}`);
};

export const updatepaidsearch = (payload, id) => {
  return putApiExternal(`${UPDATE_PAID_SEARCH}?${id}`, payload);
};

export const gettotalspenddate = (payload) => {
  return getApiExternal(`${GET_TOTAL_SPEND_DATE}?${payload}`);
};

export const aiCompanyList = (payload) => {
  return patchApiExternal(`${AI_COMPANT_LIST}?${payload}`);
};

export const aiCompanyStatistics = (id, payload) => {
  return getApiExternal(`${AI_COMPANT_STATISTICS}/${id}/total?${payload}`);
};

export const getTotalBookingAiCall = (queryParams) => {
  return getApiExternal(`${TOTAL_BOOKING}?${queryParams}`);
};

export const getExpiredCompanyList = () =>
  getApiExternal(`${AI_COMPANT_STATISTICS}/${GET_EXPIRED_COMPANY_LIST}`);

export const getMessages = (type, params, filterPayload, cancelPrev) =>
  ExternalApi.patch(
    `${AI_GET_MESSAGES}/${type}/list?${params}`,
    filterPayload,
    cancelPrev
  );

export const getExportUsers = (companyId, query) =>
  getApiExternal(
    `${AI_COMPANT_STATISTICS}/${companyId}/${GET_EXPORT_USERS}?${query}`
  );

export const createAdminUser = (payload) => {
  return postApiExternal(`${CREATE_ADMIN_USER}`, payload);
};

export const adminUserList = (params) => {
  return getApiExternal(`${CREATE_ADMIN_USER}?${params}`);
};

export const updateAdminUser = (id, payload) => {
  return putApiExternal(`${UPDATE_ADMIN_USER}/${id}`, payload);
};

export const deleteAdminUser = (id) => {
  return deleteApiExternal(`${UPDATE_ADMIN_USER}/${id}`);
};

export const adminLogin = (payload) => {
  return postApiExternal(`${LOGIN_USER_NEW}`, payload);
};

export const changePasswordAdmin = (payload) => {
  return postApiExternal(`${CHANGE_PASSWORD_NEW}`, payload);
};

export const resetPasswordAdmin = (payload) => {
  return postApiExternal(`${RESET_PASSWORD_NEW}`, payload);
};

export const setNewPassword = (payload) => {
  return postApiExternal(`${SET_NEW_PASSWORD}`, payload);
};

export const googleLogin = (payload) => {
  return postApiExternal(`${GOOGLE_AUTH_LOGIN}`, payload);
};

export const getYelpUsersList = (payload, bodyRequest) =>
  patchApiExternal(`${GET_YELP_USER_LIST}?${payload}`, bodyRequest);

export const updateYelpUser = (id, payload) =>
  putApiExternal(`${GET_YELP_USER_LIST}/${id}`, payload);

export const isUserDeleted = (id) => {
  return getApiExternal(`${IS_USER_DELETED}/${id}/ping`);
};

export const getBookingList = (id, params, payload, opt) =>
  patchApiExternal(
    `${AI_COMPANT_LIST}/${id}/${BOOKING_LIST}?${params}`,
    payload,
    opt
  );

export const getBookingListWithCancelToken = (id, params, opt) =>
  ExternalApi.patch(
    `${AI_COMPANT_LIST}/${id}/${BOOKING_LIST}?${params}`,
    null,
    opt
  );

export const getScheduledBookingListWithCancelToken = (params, cancelToken) => {
  return ExternalApi.get(
    `/common/simply-scheduled-bookings?${params}`,
    cancelToken
  );
};

export const getUsersList = (path, payload, bodyRequest) => {
  return patchApiExternal(
    `${AI_GET_USERS_LIST}/${path}/${GET_USERS_LIST}?${payload}`,
    bodyRequest
  );
};

export const updateUserStatus = (type, id, payload) => {
  return putApiExternal(`${AI_GET_USERS_LIST}/${type}/${id}`, payload);
};

export const getPerformanceList = (query, payload) => {
  return postApiExternal(`${PERFORMACE_LIST}?${query}`, payload);
};

export const getAllClients = () => getApiExternal(`${GET_ALL_CLIENTS}`);

export const getConversationDetails = (type, id, payload) =>
  patchApiExternal(
    `${GET_CONVERSATION_DETAILS}/${type}/${id}/details`,
    payload
  );

export const logout = () => postApiExternal(`${LOGOUT_API}`);

export const logoutUsers = (payload) =>
  postApiExternal(`${LOGOUT_USERS_API}`, payload);

export const getClientByUser = (id) =>
  getApiExternal(`${GET_CLIENTS_OF_USERS}${id}/clients`);

// dynamic budget sheet
export const getBusinessUnitsList = (key) =>
  getApiExternal(`clients/${key}/${BUSINESS_UNITS_LIST}?client_key=${key}`);

export const getBusinessUnitsCategoriesOptions = (key) =>
  getApiExternal(
    `clients/${key}/${BUSINESS_UNITS_LIST}/categories?client_key=${key}`
  );

export const getBusinessUnitsSubCategoriesOptions = (key) =>
  getApiExternal(
    `clients/${key}/${BUSINESS_UNITS_LIST}/sub-categories?client_key=${key}`
  );

export const updateBusinessUnits = (key, payload) =>
  putApiExternal(
    `clients/${key}/${BUSINESS_UNITS_LIST}?client_key=${key}`,
    payload
  );

export const getCampaignList = (key) => {
  return getApiExternal(
    `clients/${key}/${GET_CAMPAIGN_UNITS}?client_key=${key}`
  );
};

export const getCampaignCategoriesList = (key) => {
  return getApiExternal(
    `clients/${key}/${GET_CAMPAIGN_UNITS}/categories?client_key=${key}`
  );
};

export const updateCampaignCategories = (key, payload) => {
  return putApiExternal(
    `clients/${key}/${GET_CAMPAIGN_UNITS}?client_key=${key}`,
    payload
  );
};

export const businessUnitCategories = (key) => {
  return getApiExternal(
    `clients/${key}/${BUSINESS_UINT_CATEGORIES}?client_key=${key}`
  );
};

export const businessUnitSubCategories = (key) => {
  return getApiExternal(
    `clients/${key}/${BUSINESS_UINT_SUBCATEGORIES}?client_key=${key}`
  );
};

export const proposalsListingApi = (key, params) => {
  return getApiExternal(`clients/${key}/${PROPOSALS}?${params}`);
};

export const proposalsHistoryApi = (key, params, payload) => {
  return postApiExternal(
    `clients/${key}/${PROPOSALSHISTORY}?${params}`,
    payload
  );
};

export const getListingProposalsHistoryApi = (key, params) => {
  return getApiExternal(`clients/${key}/${PROPOSALSHISTORY}?${params}`);
};

export const getListingGoalHistoryApi = (key, params) => {
  return getApiExternal(`clients/${key}/${GOALHISTORY}?${params}`);
};

export const setMarkAsDefaultGoalApi = (key, history_id, params) => {
  return putApiExternal(
    `clients/${key}/${GOALHISTORY}/${history_id}/${GOALMARKASDEFAULT}?${params}`
  );
};

export const getListingGoalPredictionApi = (key, params) => {
  return getApiExternal(`clients/${key}/${GOALPREDICTION}?${params}`);
};

export const predictionDefaultValue = (key) => {
  return getApiExternal(`clients/${key}/${STATICDATA}`);
};

export const goalHistoryApi = (key, params, payload) => {
  return postApiExternal(`clients/${key}/${GOALHISTORY}?${params}`, payload);
};

export const goalPredictionApi = (key, params, payload) => {
  return postApiExternal(`clients/${key}/${GOALPREDICTION}?${params}`, payload);
};

export const getGoals = (key, params, cancelPrev) => {
  // return getApiExternal(`clients/${key}/${GETGOALS}?${params}`);

  return ExternalApi.get(`clients/${key}/${GETGOALS}?${params}`, cancelPrev);
};

export const getallbugetsInput = (key, params) => {
  return getApiExternal(`clients/${key}/${GETBUDGETS}?${params}`);
};

export const addCategories = (key, payload) => {
  return postApiExternal(
    `clients/${key}/${AddCategories}?client_key=${key}`,
    payload
  );
};

export const campaign_categaries = (key, params) => {
  return getApiExternal(`clients/${key}/${GETCOMPAIGN}?${params}`);
};

export const ChannelPerfMonthlyApi = (key, params) => {
  return getApiExternal(`clients/${key}/${ChannelPerfMonthly}?${params}`);
};

export const SettingCampaingsNewApi = (params) => {
  return getApiExternal(`${SettingCampaingsNew}?${params}`);
};

export const MediumTransformationApi = (params) => {
  return getApiExternal(`${MediumTransformation}?${params}`);
};

export const MediumTransformationPutApi = (params, payload) => {
  return putApiExternal(`${MediumTransformation}?${params}`, payload);
};

export const Get_categaries_api = (id) => {
  return getApiExternal(`clients/${id}/${GET_CATEGORY}`);
};

export const add_categaries_api = (id, payload) => {
  return postApiExternal(`clients/${id}/${GET_CATEGORY}`, payload);
};

export const delete_categaries_api = (id, category, params) => {
  return deleteApiExternal(
    `clients/${id}/${GET_CATEGORY}/${category}?${params}`
  );
};

export const edit_categaries_api = (id, category, params, payload) => {
  return putApiExternal(
    `clients/${id}/${GET_CATEGORY}/${category}?${params}`,
    payload
  );
};

export const GetSourceTypeSources = (param) => {
  return getApiExternal(`${GET_CAMPAIGN_LIST_MAPPING}/?${param}`);
};

// Quality Assurance
export const Get_Phone_api = (key, params) => {
  return getApiExternal(`clients/${key}/${getPhoneAutocheck}?${params}`);
};

export const Create_Phone_api = (key, payload) => {
  return postApiExternal(`clients/${key}/${getPhoneAutocheck}`, payload);
};

export const delete_qualityAssurance_modal_api = (client, id) => {
  return deleteApiExternal(
    `clients/${client}/${quality_assurance}`,
    id,
    "isBody"
  );
};

export const Edit_Phone_api = (key, id, payload) => {
  return putApiExternal(`clients/${key}/${getPhoneAutocheck}/${id}`, payload);
};

export const getCapacityMarketing = (
  formattedDate,
  dateAftersevenDays,
  clients
) => {
  return postApiExternal(
    `clients/${CAPACITY_MARKETING}?date_start=${formattedDate}&date_end=${dateAftersevenDays}`,
    clients
  );
};

export const downloadUnbookedUserInfo = (params) => {
  return getApiExternal(`${DOWNLOAD_UNBOOKED_USERINFO}${params}`);
};

const report = "qa/phone-autocheck/logs";
export const getreportdata = (clientid, params) => {
  return getApiExternal(`clients/${clientid}/${report}?${params}`);
};

const AIBOOKING_MESSAGAING = "aibookingbuddy/messaging";
export const messagingDetails = (type, messageid, payload) => {
  return patchApiExternal(
    `${AIBOOKING_MESSAGAING}/${type}/${messageid}/details`,
    payload
  );
};

export const sendingMessage = (type, messageid, payload) => {
  return postApiExternal(
    `${AIBOOKING_MESSAGAING}/${type}/${messageid}/send`,
    payload
  );
};

const listingsearach = "qa/phone-autocheck";
export const getSearchApi = (clientid, params) => {
  return getApiExternal(`clients/${clientid}/${listingsearach}?${params}`);
};

// Budget Tracker
const BudgetTracker = "budget-tracker";
const Reasons = "reasons";

export const getBudgetTrackerListing = (clientid, param) => {
  return getApiExternal(`clients/${clientid}/${BudgetTracker}?${param}`);
};

export const getTrackerReasonListing = (clientid) => {
  return getApiExternal(`clients/${clientid}/${BudgetTracker}/${Reasons}`);
};

export const addTrackerReason = (clientid, payload) => {
  return postApiExternal(`clients/${clientid}/${BudgetTracker}`, payload);
};

export const deleteTrackerReason = (clientid, param) => {
  return deleteApiExternal(`clients/${clientid}/${BudgetTracker}?${param}`);
};

export const editTrackerReason = (clientid, id, payload) => {
  return putApiExternal(`clients/${clientid}/${BudgetTracker}/${id}`, payload);
};

// Notification Center
const Notifications = "notifications";

export const getNotificationsListing = (payload) => {
  return postApiExternal(`common/clients/${Notifications}`, payload);
};

// Dynamic budget sheet - save history
let Predicted = "dbs/budgets/predicted/monthly";
let UniqueMonths = "dbs/budgets/predicted/unique-months";
let SavePrediction = "dbs/budgets/predicted/logs";

export const budgetPredictedApi = (key, payload) => {
  return postApiExternal(`clients/${key}/${SavePrediction}`, payload);
};

export const getbudgetPredictedApi = (key, params) => {
  return getApiExternal(`clients/${key}/${Predicted}?${params}`);
};

export const getUniqueMonthsApi = (key, params) => {
  return getApiExternal(`clients/${key}/${UniqueMonths}?${params}`);
};

const CHANGE_MESSAGE_STATUS = "aibookingbuddy/messaging";
export const messagingReadStatus = (messageid, messageType, payload) => {
  return putApiExternal(
    `${CHANGE_MESSAGE_STATUS}/${messageType}/${messageid}/read`,
    payload
  );
};

const GET_TAB_COUNT = "aibookingbuddy/messaging";

export const gettingCountOfTab = (payload) => {
  return patchApiExternal(`${GET_TAB_COUNT}?${payload}`);
};

// Paid + lsa
let PerformancePaidSocial = "performance/paid-social";

export const getPerformancePaidSocialApi = (key, params, cancelPrev) => {
  return getApiExternal(
    `${DATA2_LIST}/${key}/${PerformancePaidSocial}?${params}`,
    cancelPrev
  );
};

//  Dynamic budget sheet
const TitleLogs = "dbs/budgets/predicted/logs";

export const getTitleLogsApi = (key, params) => {
  return getApiExternal(`clients/${key}/${TitleLogs}?${params}`);
};

export const getTitleLogsMonthlyApi = (key, id) => {
  return getApiExternal(`clients/${key}/${TitleLogs}/${id}/monthly`);
};

//Dashboard Categories
const DashCategories = "settings/campaigns/categories";

export const getDashFilterCatApi = (key, id) => {
  return getApiExternal(`clients/${key}/${DashCategories}`);
};

// Year sheet Paidsearch info Icon
const SocialAds = "dbs/social-ads";
export const getSocialAdsApi = (key, param) => {
  return getApiExternal(`clients/${key}/${SocialAds}?${param}`);
};

const DashboardKPIs = "dashboard-kpis/visualization";
export const kpisVisualization = (key) => {
  return getApiExternal(`report/${key}/${DashboardKPIs}`);
};

// Company Module
let CompanyList = "aibookingbuddy/company";
export const companyListApi = (param, payload = {}) => {
  return patchApiExternal(`${CompanyList}?${param}`, payload);
};

export const createCompany = (payload) => {
  return postApiExternal(CompanyList, payload);
};

export const editCompany = (id, payload) =>
  putApiExternal(`${CompanyList}/${id}`, payload);

export const deleteCompany = (id) => deleteApiExternal(`${CompanyList}/${id}`);

const Yelp = "aibookingbuddy/yelp";
const YelpGeAuthUrl = "get-auth-url";
export const getYelpAuthURL = (payload) =>
  postApiExternal(`${Yelp}/${YelpGeAuthUrl}`, payload);

// Mismatch Revenue
const GET_MISMATCH_REVENUE =
  "custom/attribution-analyzer/categories/mismatch-revenue";
export const getMismatchRevenue = (params) => {
  return getApiExternal(`${GET_MISMATCH_REVENUE}?${params}`);
};

// company crm
const Crm = "crm";
export const listConnectedCRM = (id, params, payload) =>
  patchApiExternal(`${CompanyList}/${id}/${Crm}?${params}`, payload);

let CompanyCrm = "aibookingbuddy/crm";
export const connectCrm = (payload) => postApiExternal(CompanyCrm, payload);

export const updateCrmCompany = (id, payload) =>
  putApiExternal(`${CompanyCrm}/${id}`, payload);

export const deleteCRMForCompany = (id) => deleteApiExternal(`${CompanyCrm}/${id}`);