import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Table, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Sort, SortAsc, SortDesc } from "../../../utils/Images/Images";
import LoadingBlock from "../../../components/LoadingBlock";
import { DeleteIcon, EditIcon } from "../../../utils/Icons/SvgIcons";
import { debounce } from "../../../utils/common/commonFunction";
import Header from "../../../components/Header";
import { DeleteModal } from "../DeleteModal";
import {
  deleteCRMForCompany,
  listConnectedCRM,
} from "../../../services/Collections";
import { toast } from "react-toastify";
import { ArrivalWindowSettingData } from "./ArrivalWindowSettingData";

const transformData = (scheduleData) => {
  return Object.keys(scheduleData).map((day, index) => {
    const time = scheduleData[day][0];
    const count = Object.keys(scheduleData)?.length - 1;
    return {
      key: index,
      content: `${day}, ${time} ${count > 0 ? "+" : ""}  ${
        count > 0 ? count : ""
      }`,
    };
  });
};

function ConnectedCrmList() {
  const [pagesize, setPageSize] = useState(20);
  const [extraData, setExtraData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
  });
  const [tableData, setTableData] = useState([
    {
      business_unit_name: "Garage Door - Service",
      arrival_window_settings: null,
      book_or_job: "job",
      tag_name: "Test",
      job_type_id: "5159025",
      booking_provider: "51606180",
      priority: "High",
      first_appt_date: "2025-01-22",
      job_type_name: "Repair , Maintainance",
      integration_type: "live",
      technician_id: "0",
      first_appt_time: "08:00:00",
      tenant_id: "855714088",
      campaign_id: "51608017",
      max_jobs: 8,
      createdAt: "2024-03-19T06:51:20",
      app_key: "ak1.mw5evmq0vi2cqxufvvj8waio9",
      city: "Los Angeles",
      auto_capacity: true,
      updatedAt: "2024-04-02T17:16:44",
      id: 40,
      assign_tech: null,
      state: "CA",
      technician_name: "Test",
      company_id: 64,
      arrival_windows: ["08:00am to 12:00pm", "12:00pm to 04:00pm"],
      country: "US",
      tag_id: "103356103",
      business_unit_id: "3203",
      campaign_name: "Facebook Ads",
      index: 1,
    },
    {
      business_unit_name: "Garage Door - Sales Retro",
      arrival_window_settings: null,
      book_or_job: "job",
      tag_name: "test",
      job_type_id: "4221990",
      booking_provider: "51606180",
      priority: "High",
      first_appt_date: "2025-01-22",
      job_type_name: "Replacement , New Installation",
      integration_type: "live",
      technician_id: "0",
      first_appt_time: "08:00:00",
      tenant_id: "855714088",
      campaign_id: "51608017",
      max_jobs: 8,
      createdAt: "2024-04-02T17:19:19",
      app_key: "ak1.mw5evmq0vi2cqxufvvj8waio9",
      city: "Los Angeles",
      auto_capacity: true,
      updatedAt: "2024-04-02T17:19:19",
      id: 49,
      assign_tech: null,
      state: "CA",
      technician_name: "test",
      company_id: 64,
      arrival_windows: ["08:00am to 12:00pm", "12:00pm to 04:00pm"],
      country: "US",
      tag_id: "103356103",
      business_unit_id: "3716",
      campaign_name: "Facebook Ads",
      index: 2,
    },
    {
      business_unit_name: "Garage Door - Sales Retro",
      arrival_window_settings: null,
      book_or_job: "job",
      tag_name: "Test",
      job_type_id: "4221990",
      booking_provider: "51606180",
      priority: "High",
      first_appt_date: "2025-01-22",
      job_type_name: "Estimate, Quote",
      integration_type: "live",
      technician_id: "0",
      first_appt_time: "08:00:00",
      tenant_id: "855714088",
      campaign_id: "51608017",
      max_jobs: 8,
      createdAt: "2024-04-02T17:21:15",
      app_key: "ak1.mw5evmq0vi2cqxufvvj8waio9",
      city: "Los Angeles",
      auto_capacity: true,
      updatedAt: "2024-04-02T17:21:15",
      id: 50,
      assign_tech: null,
      state: "CA",
      technician_name: "Test",
      company_id: 64,
      arrival_windows: ["08:00am to 12:00pm", "12:00pm to 04:00pm"],
      country: "US",
      tag_id: "103356103",
      business_unit_id: "3716",
      campaign_name: "Facebook Ads",
      index: 3,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [editDetails, setEditDetails] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [searchWord, setSearchWord] = useState("");
  const [duplicateData, setDuplicateData] = useState(null);
  const [arrivalDataModal, setArrivalDataModal] = useState(false);
  const [arrivalDetails, setArrivalDetails] = useState(null);

  const onChangePagination = (e, limit) => {
    setCurrentPage(e);
    setPageSize(limit);
  };

  const getSortParam = (sortBasis) => {
    setCurrentPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "desc" ? "asc" : "desc",
        sortBasis,
      });
    } else {
      setSortParam({
        sortType: "desc",
        sortBasis,
      });
    }
  };

  const srcSortImage = (Basis) => {
    if (Basis === sortParam.sortBasis) {
      if (sortParam.sortType === "asc") {
        return SortAsc;
      }
      return SortDesc;
    }
    return Sort;
  };

  const handleListing = async (search) => {
    setLoading(true);
    const param = new URLSearchParams();
    sortParam?.sortBasis && param.append("sort_by", sortParam?.sortBasis);
    sortParam?.sortType && param.append("order_by", sortParam?.sortType);
    pagesize && param.append("page_size", Number(pagesize));
    currentPage && param.append("page", Number(currentPage));
    if (search !== null || search !== "") {
      if (search === undefined) {
        if (searchWord) param.append("search", searchWord);
      } else {
        search && param.append("search", search);
      }
    }
    const res = await listConnectedCRM(location?.state?.id, param?.toString());
    if (res?.status === 200) {
      setLoading(false);
      const arr = [];
      res?.data?.map((el, index) => {
        const obj = {
          ...el,
          index: pagesize * (currentPage - 1) + (1 + index),
        };
        arr.push(obj);
      });
      setTableData(arr);
      setExtraData(res?.total_count);
    } else {
      setLoading(false);
    }
  };

  const handleConnectCrm = (data, duplicate) => {
    if (duplicate) {
      if (location?.pathname?.includes("private-layout")) {
        navigate(
          `/private-layout/booking_buddy_settings/${data?.company_id}/add-crm`,
          { state: data }
        );
      } else {
        navigate(`/booking_buddy_settings/${data?.company_id}/add-crm`, {
          state: data,
        });
      }
    } else {
      if (location?.pathname?.includes("private-layout")) {
        navigate(`/private-layout/booking_buddy_settings/${data?.id}/add-crm`, {
          state: data,
        });
      } else {
        navigate(`/booking_buddy_settings/${data?.id}/add-crm`, {
          state: data,
        });
      }
    }
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    const res = await deleteCRMForCompany(editDetails?.id);
    if (res?.status == 200) {
      toast.success("CRM Deleted Successfully");
      setDeleteLoading(false);
      setDeleteModal(false);
      setEditDetails(null);
      setDuplicateData(null);

      if (currentPage == 1) {
        handleListing();
      } else {
        setCurrentPage(1);
      }
    } else {
      setDeleteLoading(false);
      const message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      toast.error(messageApi, message);
    }
  };

  const handleEditCRM = (data) => {
    if (location?.pathname?.includes("private-layout")) {
      navigate(
        `/private-layout/booking_buddy_settings/${location?.state?.id}/edit-crm`,
        { state: data }
      );
    } else {
      navigate(`/booking_buddy_settings/${location?.state?.id}/edit-crm`, {
        state: data,
      });
    }
  };

  const handleSearchListing = useCallback(debounce(handleListing), []);

  const handleSearch = (value) => {
    setSearchWord(value);
    handleSearchListing(value);
  };

  useEffect(() => {
    handleListing();
  }, [currentPage, pagesize, sortParam]);

  const columns = [
    {
      title: "",
      width: 50,
      render: (_, data) => (
        <CheckBoxWrapper>
          <label className="container-checkbox">
            <input
              type="radio"
              name="duplicate"
              checked={data?.index == duplicateData?.index}
              value={data?.index}
              onClick={() =>
                duplicateData?.index == data?.index
                  ? setDuplicateData(null)
                  : setDuplicateData({ ...data, isDuplicate: true })
              }
            />
            <span className="checkmark" />
          </label>
          {/* <p className="btn-inner-text">Credit Card</p> */}
        </CheckBoxWrapper>
      ),
    },
    {
      title: "Sr.No",
      dataIndex: "index",
      width: 80,
      render: (val) => <div>{val}.</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Business Unit Name</span>
          {/* <img
            style={{
              filter:
                sortParam?.sortBasis != "business_unit_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("business_unit_name")}
            onClick={() => getSortParam("business_unit_name")}
          /> */}
        </TitleWrapper>
      ),
      width: 200,
      dataIndex: "business_unit_name",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Business Unit ID</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "business_unit_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("business_unit_id")}
            onClick={() => getSortParam("business_unit_id")}
          /> */}
        </TitleWrapper>
      ),
      width: 160,
      dataIndex: "business_unit_id",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Campaign name</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "campaign_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("campaign_name")}
            onClick={() => getSortParam("campaign_name")}
          /> */}
        </TitleWrapper>
      ),
      width: 170,
      dataIndex: "campaign_name",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Campaign id</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "campaign_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("campaign_id")}
            onClick={() => getSortParam("campaign_id")}
          /> */}
        </TitleWrapper>
      ),
      width: 160,
      dataIndex: "campaign_id",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Booking provider</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "booking_provider" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("booking_provider")}
            onClick={() => getSortParam("booking_provider")}
          /> */}
        </TitleWrapper>
      ),
      width: 170,
      dataIndex: "booking_provider",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Integration type</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "integration_type" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("integration_type")}
            onClick={() => getSortParam("integration_type")}
          /> */}
        </TitleWrapper>
      ),
      width: 160,
      dataIndex: "integration_type",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Job type name</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "job_type_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("job_type_name")}
            onClick={() => getSortParam("job_type_name")}
          /> */}
        </TitleWrapper>
      ),
      width: 150,
      dataIndex: "job_type_name",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Job type id</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "job_type_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("job_type_id")}
            onClick={() => getSortParam("job_type_id")}
          /> */}
        </TitleWrapper>
      ),
      width: 130,
      dataIndex: "job_type_id",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Priority</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "priority" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("priority")}
            onClick={() => getSortParam("priority")}
          /> */}
        </TitleWrapper>
      ),
      width: 100,
      dataIndex: "priority",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Tenant id</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "tenant_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("tenant_id")}
            onClick={() => getSortParam("tenant_id")}
          /> */}
        </TitleWrapper>
      ),
      width: 120,
      dataIndex: "tenant_id",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>App key</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "app_key" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("app_key")}
            onClick={() => getSortParam("app_key")}
          /> */}
        </TitleWrapper>
      ),
      width: 200,
      dataIndex: "app_key",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Technician name</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "technician_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("technician_name")}
            onClick={() => getSortParam("technician_name")}
          /> */}
        </TitleWrapper>
      ),
      width: 180,
      dataIndex: "technician_name",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Technician id</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "technician_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("technician_id")}
            onClick={() => getSortParam("technician_id")}
          /> */}
        </TitleWrapper>
      ),
      width: 140,
      dataIndex: "technician_id",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Tag name</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "tag_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("tag_name")}
            onClick={() => getSortParam("tag_name")}
          /> */}
        </TitleWrapper>
      ),
      width: 120,
      dataIndex: "tag_name",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Tag id</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "tag_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("tag_id")}
            onClick={() => getSortParam("tag_id")}
          /> */}
        </TitleWrapper>
      ),
      width: 100,
      dataIndex: "tag_id",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>First appt date</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "first_appt_date" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("first_appt_date")}
            onClick={() => getSortParam("first_appt_date")}
          /> */}
        </TitleWrapper>
      ),
      width: 140,
      dataIndex: "first_appt_date",
      render: (val) => <div>{val || "N/A"}</div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>First appt time</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "first_appt_time" && "invert(1)",
            }}
            className="sortIcons"
            src={srcSortImage("first_appt_time")}
            alt=""
            onClick={() => getSortParam("first_appt_time")}
          /> */}
        </TitleWrapper>
      ),
      width: 140,
      dataIndex: "first_appt_time",
      render: (val) => (
        <div>{val ? moment(val, "HH:mm").format("HH:mm") : "N/A"} </div>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <span>City</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "first_appt_time" && "invert(1)",
            }}
            className="sortIcons"
            src={srcSortImage("first_appt_time")}
            alt=""
            onClick={() => getSortParam("first_appt_time")}
          /> */}
        </TitleWrapper>
      ),
      width: 140,
      dataIndex: "city",
      render: (val) => <div>{val ? val : "N/A"} </div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>State</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "first_appt_time" && "invert(1)",
            }}
            className="sortIcons"
            src={srcSortImage("first_appt_time")}
            alt=""
            onClick={() => getSortParam("first_appt_time")}
          /> */}
        </TitleWrapper>
      ),
      width: 80,
      dataIndex: "state",
      render: (val) => <div>{val ? val : "N/A"} </div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Country</span>
        </TitleWrapper>
      ),
      width: 80,
      dataIndex: "country",
      render: (val) => <div>{val ? val : "N/A"} </div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Assign Tech</span>
        </TitleWrapper>
      ),
      width: 120,
      dataIndex: "assign_tech",
      render: (val) => <div>{val ? val : "N/A"} </div>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Arrival Window Settings</span>
        </TitleWrapper>
      ),
      width: 200,
      dataIndex: "arrival_window_settings",
      render: (val) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (val != null) {
              if (Object.keys(val).length > 0) {
                setArrivalDetails(val);
                setArrivalDataModal(true);
              }
            }
          }}
        >
          {val ? transformData(val)?.[0]?.content : "N/A"}
        </div>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <span>Arrival Windows</span>
        </TitleWrapper>
      ),
      dataIndex: "arrival_windows",
      width: 180,
      render: (val) => (
        <div>
          {val?.length > 0 ? (
            val?.map((el, idx) => (
              <div key={idx} style={{ whiteSpace: "nowrap" }}>
                {el && el}
              </div>
            ))
          ) : (
            <div>N/A</div>
          )}
        </div>
      ),
    },
    {
      title: "Actions",
      // dataIndex: "active_status",
      fixed: "right",
      width: 100,
      render: (_, data) => (
        <ActionButtons>
          <div
            className="edit-icon"
            onClick={() => {
              handleEditCRM({...data, companyData : location?.state});
            }}
          >
            <EditIcon />
          </div>
          <div
            className="delete-icon"
            onClick={() => {
              setDeleteModal(true);
              setEditDetails(data);
            }}
          >
            <DeleteIcon />
          </div>
        </ActionButtons>
      ),
    },
  ];

  return (
    <>
      <Header
        title="Connect CRM"
        clientInfo={{ name: location?.state?.name }}
      />
      <TableWrapper location={location?.pathname?.includes("private-layout")}>
        {contextHolder}
        {deleteModal && (
          <DeleteModal
            open={deleteModal}
            handleClose={() => setDeleteModal(false)}
            title={`Are you sure you want to Delete this CRM for ${location?.state?.name} ?`}
            description="All your process and data will be saved."
            isLoading={deleteLoading}
            handleSubmit={handleDelete}
          />
        )}

        {arrivalDataModal && (
          <ArrivalWindowSettingData
            isVisible={arrivalDataModal}
            onClose={() => setArrivalDataModal(false)}
            scheduleData={arrivalDetails}
          />
        )}

        <div className="create-btn">
          {/* <div>
          <span
            style={{
              cursor: "pointer",
              display: "flex",
              gap: "5px",
              fontFamily: "Poppins",
              color: "white",
            }}
            onClick={() => navigate(-1)}
          >
            <BackSVGIcon /> Back
          </span>
        </div> */}
          <div className="title">
            {/* <h2 style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
              Company: {location?.state?.name}
            </h2> */}
          </div>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <div>
              <form autoComplete="off" onSubmit={(e) => e?.preventDefault()}>
                <input
                  type="text"
                  name="search"
                  value={searchWord}
                  placeholder="Search"
                  onChange={(e) => {
                    // e?.preventDefault();
                    setCurrentPage(1);
                    handleSearch(e?.target?.value);
                  }}
                />
              </form>
            </div>
            <div className="btn">
              <button
                style={{ width: "80px" }}
                onClick={() => handleConnectCrm(location?.state, false)}
              >
                ADD
              </button>
              {duplicateData && (
                <button
                  style={{ width: "180px" }}
                  onClick={() =>
                    handleConnectCrm({...duplicateData,companyData:location?.state}, true)                  }
                >
                  Create as duplicate
                </button>
              )}
            </div>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          {loading ? (
            <LoadingBlock height={"calc(100vh - 187px)"} size={28} />
          ) : (
            <Table
              prefixCls="custom-ui-table"
              columns={columns}
              dataSource={tableData}
              scroll={{
                x: "100%",
                y: "calc(100vh - 270px)",
              }}
              pagination={{
                current: currentPage,
                onChange: onChangePagination,
                total: extraData,
                defaultPageSize: pagesize,
                showSizeChanger: true,
                hideOnSinglePage: true,
              }}
            />
          )}
        </div>
      </TableWrapper>
    </>
  );
}

export default ConnectedCrmList;

const TableWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  padding: 20px !important;
  /* margin-top: ${({ location }) => (location ? 0 : "56px !important")}; */

  .create-btn {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    gap: 7px;

    .title {
      h2 {
        color: #000;
        font-size: 22px;
        font-family: "Poppins";
      }
    }

    input {
      height: 36px;
      width: 300px;
      background: #ffff;
      border-radius: 7px;
      border: 1px solid #cecece;
      padding: 5px;
      color: #000;
      font-family: "Poppins", Arial, sans-serif;

      ::placeholder {
        color: #b6b6b6 !important;
      }

      &:focus {
        outline: none;
        border: 1px solid #cecece;
      }
    }

    .btn {
      display: flex;
      gap: 10px;
      padding: 0;
      button {
        cursor: pointer;
        white-space: nowrap;
        padding: 7px 12px;
        border-radius: 6px;
        background: rgb(15, 16, 17);
        border: none;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: rgb(255, 255, 255);
      }
    }
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  gap: 10px;

  img {
    cursor: pointer;
    height: 20px;
  }
`;

const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;
  justify-content: center;

  .edit-icon {
    background: #f98229;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    display: grid;
    cursor: pointer;
    place-items: center;
  }
  .delete-icon {
    background: red;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    display: grid;
    cursor: pointer;
    place-items: center;
  }
`;

const CheckBoxWrapper = styled.div`
  ${"" /* position: absolute; */}
  ${"" /* left: 20px; */}
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .container-checkbox {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 24px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    input:checked ~ .checkmark {
      background-color: #2c2c2c;
      border-style: none;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: transparent;
      border: 1.6px solid #fff;
      border-radius: 50%;
      border: 1px solid #424b57;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
    .checkmark:after {
      left: 9.5px;
      top: 6px;
      width: 4px;
      height: 9px;
      border: 2.375px solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;
