import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { DatePicker, Select } from "antd";
import { getCampaignListing } from "../services/Collections";
import {
  updateSelectAll,
  classificationFilterDetails,
  updateAiCallClassificationCellData,
} from "../app/Auth/signIn/LogInSlice";
import styled from "styled-components";
import dayjs from "dayjs";
import RangeSelector from "./RangePicker";
import moment from "moment";
import { filterClientsBySection } from "../utils/common/commonFunction";
import { LoaderIcon } from "./LoadingBlock";

const ClassificationFilter = ({
  setFilterDrawer,
  handleApisCalling,
  loader,
}) => {
  const [clientOptions, setclientOptions] = useState([]);
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const newFilters = useSelector(
    (state) => state?.loginAuth?.classificationSectionFilters
  );
  const [startDate, setStartDate] = useState(
    newFilters?.date_start || prefilledData?.date_start
  );
  const [endDate, setEndDate] = useState(
    newFilters?.date_end || prefilledData?.date_end
  );
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const defaultIsSelectAll = useSelector(
    (state) => state?.loginAuth?.isSelectAll
  );

  const [selectedClient, setSelectedClient] = useState(
    newFilters?.selectedClient
      ? newFilters?.selectedClient
      : prefilledData?.selectedClient
  );

  const dispatch = useDispatch();

  const initialValues = {
    clientKey: "",
    startDate: "",
    endDate: "",
  };

  const getClientOption = () => {
    let arr = [];
    filterClientsBySection(clientOptionsData?.clients, "call-classification")
      ?.length > 0 &&
      filterClientsBySection(
        clientOptionsData?.clients,
        "call-classification"
      )?.map((ele) => {
        let obj = {
          ...ele,
          label: ele?.name,
          value: ele?.name,
        };
        arr.push(obj);
      });
    setclientOptions(arr);
  };

  // const handleCompanignList = async () => {
  //   let res = await getCampaignListing(
  //     selectedClient?.key ?? prefilledData?.selectedClient?.key
  //   );
  //   if (res?.status == 200) {
  //     let arr = [];
  //     if (res.data.length > 0) {
  //       for (let i = 0; i < res?.data.length; i++) {
  //         let obj = {
  //           value: res?.data[i]?.campaignid,
  //           label: res?.data[i]?.campaignname,
  //         };
  //         arr.push(obj);
  //       }
  //     }
  //     const filteredByVal = Array.isArray(arr)
  //       ? [
  //           ...arr
  //             .reduce((a, c) => {
  //               a?.set(c?.value, c);
  //               return a;
  //             }, new Map())
  //             .values(),
  //         ]
  //       : [];
  //     handleSouceOption(filteredByVal);
  //   } else {
  //     toast.error(res?.message || res?.error || "Something went wrong");
  //   }
  // };

  // const handleSouceOption = (data) => {
  //   if (data.length > 0) {
  //     let arr = [];
  //     if (defaultIsSelectAll) {
  //       prefilledData?.campaignsIds?.length > 0
  //         ? prefilledData?.campaignsIds?.map((el) => {
  //             data?.map((val) => {
  //               if (el == val?.value) {
  //                 arr.push(val);
  //               }
  //             });
  //           })
  //         : prefilledData?.campaigns?.map((el) => {
  //             data?.map((val) => {
  //               if (el == val?.value) {
  //                 arr.push(val);
  //               }
  //             });
  //           });
  //     } else {
  //       prefilledData?.campaigns?.length > 0
  //         ? prefilledData?.campaigns?.map((el) => {
  //             data?.map((val) => {
  //               if (el == val?.value) arr.push(val);
  //             });
  //           })
  //         : prefilledData?.campaignsIds?.map((el) => {
  //             data?.map((val) => {
  //               if (el == val?.value) arr.push(val);
  //             });
  //           });
  //     }
  //   }
  // };

  const submitHandler = async (e) => {
    let reauestedPayload = {
      date_end: endDate,
      date_start: startDate,
      selectedClient: selectedClient,
    };
    // console.log(reauestedPayload, "reauestedPayloadreauestedPayload");
    dispatch(classificationFilterDetails(reauestedPayload));
    handleApisCalling(
      selectedClient?.key || prefilledData?.selectedClient?.key,
      reauestedPayload,
      selectedClient || prefilledData?.selectedClient
    );
    dispatch(
      updateAiCallClassificationCellData({
        type: "clear",
      })
    );
    setFilterDrawer(false);
  };

  useEffect(() => {
    if (selectedClient != null) {
      dispatch(updateSelectAll(false));
      // handleCompanignList();
    }
  }, [selectedClient]);

  useEffect(() => {
    getClientOption();
    // handleCompanignList();
  }, []);

  return (
    <FilterWrapper selectedThemeColors={selectedThemeColors}>
      <div className="inner-part">
        <div className="content-box">
          <div className="filters">
            <h4>Filters</h4>
            <Formik initialValues={initialValues} onSubmit={submitHandler}>
              {({ setFieldValue }) => (
                <Form>
                  <div>
                    <label>
                      <strong>Client</strong>
                    </label>
                    <Select
                      size={"large"}
                      name="clientKey"
                      prefixCls="inner-select2"
                      defaultValue={
                        newFilters?.selectedClient
                          ? newFilters?.selectedClient
                          : prefilledData?.selectedClient ?? selectedClient
                      }
                      value={selectedClient}
                      onChange={(_, data) => setSelectedClient(data)}
                      style={{
                        width: "100%",
                        height: "48px",
                      }}
                      maxTagCount={1}
                      placeholder="Please select"
                      options={clientOptions}
                      dropdownStyle={{
                        maxHeight: "300px",
                      }}
                    />
                  </div>
                  <div>
                    <label>
                      <strong>Select Date</strong>
                    </label>{" "}
                    <RangeSelector
                      defaultDate={[
                        startDate
                          ? moment(startDate, "yyyy-MM-DD").format("yyyy-MM-DD")
                          : null,
                        endDate
                          ? moment(endDate, "yyyy-MM-DD").format("yyyy-MM-DD")
                          : null,
                      ]}
                      handleChange={(d) => {
                        const start = d[0];
                        const end = d[1];
                        setStartDate(start);
                        setEndDate(end);
                      }}
                      style={{marginTop: "4px"}}
                    />
                  </div>
                  {/* <div style={{ marginTop: "16px" }}>
                    <span>
                      <label>
                        <strong>End Date</strong>
                      </label>
                    </span>
                    <DatePicker
                      value={dayjs(endDate)}
                      clearIcon={false}
                      name="endDate"
                      prefixCls="customDatepicker"
                      onChange={(_, date) => setEndDate(date)}
                    />
                  </div> */}
                  {loader ? (
                    <button type="button">
                      <LoaderIcon />
                    </button>
                  ) : (
                    <button type="submit">Apply</button>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </FilterWrapper>
  );
};
export default ClassificationFilter;

const FilterWrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .inner-select2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    appearance: none;
    height: 50px;
    width: 100%;
    margin-top: 4px;
    padding: 0px 0px;
    border: 1px solid rgba(206, 206, 206, 1);
    border-radius: 10px;
    color: #000;
    background: #fff;

    .anticon-close {
      color: #000 !important;
    }

    .inner-select2-selector {
      border: none !important;
    }
    .inner-select2-selection-placeholder {
      color: ${({ selectedThemeColors }) =>
        selectedThemeColors?.inputPlaceHolder} !important;
    }
    .inner-select2-suffix {
      color: rgba(146, 146, 165, 1) !important;
    }
  }

  .channel-check-div {
    width: 100%;
    height: 53px;
    border-radius: 8px;
    border: 1px solid rgba(206, 206, 206, 1);
    padding: 0px 10px;
    margin-bottom: 10px;
    position: relative;
    background-color: #fff;
    color: #0f1011 !important;
    display: flex;
    align-items: center;
    ${"" /* justify-content: space-between; */}
    cursor: pointer;
  }
  .inner-part {
    .content-box {
      .header {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;

        img {
          width: 50px;
        }
        h4 {
          color: #fff;
        }
      }
      .filters {
        h4 {
          margin: 0px 0;
          margin-bottom: 15px;
          color: #0f1011;
        }
        div {
          margin-bottom: 8px;

          label {
            color: ${({ selectedThemeColors }) =>
              selectedThemeColors?.lightText};

            display: block;
            margin-bottom: 0.25rem;
          }
          span {
            display: flex;
            justify-content: space-between;

            span {
              color: #fff;
            }

            .ant-checkbox-inner {
              margin-top: 3px;
            }
            label {
              color: #7e7e7e;

              display: block;
              margin-bottom: 0.25rem;
              ${"" /* width: 100%; */}
            }
            .input {
              width: max-content;
              height: 20px;
              border-radius: 8px;
              border: none;
              padding: 0px 10px;
              margin-bottom: 10px;
              background-color: #171033;
              color: #fff;
            }
          }
          .input {
            width: 100%;
            height: 53px;
            border-radius: 8px;
            border: none;
            padding: 0px 10px;
            margin-bottom: 10px;
            background-color: #171033;
            color: #fff;
            ${
              "" /* border-color: #2a2833 !important;
            color: #3c2ac1 !important; */
            }
          }
          .ant-select {
            width: 100%;
            height: 53px;
            border-radius: 8px;
            border: none;

            .ant-select-selector {
              height: 100%;
              background-color: #05031a;
              border: none;
              .ant-select-selection-overflow {
                height: 100%;
                .ant-select-selection-overflow-item {
                  .ant-select-selection-item {
                    margin-bottom: 29px;
                    .ant-select-selection-item-content {
                      color: #fff;
                      cursor: context-menu;
                      maxwidth: 150px;
                      textoverflow: ellipsis;
                      overflow: hidden;
                      whitespace: nowrap;
                    }
                  }
                }
              }
            }
          }
        }
        button {
          background-color: ${({ selectedThemeColors }) =>
            selectedThemeColors?.buttonColor};
          border-color: #0f1011;
          color: #fff;
          text-align: center;
          width: 100%;
          font-weight: 700;
          height: 40px;
          border-radius: 8px;
          border: none;
          margin-top: 10px;
          font-size: 13px;
        }
      }
    }
  }
  .source-checkbox-wrapper .ant-checkbox-wrapper {
    margin-bottom: 0px !important;
    display: flex !important;

    .ant-checkbox {
      .ant-checkbox-inner {
        background-color: rgba(249, 249, 249, 1) !important;
        border: 1px solid rgba(206, 206, 206, 1);
      }
    }

    .ant-checkbox-inner {
      background-color: red !important;
      border: 1px solid rgba(206, 206, 206, 1);
    }
  }
`;
