import React, { useEffect, useState } from "react";
import { GraphWrapper, MainWrapper } from "./style";
import Chart from "react-apexcharts";

export default function BoxPlot({ data }) {
  const [series, setSeries] = useState([]);

  const calculateBoxPlotData = (data) => {
    data.sort((a, b) => a - b); // Sort data in ascending order
    const min = data[0];
    const max = data[data.length - 1];
    const median = data[Math.floor(data.length / 2)];
    const q1 = data[Math.floor(data.length / 4)];
    const q3 = data[Math.floor((3 * data.length) / 4)];
    return { min, q1, median, q3, max };
  };

  useEffect(() => {
    const { min, q1, median, q3, max } = calculateBoxPlotData(data);
    const series = [
      {
        type: "boxPlot",
        data: [
          {
            x: "Avg Ticket",
            y: [min, q1, median, q3, max],
          },
        ],
      },
    ];

    setSeries(series);
  }, []);

  // const options = {
  //   chart: {
  //     type: "boxPlot",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: true,
  //       barHeight: '40%'
  //     },
  //     boxPlot: {
  //       horizontal: true, // Horizontal orientation
  //       colors: {
  //         upper: "#28a745", // Upper quartile color
  //         lower: "#ffc107", // Lower quartile color
  //       },
  //     },
  //   },
  //   title: {
  //     text: "Revenue Distribution (Box Plot)",
  //     align: "center",
  //   },
  //   xaxis: {
  //     title: {
  //       text: "Revenue",
  //     },
  //   },
  //   yaxis: {
  //     title: {
  //       text: "Metrics",
  //     },
  //   },
  // };

  const options = {
    chart: {
      type: 'boxPlot',
      height: 350
    },
    title: {
      text: '',
      align: 'left'
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '40%'
      },
      boxPlot: {
        colors: {
          upper: '#B6C454',
          lower: '#EDBFB7'
        }
      }
    },
    stroke: {
      colors: ['#333']
    }
  };

  return (
    <MainWrapper>
      <div className="sub-header">
        <sub>Variability in Avg Ticket</sub>
      </div>
      <GraphWrapper className="graph-section">
        {series?.length > 0 &&
          <Chart options={options} series={series} type="boxPlot" height={400} />
        }
      </GraphWrapper>
    </MainWrapper>
  );
}
