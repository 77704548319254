import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import {
  CapacityMarketingWrapper,
  HorizontalBarWrapper,
  Line,
} from "./capacityMarketingStyle";
import { getCapacityMarketing } from "../../services/Collections";
import LoadingBlock from "../../components/LoadingBlock";
import CapacityFilter from "./CapacityFilter";
import { Drawer, Tooltip } from "antd";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import moment from "moment";
import Watermark from "../../components/Watermark";

export default function CapacityMarketing() {
  const prefillClient = useSelector(
    (state) => state?.loginAuth?.prefilledFilters?.selectedClient
  );
  const selectedClient = useSelector((state) => state?.capacityMarketing);

  const activeClient =
    selectedClient?.selectedClient?.length > 0
      ? selectedClient?.selectedClient
      : [prefillClient];
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchingListing = async () => {
    const currentdate = dayjs().format("YYYY-MM-DD");
    const Aftersevendays = dayjs(currentdate)
      .add(7, "day")
      .format("YYYY-MM-DD");

    let formattedDate = selectedClient?.formattedDate
      ? selectedClient?.formattedDate
      : currentdate;
    let dateAftersevenDays = selectedClient?.dateAftersevenDays
      ? selectedClient?.dateAftersevenDays
      : Aftersevendays;
    let req = { client_keys: activeClient?.map((el) => el.key) };
    setLoading(true);
    let res = await getCapacityMarketing(
      formattedDate,
      dateAftersevenDays,
      req
    );
    if (res?.status === 200) {
      setData(res.data);
      setLoading(false);
      setOpenDrawer(false);
    } else {
      setData([]);
      setLoading(false);
      toast.error(
        res.response.data.message ||
          res.error ||
          res.message ||
          "Something went wrong"
      );
    }
  };

  useEffect(() => {
    fetchingListing();
  }, [selectedClient]);

  return (
    <>
      <CapacityMarketingWrapper>
        {openDrawer && (
          <Drawer
            className="filter-drawer"
            placement="right"
            closable={false}
            onClose={() => setOpenDrawer(false)}
            open={openDrawer}
            width={"400px"}
            key="bottom"
          >
            <CapacityFilter loading={loading} handleAction={fetchingListing} />
          </Drawer>
        )}

        <Header
          // clientInfo={activeClient?.[0]}
          title="Capacity Marketing"
          openFilter={() => setOpenDrawer(true)}
          isFilter={true}
          isNotification={true}
        />
        <main>
          {loading ? (
            <LoadingBlock height={"calc(100vh - 116px)"} size={28} />
          ) : (
            <>
              {data &&
                data?.map((main) => (
                  <section className="main-section">
                    <section className="company-section">
                      <img src={main?.client?.logo} />
                      <p>{main?.client?.name}</p>
                    </section>
                    {main?.items?.map((trade) => (
                      <section className="trade-section">
                        <h2>{trade?.name}</h2>
                        {trade?.items &&
                          trade?.items?.length > 0 &&
                          trade?.items?.map((sub) => (
                            <div className="inner">
                              <p>{sub?.subname}</p>
                              <HorizontalBar data={sub?.subitems} />
                            </div>
                          ))}
                      </section>
                    ))}
                  </section>
                ))}
            </>
          )}
        </main>
      </CapacityMarketingWrapper>
      {!loading && <Watermark style={{ padding: "0 10px 6px" }} />}
    </>
  );
}

const HorizontalBar = ({ data }) => {
  const weekdayOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const [dayData, setDayData] = useState([]);

  const getColors = (value) => {
    console.log(value, "values00");
    if (value == 0) {
      return "#9e9e9e";
    } else if (value <= 25) {
      return "red";
    } else if (value <= 50) {
      return "orange";
    } else if (value <= 75) {
      return "yellow";
    } else if (value <= 90) {
      // light green
      return "#05E335";
    } else {
      // dark green
      return "#017d1b";
    }
  };

  useEffect(() => {
    if (data) {
      const today = moment().format("ddd");
      const todayIndex = weekdayOrder.indexOf(today);
      const reorderedWeekdayOrder = [
        ...weekdayOrder.slice(todayIndex),
        ...weekdayOrder.slice(0, todayIndex),
      ];
      const sortedData = data.sort((a, b) => {
        return (
          reorderedWeekdayOrder.indexOf(a.weekday) -
          reorderedWeekdayOrder.indexOf(b.weekday)
        );
      });
      setDayData(sortedData);
    }
  }, []);

  return (
    <HorizontalBarWrapper>
      {dayData?.map((d) => (
        <div className="wrap">
          <Line color={getColors(d?.percentage)} />
          <label style={{ color: "#333333" }}>
            {d?.weekday} ({d?.percentage?.toFixed(2)}%)
          </label>
          <label>
            ({d?.current_capacity}
            <Tooltip title={`New Customer - ${d?.new_customer}`}>
              <span className="new-customer">{`{${d?.new_customer}}`}</span>
            </Tooltip>
            /{d?.max_capacity})
          </label>
        </div>
      ))}
    </HorizontalBarWrapper>
  );
};
