import styled from "styled-components";

export const FinalReportWrapper = styled.div`
  width: 100%;
  /* height: ${({ size }) =>
    size ? "calc(100vh - 200px)" : "calc(100vh - 105px)"}; */

  .Wrap {
    overflow: auto;
    min-height: ${({ size }) =>
      size ? "calc(100vh - 225px)" : "calc(100vh - 145px)"};
    max-height: 100%;
  }
`;

export const FilterTabsWrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: ${({ position }) => (position ? "space-between" : "right")};
  align-items: end;
  background: ${({ position }) => (position ? "#fff" : "transparent")};
  padding: ${({ position }) => (position ? "10px" : "0px")};
  border-radius: 9px;
  border: ${({ position }) => (position ? "1px solid rgb(210, 212, 222)" : "")};
  margin: ${({ position }) => (position ? "10px 0" : "0 0 10px 0")};

  .flex {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #7b7f91;
  }
  .wrap {
    display: flex;
    align-items: end;
    gap: 15px;
  }

  .saveBtn {
    background: rgb(36, 36, 36);
    color: rgb(255, 255, 255);
    border-style: none;
    height: 33px;
    font-size: 13px;
    padding-right: 12px;
    padding-left: 12px;
    cursor: pointer;
    font-family: Poppins;
    height: 34px;
    min-width: 68px;
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
  }

  .submit {
    background: #d80c0c;
  }

  .select {
    .inner-select2-selector {
      min-width: 180px !important;
      background: white !important;
    }
  }

  .new-customers {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row-reverse;
  }
`;