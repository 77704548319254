import { Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  aiCompanyStatistics,
  getExportUsers,
} from "../../services/Collections";
import { saveAs } from "file-saver";
import { LeadNames, valueFormatter } from "../../utils/common/commonFunction";
import {
  selectedAiCompany,
  updateActiveTab,
  updateMessageType,
} from "./AiBookingSlice";
import moment from "moment";
import AiFilter from "./AiFilter";
import SubscriptionCheck from "../../layouts/SubscriptionCheck";
import AIBookingConversation from "./AIBookingConversation";
import { handleExcelExport } from "./exportinvoice/ExportInvoice";
import { ManageYelpUser } from "../manageYelpUser/ManageYelpUser";
import { ManageLsaUser } from "../manageYelpUser/ManageLsaUsers";
import { ManageSmsUser } from "../manageYelpUser/ManageSmsUsers";
import Header from "../../components/Header";
import TabComponent from "../../components/newComponent/TabComponent";
import { ConversationIcon, NewSearchIcon } from "../../utils/Icons/SvgIcons";
import LoadingBlock from "../../components/LoadingBlock";
import { useNavigate } from "react-router-dom";
import Watermark from "../../components/Watermark";
import ScheduledBooking from "./ScheduledBooking";

export default function AiBooking() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const [loading, setLoading] = useState(false);
  const [selectedLoading, setSelectedLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const selectedCompany = useSelector(
    (state) => state?.aiBooking?.selectedCompany
  );
  const AiDate = useSelector((state) => state?.aiBooking);
  const [cStatsData, setCStatsData] = useState([]);
  const [filterDrawers, setFilterDrawers] = useState(false);
  const activeTab = useSelector((state) => state?.aiBooking?.activeTab);
  const [buttonLabel, setButtonLabel] = useState("");
  const [leadsUsers, setLeadUsers] = useState([]);
  const [leadUserLoading, setLeadUserLoading] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [search, setSearch] = useState("");
  const [userFilters, setUserFilters] = useState(null);
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );

  const calculateTotalCost = (data) => {
    let total =
      (data?.total_leads - data?.total_booking) * 2 + data?.total_booking * 25;
    return total;
  };

  const getStatistics = async (company) => {
    var enddate = new Date();
    var startdate = new Date(new Date().setDate(enddate.getDate() - 30));

    setSelectedLoading(true);
    const requestPayload = new URLSearchParams();
    requestPayload.append(
      "start_time",
      moment(AiDate?.startDate ?? startdate).format("YYYY-MM-DD")
    );
    requestPayload.append(
      "end_time",
      moment(AiDate?.endDate ?? enddate).format("YYYY-MM-DD")
    );

    let res = await aiCompanyStatistics(company?.value, requestPayload);

    if (res?.status == 200) {
      let tempArray = [];
      for (let i = 0; i < res?.data.length; i++) {
        let element = res?.data[i];
        tempArray.push({
          ...element,
          total_booking_perc:
            element?.total_leads == 0
              ? 0
              : (element?.total_booking / element?.total_leads) * 100,
          total_cost: calculateTotalCost(element),
        });
      }
      setCStatsData(tempArray);
      setSelectedLoading(false);
    } else {
      dispatch(selectedAiCompany(null));
      setCStatsData([]);
      setSelectedLoading(false);
      let message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      // toast.error(message, { theme: "colored" });
    }
  };

  const checkSubscription = () => {
    let startDate = cStatsData?.[cStatsData?.length - 1]?.start_time;
    if (startDate === null) {
      setButtonLabel("Free Trial");
    } else {
      const currentDate = new Date();
      const startDateObject = new Date(startDate);
      const differenceInDays = Math.floor(
        (currentDate - startDateObject) / (1000 * 60 * 60 * 24)
      );
      if (differenceInDays <= 14) {
        setButtonLabel("Free Trial");
      } else {
        setButtonLabel("Live");
      }
    }
  };

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const rows = data.map((obj) =>
      headers.map((header) => obj[header]).join(",")
    );
    return [headers.join(","), ...rows].join("\n");
  };

  const exportToCSV = (data) => {
    const csvString = convertToCSV(data);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "invoice.csv");
  };

  const handleExport = (data) => {
    let newArray = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      console.log(element, "element");
      let obj = {
        ["Source"]: element?.name,
        ["Total Leads"]: element?.total_leads,
        ["Total Bookings"]: element?.total_booking,
        ["Total Cost"]: "$" + element?.total_cost,
      };
      newArray.push(obj);
    }

    exportToCSV(newArray);
  };

  const handleExportWithUsers = async () => {
    setLeadUserLoading(true);
    var enddate = new Date();
    var startdate = new Date(new Date().setDate(enddate.getDate() - 30));
    const requestPayload = new URLSearchParams();
    requestPayload.append(
      "start_time",
      moment(AiDate?.startDate ?? startdate).format("YYYY-MM-DD")
    );
    requestPayload.append(
      "end_time",
      moment(AiDate?.endDate ?? enddate).format("YYYY-MM-DD")
    );
    let res = await getExportUsers(
      selectedCompany?.value,
      requestPayload?.toString()
    );
    if (res?.status == 200) {
      setLeadUsers(res?.data);
      setLeadUserLoading(false);
    } else {
      setLeadUserLoading(false);
      let message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
    }
  };

  const getClientOption = () => {
    let arr = [];
    clientOptionsData?.clients?.length > 0 &&
      clientOptionsData?.clients?.map((ele) => {
        if (ele?.company?.id) {
          let obj = {
            label: ele?.name,
            value: ele?.company?.id,
            logo: ele?.logo,
            name: ele?.name,
            key: ele?.key,
          };
          arr.push(obj);
        }
      });
    setCompanyList(arr);
  };

  useEffect(() => {
    checkSubscription();
  }, [cStatsData?.[cStatsData?.length - 1]?.start_time]);

  useEffect(() => {
    clientOptionsData?.clients?.length > 0 && getClientOption();
    if (selectedCompany) {
      handleExportWithUsers();
      //
    }
  }, [selectedCompany, AiDate?.startDate, AiDate?.endDate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const company_id = "81";
    // const company_id = urlParams.get("company_id");
    const company_name = urlParams.get("company_name");
    if (company_id && company_name) {
      dispatch(selectedAiCompany({ value: company_id, label: company_name }));
      getStatistics({ value: company_id, label: company_name });
    } else {
      getStatistics(selectedCompany);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const conversation = urlParams.get("conversation");
    if (conversation == "true") {
      dispatch(updateActiveTab("conversation"));
    } else {
      if (activeTab == "invoice") {
        dispatch(updateActiveTab("invoice"));
      } else {
        dispatch(updateActiveTab("bookingStats"));
      }
    }
  }, []);

  const columns = [
    {
      title: "Source",
      dataIndex: "name",
      width: 150,
      fixed: "left",
    },
    {
      title: "Total Leads",
      dataIndex: "total_leads",
      width: 120,
      render: (val, record) => (
        <span
          style={{ cursor: record?.name != "Total" && "pointer" }}
          onClick={() => {
            record?.name != "Total" &&
              dispatch(updateActiveTab("conversation"));
            record?.name != "Total" &&
              dispatch(updateMessageType(LeadNames(record?.name)));
          }}
        >
          {val}
        </span>
      ),
    },
    {
      title: "Total Responses",
      dataIndex: "total_conversation",
      width: 150,
    },
    {
      title: "Total Bookings",
      dataIndex: "total_booking",
      width: 150,
    },
    {
      title: "Cancelled",
      dataIndex: "total_canceled_jobs",
      width: 100,
    },
    {
      title: "Bookings %",
      dataIndex: "total_booking_perc",
      width: 120,
      render: (val) => (
        <p style={{ margin: "0px" }}>{Number(val) ? val?.toFixed(0) : 0}%</p>
      ),
    },
    // {
    //   title: "Total Sales",
    //   dataIndex: "total_sales",
    //   width: 100,
    //   render: (val) => (
    //     <p style={{ margin: "0px", whiteSpace: "nowrap" }}>{`$${valueFormatter(
    //       val,
    //       1
    //     )}`}</p>
    //   ),
    // },
    // {
    //   title: "Total Revenue",
    //   dataIndex: "total_revenue",
    //   width: 100,
    //   render: (val) => (
    //     <p style={{ margin: "0px", whiteSpace: "nowrap" }}>{`$${valueFormatter(
    //       val,
    //       1
    //     )}`}</p>
    //   ),
    // },
    // {
    //   title: "Assisted Sales",
    //   dataIndex: "assisted_total_sales",
    //   width: 100,
    //   render: (val) => (
    //     <p style={{ margin: "0px", whiteSpace: "nowrap" }}>{`$${valueFormatter(
    //       val,
    //       1
    //     )}`}</p>
    //   ),
    // },
    {
      title: "Sales ( Total | Assisted) ",
      dataIndex: "assisted_total_revenue",
      width: 200,
      render: (_, data) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <p style={{ margin: "0px", width: "60px" }}>{`$${valueFormatter(
            data?.total_sales,
            1
          )}`}</p>
          <p style={{ width: "20px", margin: "0px" }}>{" | "}</p>
          <p style={{ margin: "0px", width: "60px" }}>{`$${valueFormatter(
            data?.assisted_total_sales,
            1
          )}`}</p>
        </div>
      ),
    },
    {
      title: "Revenue ( Total | Assisted) ",
      dataIndex: "assisted_total_revenue",
      width: 220,
      render: (_, data) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <p style={{ margin: "0px", width: "60px" }}>{`$${valueFormatter(
            data?.total_revenue,
            1
          )}`}</p>
          <p style={{ width: "20px", margin: "0px" }}>{" | "}</p>
          <p style={{ margin: "0px", width: "60px" }}>{`$${valueFormatter(
            data?.assisted_total_revenue,
            1
          )}`}</p>
        </div>
      ),
    },

    // {
    //   title: "Assisted Revenue",
    //   dataIndex: "assisted_total_revenue",
    //   width: 100,
    //   render: (val) => (
    //     <p style={{ margin: "0px", whiteSpace: "nowrap" }}>{`$${valueFormatter(
    //       val,
    //       1
    //     )}`}</p>
    //   ),
    // },

    // {
    //   title: "Avg Tickets",
    //   dataIndex: "avg_tickets",
    //   width: 100,
    //   render: (val) => (
    //     <p style={{ margin: "0px" }}>{numberWithCommas(val?.toFixed(2))}</p>
    //   ),
    // },
    {
      title: "Start Time",
      dataIndex: "start_time",
      width: 140,
      render: (val) => (
        <p style={{ margin: "0px", whiteSpace: "nowrap" }}>
          {val ? moment(val).format("DD MMM, YY") : "--"}
        </p>
      ),
    },
    {
      title: "No Reply Count",
      dataIndex: "no_reply_count",
      width: 150,
    },
  ];

  const invoiceColumns = [
    {
      title: "Source",
      dataIndex: "name",
      width: 150,
      fixed: "left",
    },
    {
      title: "Total Leads",
      dataIndex: "total_leads",
      width: 100,
      render: (val, record) => (
        <span
          style={{ cursor: record?.name != "Total" && "pointer" }}
          onClick={() => {
            record?.name != "Total" &&
              dispatch(updateActiveTab("conversation"));
            record?.name != "Total" &&
              dispatch(updateMessageType(LeadNames(record?.name)));
          }}
        >
          {val}
        </span>
      ),
    },
    {
      title: "Total Bookings",
      dataIndex: "total_booking",
      width: 100,
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      width: 100,
      render: (val) => <span>${valueFormatter(val, 1)}</span>,
    },
  ];

  const handleAction = (val) => {
    if (activeTab !== "bookingStats" && val == 0) {
      dispatch(updateActiveTab("bookingStats"));
      setSearch("");
    } else if (activeTab !== "invoice" && val == 1) {
      dispatch(updateActiveTab("invoice"));
      setSearch("");
    } else if (activeTab !== "scheduledBookings" && val == 2) {
      dispatch(updateActiveTab("scheduledBookings"));
      setSearch("");
    }
  };

  const handleCoversations = () => {
    dispatch(updateActiveTab("conversation"));
    setSearch("");
    navigate("/private-layout/conversations");
  };

  const tabs = [
    "Booking Buddy Performance",
    "Invoices",
    "Simply Scheduled Bookings",
  ];

  const activeTabFunc = useMemo(() => {
    let activeIndex = 0;
    tabs?.forEach((ele) => {
      if (activeTab === 'bookingStats') {
        activeIndex = 0;
      }
      if (activeTab === 'invoice') {
        activeIndex = 1;
      }
      if (activeTab === 'scheduledBookings') {
        activeIndex = 2;
      }
    });

    return activeIndex;
  }, [activeTab]);

  const content = (
    <div className="content">
      <div className="change-pass-box" onClick={() => handleExport(cStatsData)}>
        <p style={{ marginLeft: "10px" }}>Export without users</p>
      </div>
      <div
        className="change-pass-box"
        onClick={() => handleExcelExport(leadsUsers, cStatsData)}
      >
        <p style={{ marginLeft: "10px" }}>Export with users</p>
      </div>
    </div>
  );
  const handleUsersPermissions = () => {
    if (
      activeTab === "yelpuser" ||
      activeTab === "lsauser" ||
      activeTab === "smsuser"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Header
        title="Get Leverage"
        clientInfo={selectedCompany}
        isFilter={true}
        openFilter={() => setFilterDrawers(true)}
        isExport={activeTab == "invoice" && true}
        popOverContent={content}
        buttonLabelStatus={
          selectedCompany &&
          (!handleUsersPermissions() || companyId !== null) &&
          !loading &&
          !selectedLoading &&
          buttonLabel
        }
        isNotification={true}
      />
      <AiBookingWrapper
        selectedThemeColors={selectedThemeColors}
        loader={loading}
      >
        <SubscriptionCheck />
        {filterDrawers && (
          <AiFilter
            filterDrawers={filterDrawers}
            setFilterDrawers={setFilterDrawers}
            setCStatsData={setCStatsData}
            companyList={companyList}
            initialLoading={selectedLoading}
          />
        )}

        {selectedCompany && (
          <div
            // className="filterBtn-container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <TabComponent
              index={activeTabFunc}
              tabs={tabs}
              selectedIndex={handleAction}
              disableBtnsOnLoading={selectedLoading || loading}
            />
            <div className="conversation-button" onClick={handleCoversations}>
              <ConversationIcon /> Conversations
            </div>
          </div>
        )}
        <Wrappersubheader>
          {loading || selectedLoading ? (
            <LoadingBlock height={"calc(100vh - 200px)"} size={28} />
          ) : (
            <div className="table">
              {selectedCompany == null ? (
                <div className="not-selected-yet">
                  Kindly select a company for Booking Buddy Performance.
                </div>
              ) : (
                <>
                  {activeTab == "bookingStats" ? (
                    <Table
                      prefixCls="custom-ui-table"
                      columns={columns}
                      dataSource={cStatsData}
                      pagination={false}
                      scroll={{
                        x: "100%",
                      }}
                    />
                  ) : activeTab == "invoice" ? (
                    <Table
                      prefixCls="custom-ui-table"
                      columns={invoiceColumns}
                      dataSource={cStatsData}
                      pagination={false}
                    />
                  ) 
                  : activeTab == "scheduledBookings" ? (
                    <ScheduledBooking />
                  )
                  : activeTab == "yelpuser" ? (
                    <ManageYelpUser
                      filterModal={filterModal}
                      setFilterModal={setFilterModal}
                      searchValue={search}
                      setUserFilters={setUserFilters}
                    />
                  ) : activeTab == "smsuser" ? (
                    <ManageSmsUser
                      filterModal={filterModal}
                      setFilterModal={setFilterModal}
                      searchValue={search}
                      setUserFilters={setUserFilters}
                    />
                  ) : (
                    activeTab == "lsauser" && (
                      <ManageLsaUser
                        filterModal={filterModal}
                        setFilterModal={setFilterModal}
                        searchValue={search}
                        setUserFilters={setUserFilters}
                      />
                    )
                  )}
                </>
              )}
            </div>
          )}
        </Wrappersubheader>
      </AiBookingWrapper>
      <Watermark style={{ background: "transparent", paddingRight: "10px" }} />
    </>
  );
}

export const AiBookingWrapper = styled.div`
  padding: ${({ padding }) => padding || "32px 22px !important"};
  min-height: 100%;
  height: ${({ loader }) => (loader ? "100%" : "calc(100vh - 80px)")};
  width: 100%;

  .btn-freetrial {
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    padding: 2px 5px;
    white-space: nowrap;
    position: absolute;
    top: 1px;
    right: -73px;
    font-family: "Poppins", sans-serif;
  }

  .client-name {
    color: #000;
    position: relative;
    font-size: 16px;
  }
  .company-dropdown {
    width: 312px;
  }
  .filterBtn-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @media (max-width: 1160px) {
      flex-direction: column;
      gap: 10px;
    }

    @media (max-width: 860px) {
      flex-direction: column;
      align-items: baseline;
      gap: 10px;
    }
  }
  .btn-box {
    justify-content: flex-start;
    @media (max-width: 1030px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
    }
    @media (max-width: 682px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }

    button {
      white-space: nowrap;
    }
  }
  .filterBtn-Box {
    margin-bottom: 16px;
    display: flex;
    justify-content: start;
    border: 1px solid #e1e1e1;
    padding: 3px;
    border-radius: 6px;
    background: #f3f3f3;
    // width: 100%;
    button {
      border-style: none;
      background: #f3f3f3 !important;
      font-weight: 400;
      color: #242424;
      padding: 4px 11px;
      font-size: 13px;
      border-radius: 7px;
      margin-bottom: 0px;
    }
    .btnColor {
      color: #ffffff;
      background: #00a1e6 !important;
      font-weight: 400;
    }
    .disable {
      cursor: not-allowed;
      background: #f3f3f3 !important;
      font-weight: 400;
      color: black;
    }
  }

  .conversation-button {
    border-style: none;
    color: #f3f3f3 !important;
    background: #d80c0c;
    padding: 0 11px;
    border-radius: 6px;
    margin-bottom: 0px;
    align-items: center;
    display: flex;
    cursor: pointer;
    gap: 10px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    height: 34px;
  }
`;

const RangeWrapper = styled.div`
  width: 250px;
  padding: 0 10px;
  .ant-picker-range-separator {
    filter: none !important;
  }
`;

const PopWrapper = styled.div`
  color: red;
  position: relative;

  .content {
    width: 210px;
    border: 1px solid #cecece;
    background: white;
    padding: 4px;
    position: absolute;
    z-index: 9999 !important;
    top: 44px;
    left: -40px;
    border-radius: 9px;
  }
`;

const Wrappersubheader = styled.div`
  width: 100%;
  gap: 10px;
  border-radius: 12px;
  opacity: 0px;
  margin-top: 10px;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 7px;
  background: #f6f9fc;
  position: relative;

  input {
    height: 35px;
    width: 100%;
    background: #ffff;
    border-radius: 7px;
    border: 1px solid #cecece;
    padding: 0 25px 0 10px;
    color: #000;
    font-family: "Poppins", Arial, sans-serif;

    ::-webkit-input-placeholder {
      color: #000 !important;
    }

    &:focus {
      outline: none;
      border: 1px solid #000;
    }

    &::placeholder {
      color: #cecece !important;
    }
  }

  .btn {
    button {
      background-color: rgb(36, 36, 36);
      color: #fff;
      font-family: "Poppins", Arial, sans-serif;
      border-radius: 7px;
      font-weight: 500;
      font-size: 13px;
      border: none;
      padding: 0 10px;
      height: 43px;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  .searchIcon {
    position: absolute;
    top: 4px;
    right: 10px;
  }
`;
