import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Header from "../../components/Header";
import { debounce } from "../dynamicBudgetSheet/Year/commonFuc";
import { Sort } from "../../utils/Images/Images";
import { BackSVGIcon } from "../../utils/Icons/SvgIcons";
import { DatePicker, Modal, Pagination, Table } from "antd";
import Watermark from "../../components/Watermark";
import { getScheduledBookingListWithCancelToken } from "../../services/Collections";
import axios from "axios";
import LoadingBlock from "../../components/LoadingBlock";
import BookingModal from "../../components/BookingModal";
import { updateBookingDateFilter } from "./AiBookingSlice";
import dayjs from "dayjs";
import moment from "moment";
import RangeSelector from "../../components/RangePicker";
import { formatUSPhoneNumber } from "../../utils/common/commonFunction";
import AiFilter from "./AiFilter";

export default function ScheduledBooking() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [pagesize, setPageSize] = useState(10);
  const [extraData, setExtraData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
  });
  const dispatch = useDispatch();
  const [editDetails, setEditDetails] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const selectedCompany = useSelector(
    (state) => state?.aiBooking?.selectedCompany
  );
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const companyID = useSelector((state) => state?.loginAuth?.data?.company_id);
  const companyData = useSelector((state) => state?.loginAuth?.data);
  const { bookingDateFilter, bookingDateType, startDate, endDate } = useSelector((state) => state?.aiBooking);
  var enddate = new Date();
  var startdate = new Date(new Date().setDate(enddate.getDate() - 30));

  const [filter, setFilter] = useState(false);
  let finalId = companyID || selectedCompany?.value;
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );
  const [companyList, setCompanyList] = useState([]);

  const getClientOption = () => {
    let arr = [];
    clientOptionsData?.clients?.length > 0 &&
      clientOptionsData?.clients?.map((ele) => {
        if (ele?.company?.id) {
          let obj = {
            label: ele?.name,
            value: ele?.company?.id,
            logo: ele?.logo,
            name: ele?.name,
            key: ele?.key,
          };
          arr.push(obj);
        }
      });
    setCompanyList(arr);
  };

  useEffect(() => {
    clientOptionsData?.clients?.length > 0 && getClientOption();
  }, [selectedCompany]);


  const onChangePagination = (e, limit) => {
    setCurrentPage(e);
    setPageSize(limit);
  };

  let cancelToken = null;
  const handleBookingList = async (search) => {
    if (cancelToken) {
      cancelToken.cancel("Req cancelled by user");
      cancelToken = null; // Remove the cancel token from the mapping
    }
    try {
      cancelToken = axios.CancelToken.source();
      setLoading(true);
      // const id = location?.state?.id;
      const id = companyID || selectedCompany?.value;
      const params = new URLSearchParams();
      params.append("page", Number(currentPage));
      params.append("page_size", Number(pagesize));
      params.append("company_id", id);
      params.append("date_start", moment(startDate ?? startdate).format("YYYY-MM-DD"));
      params.append("date_end", moment(endDate ?? enddate).format("YYYY-MM-DD"));
      sortParam?.sortBasis && params.append("sort_by", sortParam?.sortBasis);
      sortParam?.sortType && params.append("order_by", sortParam?.sortType);
      if (search !== null || search !== "") {
        if (search === undefined) {
          if (searchWord) params.append("search", searchWord);
        } else {
          search && params.append("search", search);
        }
      }
      const res = await getScheduledBookingListWithCancelToken(
        params?.toString(),
        {
          cancelToken: cancelToken?.token,
        }
      );
      console.log(res, "res");
      if (res?.status === 200) {
        cancelToken = null;
        setLoading(false);
        setExtraData(res?.data?.total_count);

        if (res?.data?.data?.length > 0) {
          const arr = [];
          // eslint-disable-next-line array-callback-return
          res?.data?.data?.map((el, index) => {
            const obj = {
              ...el,
              index: pagesize * (currentPage - 1) + (1 + index),
            };
            arr.push(obj);
          });
          setTableData(arr);
        } else {
          setTableData([]);
          setLoading(false);
          // setExtraData(0);
        }
      }
    } catch (error) {
      console.log(error, "error");
      cancelToken = null;
      if (error?.name != "CanceledError") {
        setLoading(false);
        setTableData([]);
      }
    }
  };

  const handleSearchListing = useCallback(debounce(handleBookingList, 400), [
    startDate, endDate,
    bookingDateType,
  ]);

  useEffect(() => {
    handleSearchListing(searchWord);
  }, [searchWord]);

  const handleSearch = (value) => {
    setSearchWord(value);
  };

  useEffect(() => {
    handleBookingList();
  }, [currentPage, pagesize, sortParam, bookingDateType, startDate, endDate, finalId]);

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      render: (val) => <p>{val}.</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>First name</span>
        </TitleWrapper>
      ),
      dataIndex: "first_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Last name</span>
        </TitleWrapper>
      ),
      dataIndex: "last_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Phone</span>
        </TitleWrapper>
      ),
      dataIndex: "phone",
      render: (val) => <p>{formatUSPhoneNumber(val) || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Email</span>
        </TitleWrapper>
      ),
      dataIndex: "email",
    },
    {
      title: (
        <TitleWrapper>
          <span>Appt date</span>
        </TitleWrapper>
      ),
      dataIndex: "date",
      render: (val, data) => <p> {data?.appointment_date} </p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Appt time</span>
        </TitleWrapper>
      ),
      dataIndex: "date",
      render: (val, data) => (
        <p>
          {dayjs(`${data?.appointment_date} ${data?.appointment_time}`).format(
            "hh:mm A"
          )}
        </p>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <span>Job type</span>
        </TitleWrapper>
      ),
      dataIndex: "job_type",
      render: (val) => <p>{val ? val : "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Revenue</span>
        </TitleWrapper>
      ),
      dataIndex: "revenue",
      render: (val) => <p>{val ? val : "0"}</p>,
    },

    {
      title: "Action",
      align: "center",
      render: (_, data) => (
        <PodiumStatusWrapper>
          <button
            style={{
              color: "#f98229",
              border: "1px solid #f98229",
              display: "flex",
              gap: "5px",
            }}
            className="btn-connected"
            onClick={() => {
              setEditDetails(data);
              setDetailModal(true);
            }}
          >
            Details
          </button>
        </PodiumStatusWrapper>
      ),
    },
  ];

  return (
    <>
      {filter && (
        <AiFilter
          filterDrawers={filter}
          setFilterDrawers={setFilter}
          setCStatsData={null}
          companyList={companyList}
          initialLoading={false}
          stopApi={true}
        />
      )}
      <TableWrapper selectedThemeColors={selectedThemeColors}>
        {detailModal && (
          <Modal
            prefixCls="add-company"
            open={detailModal}
            centered
            onCancel={() => setDetailModal(false)}
            footer={false}
            closeIcon={null}
          >
            <ViewDetails data={editDetails} />
          </Modal>
        )}
        {/* <div className="create-btn">
          <div style={{ display: "flex", gap: "10px" }}>
            <RangeWrapper>
              <RangeSelector
                defaultDate={[compareDate.start, compareDate.end]}
                bgColor="#08051a"
                allowClear={true}
                handleChange={(d) => {
                  const start = d[0] ? moment(d[0]) : null;
                  const end = d[1] ? moment(d[1]) : null;
                  setCompareDate({ start, end });
                }}
                style={{ height: "34px" }}
              />
            </RangeWrapper>

            <form autoComplete="off" onSubmit={(e) => e?.preventDefault()}>
              <input
                type="text"
                placeholder="Search"
                style={{ height: "34px" }}
                onChange={(e) => {
                  e?.preventDefault();
                  setCurrentPage(1);
                  handleSearch(e?.target?.value);
                }}
                autoComplete="off"
              />
            </form>
          </div>
        </div> */}
        <div style={{ position: "relative" }}>
          {loading ? (
            <LoadingBlock height={"calc(100vh - 191px)"} size={28} />
          ) : (
            <Table
              prefixCls="custom-ui-table"
              columns={columns}
              dataSource={tableData}
              pagination={false}
              scroll={{
                x: "100%",
                y: "calc(100vh - 319px)",
              }}
            />
          )}
        </div>
        {!loading && (
          <div
            style={{
              marginTop: "23px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Pagination
              hideOnSinglePage={tableData?.length === 0 ? true : false}
              page={currentPage}
              limit={pagesize}
              current={currentPage}
              onChange={onChangePagination}
              total={extraData}
              defaultPageSize={pagesize}
              className="custom-ui-pagination"
              showSizeChanger={true}
            />
          </div>
        )}
      </TableWrapper>
      {/* {!loading && (
        <Watermark
          style={{
            width: "fit-content",
            position: "absolute",
            bottom: "24px",
            left: "20px",
          }}
          background="transparent"
        />
      )} */}
    </>
  );
}

const ViewDetails = ({ data }) => {
  return (
    <Container>
      <div className="title">
        <h3>Scheduled Booking Details</h3>
      </div>
      <div className="content">
        <div className="fields">
          <div style={{ textTransform: "capitalize" }}>First name</div>
          <div>{data?.first_name}</div>
        </div>

        <div className="fields">
          <div style={{ textTransform: "capitalize" }}>Last name</div>
          <div>{data?.last_name}</div>
        </div>

        <div className="fields">
          <div style={{ textTransform: "capitalize" }}>Phone</div>
          <div>{data?.phone}</div>
        </div>

        <div className="fields">
          <div style={{ textTransform: "capitalize" }}>Email</div>
          <div>{data?.email}</div>
        </div>

        <div className="fields">
          <div style={{ textTransform: "capitalize" }}>Date & Time</div>
          <div>
            {moment(
              `${data?.appointment_date} ${data?.appointment_time}`
            ).format("DD-MMM-YYYY HH:MM A")}
          </div>
        </div>

        <div className="fields">
          <div style={{ textTransform: "capitalize" }}>Job type</div>
          <div>{data?.job_type ? data?.job_type : "N/A"}</div>
        </div>
      </div>
    </Container>
  );
};

const TableWrapper = styled.div`
  * {
    margin: 0;
  }
  width: calc(100% - 20px);
  min-height: calc(100vh - 95px);
  height: calc(100% - 95px);
  height: 100%;
  padding: 20px 0 20px 20px !important;
  padding-top: 0px;

  width: 100%;
  min-height: calc(100vh - 140px);
  max-height: 100%;
  padding: 20px 0 17px !important;

  .create-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* padding: 20px 0px; */
    gap: 7px;
    padding-top: 0px;

    .backbtn-box {
      display: flex;
      align-items: center;
    }

    input {
      height: 43px;
      width: 100%;
      background: #ffff;
      border-radius: 7px;
      border: 1px solid #cecece;
      padding: 0 10px;
      color: #000;
      font-family: "Poppins", Arial, sans-serif;

      ::-webkit-input-placeholder {
        color: #000 !important;
      }

      &::placeholder {
        color: #cecece !important;
      }

      &:focus {
        outline: none;
        border: 1px solid #000;
      }
    }

    .btn {
      button {
        background-color: #343434;
        color: #fff;
        font-family: "Poppins";
        border-radius: 7px;
        font-weight: 600;
        font-size: 13px;
        border: none;
        width: 100px;
        height: 43px;
        cursor: pointer;
      }
    }
  }
  .campaignTable {
    transition: all 0.3s ease 0s;
    .campaignTable-thead {
      .campaignTable-cell {
        background: ${({ selectedThemeColors }) =>
    selectedThemeColors?.headerColor} !important;
      }
    }
    .campaignTable-tbody {
      .campaignTable-cell {
        background: ${({ selectedThemeColors }) =>
    selectedThemeColors?.CardsColor};
        color: ${({ selectedThemeColors }) =>
    selectedThemeColors?.textPrimaryColor};
        border-bottom: 1px solid rgba(232, 232, 237, 1) !important;
      }
    }
    .campaignTable-container {
      max-height: calc(100vh - 210px) !important;
      overflow: auto !important;
    }
    .campaignTable-thead {
      position: sticky !important;
      top: 0 !important;
      z-index: 99 !important;
    }
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;

  img {
    cursor: pointer;
    height: 20px;
  }
`;

const PodiumStatusWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;

  .btn-connect {
    border-radius: 12px;
    padding: 5px 10px;
    border-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    font-family: "Poppins", Arial, sans-serif;
    cursor: pointer;
  }
  .btn-connected {
    border-radius: 12px;
    padding: 5px 10px;
    border-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #71b497;
    color: #71b497;
    width: 90px;
    font-family: "Poppins", Arial, sans-serif;

    cursor: pointer;
  }
`;

const RangeWrapper = styled.div`
  width: 320px;
  padding: 0 2px;
  input {
    width: 150px;
    height: unset !important;
    background: unset !important;
    border: unset !important;
    border-radius: unset !important;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 24px;
  max-height: 96vh;
  padding-left: 20px;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #00000045;
    border-radius: 10px;
  }

  .title {
    h3 {
      color: #2c2c2c;
      font-family: "Poppins";
      font-size: 23px;
    }
  }
  .content {
    width: 100%;
    margin-top: 20px;

    .fields {
      padding: 10px;
      color: #2c2c2c;
      font-family: "Poppins";
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cecece30;
    }
  }
`;
