import React, { memo } from "react";
import { Table } from "antd";
import { formatToCurrency } from "../exceltable/commonFun";

function MismatchRevenue({ dataSource }) {
  const columns = [
    {
      title: "Category Name",
      dataIndex: "category",
      width: 100,
    },
    {
      title: "Revenue ( + )",
      dataIndex: "mismatch_attribution_plus",
      width: 100,
      render: (text) => {
        return text ? formatToCurrency(text) : "$0";
      }
    },
    {
      title: "Revenue ( - )",
      dataIndex: "mismatch_attribution_minus",
      width: 100,
      render: (text) => {
        return text ? formatToCurrency(text) : "$0";
      }
    },
  ];


  return (
    <div style={{ height: "calc(100vh - 217px)" }}>
      <Table
        prefixCls="custom-ui-table"
        columns={columns}
        dataSource={dataSource}
        pagination={{
          hideOnSinglePage: true,
          current: 1,
          pageSize: 100,
          total: 100,
          className: "custom-ui-pagination",
        }}
        scroll={{
          x: "100%",
          y: "calc(100vh - 240px)",
        }}
      />
    </div>
  );
}

export default memo(MismatchRevenue);
