import { Checkbox, DatePicker, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LoadingBlock, { LoaderIcon } from "../../../components/LoadingBlock";
import { Select } from "antd";
import dayjs from "dayjs";
import {
  businessUnitCategories,
  businessUnitSubCategories,
  getTitleLogsApi,
  getTitleLogsMonthlyApi,
  proposalsListingApi,
} from "../../../services/Collections";
import { useDispatch, useSelector } from "react-redux";
import { ApplyFilter } from "../YearSheet";
import {
  formatInteger,
  formatNumberDecimal,
  checkForInfinity,
} from "../Year/commonFuc";
import {
  finalReportData,
  finalReportFilter,
} from "../../exceltable/ExcelStore";
import Watermark from "../../../components/Watermark";
import { DownOutlined } from "@ant-design/icons";

export const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

const TableText = ({ val, weight }) => (
  <p style={{ margin: 0, fontWeight: weight || 400 }}>{val}</p>
);

const FinalReport = ({ filter, activeTabInReport }) => {
  const dispatch = useDispatch();
  const {
    data: loginData,
    summarySectionFilter: clientFilter,
    prefilledFilters,
  } = useSelector((state) => state?.loginAuth);

  const { yearSheetFilters, finalReport } = useSelector(
    (state) => state?.dynamicBudgetSheet
  );

  const activeClient =
    clientFilter == null
      ? prefilledFilters?.selectedClient?.key
      : clientFilter?.selectedClient?.key;

  const data = activeTabInReport
    ? finalReport?.predictedData
    : finalReport?.currentData;

  const activeFilter = activeTabInReport
    ? finalReport?.predictedFilter
    : finalReport?.currentFilter;

  const [tableData, setTableData] = useState(data || []);
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState(activeFilter?.month || null);
  const [noDataFound, setNoDataFound] = useState(false);
  const currentYear = dayjs().year();
  const [selectedYear, setSelectedYear] = useState(null);
  const uniqueSelectedMonths = yearSheetFilters?.uniqueMonths;
  const [categoriesListing, setCategoriesListing] = useState([]);
  const [subCategoriesListing, setSubCategoriesListing] = useState([]);
  const [catLoading, setCatLoading] = useState(false);
  const [subCatLoading, setSubCatLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(
    activeFilter?.category || []
  );
  const [selectedSubCategories, setSelectedSubCategories] = useState(
    activeFilter?.subCategory || []
  );
  const [newCustomer, setNewCustomer] = useState(
    activeFilter?.newCustomer || false
  );
  const [titleOption, setTitleOption] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(
    finalReport?.predictedFilter?.title || null
  );
  const [titleLoading, setTitleLoading] = useState(false);

  const onYearChange = (year) => {
    if (!year) {
      setSelectedYear(null);
      return;
    }
    setSelectedYear(Number(year));
  };

  const onMonthChange = (date) => {
    if (date == null || date == undefined) {
      setDate(null);
      return;
    }
    setDate(date);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category_name",
      width: 160,
      fixed: "left",
      render: (val) => <TableText val={val} weight={val == "Total" && 600} />,
    },
    {
      title: "Budget",
      dataIndex: "budget",
      width: 120,
      render: (val, record) => (
        <TableText
          val={"$" + formatInteger(val)}
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "Revenue",
      dataIndex: "sales",
      width: 120,
      render: (val, record) => (
        <TableText
          val={"$" + formatInteger(val)}
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "ROAS",
      dataIndex: "roas",
      width: 80,
      render: (_, record) => (
        <TableText
          val={formatNumberDecimal(
            checkForInfinity(record?.sales / record?.budget)
          )}
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "Leads",
      dataIndex: "leads",
      width: 70,
      render: (val, record) => (
        <TableText
          val={formatInteger(val)}
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "CPL",
      dataIndex: "cpl",
      width: 100,
      render: (_, record) => (
        <TableText
          val={
            "$" +
            formatInteger(checkForInfinity(record?.budget / record?.leads))
          }
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "Appts",
      dataIndex: "appts",
      width: 80,
      render: (val, record) => (
        <TableText
          val={formatInteger(val)}
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "CPA",
      dataIndex: "cpa",
      width: 120,
      render: (_, record) => (
        <TableText
          val={
            "$" +
            formatInteger(checkForInfinity(record?.budget / record?.appts))
          }
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "Conv %",
      dataIndex: "con_per",
      width: 80,
      render: (_, record) => (
        <TableText
          val={
            formatInteger(
              checkForInfinity(record?.appts / record?.leads) * 100
            ) + "%"
          }
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "jobs",
      dataIndex: "jobs",
      width: 80,
      render: (val, record) => (
        <TableText
          val={formatInteger(val)}
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "CPJ",
      dataIndex: "cpj",
      width: 100,
      render: (_, record) => (
        <TableText
          val={
            "$" + formatInteger(checkForInfinity(record?.budget / record?.jobs))
          }
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "Close %",
      dataIndex: "close_per",
      width: 80,
      render: (_, record) => (
        <TableText
          val={
            formatInteger(
              checkForInfinity(record?.jobs / record?.appts) * 100
            ) + "%"
          }
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
    {
      title: "Avg Ticket",
      dataIndex: "avg_ticket",
      width: 120,
      render: (_, record) => (
        <TableText
          val={
            "$" + formatInteger(checkForInfinity(record?.sales / record?.jobs))
          }
          weight={record?.category_name == "Total" && 600}
        />
      ),
    },
  ];

  const getCategoriesListing = async () => {
    try {
      setCatLoading(true);
      let res = await businessUnitCategories(activeClient);
      if (res?.status === 200) {
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.name,
          label: ele?.name,
        }));
        setCategoriesListing(updateData);
        setCatLoading(false);
      } else {
        message.error("Something went wrong");
        setCatLoading(false);
      }
    } catch (error) {
      setCatLoading(false);
      console.log("error in businessUnitCategories", error);
    }
  };

  const getSubCategoriesListing = async () => {
    try {
      setSubCatLoading(true);
      let res = await businessUnitSubCategories(activeClient);
      if (res?.status === 200) {
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.name,
          label: ele?.name,
        }));
        setSubCategoriesListing(updateData);
        setSubCatLoading(false);
      } else {
        message.error("Something went wrong");
        setSubCatLoading(false);
      }
    } catch (error) {
      setSubCatLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  const getTitleListing = async () => {
    try {
      let params = new URLSearchParams();
      params.append("user_id", loginData?.id);

      setTitleLoading(true);
      let res = await getTitleLogsApi(activeClient, params);
      if (res?.status === 200) {
        if (res?.data?.length == 0) {
          setTitleLoading(false);
          dispatch(
            finalReportFilter({
              filter: null,
              type: "pre",
            })
          );
          dispatch(finalReportData({ data: null, type: "pre" }));
          return;
        }
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.id,
          label: ele?.title,
        }));
        setTitleOption(updateData);
        setTitleLoading(false);
      } else {
        // message.error("Something went wrong");
        setTitleOption([]);
        setTitleLoading(false);
      }
    } catch (error) {
      setTitleLoading(false);
      console.log("error in title", error);
    }
  };

  const returnDataWithTotal = (res) => {
    let modified = res.filter(
      (ele) => ele?.budget != 0 || ele?.sales != 0 || ele?.roas != 0
    );
    let Total = {
      category_name: "Total",
      budget: 0,
      sales: 0,
      roas: 0,
      leads: 0,
      cpl: 0,
      appts: 0,
      cpa: 0,
      con_per: 0,
      jobs: 0,
      cpj: 0,
      close_per: 0,
      avg_ticket: 0,
      new_customer: 0,
    };
    modified.forEach((ele) => {
      Total.budget += Number(ele?.budget);
      Total.sales += Number(ele?.sales);
      Total.roas += Number(ele?.roas);
      Total.leads += Number(ele?.leads);
      Total.cpl += Number(ele?.cpl);
      Total.appts += Number(ele?.appts);
      Total.cpa += Number(ele?.cpa);
      Total.con_per += Number(ele?.con_per);
      Total.jobs += Number(ele?.jobs);
      Total.cpj += Number(ele?.cpj);
      Total.close_per += Number(ele?.close_per);
      Total.avg_ticket += Number(ele?.avg_ticket);
      Total.new_customer += Number(ele?.new_customer);
    });

    return [...modified, Total];
  };

  const getDataOFTitle = async () => {
    if (!selectedTitle?.id) {
      message.error("Please select the Predicted Title");
      return;
    }
    try {
      setLoader(true);
      let res = await getTitleLogsMonthlyApi(activeClient, selectedTitle?.id);
      dispatch(
        finalReportFilter({
          filter: {
            title: selectedTitle,
            month: selectedTitle?.month,
            category: selectedTitle?.bu_category,
            subCategory: selectedTitle?.bu_subcategory,
            newCustomer: selectedTitle?.customer_type !== "all",
          },
          type: "pre",
        })
      );

      if (res?.status === 200) {
        if (res.data?.length === 0) {
          setNoDataFound(true);
          setLoader(false);
          setTableData([]);
          setDate(null);
          return;
        }
        let final = returnDataWithTotal(res?.data);
        setTableData(final);
        dispatch(finalReportData({ data: final, type: "pre" }));
        setLoader(false);
      } else {
        message.error("Something went wrong");
        setLoader(false);
        selectedTitle(null);
      }
    } catch (error) {
      setLoader(false);
      console.log("error in finalreport", error);
    }
  };

  const handleChangeCategory = (value) => {
    setSelectedCategories(value);
  };

  const handleChangeSubCategory = (value) => {
    setSelectedSubCategories(value);
  };

  const handleTitleOnChange = (_, selectedObj) => {
    setDate(selectedObj?.month || null);
    setSelectedCategories(selectedObj?.bu_category || []);
    setSelectedSubCategories(selectedObj?.bu_subcategory || []);
    setNewCustomer(selectedObj ? selectedObj?.customer_type !== "all" : false);

    setSelectedTitle(selectedObj);
  };

  const handleFilter = async () => {
    if (!selectedYear) {
      message.error("Please select the Year & Month");
      return;
    }

    try {
      setLoader(true);
      let params = new URLSearchParams();

      selectedCategories?.length > 0 &&
        params.append("bu_category", selectedCategories);
      selectedSubCategories?.length > 0 &&
        params.append("bu_subcategory", selectedSubCategories);
      (date || date == 0) && params.append("month", date);
      params.append("year", selectedYear);
      params.append("client_key", activeClient);
      params.append("user_id", loginData?.id);
      params.append("customer_type", newCustomer ? "new" : "all");

      dispatch(
        finalReportFilter({
          filter: {
            month: date,
            category: selectedCategories,
            subCategory: selectedSubCategories,
            newCustomer: newCustomer,
          },
          type: "cur",
        })
      );

      let res = await proposalsListingApi(activeClient, params);
      if (res?.status === 200) {
        if (res?.data?.length === 0 || res?.data?.current?.length === 0) {
          setNoDataFound(true);
          setLoader(false);
          setTableData([]);
          dispatch(finalReportData({ data: [], type: "cur" }));
          return;
        }
        let final = returnDataWithTotal(res?.data?.current);
        setTableData(final);
        dispatch(finalReportData({ data: final, type: "cur" }));
        setLoader(false);
      } else {
        message.error("Something went wrong");
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log("error in finalreport", error);
    }
  };

  useEffect(() => {
    activeTabInReport && setSelectedTitle(finalReport?.predictedFilter?.title);
    setTableData(data || []);
    setDate(activeFilter?.month || null);
    setSelectedCategories(activeFilter?.category || []);
    setSelectedSubCategories(activeFilter?.subCategory || []);
    setNewCustomer(activeFilter?.newCustomer || false);
  }, [activeTabInReport, activeFilter]);

  useEffect(() => {
    setCategoriesListing([]);
    setSubCategoriesListing([]);
    setTitleOption(null);

    if (activeClient) {
      getCategoriesListing();
      getSubCategoriesListing();
      getTitleListing();
    }
  }, [activeClient]);

  return (
    <FinalReportWrapper size={filter} disCheck={activeTabInReport}>
      <FilterTabsWrapper position={filter}>
        {filter && (
          <div className="wrap">
            {activeTabInReport && (
              <section>
                <div className="label">Predicted Title</div>
                <Select
                  placeholder="Please select"
                  prefixCls="inner-select2"
                  className="select"
                  loading={titleLoading}
                  onChange={handleTitleOnChange}
                  options={titleOption}
                  value={titleLoading ? null : selectedTitle?.id}
                  size="middle"
                  allowClear
                />
              </section>
            )}
            <section>
              <div className="label">Select Year & Month</div>
              <div className="flex">
                <DatePicker
                  disabled={activeTabInReport}
                  picker="year"
                  value={
                    activeTabInReport
                      ? selectedTitle?.year
                        ? dayjs().year(selectedTitle?.year)
                        : null
                      : selectedYear
                      ? dayjs().year(selectedYear)
                      : null
                  }
                  onChange={(_, dateString) => onYearChange(dateString)}
                  disabledDate={(date) =>
                    date.year() > currentYear || date.year() < currentYear - 1
                  }
                />
                <Select
                  suffixIcon={activeTabInReport || <DownOutlined />}
                  disabled={activeTabInReport}
                  placeholder="Select month"
                  prefixCls="inner-select2"
                  className="select"
                  onChange={onMonthChange}
                  // options={months}
                  value={date}
                  size="middle"
                  allowClear
                >
                  {months?.map((item) => (
                    <Option key={item?.value} value={item?.value}>
                      <span
                        style={{
                          color:
                            uniqueSelectedMonths?.includes(item?.value) &&
                            item?.value != date &&
                            "green",
                        }}
                      >
                        {item?.label}
                      </span>
                    </Option>
                  ))}
                </Select>
              </div>
            </section>

            <section>
              <div className="label">Category</div>
              <Select
                suffixIcon={activeTabInReport || <DownOutlined />}
                disabled={activeTabInReport}
                placeholder="Please select"
                prefixCls="inner-select2"
                className="select"
                loading={catLoading}
                onChange={handleChangeCategory}
                options={categoriesListing}
                value={selectedCategories || []}
                mode="multiple"
                size="middle"
                maxTagCount={2}
              />
            </section>
            <section>
              <div className="label">Sub-Category</div>
              <Select
                suffixIcon={activeTabInReport || <DownOutlined />}
                disabled={activeTabInReport}
                placeholder="Please select"
                prefixCls="inner-select2"
                className="select"
                loading={subCatLoading}
                onChange={handleChangeSubCategory}
                options={subCategoriesListing}
                value={selectedSubCategories || []}
                mode="multiple"
                maxTagCount={2}
              />
            </section>

            <section className="new-customers">
              <div className="label">New Customers</div>
              <Checkbox
                prefixCls="newCustomer"
                disabled={activeTabInReport}
                checked={newCustomer}
                style={{ height: "32px" }}
                onChange={(e) => setNewCustomer(e?.target?.checked)}
              />
            </section>

            <button
              className="saveBtn submit"
              onClick={() =>
                !loader &&
                (activeTabInReport ? getDataOFTitle() : handleFilter())
              }
            >
              {loader ? <LoaderIcon size="14" /> : "Submit"}
            </button>
          </div>
        )}
      </FilterTabsWrapper>
      <div className="Wrap">
        {loader ? (
          <LoadingBlock
            // height={filter ? "calc(100vh - 291px)" : "calc(100vh - 125px)"}
            height={"calc(100vh - 210px)"}
            size={28}
          />
        ) : tableData?.length > 0 ? (
          <Table
            prefixCls="custom-final-table-ui"
            columns={columns}
            dataSource={tableData}
            pagination={false}
            // scroll={{
            //   x: "100%",
            //   y: "calc(100vh - 210px)",
            // }}
          />
        ) : (
          <ApplyFilter
            title="title"
            yearSheetFilter={true}
            message={
              noDataFound &&
              "The prediction data for the specified period does not exist. Please set up the prediction data for the given period."
            }
          />
        )}
      </div>
      {tableData?.length > 0 && !loader && (
        <Watermark
          style={{ paddingBottom: "6px" }}
          background={"transparent"}
        />
      )}
    </FinalReportWrapper>
  );
};

export default FinalReport;

const FinalReportWrapper = styled.div`
  width: 100%;
  .Wrap {
    overflow: auto;
    min-height: ${({ size }) =>
      size ? "calc(100vh - 230px)" : "calc(100vh - 145px)"};
    max-height: 100%;
  }

  .newCustomer-checked .newCustomer-inner {
    background-color: ${({ disCheck }) =>
      disCheck ? "rgba(0, 0, 0, 0.04) !important" : "#000 !important"};
    border-color: ${({ disCheck }) =>
      disCheck ? "#d9d9d9 !important" : "#000 !important"};
  }

  .newCustomer-wrapper:not(.newCustomer-wrapper-disabled):hover
    .newCustomer-checked:not(.newCustomer-disabled)
    .newCustomer-inner {
    background-color: #000 !important;
    border-color: transparent !important;
  }

  .newCustomer:not(.newCustomer-disabled):hover .newCustomer-inner {
    border-color: #000 !important;
  }

  .newCustomer .newCustomer-input:focus-visible + .newCustomer-inner {
    outline: 3px solid #000 !important;
  }
`;

export const FilterTabsWrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: ${({ position }) => (position ? "space-between" : "right")};
  align-items: end;
  background: ${({ position }) => (position ? "#fff" : "transparent")};
  padding: ${({ position }) => (position ? "10px" : "0px")};
  border-radius: 9px;
  border: ${({ position }) => (position ? "1px solid rgb(210, 212, 222)" : "")};
  margin: ${({ position }) => (position ? "10px 0" : "0 0 10px 0")};

  .flex {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #7b7f91;
  }
  .wrap {
    display: flex;
    align-items: end;
    gap: 15px;
  }

  .saveBtn {
    background: rgb(36, 36, 36);
    color: rgb(255, 255, 255);
    border-style: none;
    height: 33px;
    font-size: 13px;
    padding-right: 12px;
    padding-left: 12px;
    cursor: pointer;
    font-family: Poppins;
    height: 34px;
    min-width: 68px;
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
  }

  .submit {
    background: #d80c0c;
  }

  .select {
    .inner-select2-selector {
      min-width: 180px !important;
      background: white !important;
    }
  }

  .new-customers {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row-reverse;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: "start";
  margin: 7px 0 10px;
  .label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #7b7f91;
  }

  .select {
    .inner-select2-selector {
      min-width: 250px !important;
      background: white !important;
    }
  }
`;

const SelectNoData = styled.div`
  padding: 10px;
  color: rgb(107, 114, 128);
`;
