import React, { useEffect, useMemo, useState } from "react";
import BudgetSheetNavbar from "../../components/BudgetSheetNavbar";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import YearSheet from "./YearSheet";
import Goals from "./Goals/Goals";
import Header from "../../components/Header";
import SummaryReport from "./SummaryReport/SummaryReport";
import FinalReport from "./finalReport/FinalReport";
import { getUniqueMonthsApi } from "../../services/Collections";
import { updateYearSheetFilters } from "../exceltable/ExcelStore";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  formatInteger,
  formatNumberDecimal,
  checkForInfinity,
} from "./Year/commonFuc";

const DynamicBudgetSheet = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const loginAuth = useSelector((state) => state?.loginAuth);
  const goalFilters = useSelector(
    ({ dynamicBudgetSheet }) => dynamicBudgetSheet?.goalFilters
  );
  // const selectedHistoryData = useSelector(
  //   ({ dynamicBudgetSheet }) => dynamicBudgetSheet?.selectedHistoryData
  // );
  const { yearSheetFilters, yearData, finalReport } = useSelector(
    (state) => state?.dynamicBudgetSheet
  );
  const [active, setActive] = useState(loginAuth?.dynamicSheetTab || "goals");
  const [yearsheetData, setYearsheetData] = useState(yearData?.current || []);
  const [year, setYear] = useState(goalFilters?.year || null);
  const [yearSheetFilter, setYearSheetFilter] = useState(true);
  const [openPop, setOpenPop] = useState(false);
  const { data, prefilledFilters, summarySectionFilter } = loginAuth;
  const activeClient =
    summarySectionFilter?.selectedClient || prefilledFilters?.selectedClient;

  const [activeTabInReport, setActiveTabInReport] = useState("predicted");

  const handleUnique = async () => {
    try {
      let params = new URLSearchParams();
      params.append("user_id", data?.id);
      params.append("year", dayjs().year() + 1);
      // params.append("month", date || 0);

      let res = await getUniqueMonthsApi(activeClient?.key, params);

      if (res?.status === 200) {
        dispatch(
          updateYearSheetFilters({
            ...yearSheetFilters,
            uniqueMonths: res?.data,
          })
        );
      }
    } catch (error) {
      setLoader(false);
      console.log("error in finalreport", error);
    }
  };

  const isExportActive = useMemo(() => {
    if (active === 'report' && finalReport?.predictedData?.length > 0 && activeTabInReport == "predicted") {
      return true
    } else if (active === 'report' && finalReport?.currentData?.length > 0 && activeTabInReport !== "predicted") {
      return true
    } else {
      return false
    }
  }, [activeTabInReport, finalReport,active]);

  const activeExportData = useMemo(() => {
    if (activeTabInReport == "predicted") {
      return { data: finalReport?.predictedData, fileName: finalReport?.predictedFilter?.title?.title + ".csv" }
    } else {
      return { data: finalReport?.currentData, fileName: moment().month(finalReport?.currentFilter?.month - 1).format('MMMM') + ".csv" }
    }
  }, [activeTabInReport, finalReport])

  function jsonToCsv(jsonData, fileName = "data.csv") {
    if (!jsonData || !jsonData.length) {
      console.error("Invalid JSON data");
      return;
    }
    let csv = '';

    const headers = [
      "category_name", "budget", "sales", "roas", "leads", "cpl",
      "appts", "cpa", "con_per", "jobs", "cpj", "close_per", "avg_ticket",
    ];
    const displayHeaders = [
      "Category", "Budget", "Revenue", "ROAS", "Leads", "CPL",
      "Appts", "CPA", "Conv %", "jobs", "CPJ", "Close %", "Avg Ticket",
    ];
    csv += displayHeaders.join(',') + '\n';

    let one = ["budget", "sales"]
    let two = ["leads", "appts", "jobs"]
    jsonData.forEach(obj => {
      const values = headers.map(header => {
        let value = obj[header] === null || obj[header] === undefined ? '' : obj[header];

        let check = obj?.category_name !== "Total";

        if (one?.includes(header)) {
          return value ? `"$${formatInteger(value)}"` : "$0";
        }

        if (header == "roas") {
          return value ? `"${formatNumberDecimal(
            check ? value : checkForInfinity(obj?.sales / obj?.budget)
          )}"` : "0.00";
        }

        if (two?.includes(header)) {
          return value ? `"${formatInteger(value)}"` : "0";
        }

        if (header == "cpl") {
          return value ? `"$${formatInteger(check ? value : checkForInfinity(obj?.budget / obj?.leads))}"` : "0";
        }
        if (header == "cpa") {
          return value ? `"$${formatInteger(check ? value : checkForInfinity(obj?.budget / obj?.appts))}"` : "0";
        }
        if (header == "con_per") {
          return value ? `"${formatInteger(
            check ? value : checkForInfinity(obj?.appts / obj?.leads) * 100
          )}%"` : "0";
        }
        if (header == "cpj") {
          return value ? `"$${formatInteger(check ? value : checkForInfinity(obj?.budget / obj?.jobs))}"` : "0";
        }
        if (header == "close_per") {
          return value ? `"${check ? formatInteger(value) : formatInteger(
            checkForInfinity(obj?.jobs / obj?.appts) * 100
          )}%"` : "0";
        }
        if (header == "avg_ticket") {
          return value ? `"$${formatInteger(check ? value : checkForInfinity(obj?.sales / obj?.jobs))}"` : "0";
        }
        return value;
      });
      csv += values.join(',') + '\n';
    });

    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleUnique();
  }, [activeClient?.key]);
  console.log("activeExportData?.data", activeExportData?.data)

  return (
    <>
      <Header
        isSetting={active == "goals"}
        openSetting={() => setOpenPop(true)}
        isNotification={true}
        title="Dynamic Budget Sheet"
        isExport={isExportActive}
        clickExport={() => jsonToCsv(activeExportData?.data, activeExportData?.fileName)}
        setYear={setYear}
      />

      <SummaryWrapper style={{ background: active == "summary" && "#f3f3f3" }}>
        <BudgetSheetNavbar
          active={active}
          setActive={setActive}
          setYearSheetFilter={setYearSheetFilter}
          yearSheetFilter={yearSheetFilter}
          openPop={openPop}
          setOpenPop={setOpenPop}
          activeTabInReport={activeTabInReport}
          setActiveTabInReport={setActiveTabInReport}
        />
        {active == "goals" ? (
          <Goals
            selectedHistory={goalFilters || {}}
            year={year}
            setYear={setYear}
          />
        ) : active == "yearsheet" ? (
          <YearSheet
            handleUnique={handleUnique}
            // selectedHistory={selectedHistoryData || []}
            yearsheetData={yearsheetData}
            setYearsheetData={setYearsheetData}
            yearSheetFilter={yearSheetFilter}
          />
        ) : active == "summary" ? (
          <SummaryReport />
        ) : (
          <FinalReport
            filter={yearSheetFilter}
            activeTabInReport={activeTabInReport === "predicted"}
          />
        )}
      </SummaryWrapper>
    </>
  );
};

export default DynamicBudgetSheet;

const SummaryWrapper = styled.div`
  padding: 10px 10px 0 10px;
  background: #fff;
  height: calc(100vh - 56px);
  overflow: auto;
  .main-heading {
    margin-bottom: 20px;
    font-size: 16px;
    font-family: "Poppins";
  }
`;
