import { motion } from "framer-motion";
import styled from "styled-components";

export const QualityAssWrapper = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : "100%")};
  background: #f2f2f2;

  .main-wrapper {
    padding: 20px 20px 16px 20px;
  }

  main {
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .sub-header {
      display: flex;
      justify-content: space-between;
    }

    .card-wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      
      @media (max-width: 930px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
  }
`;

export const CardWrapper = styled(motion.div)`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;
  border-radius: 16px;
`;

export const CardContent = styled(motion.div)`
  padding: 16px;
  background: #ffffff;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  background-color: #3b82f6;
  color: white;
`;

export const TextContainer = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Label = styled.p`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #111111;
  margin: 0;
`;

export const PhoneNumber = styled(motion.p)`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
  color: #696969;
  margin: 0;
`;

export const ButtonContainer = styled(motion.div)`
  display: flex;
  gap: 0.5rem;
`;

export const StyledButton = styled(motion.button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid;
  cursor: pointer;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
`;

export const EditButton = styled(StyledButton)`
  color: #2563eb;
  border-color: #93c5fd;
  background-color: #fff;
  gap: 8px;

  &:hover {
    background-color: #dbeafe;
    color: #1d4ed8;
  }
`;

export const DeleteButton = styled(StyledButton)`
  color: #dc2626;
  border-color: #fca5a5;
  background-color: #fff;
  gap: 8px;

  &:hover {
    background-color: #fee2e2;
    color: #b91c1c;
  }
`;
