import React, { useEffect, useState } from "react";
import { Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getCampaignListing,
  getDataFromFilters,
} from "../../services/Collections";
import {
  dashboardData,
  prefilledFilters,
  selectedModule,
  updateLoginType,
  updateSelectAll,
  updateVisualizationData,
} from "../../app/Auth/signIn/LogInSlice";
import { updateCampaignData } from "../../module/campaignTable/CampaignSlice";
import {
  selectedAiCompany,
  updateDate,
} from "../../module/AIBooking/AiBookingSlice";
import styled from "styled-components";
import { MainAppIcon } from "../../utils/Images/Images";
import moment from "moment";
import { NoDataDropdown } from "../../components/common/NoDataDropdown";
import { filterClientsBySection } from "../../utils/common/commonFunction";
import { LoaderIcon } from "../../components/LoadingBlock";

export default function SelectClientModal({
  isModalOpen,
  setSClient,
  tabName,
  sectionName,
  clientOptions,
  optionsLoading,
  path,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientList, setClientList] = useState(clientOptions ?? []);
  // Fetch logged-in user info
  const currentUserDeatils = useSelector(({ loginAuth }) => loginAuth?.data);

  // Handle client list formatting based on section
  useEffect(() => {
    if (sectionName === "ai-booking-stats") {
      let arr = [];
      clientOptions?.length > 0 &&
        clientOptions?.map((ele) => {
          if (ele?.company?.id) {
            let obj = {
              ...ele,
              label: ele?.name,
              value: ele?.name,
            };
            arr.push(obj);
          }
        });
      setClientList(arr);
    } else {
      let filteredOptions = filterClientsBySection(
        clientOptions ?? [],
        sectionName
      );
      setClientList(filteredOptions ?? []);
    }
  }, [sectionName, clientOptions]);

  // Main function to handle access based on section
  const handleAccess = async () => {
    if (!selectedClient) {
      toast.warning("Please select a client", { theme: "colored" });
      return;
    }

    const hasAccess =
      selectedClient?.allocated_boards?.includes(sectionName) ||
      sectionName === "settings" ||
      sectionName === "dynamic-budget-sheet";

    if (!hasAccess) {
      toast.warning(
        "You are not allowed to access this section with this client.",
        { theme: "colored" }
      );
      return;
    }

    if (["dashboard", "data-table", "ai-booking-stats"].includes(sectionName)) {
      await submitHandler();
    } else {
      const today = new Date();
      const priorDate = new Date(today.setDate(today.getDate() - 30));
      const startdate = moment(priorDate).format("YYYY-MM-DD");
      const enddate = moment(new Date()).format("YYYY-MM-DD");

      // const firstDayOfCurrentMonth = moment().startOf('month');
      const oneYearAgo = moment().subtract(1, 'year');
      const LastYearStartdate = moment(oneYearAgo).format("YYYY-MM");
      const LastYearEnddate = moment(new Date()).format("YYYY-MM");

      const requestPayload = {
        campaigns: null,
        date_start: startdate,
        date_end: enddate,
        compare_enable: 0,
        date_compare_start: startdate,
        date_compare_end: enddate,
      };

      const payload = {
        ...requestPayload,
        selectedClient: selectedClient,
      };

      const dateObject = { date_start: startdate, date_end: enddate };

      dispatch(prefilledFilters(payload));
      dispatch(
        updateCampaignData({
          ...payload,
          interval: 90,
          selectSource: [],
          isCampaignShowTable: false,
          misattributed_jobs: 0
        })
      );
      dispatch(
        updateVisualizationData({
          selectedClient: selectedClient,
          date_start: LastYearStartdate,
          date_end: LastYearEnddate,
          customer_type: "all",
          new_customer_nod: 30,
        })
      );
      dispatch(selectedModule(path));
      dispatch(updateLoginType(true));
      navigate(`/private-layout/${path}`);
      dispatch(updateDate(dateObject));
      dispatch(dashboardData({ current: null }));
    }
  };

  // Function to handle form submission based on section
  const submitHandler = async () => {
    if (sectionName === "ai-booking-stats") {
      dispatch(
        selectedAiCompany({
          value: selectedClient?.company?.id,
          label: selectedClient?.name,
          name: selectedClient?.name,
          logo: selectedClient?.logo,
        })
      );
      currentUserDeatils?.client_admin_type == 1
        ? navigate("/private-layout/booking-buddy-performance")
        : navigate("/private-layout/conversations");
      dispatch(dashboardData({ current: null }));
    } else {
      if (
        selectedClient?.allocated_boards?.includes(sectionName) ||
        sectionName === "settings"
      ) {
        setLoading(true);
        const sourceOption = await handleCampaignList(selectedClient);
        if (!sourceOption) {
          setLoading(false);
          return;
        }

        const today = new Date();
        const priorDate = new Date(today.setDate(today.getDate() - 30));
        const startdate = moment(priorDate).format("YYYY-MM-DD");
        const enddate = moment(new Date()).format("YYYY-MM-DD");

        // const prevToPrevStartDate = moment().subtract(2, "months").startOf("month").format("YYYY-MM-DD");
        // const prevToPrevEndDate = moment().subtract(2, "months").endOf("month").format("YYYY-MM-DD");

        const daysInRange = moment(enddate).diff(moment(startdate), "days") + 1;
        const prevToPrevStartDate = moment(startdate).subtract(daysInRange, "days").format("YYYY-MM-DD");
        const prevToPrevEndDate = moment(enddate).subtract(daysInRange, "days").format("YYYY-MM-DD");

        // const firstDayOfCurrentMonth = moment().startOf('month');
        const oneYearAgo = moment().subtract(1, 'year');
        const LastYearStartdate = moment(oneYearAgo).format("YYYY-MM");
        const LastYearEnddate = moment(new Date()).format("YYYY-MM");

        const requestPayload = {
          // campaigns: [sourceOption?.[0]?.value],
          // campaigns: sourceOption?.map((ele) => ele?.value),
          campaigns: [],
          date_start: startdate,
          date_end: enddate,
          compare_enable: 1,
          date_compare_start: prevToPrevStartDate,
          date_compare_end: prevToPrevEndDate,
        };

        const payload = {
          ...requestPayload,
          selectedClient: selectedClient,
        };

        const dateObject = { date_start: startdate, date_end: enddate };

        dispatch(prefilledFilters(payload));
        dispatch(updateSelectAll(true));
        dispatch(
          updateCampaignData({
            ...payload,
            interval: 90,
            selectSource: [],
            isCampaignShowTable: false,
          })
        );

        dispatch(
          updateVisualizationData({
            selectedClient: selectedClient,
            date_start: LastYearStartdate,
            date_end: LastYearEnddate,
            customer_type: "all",
            new_customer_nod: 30,
          })
        );

        dispatch(updateDate(dateObject));
        dispatch(dashboardData({}));
        navigate("/");

        setLoading(false);
      } else {
        toast.warning(
          "You are not allowed to access this section with this client."
        );
      }
    }
  };

  // Fetch and format campaign list for the selected client
  const handleCampaignList = async (client) => {
    try {
      const res = await getCampaignListing(client?.key);
      if (res?.status === 200) {
        const campaigns = res?.data?.map((campaign) => ({
          value: campaign?.campaignid,
          label: campaign?.campaignname,
        }));
        return campaigns;
      } else {
        toast.error(res?.message || "Something went wrong");
        return null;
      }
    } catch (error) {
      console.error("Error fetching campaigns", error);
      toast.error("Something went wrong while fetching campaigns");
      return null;
    }
  };

  return (
    <Modal open={isModalOpen} footer={null} centered closable={false}>
      <SelectClientModalWrapper>
        <AppLogo>
          <img src={MainAppIcon} alt="app-icon" />
        </AppLogo>
        <header>Select The Client</header>
        <p>Apply Client filter for {tabName}</p>
        <Select
          size={"large"}
          onChange={(_, data) => setSelectedClient(data)}
          prefixCls="inner-select2"
          style={{ width: "100%", height: "48px" }}
          loading={optionsLoading}
          placeholder="Please select"
          options={clientList}
          notFoundContent={
            optionsLoading ? (
              <NoDataDropdown text={"Processing..."} />
            ) : (
              <NoDataDropdown text={"No Data"} />
            )
          }
        />
      </SelectClientModalWrapper>

      <ButtonWrapper>
        <button onClick={() => setSClient(false)}>Cancel</button>
        <button onClick={loading ? null : handleAccess}>
          {loading ? <LoaderIcon /> : "Apply"}
        </button>
      </ButtonWrapper>
    </Modal>
  );
}

// Styled components
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  gap: 8px;

  button {
    width: 100%;
    height: 48px;
    border-radius: 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    border: none;

    &:first-child {
      background: #000;
    }
    &:last-child {
      background: #d80c0c;
    }
  }
`;

const SelectClientModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  header {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #111111;
  }

  p {
    font-family: Poppins;
    font-size: 14px;
    text-align: center;
    color: #7a7b7a;
  }
`;

const AppLogo = styled.div`
  margin-bottom: 10px;

  img {
    height: 54px;
  }
`;
