import { Col, Drawer, Row, Skeleton, Spin, Switch, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Cell, Legend, Pie, PieChart, Tooltip as ChartTooltip } from "recharts";
import styled from "styled-components";
import { toast } from "react-toastify";
import {
  downloadUnbookedUserInfo,
  getAICallClassification,
  getTotalBookingAiCall,
  getWeekOverWeek,
  inboundedcallclassification,
} from "../../services/Collections";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import useWindowWidth from "../../customHook/GetWindowWidth";
import ClassificationFilter from "../../components/ClassificationFilter";
import { Sort, SortAsc, SortDesc } from "../../utils/Images/Images";
import { sortingFunction } from "../../utils/common/commonFunction";
import { useCallback } from "react";
import { DownloadIcon, RefreshIcon } from "../../utils/Icons/SvgIcons";
import { updateAiCallClassificationCellData } from "../../app/Auth/signIn/LogInSlice";
import Header from "../../components/Header";
import { LoadingOutlined } from "@ant-design/icons";
import ThreeDotLoader from "../../components/newComponent/ThreeDotLoader";
import Watermark from "../../components/Watermark";
import DoubleBarChart from "./DoubleBarChart";
import BarLineChart from "./BarLineChart";
import SingleBarChart from "./SingleBarChart";
import DoubleLineChart from "./DoubleLineChart";
import TempPlayGroundGraph from "./TempPlayGroundGraph";
import SimpleDoubleBarChart from "./SimpleDoubleBarChart";

const COLORS = [
  "#FBBC04",
  "#29C98A",
  "#4285F4",
  "#71C287",
  "#FF7F21",
  "#46BDC6",
  "#7BAAF7",
  "#37ED6A",
  "#EA4335",
  "#2CAFFE",
  "#2EE0CA",
  "#FA4B42",
];

export const CallClassification = () => {
  const [inboundCalls, setIboundCallData] = useState(null);
  const [data2, setData2] = useState([]);
  const [inboundCallsLoader, setInboundCallsLoader] = useState(false);
  const [aiCallsData, setAiCallsData] = useState([]);
  const [width] = useWindowWidth();
  const [aiCallsGraphData, setAiCallsGraphData] = useState([]);
  const [aiCallsLoader, setAiCallsLoader] = useState(true);
  const [switchToggle, setSwitchToggle] = useState(false);
  const [openDrawer, setFilterDrawer] = useState(false);
  const [weekOverWeekData, setWeekOverWeekData] = useState([]);
  const [weekoverweekTotalCalls, setWeekoverweekTotalCalls] = useState(null);
  const [weekoverweekFinalData, setWeekoverweekFinalData] = useState([]);
  const [weekoverweekPage, setWeekoverweekPage] = useState(0);
  const [weekOverLoader, setWeekOverLoader] = useState(true);

  const newFilters = useSelector(
    (state) => state?.loginAuth?.classificationSectionFilters
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const activeClient = newFilters?.selectedClient
    ? newFilters?.selectedClient
    : prefilledData?.selectedClient;

  const [graphPage, setGraphPage] = useState(0);
  const [sortParam, setSortParam] = useState({
    sortType: "desc",
    sortBasis: "total_calls",
  });
  const [tableData, setTableData] = useState([]);
  const AllClients = useSelector(
    (state) => state?.loginAuth?.clientsList?.clients
  );
  const dispatch = useDispatch();

  // const getSortParam = (sortBasis) => {
  //   if (sortBasis === sortParam.sortBasis) {
  //     let obj = {
  //       sortType: sortParam.sortType === "desc" ? "asc" : "desc",
  //       sortBasis: sortBasis,
  //     };
  //     setSortParam(obj);
  //     setTableData(sortingFunction(aiCallsData, obj?.sortBasis, obj?.sortType));
  //   } else {
  //     let obj = {
  //       sortType: "desc",
  //       sortBasis: sortBasis,
  //     };
  //     setSortParam(obj);
  //     setTableData(sortingFunction(aiCallsData, obj?.sortBasis, obj?.sortType));
  //   }
  // };

  // const srcSortImage = (Basis) => {
  //   if (Basis === sortParam.sortBasis) {
  //     if (sortParam.sortType === "asc") {
  //       return SortAsc;
  //     } else {
  //       return SortDesc;
  //     }
  //   } else {
  //     return Sort;
  //   }
  // };

  const [bookingColumnLoading, setBookingColumnLoading] = useState([]);

  // const getTotalBookingValues = async (payload) => {
  //   setBookingColumnLoading([...bookingColumnLoading, payload?.source]);
  //   const params = new URLSearchParams();
  //   params.append(
  //     "start_date",
  //     newFilters?.date_start || prefilledData?.date_start
  //   );
  //   params.append("end_date", newFilters?.date_end || prefilledData?.date_end);
  //   params.append("source", payload?.source);
  //   params.append(
  //     "db",
  //     newFilters?.selectedClient?.db || prefilledData?.selectedClient?.db
  //   );

  //   let response = await getTotalBookingAiCall(params?.toString());
  //   if (response?.status === 200) {
  //     setTableData(
  //       (prev) =>
  //         prev?.length > 0 &&
  //         prev?.map((d) =>
  //           d?.source == payload?.source
  //             ? { ...d, total_booking: response?.data }
  //             : { ...d }
  //         )
  //     );
  //     setBookingColumnLoading((prev) =>
  //       prev?.filter((f) => f != payload?.source)
  //     );
  //   } else {
  //     setBookingColumnLoading((prev) =>
  //       prev?.filter((f) => f != payload?.source)
  //     );
  //     let message =
  //       response?.response?.data?.message ||
  //       response?.message ||
  //       response?.error ||
  //       "Something went wrong";
  //     // toast.error(message, { theme: "colored" });
  //   }
  // };

  const getAllTotalBookingValues = async (tableData, db) => {
    return "hello";
    let loadingAll = tableData?.map(
      (s) => s?.total_booking === null && s.source
    );
    setBookingColumnLoading([...bookingColumnLoading, ...loadingAll]);
    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i]?.total_booking == null) {
        const params = new URLSearchParams();
        params.append(
          "start_date",
          newFilters?.date_start || prefilledData?.date_start
        );
        params.append(
          "end_date",
          newFilters?.date_end || prefilledData?.date_end
        );
        params.append("source", tableData[i]?.source);
        params.append("db", db);

        let response = await getTotalBookingAiCall(params?.toString());
        if (response?.status === 200) {
          console.log(response, "ressssssssssssss");

          setBookingColumnLoading((prev) =>
            prev?.filter((f) => f != tableData[i]?.source)
          );
        } else {
          setBookingColumnLoading((prev) =>
            prev?.filter((f) => f != tableData[i]?.source)
          );
          let message =
            response?.response?.data?.message ||
            response?.message ||
            response?.error ||
            "Something went wrong";
          toast.error(message, { theme: "colored" });
        }
      }
    }
  };

  const columns = [
    {
      title: "Source",
      dataIndex: "source",
      width: 160,
      fixed: "left",
      align: "left",
      render: (val) => (
        <p style={{ margin: 0, textTransform: "capitalize" }}>{val}</p>
      ),
    },
    {
      title: "Total Calls",
      dataIndex: "calls",
      width: 100,
    },
    {
      title: "Leads",
      dataIndex: "leads",
      width: 100,
    },
    {
      title: "Lead %",
      width: 100,
      dataIndex: "lead_per",
      render: (value) => (
        <p style={{ margin: 0, textTransform: "capitalize" }}>
          {value == 0 ? "0.00" : value}%
        </p>
      ),
    },
    {
      title: "<30 Secs",
      dataIndex: "under_30_seconds_calls",
      width: 100,
    },
    {
      title: "30-60 Secs",
      dataIndex: "under_30_60_seconds_calls",
      width: 120,
    },
    {
      title: "Total < 60 Secs",
      dataIndex: "under_60_seconds_calls",
      width: 150,
    },
    {
      title: "Missed Opps",
      dataIndex: "missed_opps",
      width: 140,
    },
    {
      title: "Req. not Serviced",
      dataIndex: "request_not_serviced",
      width: 150,
    },
    {
      title: "Hang Up",
      dataIndex: "hang_up",
      width: 100,
    },
    {
      title: "Wrong Number",
      dataIndex: "wrong_number",
      width: 140,
    },
    {
      title: "Cancellation",
      dataIndex: "cancellation",
      width: 120,
    },
    {
      title: "Call Drop",
      dataIndex: "call_drop",
      width: 100,
    },
    {
      title: "Bad Lead",
      dataIndex: "bad_lead",
      width: 100,
    },
    {
      title: "Spam",
      dataIndex: "spam",
      width: 100,
    },
    {
      title: "Out of Area",
      dataIndex: "out_of_area",
      width: 120,
    },
    {
      title: "Not Interested",
      dataIndex: "not_interested",
      width: 130,
    },
    {
      title: "Undefined",
      dataIndex: "undefined",
      width: 100,
    },
    {
      title: "Forms",
      dataIndex: "forms",
      width: 100,
    },
    {
      title: "Booking %",
      // dataIndex: "total_booking",
      dataIndex: "booking_per",
      render: (value, data) => (
        <p
          style={{
            margin: 0,
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          {value > 100 ? "100.00%" : `${value}%`}
          {/* <AddBookingCellComponent data={data} /> */}
        </p>
      ),
      width: 100,
    },
    {
      title: "Total Bookings",
      dataIndex: "total_booking",
      render: (value, data) => (
        <p
          style={{
            margin: 0,
            // textTransform: "capitalize",
            // textAlign: "center",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
          }}
        >
          {value}
          {/* <TotalBookingCellComponent
            data={data}
            db={
              newFilters?.selectedClient?.db ||
              prefilledData?.selectedClient?.db
            }
            start_date={newFilters?.date_start || prefilledData?.date_start}
            end_date={newFilters?.date_end || prefilledData?.date_end}
          /> */}
        </p>
      ),
      width: 140,
    },
    {
      title: <UnbookedTitle />,
      dataIndex: "unbooked",

      // render: (value, data) => (
      //   <p
      //     style={{
      //       margin: 0,
      //       textTransform: "capitalize",
      //       textAlign: "center",
      //     }}
      //   >
      //     <UnbookedCellComponent data={data} />
      //     {/* {value == null ? "" : data?.calls + data?.forms - value} */}
      //   </p>
      // ),
      width: 150,
    },
    // Followup Pending = (Total Calls + Total Forms ) - Total Bookings
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {percent * 100 > 10 ? `${(percent * 100).toFixed(0)}%` : ""}
      </text>
    );
  };

  function createObjectSets(array) {
    const sets = [];
    for (let i = 0; i < array.length; i += 10) {
      sets.push(array.slice(i, i + 10));
    }
    return sets;
  }

  const handleSwitch = (values) => {
    setSwitchToggle(values);

    let result = values
      ? manipulateData(weekOverWeekData, "total_leads")
      : manipulateData(weekOverWeekData, "total_calls");
    setWeekoverweekFinalData(result);
  };

  const handleGetIboundedCallsData = async (id, req, selectedClient) => {
    setInboundCallsLoader(true);
    const start = req?.date_start
      ? moment(req?.date_start, "yyyy-MM-DD").format("yyyy-MM-DD")
      : null;
    const end = req?.date_end
      ? moment(req?.date_end, "yyyy-MM-DD").format("yyyy-MM-DD")
      : null;
    let res = await inboundedcallclassification(start, end, selectedClient?.db);

    if (res.status === 200) {
      setInboundCallsLoader(false);
      setIboundCallData(res?.data);
      handleData2(res?.data);
    } else {
      setInboundCallsLoader(false);
      // toast.error(
      //   res.response.data.message ||
      //     res.error ||
      //     res.message ||
      //     "Something went wrong"
      // );
    }
  };

  const handleGetAIClassification = async (req, selectedClient) => {
    setTableData([]);
    const { date_start, date_end } = req;
    let params = new URLSearchParams();
    params.append(
      "start_date",
      moment(date_start, "yyyy-MM-DD").format("yyyy-MM-DD")
    );
    params.append(
      "end_date",
      moment(date_end, "yyyy-MM-DD").format("yyyy-MM-DD")
    );
    params.append("db", selectedClient?.db);
    setAiCallsLoader(true);
    const res = await getAICallClassification(params);
    if (res?.status === 200) {
      let response = res?.data?.data?.map((d) => {
        let booking_per =
          d?.leads == 0 || d?.booked == 0
            ? 0
            : Number((d?.booked * 100) / (d?.leads + d?.forms)).toFixed(2);

        let lead_per =
          d?.leads == 0 && d?.calls == 0
            ? 0
            : ((d?.leads / d?.calls) * 100).toFixed(2);

        return {
          ...d,
          total_booking: d?.booked,
          booking_per: booking_per > 100 ? 100 : booking_per,
          lead_per,
        };
      });
      const objectSets = createObjectSets(response);
      setAiCallsGraphData(objectSets);
      setAiCallsData(response);
      let result = sortingFunction(
        response,
        sortParam?.sortBasis,
        sortParam?.sortType
      );
      const updatedSourcePosition = result?.map((el, index) => ({
        ...el,
        position: index + 1,
      }));
      setTableData(updatedSourcePosition);
      setAiCallsLoader(false);
    } else {
      setAiCallsLoader(false);
    }
    setAiCallsLoader(false);
  };

  const handleData2 = (inboundCalls) => {
    let arr = [];
    inboundCalls?.length > 0 &&
      inboundCalls?.map((el) => {
        let obj = {
          name: el?.tags,
          value: el?.total_count,
        };
        arr.push(obj);
      });
    setData2(arr);
  };

  const CustomTooltip = ({ payload, data }) => {
    const calcPercentage = useMemo(() => {
      const gender = Object.values(data);
      let total = 0;
      for (let i = 0; i < gender.length; i += 1) {
        total += gender[i].value;
      }
      return ((payload?.[0]?.payload?.value / total) * 100).toFixed(2);
    }, [data, payload]);

    return (
      <div className="tooltip-box">
        <div className="ant-popover-arrow" />
        <div className="content">
          <h5>{payload?.[0]?.payload?.name}</h5>
          <p>
            <b>
              <span style={{ fontSize: "14px" }}>{calcPercentage}%</span>{" "}
              <span style={{ fontSize: "12px" }}>
                ({payload?.[0]?.payload?.value})
              </span>
            </b>
          </p>
        </div>
      </div>
    );
  };

  const manipulateData = (payload, type) => {
    let mainArray = [];
    let week1 = payload?.[0]?.week_period;
    let week2 = payload?.[1]?.week_period;
    let week3 = payload?.[2]?.week_period;
    let week4 = payload?.[3]?.week_period;

    let week1Array = [];
    let week2Array = [];
    let week3Array = [];
    let week4Array = [];

    let week1obj = {};
    let week2obj = {};
    let week3obj = {};
    let week4obj = {};

    for (let i = 0; i < payload?.length; i++) {
      if (payload?.[i]?.week_period === week1) {
        if (Object.keys(week1obj)?.length < 10) {
          week1obj = {
            ...week1obj,
            name: payload?.[i]?.week_period,

            [payload?.[i]?.source]: payload?.[i]?.[type],
          };
        } else {
          week1Array.push({
            ...week1obj,
            [payload?.[i]?.source]: payload?.[i]?.[type],
          });
          week1obj = {};
        }
      }
      if (payload?.[i]?.week_period === week2) {
        if (Object.keys(week2obj)?.length < 10) {
          week2obj = {
            ...week2obj,
            name: payload?.[i]?.week_period,

            [payload?.[i]?.source]: payload?.[i]?.[type],
          };
        } else {
          week2Array.push({
            ...week2obj,
            [payload?.[i]?.source]: payload?.[i]?.[type],
          });
          week2obj = {};
        }
      }
      if (payload?.[i]?.week_period === week3) {
        if (Object.keys(week3obj)?.length < 10) {
          week3obj = {
            ...week3obj,
            name: payload?.[i]?.week_period,
            [payload?.[i]?.source]: payload?.[i]?.[type],
          };
        } else {
          week3Array.push({
            ...week3obj,
            [payload?.[i]?.source]: payload?.[i]?.[type],
          });
          week3obj = {};
        }
      }
      if (payload?.[i]?.week_period === week4) {
        if (Object.keys(week4obj)?.length < 10) {
          week4obj = {
            ...week4obj,
            name: payload?.[i]?.week_period,
            [payload?.[i]?.source]: payload?.[i]?.[type],
          };
        } else {
          week4Array.push({
            ...week4obj,
            [payload?.[i]?.source]: payload?.[i]?.[type],
          });
          week4obj = {};
        }
      }

      if (i === payload?.length - 1) {
        week1Array.push({
          ...week1obj,
          [payload?.[i]?.source]: payload?.[i]?.[type],
        });

        week2Array.push({
          ...week2obj,
          [payload?.[i]?.source]: payload?.[i]?.[type],
        });

        week3Array.push({
          ...week3obj,
          [payload?.[i]?.source]: payload?.[i]?.[type],
        });

        week4Array.push({
          ...week4obj,
          [payload?.[i]?.source]: payload?.[i]?.[type],
        });
      }
    }

    mainArray = [week1Array, week4Array, week3Array, week2Array];
    return mainArray;
  };

  const setDataByPagination = (payload, page) => {
    let tempArray = [];
    let activeKeys = [];
    let opacitySet = {};
    for (let i = 0; i < payload?.length; i++) {
      let datearr = payload?.[i]?.[page]?.name?.split(" - ");
      let newDate = datearr
        ?.map((e) => moment(e).format("YY-MM-DD"))
        ?.join(" - ");
      tempArray.push({ ...payload?.[i]?.[page], name: newDate });
    }
    for (const key in tempArray[0]) {
      activeKeys.push(key);
    }
    for (const Objectkey in tempArray[0]) {
      opacitySet = { ...opacitySet, [Objectkey]: 1 };
    }

    setWeekoverweekTotalCalls({
      data: tempArray,
      activeKeys: activeKeys,
      opacitySet: opacitySet,
    });
  };

  useMemo(() => {
    weekoverweekFinalData?.length > 0 &&
      setDataByPagination(weekoverweekFinalData, weekoverweekPage);
  }, [weekoverweekPage, weekoverweekFinalData]);

  const handleGetWeekOverWeek = async (db) => {
    setWeekOverLoader(true);
    let params = new URLSearchParams();
    params.append("client_key", db?.key);
    const res = await getWeekOverWeek(params.toString());
    if (res?.status === 200) {
      setWeekOverWeekData(res?.data?.data);
      let result = manipulateData(res?.data?.data, "total_calls");
      setWeekoverweekFinalData(result);
      setWeekOverLoader(false);
    } else {
      // toast.error(
      //   res?.response?.data.message ||
      //     res?.error ||
      //     res?.message ||
      //     " Something went wrong"
      // );
    }
    setWeekOverLoader(false);
  };

  const handleApisCalling = (id, req, db) => {
    dispatch(updateAiCallClassificationCellData({ type: "clear" }));
    handleGetIboundedCallsData(id, req, db);
    handleGetAIClassification(req, db);
    // handleGetWeekOverWeek(db);
  };

  useEffect(() => {
    let requestPayload = {
      date_end: newFilters?.date_end || prefilledData?.date_end,
      date_start: newFilters?.date_start || prefilledData?.date_start,
    };

    handleApisCalling(
      newFilters?.selectedClient?.key || prefilledData?.selectedClient?.key,
      requestPayload,
      newFilters?.selectedClient || prefilledData?.selectedClient
    );
  }, []);

  const TableComponent = useCallback(() => {
    return (
      <Table
        prefixCls="custom-ui-table"
        style={{ height: "100%" }}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{
          x: "100%",
          y: "500px",
        }}
      />
    );
  }, [tableData]);

  const TotalbookingVSunbooked = useCallback(() => {
    // const aiCellDataGraph = useSelector(
    //   (state) => state?.loginAuth?.aiCallClassificationCellData
    // );
    return (
      <Col xs={24}>
        {aiCallsLoader ? (
          <div className="loading-wrap" style={{ marginTop: "25px" }}>
            <Skeleton active />
          </div>
        ) : (
          <DoubleBarChart
            payload={tableData}
            key="Total booking VS Unbooked by Source"
            title="Total booking VS Unbooked by Source"
            firstLineChartName="Unbooked"
            firstLineChartLabel="unbooked"
            firstLineChartColor="#ffc04d"
            secondLineChartName="Total bookings"
            secondLineChartLabel="booked"
            secondLineChartColor="#4ca64c"
          />
        )}
      </Col>
    );
  }, [tableData]);

  const LeadperVSbookingper = useCallback(() => {
    // const aiCellDataGraph = useSelector(
    //   (state) => state?.loginAuth?.aiCallClassificationCellData
    // );
    return (
      <Col xs={24}>
        {aiCallsLoader ? (
          <div className="loading-wrap" style={{ marginTop: "25px" }}>
            <Skeleton active />
          </div>
        ) : (
          <DoubleLineChart
            payload={tableData}
            title="Lead % VS Booking % by Source"
            lineChartName="Booking %"
            lineChartLabel="booking_per"
            lineChartColor="#f79562"
            barChartName="Lead %"
            barChartLabel="lead_per"
            barChartColor="#ffc64d"
          />
        )}
      </Col>
    );
  }, [tableData]);

  return (
    <>
      <Header
        clientInfo={activeClient}
        title="Call classification"
        isFilter={true}
        openFilter={() => setFilterDrawer(true)}
        isNotification={true}
      />

      <HomeWrapper selectedThemeColors={selectedThemeColors}>
        {openDrawer && (
          <Drawer
            className="filter-drawer"
            placement="right"
            closable={false}
            onClose={() => setFilterDrawer(false)}
            open={openDrawer}
            width={"500px"}
            key="bottom"
          >
            <ClassificationFilter
              drawer={true}
              setFilterDrawer={setFilterDrawer}
              handleApisCalling={handleApisCalling}
              width={width}
              loader={aiCallsLoader}
            />
          </Drawer>
        )}

        <Row className="">
          {/* <Col xs={24}>
            <TempPlayGroundGraph />
          </Col> */}

          <Col xs={24}>
            {!aiCallsLoader ? (
              <>
                <div className="title">
                  <h4 className="clasification-haddings">
                    Calls Classifications
                  </h4>
                </div>

                <div className="tableWrapper">
                  <TableComponent />
                </div>
              </>
            ) : (
              <div className="loading-wrap" style={{ marginTop: "25px" }}>
                <Skeleton active />
              </div>
            )}
          </Col>

          <Col xs={24}>
            {aiCallsLoader ? (
              <div className="loading-wrap" style={{ marginTop: "25px" }}>
                <Skeleton active />
              </div>
            ) : (
              <SimpleDoubleBarChart
                key={"Total Calls Vs Leads By Source"}
                payload={tableData}
                title="Total Calls Vs Leads By Source"
                firstLineChartName="calls"
                firstLineChartLabel="calls"
                firstLineChartColor="#f4c259"
                secondLineChartName="leads"
                secondLineChartLabel="leads"
                secondLineChartColor="#f8842d"
              />
            )}
          </Col>

          <Col xs={24}>
            {aiCallsLoader ? (
              <div className="loading-wrap" style={{ marginTop: "25px" }}>
                <Skeleton active />
              </div>
            ) : (
              <DoubleBarChart
                key={"Call Durations by Source"}
                payload={tableData}
                title="Call Durations by Source"
                firstLineChartName="< 30 Secs"
                firstLineChartLabel="under_30_seconds_calls"
                firstLineChartColor="#ffc64d"
                secondLineChartName="30-60 Secs"
                secondLineChartLabel="under_30_60_seconds_calls"
                secondLineChartColor="#f79562"
              />
            )}
          </Col>

          <Col xs={24}>
            {aiCallsLoader ? (
              <div className="loading-wrap" style={{ marginTop: "25px" }}>
                <Skeleton active />
              </div>
            ) : (
              <BarLineChart
                payload={tableData}
                title="Missed Opportunities VS Total Calls By Source"
                lineChartName="Total Calls"
                lineChartLabel="calls"
                lineChartColor="#1500ff"
                barChartName="Missed Opportunities"
                barChartLabel="missed_opps"
                barChartColor="#fe4b4b"
              />
            )}
          </Col>

          {/* <Col xs={24}>
            {aiCallsLoader ? (
              <div className="loading-wrap" style={{ marginTop: "25px" }}>
                <Skeleton active />
              </div>
            ) : (
              <SingleBarChart
                payload={tableData}
                title="Spam Calls as Percentage of Total Calls"
                name="Spam Calls as Percentage"
                label="spam"
                color="#a64da6"
              />
            )}
          </Col> */}

          <TotalbookingVSunbooked />

          <LeadperVSbookingper />

          <Col className="chart-div" xs={24}>
            {!inboundCallsLoader ? (
              inboundCalls?.length > 0 ? (
                <>
                  <div style={{ marginTop: "30px" }} className="title">
                    <h4>Google Ads I/B Calls Classifications</h4>
                  </div>
                  <div className="chart-content">
                    <PieChart width={800} height={400}>
                      <Legend
                        layout="vertical"
                        verticalAlign="middle"
                        itemGap={20}
                        align="right"
                        iconType="circle" // Set legend icons to circles
                        formatter={(value) => (
                          <span style={{ color: "#7a7b7a" }}>{value}</span> // Gray color for legend text
                        )}
                        wrapperStyle={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(10%, -30%)",
                        }}
                      />
                      <Pie
                        data={data2}
                        cx={195}
                        cy={195}
                        innerRadius={0}
                        outerRadius={160}
                        fill="#8884d8"
                        legendType="circle"
                        paddingAngle={0}
                        dataKey="value"
                        label={renderCustomizedLabel}
                        labelLine={false}
                      >
                        {data2?.length > 0 &&
                          data2?.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                      </Pie>
                      <ChartTooltip content={<CustomTooltip data={data2} />} />
                    </PieChart>
                  </div>
                </>
              ) : (
                <MainWrapper>
                  <div className="sub-header">
                    <sub>Google Ads I/B Calls Classifications</sub>
                  </div>
                  <GraphWrapper
                    className="nodata-section"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "400px",
                      fontSize: "22px",
                    }}
                  >
                    NO DATA FOUND
                  </GraphWrapper>
                </MainWrapper>
              )
            ) : (
              <div className="loading-wrap" style={{ marginTop: "25px" }}>
                <Skeleton active />
              </div>
            )}
          </Col>
        </Row>
      </HomeWrapper>

      {!aiCallsLoader && (
        <Watermark sub="CallRail" style={{ padding: "0 10px 6px" }} />
      )}
    </>
  );
};

// const TotalBookingCellComponent = ({ data, db, start_date, end_date }) => {
//   const dispatch = useDispatch();
//   const aiCellData = useSelector(
//     (state) => state?.loginAuth?.aiCallClassificationCellData
//   );
//   const [value, setValue] = useState(null);
//   const [spinner, setSpinner] = useState(true);

//   const getAllTotalBookingValues = async (payload) => {
//     if (payload.data?.total_booking == null) {
//       const params = new URLSearchParams();
//       params.append("start_date", payload?.start_date);
//       params.append("end_date", payload?.end_date);
//       params.append("source", payload.data?.source);
//       params.append("db", payload?.db);

//       let response = await getTotalBookingAiCall(params?.toString());
//       if (response?.status === 200) {
//         let booking_per =
//           data?.leads == 0 || response?.data == 0
//             ? 0
//             : Number(
//                 (response?.data * 100) / (data?.leads + data?.forms)
//               ).toFixed(2);

//         let lead_per =
//           data?.leads == 0 && data?.calls == 0
//             ? 0
//             : ((data?.leads / data?.calls) * 100).toFixed(2);
//         dispatch(
//           updateAiCallClassificationCellData({
//             d: {
//               ...data,
//               source: payload.data?.source,
//               count: response?.data,
//               total_booking: response?.data,
//               booking_per: booking_per > 100 ? 100 : booking_per,
//               lead_per,
//             },
//             type: "append",
//           })
//         );

//         return;
//       } else {
//         setSpinner(false);
//         let message =
//           response?.response?.data?.message ||
//           response?.message ||
//           response?.error ||
//           "Something went wrong";
//         // toast.error(message, { theme: "colored" });
//       }
//     }
//   };

//   useEffect(() => {
//     getAllTotalBookingValues({
//       data,
//       db,
//       start_date,
//       end_date,
//     });
//   }, [spinner]);

//   useEffect(() => {
//     let checking =
//       (aiCellData?.length > 0 &&
//         aiCellData?.find((item) => item.source === data.source)) ||
//       null;
//     if (checking) {
//       setValue(checking.count);
//     }
//   }, [aiCellData]);

//   return (
//     <>
//       {value === null ? (
//         spinner ? (
//           // <InfinityRotation>
//           //   <RefreshIcon />
//           // </InfinityRotation>
//           <ThreeDotLoader color={"gray"} />
//         ) : (
//           <i onClick={() => setSpinner(true)}>
//             <RefreshIcon />
//           </i>
//         )
//       ) : (
//         value
//       )}
//     </>
//   );
// };

// const UnbookedCellComponent = ({ data }) => {
//   const aiCellData = useSelector(
//     (state) => state?.loginAuth?.aiCallClassificationCellData
//   );
//   const [value, setValue] = useState(null);

//   useEffect(() => {
//     let checking =
//       (aiCellData?.length > 0 &&
//         aiCellData?.find((item) => item.source === data.source)) ||
//       null;
//     if (checking) {
//       let v = data?.calls + data?.forms - checking.count;
//       setValue(v > 0 ? v : 0);
//     }
//   }, [aiCellData]);
//   // data?.calls + data?.forms - total_booking}
//   return <>{value}</>;
// };

// const AddBookingCellComponent = ({ data }) => {
//   const aiCellData = useSelector(
//     (state) => state?.loginAuth?.aiCallClassificationCellData
//   );
//   const [value, setValue] = useState(null);

//   useEffect(() => {
//     let checking =
//       (aiCellData?.length > 0 &&
//         aiCellData?.find((item) => item.source === data.source)) ||
//       null;
//     if (checking) {
//       if (data?.leads == 0 || checking.count == 0) {
//         setValue(0);
//       } else {
//         setValue(
//           Number((checking.count * 100) / (data?.leads + data?.forms)).toFixed(
//             2
//           )
//         );
//         // Number((data?.leads + data?.forms / checking.count) * 100).toFixed(2)
//         // setValue(Number((checking.count / data?.leads) * 100).toFixed(2));
//       }
//     }
//   }, [aiCellData]);
//   return (
//     <>
//       {value === null ? (
//         <ThreeDotLoader color={"gray"} />
//       ) : value > 100 ? (
//         "100.00%"
//       ) : (
//         `${value}%`
//       )}
//     </>
//   );
// };

const UnbookedTitle = () => {
  const newFilters = useSelector(
    (state) => state?.loginAuth?.classificationSectionFilters
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const [exportLoading, setExportLoading] = useState(false);

  const downloadUnbookedUser = async () => {
    setExportLoading(true);

    let params = new URLSearchParams();
    params.append(
      "start_date",
      moment(newFilters?.date_start || prefilledData?.date_start).format(
        "YYYY-MM-DD"
      )
    );
    params.append(
      "end_date",
      moment(newFilters?.date_end || prefilledData?.date_end).format(
        "YYYY-MM-DD"
      )
    );
    params.append(
      "client",
      newFilters?.selectedClient?.key || prefilledData?.selectedClient?.key
    );

    let response = await downloadUnbookedUserInfo(params?.toString());
    if (response?.status === 201) {
      const url = response.data?.download_link;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "export.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExportLoading(false);
    } else {
      console.error("Error downloading file:", response?.error);
      toast.error("Error downloading file", { theme: "colored" });
      setExportLoading(false);
    }
  };

  return (
    <div>
      Unbooked
      <i
        style={{ marginLeft: "6px", cursor: "pointer" }}
        onClick={!exportLoading && downloadUnbookedUser}
      >
        {exportLoading ? (
          <Spin indicator={<LoadingOutlined spin />} size="small" />
        ) : (
          <DownloadIcon />
        )}
      </i>
    </div>
  );
};

const InfinityRotation = styled.div`
  width: fit-content;
  animation: spinner 0.6s linear infinite;
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
`;

const SubHeader = styled.div`
  width: 100%;
  height: 100%;
  // background:red;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .client-name {
    color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.textPrimaryColor};
    font-size: 16px;
  }
`;

const WeekOverWeekActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .switch-logo-div {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .switch-call-pera {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0 !important;
  }
`;

const HomeWrapper = styled.div`
  background: #f3f3f3;
  width: 100%;
  height: 100%;
  min-height: 91vh;
  padding: 24px 22px;

  .btn-div {
    display: flex;
    justify-content: end;
    align-items: center;
    button {
      height: 33px;
      font-size: 13px;
      background: rgb(36, 36, 36);
      color: rgb(255, 255, 255);
      border-style: none;
      /* padding: 10px 15px; */
      border-radius: 10px;
      cursor: pointer;
      width: 100px;
      font-family: Poppins;
      margin-bottom: 10px;
    }
  }

  .pagination {
    display: flex;
    gap: 6px;
    width: 66px;
    height: 30px;

    button {
      color: white;
      background-color: #242424;
      border-radius: 2px;
      width: 30px;
      height: 30px;
      border: none;
      border-radius: 2px;
    }
  }

  .prev-next {
    display: flex;
    gap: 6px;
    width: 66px;
    height: 30px;

    button {
      color: white;
      background-color: #242424;
      border-radius: 2px;
      width: 30px;
      height: 30px;
      border: none;
      border-radius: 2px;
    }
  }

  .tableWrapper {
    margin-bottom: 40px;
    width: 100%;
  }

  .dashboardWrapper {
    .filters-section {
      background: ${({ selectedThemeColors }) =>
        selectedThemeColors?.CardsColor} !important;
    }
  }

  .title {
    color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.textPrimaryColor};

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h4 {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .chart-content {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    padding: 16px;
    align-items: center;
    background: var(--Color-Primary-White, #ffffff);
    margin-bottom: 32px;
  }

  .tooltip-box {
    width: max-content;
    padding: 15px;
    background: #fff;
    position: relative;

    h5,
    p {
      margin: 0;
    }

    .ant-popover-arrow {
      left: 50%;
      transform: translateX(-50%) translateY(100%) rotate(180deg);
      position: absolute;
      bottom: 0px;
      left: 100.5px;
    }
    ${"" /* border-radius: 15px; */}
  }
  .loading-wrap {
    width: 100%;
    background: ${({ selectedThemeColors }) => selectedThemeColors?.CardsColor};
    padding: 15px;
    height: 200px;
    display: flex;
    border-radius: 7px;
    align-items: center;
  }

  .channel-campaign-row {
    display: flex;
    gap: 10px;
    margin-top: 30px;

    .channel-campaign-inner {
      width: 100%;
      padding: 20px;
      border-radius: 10px;
      background: ${({ selectedThemeColors }) =>
        selectedThemeColors?.CardsColor};
      min-height: 258px;
      line-height: 2;

      .channel-iner-heading {
        color: ${({ selectedThemeColors }) =>
          selectedThemeColors?.textPrimaryColor};
        font-size: 32px;
        margin-bottom: 20px;
      }
      .channel-iner-heading2 {
        color: ${({ selectedThemeColors }) => selectedThemeColors?.lightText};
        font-size: 15px;
      }

      p {
        color: ${({ selectedThemeColors }) => selectedThemeColors?.lightText};
        margin: 0px;
        margin: 0px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 13px;

        span {
          color: ${({ selectedThemeColors }) =>
            selectedThemeColors?.textPrimaryColor};
          font-size: 15px;
        }
      }
    }
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  gap: 10px;

  img {
    cursor: pointer;
    height: 20px;
  }
`;

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;

  .sub-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    sub {
      font-size: 20px;
      font-weight: 600;
    }
  }
`;

const GraphWrapper = styled.div`
  height: 500px;
  width: 100%;
  background: #ffffff;
  margin: 20px 0;
  padding: 20px 0;
  .recharts-legend-wrapper {
    height: auto !important;
  }
`;
