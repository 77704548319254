import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCompany: null,
  startDate: null,
  endDate: null,
  activeTab: "bookingStats",
  bookingDateFilter: null,
  bookingDateType: "created_at",
  lastSeen: {
    date: null,
    hasSeen: false,
  },
  messageType: "chatwidget",
  selectedChat: {},
  isIndicatorMessage:[],
  isIndicatorTab: [],
  chatwidget: {
    chat: [],
    selectedChat: null,
    fetchedCount: 0,
    searchedChat: [],
    totalCount: 0,
  },
  sms: {
    chat: [],
    selectedChat: null,
    fetchedCount: 0,
    searchedChat: [],
    totalCount: 0,
  },
  lsa: {
    chat: [],
    selectedChat: null,
    fetchedCount: 0,
    searchedChat: [],
    totalCount: 0,
  },
  yelp: {
    chat: [],
    selectedChat: null,
    fetchedCount: 0,
    searchedChat: [],
    totalCount: 0,
  },
};

export const AiBookingSlice = createSlice({
  name: "AiBookingSlice",
  initialState,
  reducers: {
    updateDate: (state, action) => {
      state.startDate = action?.payload?.date_start;
      state.endDate = action?.payload?.date_end;
    },
    selectedAiCompany: (state, action) => {
      state.selectedCompany = action?.payload;
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    updateSeenStatus: (state, action) => {
      state.lastSeen = action.payload;
    },
    updateMessageType: (state, actions) => {
      if (actions) {
        state.messageType = actions.payload;
      }
    },
    updateSelectedChat: (state, actions) => {
      if (actions) {
        state.selectedChat = actions.payload;
      }
    },
    updatedChats: (state, action) => {
      if (action?.payload?.type === "chatwidget") {
        state.chatwidget.chat = action?.payload?.chat;
        if (action?.payload?.fetchedCount) {
          state.chatwidget.fetchedCount = action?.payload?.fetchedCount;
        }
        if (action?.payload?.totalCount) {
          state.chatwidget.totalCount = action?.payload?.totalCount;
        }
      } else if (action?.payload?.type === "sms") {
        state.sms.chat = action?.payload?.chat;
        if (action?.payload?.fetchedCount) {
          state.sms.fetchedCount = action?.payload?.fetchedCount;
        }
        if (action?.payload?.totalCount) {
          state.sms.totalCount = action?.payload?.totalCount;
        }
      } else if (action?.payload?.type === "lsa") {
        state.lsa.chat = action?.payload?.chat;
        if (action?.payload?.fetchedCount) {
          state.lsa.fetchedCount = action?.payload?.fetchedCount;
        }
        if (action?.payload?.totalCount) {
          state.lsa.totalCount = action?.payload?.totalCount;
        }
      } else if (action?.payload?.type === "yelp") {
        state.yelp.chat = action?.payload?.chat;
        if (action?.payload?.fetchedCount) {
          state.yelp.fetchedCount = action?.payload?.fetchedCount;
        }
        if (action?.payload?.totalCount) {
          state.yelp.totalCount = action?.payload?.totalCount;
        }
      }
    },
    updatedSelectedChats: (state, action) => {
      if (action?.payload?.type === "chatwidget") {
        state.chatwidget.selectedChat = action?.payload?.selectedChat;
      } else if (action?.payload?.type === "sms") {
        state.sms.selectedChat = action?.payload?.selectedChat;
      } else if (action?.payload?.type === "lsa") {
        state.lsa.selectedChat = action?.payload?.selectedChat;
      } else if (action?.payload?.type === "yelp") {
        state.yelp.selectedChat = action?.payload?.selectedChat;
      }
    },
    updateisIndicatorMessage: (state, action) => {
      if(action?.payload){
        state.isIndicatorMessage = action?.payload;
      }
    },
    updateisIndicatorTab: (state, action) => {
      if(action?.payload){
        state.isIndicatorTab = action?.payload;
      }
    },
    updateBookingDateFilter: (state, action) => {
      state.bookingDateFilter = action?.payload;
    },
    updateBookingDateType: (state, action) => {
      state.bookingDateType = action?.payload;
    },
    resetChat: (state) => {
      state.chatwidget = {
        chat: [],
        selectedChat: null,
        fetchedCount: 0,
        searchedChat: [],
        totalCount: 0,
      };
      state.sms = {
        chat: [],
        selectedChat: null,
        fetchedCount: 0,
        searchedChat: [],
        totalCount: 0,
      };
      state.lsa = {
        chat: [],
        selectedChat: null,
        fetchedCount: 0,
        searchedChat: [],
        totalCount: 0,
      };
      state.yelp = {
        chat: [],
        selectedChat: null,
        fetchedCount: 0,
        searchedChat: [],
        totalCount: 0,
      };
      state.isIndicatorMessage = [];
      state.isIndicatorTab = [];
    },
    reset_AiBookingSlice: () => initialState,
  },
});

export const {
  selectedAiCompany,
  updateDate,
  updateActiveTab,
  updateSeenStatus,
  updateMessageType,
  updateSelectedChat,
  updatedChats,
  updatedSelectedChats,
  updateisIndicatorMessage,
  updateisIndicatorTab,
  resetChat,
  
  updateBookingDateFilter,
  updateBookingDateType,
  reset_AiBookingSlice,
} = AiBookingSlice.actions;
export default AiBookingSlice.reducer;
