import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { SyncOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import PropTypes from "prop-types";
import onlyNsight from "../assets/Images/onlynsight.png";

const flashlight = keyframes`
  0% {
    background-position: -200%;
  }
  50% {
    background-position: 50%;
  }
  100% {
    background-position: 200%;
  }
`;

const loadingMessages = [
  // "Hang tight! We're working on it.",
  // "Just a moment—good things take time!",
  // "Almost there! Thanks for your patience.",
  // "Loading magic, please wait...",
  // "Your request is in progress!",
  // "Sit tight! This won’t take long.",
  // "Hold on, we're making it happen!",
  // "Processing… stay tuned!",
  // "Thanks for waiting, we're nearly there.",
  // "One moment, bringing it to life!",
  "Fetching data, please wait...",
  "Processing your request, almost done!",
  "Loading, thank you for your patience.",
  "Preparing your data, almost ready!",
  "Processing data, thank you for waiting.",
  "Request in progress, please hold on."
];

export default function LoadingBlock({
  height,
  size,
  color,
  onlySpinner = true,
}) {
  const antIcon = (
    <SyncOutlined
      style={{
        fontSize: size || 46,
        color: color || "#d80c0c",
      }}
      spin
    />
  );
  const [message, setMessage] = useState("");
  useEffect(() => {
    const randomMessage =
      loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
    setMessage(randomMessage);
  }, []);

  return (
    <LoadingbuttonWrapper height={height}>
      <Spin style={{ margin: "0px" }} indicator={antIcon} />
      {onlySpinner && <HighlightP>{message}</HighlightP>}
    </LoadingbuttonWrapper>
  );
}

LoadingBlock.propTypes = {
  height: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
};

const LoadingbuttonWrapper = styled.div`
  width: 100%;
  height: ${({ height }) => height || "100%"};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const HighlightP = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font-size: 22px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, transparent, rgb(0, 0, 0), transparent);
  background-size: 200%;
  animation: ${flashlight} 6s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.18);

  span {
    margin: 0 0 10px 0;
    color: #d80c0c;
    -webkit-text-fill-color: red;
  }
`;



export const LoaderIcon = ({size}) => {
  const loaderantIcon = (
    <SyncOutlined
      style={{
        fontSize: size || 26,
        color: "#fff",
      }}
      spin
    />
  );

  return(
    <Spin style={{ margin: "0px" }} indicator={loaderantIcon} />
  )
}