import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { Checkbox, Select, Tooltip } from "antd";
// import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  getCampaignListing,
  getDashFilterCatApi,
} from "../services/Collections";
import {
  alrdyAppliedMargin,
  preFillApplyMargin,
  prefilledChecksDashboard,
  prefilledFilters,
  updateSelectAll,
} from "../app/Auth/signIn/LogInSlice";
import styled from "styled-components";
import RangeSelector from "./RangePicker";
import { filterClientsBySection } from "../utils/common/commonFunction";
import { NoDataDropdown } from "./common/NoDataDropdown";
import { LoaderIcon } from "./LoadingBlock";

const FilterComponent = ({
  drawer,
  setFilterDrawer,
  handleDashboardApis,
  setChannelCheckActive,
  channelCheckActive,
  setIgnoreActive,
  ignoreActive,
  setApplyMarginData,
  applyMarginData,
  marginOptions,
  initialApplyMargin,
  setInitialApplyMargin,
  isCompareDisabled,
  setIsCompareDisabled,
  filterLoader,
  width,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  console.log(selectedValues, "dsfgsdsdselectedValues");
  let applymarginOption = marginOptions?.filter((el) => el?.value != "Yelp");
  const [clientOptions, setclientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [sourceOption, setSourceOption] = useState([]);
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );
  const [sourceLoading, setsourceLoading] = useState(false);
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const defaultIsSelectAll = useSelector(
    (state) => state?.loginAuth?.isSelectAll
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const dispatch = useDispatch();
  const [currentDate, setCurrentDate] = useState({
    start: moment(prefilledData?.date_start).format("yyyy-MM-DD"),
    end: moment(prefilledData?.date_end).format("yyyy-MM-DD"),
  });
  const [compareDate, setCompareDate] = useState({
    start: moment(prefilledData?.date_compare_start).format("yyyy-MM-DD"),
    end: moment(prefilledData?.date_compare_end).format("yyyy-MM-DD"),
  });

  const [catLoading, setCatLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(
    prefilledData?.selectedCategory || null
  );

  const initialValues = {
    clientKey: "",
    Source: "",
  };

  // const formatDate = (d) => {
  //   const date = new Date(d);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const formattedDate = `${year}-${month}-${day}`;
  //   return formattedDate;
  // };

  const getClientOption = () => {
    let arr = [];
    filterClientsBySection(clientOptionsData?.clients, "dashboard")?.length >
      0 &&
      // eslint-disable-next-line array-callback-return
      filterClientsBySection(clientOptionsData?.clients, "dashboard")?.map(
        (ele) => {
          let obj = {
            ...ele,
            label: ele?.name,
            value: ele?.name,
          };
          arr.push(obj);
        }
      );
    setclientOptions(arr);
  };

  console.log(clientOptions, "clientOptions");

  const handleAllClear = () => {
    dispatch(updateSelectAll(false));
    setSelectedValues([]);
  };

  const handleCompanignList = async () => {
    setsourceLoading(true);
    setSourceOption(() => []);
    setSelectedValues([]);
    let res = await getCampaignListing(
      selectedClient?.key ?? prefilledData?.selectedClient?.key
    );
    if (res?.status == 200) {
      let arr = [];
      if (res.data.length > 0) {
        for (let i = 0; i < res?.data.length; i++) {
          let obj = {
            value: res?.data[i]?.campaignid,
            label: res?.data[i]?.campaignname,
          };
          arr.push(obj);
        }
      }
      const filteredByVal = Array.isArray(arr)
        ? [
          ...arr
            .reduce((a, c) => {
              a?.set(c?.value, c);
              return a;
            }, new Map())
            .values(),
        ]
        : [];
      setsourceLoading(false);
      setSourceOption(filteredByVal);
      handleSouceOption(filteredByVal);
    } else {
      toast.error(res?.message || res?.error || "Something went wrong");
      setsourceLoading(false);
    }
  };

  const handleSouceOption = (data) => {
    if (data.length > 0) {
      let arr = [];
      if (defaultIsSelectAll) {
        arr = data;
        // prefilledData?.campaignsIds?.length > 0
        //   ? prefilledData?.campaignsIds?.map((el) => {
        //       data?.map((val) => {
        //         if (el == val?.value) {
        //           arr.push(val);
        //         }
        //       });
        //     })
        //   : prefilledData?.campaigns?.map((el) => {
        //       data?.map((val) => {
        //         if (el == val?.value) {
        //           arr.push(val);
        //         }
        //       });
        //     });
      } else {
        prefilledData?.campaigns?.length > 0
          ? prefilledData?.campaigns?.map((el) => {
            data?.map((val) => {
              if (el == val?.value) arr.push(val);
            });
          })
          : prefilledData?.campaignsIds?.map((el) => {
            data?.map((val) => {
              if (el == val?.value) arr.push(val);
            });
          });
      }
      setSelectedValues(arr);
      console.log(arr, "dataaaaArrrr");
    }
  };

  const submitHandler = async (e) => {
    if (selectedValues.length === 0) {
      toast.error("Please select one source atleast");
      return false;
    } else {
      if (currentDate) {
        let sourceArray = [];
        if (sourceOption.length > 0) {
          if (sourceOption.length !== selectedValues.length) {
            for (let i = 0; i < sourceOption.length; i++) {
              for (let j = 0; j < selectedValues.length; j++) {
                if (sourceOption[i].value === selectedValues[j])
                  sourceArray.push(sourceOption[i].value);
              }
            }
          } else sourceArray = [];
        }

        let requestPayload = {
          campaigns:
            !defaultIsSelectAll && sourceArray?.length == 0
              ? prefilledData?.campaigns
              : sourceArray,
          date_start: currentDate?.start?._d
            ? moment(currentDate?.start?._d).format("YYYY-MM-DD")
            : currentDate?.start,
          date_end: currentDate?.end?._d
            ? moment(currentDate?.end?._d).format("YYYY-MM-DD")
            : currentDate?.end,
          compare_enable: isCompareDisabled ? 0 : 1,
          date_compare_start: compareDate
            ? compareDate?.start?._d
              ? moment(compareDate?.start?._d).format("YYYY-MM-DD")
              : compareDate?.start
            : "",
          date_compare_end: compareDate
            ? compareDate?.end?._d
              ? moment(compareDate?.end?._d).format("YYYY-MM-DD")
              : compareDate?.end
            : "",
          use_ignore: ignoreActive ? 1 : 0,
        };

        handleDashboardApis(
          selectedClient?.key ?? prefilledData?.selectedClient?.key,
          requestPayload,
          selectedClient != null
            ? selectedClient
            : prefilledData?.selectedClient
        );
        if (drawer) {
          setFilterDrawer(false);
        }
        let obj = {
          ...requestPayload,
          selectedClient:
            selectedClient != null
              ? selectedClient
              : prefilledData?.selectedClient,
          selectedCategory: selectedCategories ? selectedCategories : null,
        };
        if (selectedValues?.length == sourceOption?.length) {
          obj = {
            ...obj,
            campaignsIds: sourceOption?.map((el) => el?.value),
          };
        } else dispatch(updateSelectAll(false));
        dispatch(prefilledFilters(obj));
        dispatch(preFillApplyMargin(applyMarginData));
      } else toast.error("Please fill required fields");
    }
  };

  const handleCategoryOptions = async () => {
    setCatLoading(true);
    let clientKey = selectedClient?.key ?? prefilledData?.selectedClient?.key;
    let res = await getDashFilterCatApi(clientKey);

    if (res?.status === 200) {
      let updateData = res?.data
        ?.filter((ele) => ele?.category && ele)
        ?.map((el) => ({
          ...el,
          label: el?.category,
          value: el?.category,
        }));
      console.log(updateData, "updateData");
      setCategories(updateData);
      setCatLoading(false);
    } else {
      toast.error(res?.message || res?.error || "Something went wrong");
      setCatLoading(false);
    }
  };

  const handleSelectedHistory = (data) => {
    console.log(data, "data", sourceOption);
    if (data) {
      setSelectedCategories(data);
      let campaigns = [];
      sourceOption?.forEach((ele) => {
        data?.campaigns?.forEach((val) => {
          if (ele?.value === val) {
            campaigns.push(val);
          }
        });
      });
      setSelectedValues(campaigns);
    } else {
      setSelectedCategories(null);
      setSelectedValues([]);
    }
  };

  useEffect(() => {
    setIsCompareDisabled(prefilledData?.compare_enable == 0 ? true : false);
  }, [prefilledData?.compare_enable]);

  useEffect(() => {
    if (selectedClient != null) {
      dispatch(updateSelectAll(true));
      handleCompanignList();
      handleCategoryOptions();
    }
  }, [selectedClient]);

  useEffect(() => {
    getClientOption();
    handleCompanignList();
    handleCategoryOptions();
  }, []);
  return (
    <FilterWrapper selectedThemeColors={selectedThemeColors}>
      <div className="inner-part">
        <div className="content-box">
          <div className="filters">
            <h4>Filters</h4>
            <Formik initialValues={initialValues} onSubmit={submitHandler}>
              {({ setFieldValue }) => (
                <Form>
                  <div>
                    <label>
                      <strong>Client</strong>
                    </label>
                    <Select
                      size={"large"}
                      prefixCls="inner-select2"
                      defaultValue={
                        prefilledData?.selectedClient ?? selectedClient
                      }
                      onChange={(_, data) => {
                        setSelectedClient(data);
                        setSelectedCategories(null);
                      }}
                      style={{
                        width: "100%",
                        height: "48px",
                      }}
                      maxTagCount={1}
                      placeholder="Please select"
                      options={clientOptions}
                      dropdownStyle={{
                        maxHeight: "300px",
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    <label>
                      <strong>Categories</strong>
                    </label>

                    <Select
                      loading={catLoading}
                      size={"large"}
                      prefixCls="inner-select2"
                      value={catLoading ? null : selectedCategories}
                      // defaultValue={
                      //   prefilledData?.selectedClient ?? selectedClient
                      // }
                      allowClear
                      onChange={(_, data) => handleSelectedHistory(data)}
                      style={{
                        width: "100%",
                        height: "48px",
                      }}
                      notFoundContent={
                        catLoading ? (
                          <NoDataDropdown text={"Processing..."} />
                        ) : (
                          <NoDataDropdown text={"No data"} />
                        )
                      }
                      placeholder="Please select"
                      options={categories}
                      dropdownStyle={{
                        maxHeight: "300px",
                      }}
                    />
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "transparent",
                        height: "100%",
                        marginBottom: "0px",
                      }}
                    >
                      <label
                        className="mb-1  "
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <strong>Select Sources</strong>
                      </label>
                      {(selectedClient || prefilledData?.selectedClient) &&
                        !sourceLoading && (
                          <>
                            {sourceOption?.length &&
                              selectedValues?.length === sourceOption?.length ? (
                              <span
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  alignContent: "center",
                                  height: "20px",
                                }}
                                className="ml-2"
                              >
                                <span
                                  className="mr-2"
                                  style={{
                                    color: "#828690",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    fontStyle: "normal",
                                  }}
                                >
                                  {" "}
                                  Clear All
                                </span>
                                <span aria-disabled>
                                  <Checkbox
                                    disabled={sourceLoading}
                                    checked={
                                      selectedValues?.length ==
                                        sourceOption?.length
                                        ? true
                                        : defaultIsSelectAll
                                    }
                                    onChange={(e) => {
                                      dispatch(updateSelectAll(e?.target?.checked));
                                      setSelectedValues([]);
                                    }}
                                  />
                                </span>
                              </span>
                            ) : (
                              <span
                                className="ml-2"
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  alignContent: "center",
                                  height: "20px",
                                }}
                              >
                                <span
                                  className="mr-2"
                                  style={{
                                    color: "#828690",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    fontStyle: "normal",
                                  }}
                                >
                                  Select All
                                </span>
                                <span
                                  aria-disabled
                                  className="source-checkbox-wrapper"
                                >
                                  <Checkbox
                                    disabled={sourceLoading}
                                    checked={
                                      selectedValues?.length ==
                                      sourceOption?.length && true
                                    }
                                    onChange={(e) => {
                                      setSelectedCategories(null);
                                      // setIsSelectAll(e?.target?.checked);
                                      dispatch(
                                        updateSelectAll(e?.target?.checked)
                                      );
                                      if (e.target.checked) {
                                        setSelectedValues(sourceOption);
                                      }
                                    }}
                                  />
                                </span>
                              </span>
                            )}
                          </>
                        )}
                    </div>
                    <Tooltip>
                      <Select
                        mode="multiple"
                        prefixCls="inner-select2"
                        allowClear={false}
                        loading={sourceLoading}
                        style={{
                          width: "100%",
                        }}
                        maxTagCount={1}
                        value={selectedValues}
                        placeholder="Please select"
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onClear={handleAllClear}
                        onChange={(val) => {
                          setSelectedValues(val);
                          selectedCategories && setSelectedCategories(null);
                        }}
                        options={sourceOption}
                        notFoundContent={
                          sourceLoading ? (
                            <NoDataDropdown text={"Processing..."} />
                          ) : (
                            <NoDataDropdown text={"No data"} />
                          )
                        }
                      />
                    </Tooltip>
                  </div>

                  {/* <div className="channel-check-div">
                    <div className="channel-performance">
                      <div className="channel-checkbox">
                        <input
                          style={{
                            accentColor: "rgba(36, 36, 36, 1)",
                            width: "16px",
                            height: "28px",
                            borderRadius: "50%",
                            border: "1px solid #ddd",
                          }}
                          type="checkbox"
                          onChange={(e) => {
                            setIgnoreActive(false);
                            setChannelCheckActive(e.target.checked);
                          }}
                          checked={channelCheckActive}
                        />
                      </div>
                      <p className="channel-heading">
                        View Channel Performance
                      </p>
                    </div>
                  </div>

                  <div className="channel-check-div">
                    <div className="channel-performance">
                      <div className="channel-checkbox">
                        <input
                          style={{
                            accentColor: "rgba(36, 36, 36, 1)",
                            width: "16px",
                            height: "28px",
                            borderRadius: "50%",
                            border: "1px solid #ddd",
                            cursor: !channelCheckActive && "not-allowed",
                          }}
                          type="checkbox"
                          onChange={(e) => setIgnoreActive(e.target.checked)}
                          checked={ignoreActive}
                          disabled={!channelCheckActive}
                        />
                      </div>
                      <p className="channel-heading">Using Ignore Campaign</p>
                    </div>
                  </div> */}

                  {/*                   
                  <div>
                    <label>
                      <strong>Apply Margin</strong>
                    </label>
                    <Select
                      size={"large"}
                      mode="multiple"
                      prefixCls="inner-select2"
                      value={
                        initialApplyMargin
                          ? applyMarginData?.length > 0
                            ? applyMarginData
                            : applymarginOption
                          : applyMarginData
                      }
                      onChange={(_, data) => {
                        setApplyMarginData(data);
                        setInitialApplyMargin(false);
                      }}
                      style={{
                        width: "100%",
                      }}
                      maxTagCount={1}
                      placeholder="Please select"
                      options={marginOptions}
                    />
                  </div> */}

                  <div>
                    <label>
                      <strong>Current (C)</strong>
                    </label>
                    <RangeSelector
                      defaultDate={[
                        prefilledData?.date_start
                          ? moment(
                            prefilledData?.date_start,
                            "yyyy-MM-DD"
                          ).format("yyyy-MM-DD")
                          : null,
                        prefilledData?.date_end
                          ? moment(
                            prefilledData?.date_end,
                            "yyyy-MM-DD"
                          ).format("yyyy-MM-DD")
                          : null,
                      ]}
                      handleChange={(d) => {
                        const start = moment(d[0]);
                        const end = moment(d[1]);
                        setCurrentDate({ start, end });
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <span>
                      <label>
                        <strong>Compare To (CT)</strong>
                      </label>
                      <input
                        style={{
                          accentColor: "rgba(36, 36, 36, 1)",
                          width: "16px",
                          height: "28px",
                          borderRadius: "50%",
                          border: "1px solid #ddd",
                        }}
                        defaultChecked={prefilledData?.compare_enable}
                        type="checkbox"
                        disabled={filterLoader ? true : false}
                        onChange={(e) =>
                          setIsCompareDisabled(!e.target.checked)
                        }
                      />
                    </span>
                    <RangeSelector
                      defaultDate={[
                        prefilledData?.date_compare_start
                          ? moment(
                            prefilledData?.date_compare_start,
                            "yyyy-MM-DD"
                          ).format("yyyy-MM-DD")
                          : null,
                        prefilledData?.date_compare_end
                          ? moment(
                            prefilledData?.date_compare_end,
                            "yyyy-MM-DD"
                          ).format("yyyy-MM-DD")
                          : null,
                      ]}
                      handleChange={(d) => {
                        const start = moment(d[0]);
                        const end = moment(d[1]);
                        setCompareDate({ start, end });
                      }}
                      disabled={isCompareDisabled}
                    />
                  </div>
                  {!filterLoader ? (
                    <button
                      type="submit"
                      style={{ marginTop: "17px" }}
                      onClick={() => {
                        dispatch(prefilledChecksDashboard(channelCheckActive));
                        initialApplyMargin == false &&
                          dispatch(alrdyAppliedMargin(false));
                      }}
                    >
                      Apply
                    </button>
                  ) : (
                    <div
                      style={{
                        marginTop: "17px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingButton selectedThemeColors={selectedThemeColors}>
                        <LoaderIcon />
                      </LoadingButton>


                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </FilterWrapper>
  );
};
export default FilterComponent;

const FilterWrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .inner-select2-multiple .inner-select2-selection-wrap {
    align-self: center !important;
  }
  .inner-select2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    appearance: none;
    height: 50px;
    width: 100%;
    margin-top: 4px;
    padding: 0px 0px;
    border: 1px solid rgba(206, 206, 206, 1);
    border-radius: 10px;
    color: #000;
    background: #fff;

    .anticon-close {
      color: #000 !important;
    }

    .inner-select2-selector {
      border: none !important;
    }
    .inner-select2-selection-placeholder {
      color: ${({ selectedThemeColors }) =>
    selectedThemeColors?.inputPlaceHolder} !important;
    }
    .inner-select2-suffix {
      color: rgba(146, 146, 165, 1) !important;
    }
  }

  .channel-check-div {
    width: 100%;
    height: 53px;
    border-radius: 8px;
    border: 1px solid rgba(206, 206, 206, 1);
    padding: 0px 10px;
    margin-bottom: 10px;
    position: relative;
    background-color: #fff;
    color: #0f1011 !important;
    display: flex;
    align-items: center;

    .channel-performance {
      dispay: flex;
      align-items: center;

      .channel-checkbox > input {
        cursor: pointer;
      }
    }
  }
  .inner-part {
    .content-box {
      .header {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;

        img {
          width: 50px;
        }
        h4 {
          color: #fff;
        }
      }
      .filters {
        h4 {
          margin: 0px 0;
          margin-bottom: 15px;
          color: #0f1011;
        }
        div {
          /* margin-bottom: 8px; */

          label {
            color: ${({ selectedThemeColors }) =>
    selectedThemeColors?.lightText};

            display: block;
            margin-bottom: 0.25rem;
          }
          span {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              // color: #fff;
            }

            .ant-checkbox-inner {
              margin-top: 3px;
            }
            label {
              color: #7e7e7e;

              display: block;
              margin-bottom: 0.25rem;
              ${"" /* width: 100%; */}
            }
            .input {
              width: max-content;
              height: 20px;
              border-radius: 8px;
              border: none;
              padding: 0px 10px;
              margin-bottom: 10px;
              background-color: #171033;
              color: #fff;
            }
          }
          .input {
            width: 100%;
            height: 53px;
            border-radius: 8px;
            border: none;
            padding: 0px 10px;
            margin-bottom: 10px;
            background-color: #171033;
            color: #fff;
            ${"" /* border-color: #2a2833 !important;
            color: #3c2ac1 !important; */
  }
          }
          .ant-select {
            width: 100%;
            height: 53px;
            border-radius: 8px;
            border: none;

            .ant-select-selector {
              height: 100%;
              background-color: #05031a;
              border: none;
              .ant-select-selection-overflow {
                height: 100%;
                .ant-select-selection-overflow-item {
                  .ant-select-selection-item {
                    margin-bottom: 29px;
                    .ant-select-selection-item-content {
                      color: #fff;
                      cursor: context-menu;
                      maxwidth: 150px;
                      textoverflow: ellipsis;
                      overflow: hidden;
                      whitespace: nowrap;
                    }
                  }
                }
              }
            }
          }
        }
        button {
          background-color: ${({ selectedThemeColors }) =>
    selectedThemeColors?.buttonColor};
          border-color: #0f1011;
          color: #fff;
          text-align: center;
          width: 100%;
          font-weight: 700;
          height: 40px;
          border-radius: 8px;
          border: none;
          margin-top: 10px;
          font-size: 13px;
        }
      }
    }
  }
  .source-checkbox-wrapper .ant-checkbox-wrapper {
    margin-bottom: 0px !important;
    display: flex !important;

    .ant-checkbox {
      .ant-checkbox-inner {
        background-color: rgba(249, 249, 249, 1) !important;
        border: 1px solid rgba(206, 206, 206, 1);
      }
    }

    .ant-checkbox-inner {
      background-color: red !important;
      border: 1px solid rgba(206, 206, 206, 1);
    }
  }
`;

const LoadingButton = styled.p`
  ${"" /* width: 100%; */}
  font-weight: 700;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.75rem;
  width: 100%;
  background-color: ${({ selectedThemeColors }) =>
    selectedThemeColors?.buttonColor};
  border: none;
  color: #fff;
  text-align: center;
  margin-top: 8px;
  font-size: 13px;
`;
