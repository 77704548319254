import { Modal } from "antd";
import React from "react";
import styled from "styled-components";
import { MainAppIcon } from "../../utils/Images/Images";
import {
  AIBookingStatsTabs,
  ChangePassword,
  ClientsettingArrow,
  Clientsettinglogo,
  CompanyUserSVG,
  DashboardTabs,
  DynamicBudgetSheetTabs,
  Logouticon,
  Setting,
} from "../../utils/Icons/SvgIcons";
import { useSelector } from "react-redux";
import { ModalLoader } from "../../components/Loader";

export const SettingModal = ({
  isModalOpen,
  setSettingModal,
  handleSettingAction,
  logoutLoading,
}) => {
  const userDetails = useSelector(
    (state) => state?.loginAuth?.adleverageUserId
  );
  return (
    <Modal
      open={isModalOpen}
      footer={null}
      centered
      onCancel={() => setSettingModal(false)}
      prefixCls="custom-modal-height"
      width="464px"
    >
      <SelectClientModalWrapper>
        <AppLogo>
          <img src={MainAppIcon} alt="n-sight" />
        </AppLogo>

        <header>Select The Action</header>
        <p>Please select an action from the options below to continue.</p>

        <TabsWrapper>
          {userDetails?.permissions?.includes("settings") && (
            <div
              className="tabWrap"
              onClick={() => handleSettingAction("booking_buddy_settings")}
            >
              <div className="tab">
                <div className="icon settingIcon">
                  <AIBookingStatsTabs width='24' height='24' />
                </div>
                <p>Booking Buddy Settings</p>
              </div>
              <ClientsettingArrow />
            </div>
          )}

          {userDetails?.permissions?.includes("settings") && (
            <div
              className="tabWrap"
              onClick={() => handleSettingAction("dbs_settings")}
            >
              <div className="tab">
                <div className="icon settingIcon">
                 <DynamicBudgetSheetTabs width='24' height='24' />
                </div>
                <p>Dynamic Budget Settings</p>
              </div>
              <ClientsettingArrow />
            </div>
          )}

          {userDetails?.permissions?.includes("settings") && (
            <div
              className="tabWrap"
              onClick={() => handleSettingAction("client")}
            >
              <div className="tab">
                <div className="icon settingIcon">
                  <Clientsettinglogo />
                </div>
                <p> Client Settings</p>
              </div>
              <ClientsettingArrow />
            </div>
          )}

          {/* {userDetails?.permissions?.includes("settings") && (
            <div className="tab" onClick={() => handleSettingAction("admin")}>
              <div className="icon">
                <CompanyUserSVG />
              </div>
              <p> Admin Users</p>
            </div>
          )} */}
          <div
            className="tabWrap"
            onClick={() => handleSettingAction("password")}
          >
            <div className="tab">
              <div className="icon">
                <ChangePassword />
              </div>
              <p> Change Password</p>
            </div>
            <ClientsettingArrow />
          </div>
          <div
            className="tabWrap"
            onClick={() => !logoutLoading && handleSettingAction("logout")}
          >
            <div className="tab">
              <div className="icon">
                {logoutLoading ? (
                  <ModalLoader color={"#000"} size={18} />
                ) : (
                  <Logouticon />
                )}
              </div>
              <p> Logout</p>
            </div>
          </div>
        </TabsWrapper>
      </SelectClientModalWrapper>

      {/* <ButtonWrapper>
        <button onClick={() => setSettingModal(false)}>Cancel</button>
        <button>Apply</button>
      </ButtonWrapper> */}
    </Modal>
  );
};

const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .disabled {
    opacity: 0.5;
    background: #cecece;
    cursor: not-allowed !important;
    border-radius: 7px;
  }

  .tabWrap {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 16px 13px;
    box-shadow: 0px 4px 15px 0px #0000001a;
    border-radius: 6px;
    justify-content: space-between;
  }

  .tab {
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    p {
      margin: 0;
    }
  }

  .settingIcon {
    svg {
      path {
        stroke: #000;
      }
    }
  }

  .icon {
    min-width: 25px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  gap: 8px;

  button {
    &:nth-child(1) {
      width: 30%;
      height: 48px;
      padding: 10.5px 12px 10.5px 12px;
      gap: 10px;
      border-radius: 10px;
      opacity: 0px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
      text-align: center;
      background: #000;
      color: #fff;
      border: none;
    }
    &:nth-child(2) {
      width: 70%;
      height: 48px;
      padding: 10.5px 12px 10.5px 12px;
      gap: 10px;
      border-radius: 10px;
      opacity: 0px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
      text-align: center;
      background: #d80c0c;
      color: #fff;
      border: none;
    }
  }
`;

const SelectClientModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  header {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #111111;
  }

  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #7a7b7a;
  }
`;

const AppLogo = styled.div`
  img {
    height: 54px;
  }
  margin-bottom: 10px;
`;
