import React from "react";
import { Modal, Table, Tooltip } from "antd";
import LoadingBlock from "../../../components/LoadingBlock";
import { IoMdInformationCircleOutline } from "react-icons/io";

const PaidSearchModal = ({
  isModalOpen,
  setIsModalOpen,
  impressionShare,
  isPaidLoading,
}) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
        >
          Budget
          <Tooltip color={"rgb(216, 12, 12)"} title="Rolling 30 Days">
            <IoMdInformationCircleOutline
              size={16}
              style={{cursor: "pointer" }}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "budget",
      width: 200,
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
        >
          Impression Share
          <Tooltip color={"rgb(216, 12, 12)"} title="Rolling 30 Days">
            <IoMdInformationCircleOutline
              size={16}
              style={{cursor: "pointer" }}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "value",
      width: 200,
    },
  ];
  return (
    <>
      <Modal
        width={900}
        prefixCls="paidSerachSheet"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        {isPaidLoading ? (
          <LoadingBlock height={"calc(100vh - 300px)"} size={28} />
        ) : (
          <Table
            prefixCls="custom-ui-table"
            columns={columns}
            dataSource={impressionShare}
            pagination={false}
            scroll={{
              x: "100%",
              y: "calc(100vh - 300px)",
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default PaidSearchModal;
