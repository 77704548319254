import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LoginBg, MainAppIcon } from "../utils/Images/Images";
import {
  AIBookingStatsTabs,
  AICallClassificationTabs,
  CampaignsAnalyzerTabs,
  CompanyUserSVG,
  DashboardTabs,
  DynamicBudgetSheetTabs,
  MarketingTrackerTabs,
  PaidLSAPerfTabs,
  PerformanceMetricsTabs,
  Setting,
  VisualizationTabs,
} from "../utils/Icons/SvgIcons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  reset_loginAuth,
  selectedModule,
  updateClientList,
  updateLoginType,
} from "../app/Auth/signIn/LogInSlice";
import SelectClientModal from "../app/components/SelectClientModal";
import SubscriptionCheck from "../layouts/SubscriptionCheck";
import { SetNewPassword } from "./SetNewPassword";
import { SettingModal } from "../module/settings/SettingModal";
import { resetCampaignslice } from "../module/campaignTable/CampaignSlice";
import { reset_AiBookingSlice } from "../module/AIBooking/AiBookingSlice";
import { resetLsaPerformance } from "../module/PaidSearch/LsaPerformanceStore";
import { getClientByUser, logout, } from "../services/Collections";
import { Tooltip } from "antd";
import { resetDynamicBudgetSlice } from "../module/exceltable/ExcelStore";
import { resetCapacitySlice } from "../module/capacityMarketing/CapacitySlice";
import { resetQualitySlice } from "../module/qualityAssurance/QualityAssSlice";
import { DeleteModal } from "./DeleteModal";
import { resetBudgetTrckerSlice } from "../module/BudgetTracker/BudgetTrckerSlice";
import { resetNotificationSlice } from "../module/NotificationCenter/NotificationSlice";

export default function SelectModule() {
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const [logoutModal, setLogout] = useState(false);

  const [tabName, setTabName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const location = useLocation()?.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector(({ loginAuth }) => loginAuth?.data);
  const clientListing = useSelector(({ loginAuth }) => loginAuth?.clientsList);

  const [logoutLoading, setLogoutLoading] = useState(false);
  const [clientOptions, setclientOptions] = useState(
    clientListing?.clients ?? []
  );
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [clientsList, setClientList] = useState([]);
  const [clickedPath, setClickedPath] = useState("");
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );

  const handleSettings = () => {
    if (
      location?.includes("/private-layout/") &&
      prefilledData != null &&
      prefilledData?.selectedClient?.allocated_boards?.includes("settings")
    ) {
      navigate("/private-layout/settings");
    } else {
      setSClient(true);
      setTabName("Client Settings");
      setSectionName("settings");
      dispatch(selectedModule("settings"));
    }
  };

  const handleBookingBuddy = () => {
    if (location?.includes("/private-layout/")) {
      navigate("/private-layout/booking_buddy_settings");
    } else {
      dispatch(updateLoginType(false));
      navigate("/booking_buddy_settings");
    }
  };

  const handleDynamicBudgetSetting = () => {
    // if (location?.includes("/private-layout/")) {
    //   navigate("/private-layout/budget-setting");
    // } else {
    //   dispatch(updateLoginType(false));
    //   navigate("/budget-setting");
    // }
    if (
      location?.includes("/private-layout/") &&
      prefilledData != null 
    ) {
      navigate("/private-layout/budget-setting");
    } else {
      setSClient(true);
      setTabName("Budget Settings");
      setSectionName("dynamic-budget-sheet");
      dispatch(selectedModule("dynamic-budget-sheet"));
      setClickedPath("budget-setting")
    }
  };

  const handleAdminUsers = () => {
    if (location?.includes("/private-layout/")) {
      navigate("/private-layout/admin-users");
    } else {
      dispatch(updateLoginType(false));
      navigate("/admin-users");
    }
  };

  const handleLogoutApi = async () => {
    try {
      setLogoutLoading(true);
      const res = await logout();
      if (res?.status == 200) {
        navigate("/");
        handleLogout();
        setLogoutLoading(false);
      } else {
        setLogoutLoading(false);
        navigate("/");
        handleLogout();
      }
    } catch (err) {
      navigate("/");
      handleLogout();
      setLogoutLoading(false);
    }
    setLogout(false);
  };

  const handleLogout = () => {
    // requestNotificationPermission(loggedInUser);
    dispatch(resetLsaPerformance());
    dispatch(resetDynamicBudgetSlice());
    dispatch(resetCampaignslice());
    dispatch(reset_AiBookingSlice());
    dispatch(reset_loginAuth());
    dispatch(resetCapacitySlice());
    dispatch(resetQualitySlice());
    dispatch(resetBudgetTrckerSlice());
    dispatch(resetNotificationSlice());
  };

  const handleSettingAction = async (actionType) => {
    if (actionType === "client") {
      handleSettings();
      setSettingModal(false);
    }else if( actionType === 'booking_buddy_settings') {
      handleBookingBuddy();
      setSettingModal(false);
    }
    else if( actionType === 'dbs_settings') {
      handleDynamicBudgetSetting();
      setSettingModal(false);
    }
    
    else if (actionType === "admin") {
      handleAdminUsers();
      setSettingModal(false);
    } else if (actionType === "password") {
      setShowNewPasswordModal(true);
      setSettingModal(false);
    } else if (actionType === "logout") {
      setLogout(true);
      setSettingModal(false);
      // await handleLogoutApi();
    }
  };

  const userDetails = useSelector(
    (state) => state?.loginAuth?.adleverageUserId
  );

  const sidebar = [
    {
      name: "Dashboard",
      description: "All your tracking data consolidated in one place.",
      icon: <DashboardTabs />,
      path: "dashboard",
      sectionName: "dashboard",
      content: "All your tracking data consolidated in one place.",
    },
    {
      name: "Marketing Tracker",
      description: "Boost Your Marketing with Real-Time Tracking.",
      icon: <MarketingTrackerTabs />,
      path: "data-table",
      sectionName: "data-table",
      content: "Boost Your Marketing with Real-Time Tracking.",
    },

    {
      name: "Attribution Analyzer",
      description: "Optimize Your Attribution with Advanced Analysis.",
      icon: <CampaignsAnalyzerTabs />,
      path: "campaign-table",
      sectionName: "campaign-table",
      content: "Optimize Your Attribution with Advanced Analysis.",
    },
    {
      name: "Performance Metrics",
      description: "Measure Your Impact with Performance Metrics.",
      icon: <PerformanceMetricsTabs />,
      path: "report-table",
      sectionName: "report-table",
      content: "Measure Your Impact with Performance Metrics.",
    },
    {
      name: "Visualization",
      description: "Transform Data into Actionable Visualizations.",
      icon: <VisualizationTabs />,
      path: "visualization",
      sectionName: "visualization",
      content: "All your tracking data consolidated in one place.",
    },
    {
      name: "Call classification",
      description: "Enhance Efficiency with Call Classification.",
      icon: <AICallClassificationTabs />,
      path: "call-classification",
      sectionName: "call-classification",
      content: "All your tracking data consolidated in one place.",
    },
    {
      name: "Dynamic Budget Sheet",
      description: "Discover the services you need with ease.",
      icon: <DynamicBudgetSheetTabs />,
      path: "dynamic-budget-sheet",
      sectionName: "dynamic-budget-sheet",
    },
    {
      name: "Paid + LSA Perf.",
      description: "Discover the services you need with ease.",
      icon: <PaidLSAPerfTabs />,
      path: "paid-search",
      sectionName: "paid-search",
      content: "All your tracking data consolidated in one place.",
    },
    {
      name: "Get Leverage",
      description: "Enhances scheduling with seamless management and insights.",
      icon: <AIBookingStatsTabs />,
      path: "booking-buddy-performance",
      sectionName: "ai-booking-stats",
      hide: !userDetails?.clients?.some((el) => el?.company?.id),
      content: "Enhances scheduling with seamless management and insights.",
    },
    {
      name: "Quality Assurance",
      description: "Manage Client Phone Number.",
      icon: <Setting />,
      path: "quality-assurance",
      sectionName: "quality-assurance",
      content: "All your tracking data consolidated in one place.",
    },
    {
      name: "Capacity Marketing",
      description: "Analysis the 7 days capacity marketing",
      icon: <CampaignsAnalyzerTabs />,
      path: "capacity-marketing",
      sectionName: "capacity-marketing",
      content: "Analysis the 7 days capacity marketing.",
    },
    {
      name: "Budget Tracker",
      description: "Track your budegt in easy way.",
      icon: <DynamicBudgetSheetTabs />,
      path: "budget-tracker",
      sectionName: "budget-tracker",
      content: "Track your budegt in easy way.",
    },
    // {
    //   name: "Notification Center",
    //   description: "Manage All notification in one place ",
    //   icon: <CampaignsAnalyzerTabs />,
    //   path: "notification-center",
    //   sectionName: "notification-center",
    //   content: "Manage All notification in one place ",
    // },
    {
      name: "Admin Users",
      description: "Manage Admin Users & their Roles.",
      icon: <CompanyUserSVG />,
      path: "admin-users",
      sectionName: "admin-users",
      hide: true,
      content: "All your tracking data consolidated in one place.",
    },
    {
      name: "Settings",
      description: "Manage Client Settings & Account.",
      icon: <Setting />,
      path: "settings",
      sectionName: "Actions",
      content: "All your tracking data consolidated in one place.",
    },
  ];

  const filteredSidebar = sidebar
    .map((item) =>
      item?.sectionName === "Actions" ||
      userDetails?.permissions?.includes(item?.sectionName)
        ? { ...item, showCard: true }
        : { ...item, showCard: false }
    )
    .filter((ele) =>
      ele?.sectionName === "ai-booking-stats"
        ? !ele?.hide
        : !ele?.hide || userDetails?.permissions?.includes(ele?.sectionName)
    );

  const [sClient, setSClient] = useState(false);

  const handleClientsByUser = async () => {
    setOptionsLoading(true);
    let res = await getClientByUser(loggedInUser?.id);
    if (res?.status === 200) {
      let arr = [];
      let filteredObject = {
        ...userDetails,
        clients: res?.data?.clients,
      };
      dispatch(updateClientList(res?.data ?? null));
      // dispatch(loginUserDetail(filteredObject));
      // dispatch(loginSuccess(filteredObject));
      res?.data?.clients?.length > 0 &&
        res?.data?.clients?.map((ele) => {
          if (tabName == "Get Leverage") {
            if (ele?.company?.id) {
              let obj = {
                ...ele,
                label: ele?.name,
                value: ele?.name,
              };
              arr.push(obj);
            }
          } else {
            let obj = {
              ...ele,
              label: ele?.name,
              value: ele?.name,
            };
            arr.push(obj);
          }
        });

      setclientOptions(arr);
      setOptionsLoading(false);
    } else {
      setOptionsLoading(false);
    }
  };

  const getClientOption = () => {
    let arr = [];
    clientListing?.clients?.length > 0 &&
      clientListing?.clients?.map((ele) => {
        let obj = {
          ...ele,
          label: ele?.name,
          value: ele?.name,
        };
        arr.push(obj);
      });
    setclientOptions(arr);
  };

  useEffect(() => {
    // checkIsUserDeleted();
    if (!location?.includes("/private-layout")) {
      handleClientsByUser();
    } else {
      getClientOption();
    }
  }, []);

  return (
    <SelectModuleWrapper back={LoginBg}>
      <SubscriptionCheck />
      {sClient && (
        <SelectClientModal
          isModalOpen={sClient}
          setSClient={setSClient}
          tabName={tabName}
          sectionName={sectionName}
          clientOptions={clientOptions}
          optionsLoading={optionsLoading}
          path={clickedPath}
        />
      )}
      {showNewPasswordModal && (
        <SetNewPassword
          showNewPasswordModal={showNewPasswordModal}
          handleCancelPasswordModal={() => setShowNewPasswordModal(false)}
        />
      )}

      {settingModal && (
        <SettingModal
          isModalOpen={settingModal}
          setSettingModal={setSettingModal}
          handleSettingAction={handleSettingAction}
          logoutLoading={logoutLoading}
        />
      )}
      {logoutModal && (
        <DeleteModal
          handleClose={() => setLogout(false)}
          open={logoutModal}
          title="Are you sure you want to Log out ?"
          description="All your process and data will be saved."
          button="Log out"
          btnColor="#ed0101"
          handleSubmit={handleLogoutApi}
          isLoading={logoutLoading}
        />
      )}
      <div className="main-logo-div">
        <img className="loginLogo" src={MainAppIcon} alt="" />
      </div>

      <CardWrapper>
        {filteredSidebar &&
          filteredSidebar?.map((t) => (
            <ModuleCards
              name={t.name}
              description={t.description}
              icon={t.icon}
              path={t.path}
              setSClient={setSClient}
              setSettingModal={setSettingModal}
              setTabName={setTabName}
              section={t.sectionName}
              showCard={t.showCard}
              content={t.content}
              setSectionName={setSectionName}
              setClickedPath={setClickedPath}
              // selectedCompany={selectedCompany}
            />
          ))}
      </CardWrapper>
    </SelectModuleWrapper>
  );
}

const ModuleCards = ({
  name,
  description,
  icon,
  path,
  setSClient,
  setSettingModal,
  setTabName,
  section,
  showCard,
  content,
  setSectionName,
  setClickedPath,
  // selectedCompany,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUserDeatils = useSelector(({ loginAuth }) => loginAuth?.data);
  const selectedCompany = useSelector( (state) => state?.aiBooking?.selectedCompany );
  const prefilledData = useSelector( (state) => state?.loginAuth?.prefilledFilters );

  const handleReOpenModal = (sectionName) => {
    if (path?.includes("dashboard") || path?.includes("data-table")) {
      if (prefilledData?.campaigns === null || prefilledData === null) {
        return true;
      } else {
        return false;
      }
    } else {
      if (prefilledData === null) {
        return true;
      } else {
        if (
          !prefilledData?.selectedClient?.allocated_boards?.includes(
            sectionName
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const handleNavigation = (path) => {
    setClickedPath(path);
    if (path?.includes("booking-buddy-performance")) {
      if (selectedCompany?.value) {
        setTabName(name);
        setSectionName(section);
        dispatch(selectedModule(path));
        dispatch(updateLoginType(true));
        currentUserDeatils?.client_admin_type == 1
        ? navigate("/private-layout/booking-buddy-performance")
        : navigate("/private-layout/conversations");
      } else {
        setSClient(true);
        setTabName(name);
        setSectionName(section);
        dispatch(selectedModule(path));
        dispatch(updateLoginType(true));
      }
    } else {
      if (name === "Settings") {
        setSettingModal(true);
      } else if (name === "Admin Users") {
        dispatch(updateLoginType(false));
        navigate("/admin-users");
        setTabName(name);
        setSectionName(section);
        dispatch(selectedModule(path));
      }
      else {
        setTabName(name);
        setSectionName(section);
        dispatch(selectedModule(path));
        dispatch(updateLoginType(true));
        if (
          location?.pathname?.includes("/private-layout/") &&
          !handleReOpenModal(section)
        ) {
          navigate(`/private-layout/${path}`);
        } else {
          setSClient(true);
        }
      }
    }
  };

  return (
    <Tooltip title={description}>
      <ModuleCardsStyles
        showCard={showCard}
        onClick={() => showCard && handleNavigation(path)}
      >
        <i
          className={`cardIcons ${
            (name == "Settings" || name == "Quality Assurance") &&
            "settingCardIcon"
          } ${
            (name == "Admin Users" || name == "Manage Yelp Users") &&
            "admin-users-icon"
          }`}
        >
          {icon}
        </i>

        <h2>{name}</h2>
        <p>{description}</p>
      </ModuleCardsStyles>
    </Tooltip>
  );
};

const ModuleCardsStyles = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 300px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px !important;
  cursor: ${({ showCard }) => (showCard ? "pointer" : "not-allowed")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 4ms;

  &:hover {
    background: ${({ showCard }) => (showCard ? "#d80c0c" : "#ffffff")};
    transform: scale(1.04);

    .cardIcons {
      background: ${({ showCard }) => showCard && "#ffffff"};
      svg {
        color: ${({ showCard }) => showCard && "#000"};
      }
    }

    .admin-users-icon {
      svg {
        fill: ${({ showCard }) => showCard && "#000 !important"};
      }
    }

    .settingCardIcon {
      svg {
        path {
          stroke: ${({ showCard }) => showCard && "#000 !important"};
        }
      }
    }
    h2,
    p {
      color: ${({ showCard }) => showCard && "#ffffff"};
    }
  }

  h2 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #000;
    opacity: ${({ showCard }) => (showCard ? "unset" : "0.4")};
  }

  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #7a7b7a;
    opacity: ${({ showCard }) => (showCard ? "unset" : "0.4")};
  }

  .cardIcons {
    width: 60px;
    height: 60px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 16px;
    opacity: ${({ showCard }) => (showCard ? "unset" : "0.4")};
    svg {
      width: 30px;
      height: 30px;
      color: #fff;
    }
  }
`;

const CardWrapper = styled.div`
  width: 80%;
  margin: auto !important;
  height: calc(100% - 122px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  padding-bottom: 20px;
  @media screen and (max-width: 780px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 520px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const SelectModuleWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 20px !important;
  background-image: ${({ back }) => `url(${back.toString()})`};
  background-size: 100% 100%;

  /* overflow-y: scroll; */

  .main-logo-div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 40px 0;
    .loginLogo {
      height: 102px;
    }
  }
`;
