import styled from "styled-components";

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 40px 0 0;

  .sub-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    position: relative;
    z-index: 1;

    sub {
      font-size: 20px;
      font-weight: 600;
    }
  }
`;

export const GraphWrapper = styled.div`
  height: 500px;
  width: 100%;
  background: #ffffff;
  margin: 20px 0;
  padding: 20px;
  .recharts-legend-wrapper {
    height: auto !important;
    margin-top: 20px; /* Add space between legend and X-axis */
  }

  // .apexcharts-toolbar {
  // display:none;
  // }
`;
