import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  ChatGPTIcon,
  HeaderBackIcon,
  HeaderExportIcon,
  HeaderFilterIcon,
  HeaderSettingIcon,
  Logout,
} from "../utils/Icons/SvgIcons";
import { useLocation, useNavigate } from "react-router-dom";
import { SetNewPassword } from "./SetNewPassword";
import SelectClientModal from "../app/components/SelectClientModal";
import BudgetSheetHeaderFilter from "./BudgetSheetHeaderFilter";
import LoadingBlock from "./LoadingBlock";
import { Drawer, Popover } from "antd";
import { MainAppIcon } from "../utils/Images/Images";
import { DeleteModal } from "./DeleteModal";
import { logout } from "../services/Collections";
import { resetLsaPerformance } from "../module/PaidSearch/LsaPerformanceStore";
import { resetDynamicBudgetSlice } from "../module/exceltable/ExcelStore";
import { resetCampaignslice } from "../module/campaignTable/CampaignSlice";
import { reset_AiBookingSlice } from "../module/AIBooking/AiBookingSlice";
import { reset_loginAuth } from "../app/Auth/signIn/LogInSlice";
import { resetCapacitySlice } from "../module/capacityMarketing/CapacitySlice";
import { resetQualitySlice } from "../module/qualityAssurance/QualityAssSlice";
import NotificationFilter from "../module/NotificationCenter/NotificationFilter";
import { IoNotificationsOutline } from "react-icons/io5";
import { resetBudgetTrckerSlice } from "../module/BudgetTracker/BudgetTrckerSlice";
import { resetNotificationSlice } from "../module/NotificationCenter/NotificationSlice";

const Header = ({
  title = "",
  clientInfo,
  openFilter,
  isFilter = false,
  openSetting,
  isSetting = false,
  clickExport,
  isExport = false,
  isExportLoading = false,
  loading = false,
  popOverContent,
  openPopover = false,
  setPopOver,
  buttonLabelStatus = null,
  isNotification = false,
  setYear,
  isAI = false,
  openAiSummary
}) => {
  const dispatch = useDispatch();
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const isSideBarOpen = useSelector((state) => state?.LoginSlice?.sideNav);
  const themeColors = useSelector((state) => state?.loginAuth?.themeColors);
  const [sClient, setSClient] = useState(false);
  const [logoutModal, setLogout] = useState(false);
  const [isNotificationDrawer, setIsNotificationDrawer] = useState(false);

  const location = useLocation().pathname;
  const navigate = useNavigate();

  const handleError = (e) => {
    e.target.onerror = null;
    e.target.src = MainAppIcon;
  };

  const handleCancelPasswordModal = () => {
    setShowNewPasswordModal(false);
  };

  const handleLogout = async () => {
    logout();
    navigate("/");
    cleanReduxWhileLogout();
  };

  const handleRedirection = () => {
    if (
      location?.includes("/conversations") ||
      location?.includes("booking_buddy_settings")
    ) {
      navigate(-1);
    } else if (location?.includes("/private-layout/")) {
      navigate("/private-layout/select-module");
    } else {
      navigate("/select-module");
    }
  };

  const cleanReduxWhileLogout = () => {
    dispatch(resetLsaPerformance());
    dispatch(resetDynamicBudgetSlice());
    dispatch(resetCampaignslice());
    dispatch(reset_AiBookingSlice());
    dispatch(reset_loginAuth());
    dispatch(resetCapacitySlice());
    dispatch(resetQualitySlice());
    dispatch(resetBudgetTrckerSlice());
    dispatch(resetNotificationSlice());
  };

  return (
    <HeaderWrapper themeColors={themeColors} title={title}>
      {sClient && (
        <SelectClientModal isModalOpen={sClient} setSClient={setSClient} />
      )}

      {showNewPasswordModal && (
        <SetNewPassword
          showNewPasswordModal={showNewPasswordModal}
          handleCancelPasswordModal={handleCancelPasswordModal}
        />
      )}

      {logoutModal && (
        <DeleteModal
          handleClose={() => setLogout(false)}
          open={logoutModal}
          title="Are you sure you want to Log out ?"
          description="All your process and data will be saved."
          button="Log out"
          btnColor="#ed0101"
          handleSubmit={handleLogout}
        />
      )}

      <div className="header">
        <div
          className="dashboard-head"
          style={{ marginLeft: isSideBarOpen ? "225px" : 0 }}
        >
          <i className="backIcon" onClick={handleRedirection}>
            <HeaderBackIcon />
          </i>
          <h1>
            <h1>{title}</h1>
          </h1>
        </div>

        <div className="dynamicheader">
          <ClientWrapper>
            {location.includes("dynamic-budget-sheet") ||
            location.includes("budget-setting") ? (
              <>
                <BudgetSheetHeaderFilter setYear={setYear} />
                <>
                  {isExport &&
                    (isExportLoading ? (
                      <IconsWrappers height="24px">
                        <LoadingBlock
                          height={"100%"}
                          size={14}
                          color="white"
                          onlySpinner={false}
                        />
                      </IconsWrappers>
                    ) : (
                      <>
                        <IconsWrappers
                          onClick={() => clickExport && clickExport()}
                        >
                          <HeaderExportIcon />
                        </IconsWrappers>
                      </>
                    ))}
                  {isSetting && (
                    <IconsWrappers
                      disable={loading}
                      onClick={() => !loading && openSetting && openSetting()}
                    >
                      <HeaderSettingIcon />
                    </IconsWrappers>
                  )}
                  {isNotification && (
                    <IconsWrappers
                      disable={loading}
                      onClick={() => !loading && setIsNotificationDrawer(true)}
                    >
                      <IoNotificationsOutline size={"20px"} />
                    </IconsWrappers>
                  )}
                </>
              </>
            ) : (
              <>
                {clientInfo && (
                  <div className="client">
                    <div className="logo">
                      {!location?.includes("capacity-marketing") &&
                        !location?.includes("booking_buddy_settings") && (
                          <img
                            src={clientInfo?.logo || MainAppIcon}
                            alt="client_logo"
                            onError={handleError}
                          />
                        )}
                    </div>
                    <span className="name">{clientInfo?.name}</span>
                  </div>
                )}
                {buttonLabelStatus && (
                  <IconsWrappers style={{ fontStyle: "normal" }}>
                    <p className="booking-company-tag">
                      {buttonLabelStatus == "Live" ? "[Live]" : "Free Trial"}
                    </p>
                  </IconsWrappers>
                )}
                {isExport &&
                  (isExportLoading ? (
                    <IconsWrappers height="24px">
                      <LoadingBlock
                        height={"100%"}
                        size={14}
                        color="white"
                        onlySpinner={false}
                      />
                    </IconsWrappers>
                  ) : (
                    <>
                      {popOverContent ? (
                        <Popover
                          content={popOverContent}
                          arrow={false}
                          trigger="hover"
                        >
                          <IconsWrappers>
                            <HeaderExportIcon />
                          </IconsWrappers>
                        </Popover>
                      ) : (
                        <IconsWrappers
                          onClick={() => clickExport && clickExport()}
                        >
                          <HeaderExportIcon />
                        </IconsWrappers>
                      )}
                    </>
                  ))}

                {isSetting && (
                  <>
                    {popOverContent ? (
                      <Popover
                        content={popOverContent}
                        arrow={false}
                        trigger="click"
                        open={openPopover}
                        onOpenChange={setPopOver}
                      >
                        <IconsWrappers>
                          <HeaderSettingIcon />
                        </IconsWrappers>
                      </Popover>
                    ) : (
                      <IconsWrappers
                        disable={loading}
                        onClick={() => !loading && openSetting && openSetting()}
                      >
                        <HeaderSettingIcon />
                      </IconsWrappers>
                    )}
                  </>
                )}

                {isFilter && (
                  <IconsWrappers
                    disable={loading}
                    onClick={() => !loading && openFilter && openFilter()}
                  >
                    <HeaderFilterIcon />
                  </IconsWrappers>
                )}

                {isNotification && (
                  <IconsWrappers
                    disable={loading}
                    onClick={() => !loading && setIsNotificationDrawer(true)}
                  >
                    <IoNotificationsOutline size={"20px"} />
                  </IconsWrappers>
                )}
              </>
            )}

            {isAI && (
              <IconsWrappers>
                <AISummaryWrapper onClick={()=> openAiSummary && openAiSummary()} >
                  <div>
                    <i>
                      <ChatGPTIcon />
                    </i>
                    <p>AI Summary</p>
                  </div>
                </AISummaryWrapper>
              </IconsWrappers>
            )}

            <IconsWrappers disable={loading} onClick={() => setLogout(true)}>
              <Logout />
            </IconsWrappers>
          </ClientWrapper>
        </div>
      </div>
      {isNotificationDrawer && (
        <Drawer
          placement="right"
          closable={false}
          onClose={() => setIsNotificationDrawer(false)}
          open={isNotificationDrawer}
          width={"500px"}
          key="bottom"
        >
          <NotificationFilter setFilterDrawer={setIsNotificationDrawer} />
        </Drawer>
      )}
    </HeaderWrapper>
  );
};

export default Header;
const HeaderWrapper = styled.div`
  padding-top: 56px !important;
  .header {
    background: ${({ themeColors, title }) =>
      title === "Conversations" ? "#000" : themeColors?.headerColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 56px !important;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;

    .dashboard-head {
      display: flex;
      gap: 5px;
      transition: all 0.5s;
      img {
        width: 32px;
        height: 27px;
        cursor: pointer;
      }
      h1 {
        color: ${({ themeColors }) => themeColors?.CardsColor};
        font-size: 16px;
        font-weight: 600;
        margin: 0px;
        padding-top: 2px;
      }
    }
    .profileDiv {
      background: ${({ themeColors }) => themeColors?.CardsColor};
      padding: 5px 20px 5px 5px;
      border-radius: 40px;
      display: flex;
      gap: 8px;
      height: 31px;

      img {
        width: 25px !important;
        height: 25px !important;
      }

      div {
        display: flex;
        align-items: center;
        gap: 4px;
        p {
          margin: 0px;
          color: ${({ themeColors }) => themeColors?.headerProfileText};
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 500;
        }
        svg {
          cursor: pointer;
        }
      }
      select {
        border-radius: 40px;
        background: transparent;
        border: none;
        color: #fff;
      }
    }
  }
  .backIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .dynamicheader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .booking-company-tag {
    color: red;
    margin: 0;
    white-space: nowrap;
  }
`;

const PopWrapper = styled.div`
  color: red;
  position: relative;

  .content {
    width: 160px;
    background: white;
    padding: 4px;
    position: absolute;
    z-index: 9999 !important;
    top: 40px;
    left: -140px;
    border-radius: 9px;
    border: 1px solid #cecece;
    box-shadow: 0px 2px 8px rgba(61, 107, 192, 0.28);
  }
`;

const ClientWrapper = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 16px;

  .client {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 7px;

    .logo {
      width: 100%;
      height: 42px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-wrap: nowrap;
      padding-right: 16px;
    }
  }

  .hr {
    width: 1px;
    height: 22px;
    background: #7c7c7c;
  }
`;

const IconsWrappers = styled.i`
  cursor: ${({ disable }) => (disable ? "not-allowed" : "pointer")};
  height: ${({ height }) => (height ? "24px" : "auto")};
  padding-left: 16px !important;
  border-left: 1px solid #7c7c7c;
`;

const AISummaryWrapper = styled.div`
  width: 40px;
  height: 100%;
  border-radius: 100px;
  background: linear-gradient(
    98.92deg,
    #14b38b 4.57%,
    #3cbfff 55.54%,
    #ff2991 106.52%
  );
  padding: 2px;
  transition: all 400ms ease-out;
  overflow: hidden;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 6px;
    background: #242424;
    border-radius: 100px;
  }

  p {
    display: none;
    margin: 0;
    white-space: nowrap;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 8.31px;
  }

  :hover {
    width: 144px;
    p {
      display: flex;
    }
  }
`;
