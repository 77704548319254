const distribution = [
  {
    name: "Unbooked %",
    type: "monotone",
    dataKey: "unbooked_rate",
    color: "#ffbf33",
  },
  {
    name: "Excused %",
    type: "monotone",
    dataKey: "excused_rate",
    color: "#f6874c",
  },
  {
    name: "Abandon %",
    type: "monotone",
    dataKey: "abandon_rate",
    color: "#f75a77",
  },
];
const distribution2 = [
  {
    name: "Lead %",
    type: "monotone",
    dataKey: "lead_calls_perc",
    color: "#f6874c",
  },
  {
    name: "Booking %",
    type: "monotone",
    dataKey: "unique_call_booking_perc",
    color: "#ffbf33",
  },
  {
    name: "Closing %",
    type: "monotone",
    dataKey: "closing_perc",
    color: "#f75a77",
  },
];
const titles = [
  "Distribution Over Time",
  "Trend of Lead %, Booking % and Closing %",
  "Avg Ticket and Cancellation %",
  "Variability in Avg Ticket",
  "Correlation Between KPI's",
  "Distribution of Avg Ticket",
];

export { distribution, distribution2, titles };
