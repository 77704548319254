import { Select } from "antd";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { filterClientsBySection } from "../../utils/common/commonFunction";
import { updateQualityAssuranceClient } from "./QualityAssSlice";
import LoadingBlock from "../../components/LoadingBlock";

export default function QualityFilter({ paidLoading }) {
  const dispatch = useDispatch();

  const {
    themeColors: selectedThemeColors,
    prefilledFilters: prefilledData,
    clientsList: clientOptionsData,
  } = useSelector((state) => state?.loginAuth);

  const selectedClient = useSelector(
    (state) => state?.qualityAssurance?.selectedClient
  );

  const [activeClient, setActiveClient] = useState(
    selectedClient ? selectedClient : prefilledData?.selectedClient
  );

  const [clientOptions, setclientOptions] = useState([]);

  const handleSubmit = async () => {
    dispatch(updateQualityAssuranceClient(activeClient));
  };

  const getClientOption = () => {
    let arr = [];
    filterClientsBySection(clientOptionsData?.clients, "quality-assurance")
      ?.length > 0 &&
      // eslint-disable-next-line array-callback-return
      filterClientsBySection(
        clientOptionsData?.clients,
        "quality-assurance"
      )?.map((ele) => {
        let obj = {
          ...ele,
          label: ele?.name,
          value: ele?.name,
        };
        arr.push(obj);
      });
    setclientOptions(arr);
  };

  console.log(clientOptionsData?.clients, clientOptions, "clientOptions");

  useEffect(() => {
    getClientOption();
  }, []);

  return (
    <PaidFilterWrapper selectedThemeColors={selectedThemeColors}>
      <div className="title">
        <h3>Filters</h3>
      </div>

      <div className="content">
        <Formik initialValues={{}} onSubmit={handleSubmit}>
          {() => (
            <Form>
              <div style={{ marginTop: "20px" }} className="select-wrap">
                <label>
                  <strong>Client</strong>
                </label>
                <br />
                <Select
                  prefixCls="inner-select2"
                  value={activeClient}
                  onChange={(_, data) => setActiveClient(data)}
                  style={{
                    width: "100%",
                    height: "48px",
                    marginTop: "6px",
                  }}
                  maxTagCount={1}
                  placeholder="Please select"
                  options={clientOptions}
                />
              </div>

              {paidLoading ? (
                <LoadingButton
                  selectedThemeColors={selectedThemeColors}
                  style={{ marginTop: "30px" }}
                >
                  <LoadingBlock
                    height="fit-content"
                    size={28}
                    onlySpinner={false}
                    color={"#fff"}
                  />
                </LoadingButton>
              ) : (
                <button className="btn-wrap" type="submit">
                  <b>Submit</b>
                </button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </PaidFilterWrapper>
  );
}

const PaidFilterWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px 0;

  .btn-wrap {
    margin-top: 30px;
    width: 100%;
    border-style: none;
    background-color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.buttonColor};
    border-radius: 7px;
    padding: 10px 15px;
    color: #fff;
  }

  .title {
    width: 100%;
    margin-bottom: 20px;

    h3 {
      font-size: 24px;
      color: rgba(25, 25, 25, 1);
    }
  }
  .content {
    width: 100%;
    padding-bottom: 20px;

    label {
      color: ${({ selectedThemeColors }) => selectedThemeColors?.lightText};
      font-size: 15px;
    }
    .select-wrap {
      margin-top: 10px;
    }
  }
  .add-more {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 10px;

    button {
      border: none;
      padding: 3px 7px;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      background-color: rgba(36, 36, 36, 1);
    }
  }
`;

const LoadingButton = styled.p`
  margin-top: 13px;
  width: 100%;
  border-style: none;
  background-color: ${({ selectedThemeColors }) =>
    selectedThemeColors?.buttonColor};
  border-radius: 7px;
  padding: 10px 15px;
  color: #fff;

  text-align: center;
`;
