import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import AIBookingConversation from "./AIBookingConversation";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import AiFilter from "./AiFilter";
import Watermark from "../../components/Watermark";
import TabComponent from "../../components/newComponent/TabComponent";
import {
  resetChat,
  updatedChats,
  updatedSelectedChats,
  updateisIndicatorMessage,
  updateisIndicatorTab,
  updateMessageType,
} from "./AiBookingSlice";
import BookingList from "./BookingList";
import ScheduledBooking from "./ScheduledBooking";

import { getMessages } from "../../services/Collections";
import {
  debounce as customDebounce,
  formatUSPhoneNumber,
  timeAgo,
} from "../../utils/common/commonFunction";
import { updateSelectedChat } from "./AiBookingSlice";
import moment from "moment";
import axios from "axios";
import { debounce } from "lodash";
import {
  AvatarBoy,
  AvatarGamer,
  AvatarMan,
  AvatarMan2,
  AvatarUser,
  AvatarWoman,
  chat,
  people,
  AvatarAssistant,
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
} from "../../utils/Images/Images";
import { useInfiniteScroll } from "../../customHook/InfiniteScroll";
import socket from "../../app/socket/socketConnection";
import { soundNotificationTigger } from "./commonFun";

const avatarArray = [
  AvatarBoy,
  AvatarGamer,
  AvatarWoman,
  AvatarMan,
  AvatarMan2,
  AvatarUser,
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
];

const tabIndicatorEnum = {
  widget: "Chat Widget",
  sms: "SMS Message",
  lsa: "LSA Message",
  yelp: "Yelp",
};

export default function ConversationGround() {
  const [loadMore] = useInfiniteScroll({
    elementId: "scrollable-container",
    height: window.innerHeight,
  });

  const dispatch = useDispatch();
  const aiSelectedCompany = useSelector(
    (state) => state?.aiBooking?.selectedCompany
  );

  const [filterDrawers, setFilterDrawers] = useState(false);
  const cancelToken = useRef(null);
  const [companyList, setCompanyList] = useState([]);
  const AiData = useSelector((state) => state?.aiBooking);
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );
  const { messageType, isIndicatorMessage, isIndicatorTab } = AiData;

  const selectedCompany = useSelector(
    (state) => state?.aiBooking?.selectedCompany?.value
  );
  const AiStartDate = useSelector((state) => state?.aiBooking?.startDate);
  const AiEndDate = useSelector((state) => state?.aiBooking?.endDate);

  var enddate = new Date();
  var startdate = new Date(new Date().setDate(enddate.getDate() - 30));

  const [loading, setLoading] = useState(false);

  const [paginationLoader, setPaginationLoader] = useState(false);
  const [page, setPage] = useState(1);
  // const [isIndicatorMessage, setIsIndicatorMessage] = useState([]);
  // const [isIndicatorTab, setIsIndicatorTab] = useState([]);
  const [tabCount, setTabCount] = useState({
    widget: [],
    sms: [],
    lsa: [],
    "custom-sms": [],
    yelp: [],
    facebook: [],
  });

  const findActiveTab = useMemo(() => {
    let tabTypes = ["chatwidget", "sms", "lsa", "yelp", "booking", "scheduled"];
    return tabTypes?.indexOf(messageType);
  }, [messageType]);

  const handleAction = (val) => {
    if (val === 0 && messageType !== "chatwidget") {
      handleMessageType("chatwidget");
    } else if (val === 1 && messageType !== "sms") {
      handleMessageType("sms");
    } else if (val === 2 && messageType !== "lsa") {
      handleMessageType("lsa");
    } else if (val === 3 && messageType !== "yelp") {
      handleMessageType("yelp");
    } else if (val === 4) {
      handleMessageType("booking");
    } else if (val === 5) {
      handleMessageType("scheduled");
    }
  };

  const handleMessageType = (el) => {
    dispatch(updateMessageType(el));
    // dispatch(updateSelectedChat(null));
    // setTrackSelectedChat(!trackSelectedChat);
    // setPage(1);
    // setSearchWord("");
    // if (messageType !== el) {
    //   setResponsiveChat("user");
    // }
  };

  const getClientOption = () => {
    let arr = [];
    clientOptionsData?.clients?.length > 0 &&
      clientOptionsData?.clients?.map((ele) => {
        if (ele?.company?.id) {
          let obj = {
            label: ele?.name,
            value: ele?.company?.id,
            logo: ele?.logo,
            name: ele?.name,
            key: ele?.key,
          };
          arr.push(obj);
        }
      });
    setCompanyList(arr);
  };

  const tabs = [
    "Chat Widget",
    "SMS Message",
    "LSA Message",
    "Yelp",
    "BB Bookings",
    // "Simply Scheduled Bookings",
  ];

  const handleList = async (
    search,
    pageNumber,
    showLoader = true,
    messtype,
    tokenAPI = true
  ) => {
    let currentMessageType = messageType;
    if (messtype) {
      currentMessageType = messtype;
    }

    if (cancelToken.current) {
      cancelToken.current.cancel("Request cancelled by user");
    }
    cancelToken.current = axios.CancelToken.source();

    try {
      const currentPage = pageNumber || page;
      if (currentPage === 1) {
        setLoading(showLoader);
        setPaginationLoader(false);
      } else {
        setPaginationLoader(true);
      }

      const params = new URLSearchParams();
      params.append("page", currentPage);
      params.append("page_size", 20);
      params.append("sort_by", "modified_at");
      params.append("order_by", "desc");
      params.append("company_id", selectedCompany);
      params.append(
        "start_time",
        moment(AiStartDate ?? startdate).format("YYYY-MM-DD")
      );
      params.append(
        "end_time",
        moment(AiEndDate ?? enddate).format("YYYY-MM-DD")
      );

      if (search) {
        params.append("search", search);
      } else if (searchWord) {
        params.append("search", searchWord);
      }

      const res = await getMessages(
        currentMessageType,
        params,
        [{ field: "company_id", value: selectedCompany }],
        tokenAPI && { cancelToken: cancelToken.current.token }
      );

      if (res?.status === 200) {
        let formattedArray = [];

        if (currentMessageType === "chatwidget") {
          formattedArray = res?.data?.data?.map((el, idx) => ({
            ...el,
            name: el?.title,
            displayName: el?.lead_name ? el?.lead_name : "Customer",
            phone: el?.lead_phone ? el?.lead_phone : "+1(XXX) XXX-XXXX",
            lastMessage:
              el?.content?.length > 0
                ? el?.content[el?.content?.length - 1]?.content
                : "No message",
            date: timeAgo(el?.content[el?.content?.length - 1]?.time),
            avatar: avatarArray[idx % 19],
          }));
        }

        if (currentMessageType === "sms") {
          formattedArray = res?.data?.data?.map((el, idx) => ({
            ...el,
            name: el?.sms_user?.lead_first_name
              ? el?.sms_user?.lead_first_name +
              " " +
              el?.sms_user?.lead_last_name
              : "Customer",
            displayName: el?.sms_user?.lead_first_name
              ? el?.sms_user?.lead_first_name +
              " " +
              el?.sms_user?.lead_last_name
              : "Customer",
            phone: el?.sms_user?.lead_phone
              ? el?.sms_user?.lead_phone
              : "+1(XXX) XXX-XXXX",
            lastMessage:
              el?.content?.length > 0
                ? el?.content[el?.content?.length - 1]?.content
                : "No message",
            date: timeAgo(el?.content[el?.content?.length - 1]?.time),
            avatar: avatarArray[idx % 19],
          }));
        }

        if (currentMessageType === "lsa") {
          formattedArray = res?.data?.data?.map((el, idx) => ({
            ...el,
            name: el?.lsa_user?.lead_name
              ? el?.lsa_user?.lead_name
              : "Customer",
            displayName: el?.lsa_user?.lead_name
              ? el?.lsa_user?.lead_name
              : "Customer",
            phone: el?.lsa_user?.lead_phone
              ? el?.lsa_user?.lead_phone
              : "+1(XXX) XXX-XXXX",

            lastMessage:
              el?.content?.length > 0
                ? el?.content[el?.content?.length - 1]?.content
                : "No message",
            date: timeAgo(el?.content[el?.content?.length - 1]?.time),
            avatar: avatarArray[idx % 19],
          }));
        }

        if (currentMessageType === "yelp") {
          formattedArray = res?.data?.data?.map((el, idx) => ({
            ...el,
            name: el?.yelp_user?.name ? el?.yelp_user?.name : "Customer",
            displayName: el?.yelp_user?.name ? el?.yelp_user?.name : "Customer",
            phone: el?.yelp_user?.lead_phone
              ? el?.yelp_user?.lead_phone
              : "+1(XXX) XXX-XXXX",
            lastMessage:
              el?.content?.length > 0
                ? el?.content[el?.content?.length - 1]?.content
                : "No message",
            date: timeAgo(el?.content[el?.content?.length - 1]?.time),
            avatar: avatarArray[idx % 19],
          }));
        }

        let isUnreadIDs = res?.data?.data?.map(
          (el, idx) => !el?.is_read && el?.id
        );
        const newIDs = isUnreadIDs.filter(
          (id) => id && !isIndicatorMessage.includes(id)
        );

        dispatch(
          updateisIndicatorMessage([
            ...(Array.isArray(isIndicatorMessage) ? isIndicatorMessage : []),
            ...newIDs,
          ])
        );

        // if (AiData[currentMessageType]?.selectedChat) {
        //   let tempindicatorMessage = isIndicatorMessage.filter( (el) => el !== AiData[currentMessageType]?.selectedChat?.id );
        //   let tempnewids = newIDs.filter( (el) => el !== AiData[currentMessageType]?.selectedChat?.id );          
        //   dispatch(
        //     updateisIndicatorMessage([
        //       ...(Array.isArray(tempindicatorMessage) ? tempindicatorMessage : []),
        //       ...tempnewids,
        //     ])
        //   );
        // } else {
        //   dispatch(
        //     updateisIndicatorMessage([
        //       ...(Array.isArray(isIndicatorMessage) ? isIndicatorMessage : []),
        //       ...newIDs,
        //     ])
        //   );
        // }

        if (currentPage === 1) {
          dispatch(
            updatedChats({
              chat: formattedArray,
              fetchedCount: 1,
              type: currentMessageType,
              totalCount: res?.data?.total_count,
            })
          );
          setPage(1);
          setLoading(false);
        } else {
          dispatch(
            updatedChats({
              chat: [...AiData[currentMessageType]?.chat, ...formattedArray],
              fetchedCount: currentPage,
              type: currentMessageType,
              totalCount: res?.data?.total_count,
            })
          );
          setPaginationLoader(false);
        }
      } else {
        if (currentPage === 1) {
          setLoading(false);
        } else {
          setPaginationLoader(false);
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        const currentPage = pageNumber || page;
        if (currentPage > 1) {
          setPage(currentPage - 1);
          dispatch(
            updatedChats({
              chat: [...AiData[currentMessageType]?.chat],
              fetchedCount: currentPage - 1,
              type: currentMessageType,
            })
          );
        }
        console.log("Request cancelled:", currentMessageType, error.message, currentPage);
      } else {
        console.error("Request failed:", error);
        setLoading(false);
        setPaginationLoader(false);
      }
    }
  };

  const manageTabCount = (tab, type, messageid) => {
    if (tabCount[tab] !== undefined) {
      // Increase tab count
      if (type === "increase" && !tabCount[tab]?.includes(messageid)) {
        tabCount[tab]?.push(messageid);
      }
      // Decrease tab count but ensure it doesn't go below 0
      else if (type === "decrease" && tabCount[tab]?.includes(messageid)) {
        tabCount[tab] = tabCount[tab]?.filter((el) => el !== messageid);
      }
      setTabCount(tabCount);
    }
    let t = isIndicatorTab;
    for (const key in tabCount) {
      if (tabCount[key]?.length === 0) {
        if (tabIndicatorEnum[key]) {
          let updated = t?.filter((el) => el !== tabIndicatorEnum[key]);
          t = updated;
        }
      } else {
        if (tabIndicatorEnum[key]) {
          t = [...(Array.isArray(t) ? t : []), tabIndicatorEnum[key]];
        }
      }
    }

    dispatch(updateisIndicatorTab(t));
  };

  const appendMessage = (message, messageType) => {
    console.log(message, messageType, "appendMessage");

    if (message?.message?.length > 0) {
      let tempEnums = {
        widget: "chatwidget",
        sms: "sms",
        lsa: "lsa",
        yelp: "yelp",
      };

      if (AiData[tempEnums[message?.type]]?.chat) {
        let tempChat = AiData[tempEnums[message?.type]]?.chat;
        if (
          tempChat?.length > 0 &&
          tempChat?.some((el) => el?.id === message?.id)
        ) {
          let findChat = tempChat?.find((el) => el?.id === message?.id);
          let filtered = tempChat?.filter((el) => el?.id !== message?.id);

          let updatedChat = {
            ...findChat,
            content: [...findChat?.content, ...message?.message],
            modified_at: message?.message[message?.message?.length - 1]?.time,
            lastMessage:
              message?.message?.length > 0
                ? message?.message[message?.message?.length - 1]?.content
                : "No message",
          };

          dispatch(
            updatedChats({
              type: tempEnums[message?.type],
              chat: [updatedChat, ...filtered],
            })
          );

          if (
            AiData[messageType]?.selectedChat &&
            AiData[messageType]?.selectedChat?.id === message?.id
          ) {
            dispatch(
              updatedSelectedChats({
                type: messageType,
                selectedChat: updatedChat,
              })
            );
          }
        } else {
          handleList("", 1, false, tempEnums[message?.type]);
        }
      }
    }
  };
  const [searchWord, setSearchWord] = useState("");

  const handleSearchListing = useCallback(customDebounce(handleList), [
    messageType,
    selectedCompany,
    AiEndDate,
    AiStartDate,
  ]);

  const handleSearch = (value) => {
    setSearchWord(value);
    handleSearchListing(value, 1);
  };

  useEffect(() => {
    clientOptionsData?.clients?.length > 0 && getClientOption();
  }, [aiSelectedCompany, AiData?.startDate, AiData?.endDate]);

  useEffect(() => {
    if (messageType !== "booking" && messageType !== "scheduled") {
      const debouncedSearch = debounce(() => {
        if (searchWord) {
          handleSearch(searchWord);
        } else {
          setPage(1);
          dispatch(
            updatedSelectedChats({ type: messageType, selectedChat: null })
          );
          handleList("", 1);
        }
      }, 500); // debounce time (500ms)

      if (AiData[messageType]?.chat?.length > 0) {
        debouncedSearch();
      } else {
        setPage(1);
        dispatch(
          updatedSelectedChats({ type: messageType, selectedChat: null })
        );
        handleList("", 1);
      }
      return () => debouncedSearch.cancel();
    }
  }, [searchWord]);

  useEffect(() => {
    socket.on("admin", (message) => {
      if (!socket.connected) {
        console.warn("Socket.IO is not connected. Message handling skipped.");
      }
      if (message?.company_id === selectedCompany) {
        // MANAGE TAB COUNTS
        manageTabCount(message?.type, "increase", message?.id);
        // ADDED A SOUND OF NOTIFICATION
        soundNotificationTigger();

        appendMessage(message, messageType);

        dispatch(updateisIndicatorMessage([...isIndicatorMessage, message?.id]));
      }
    });

    return () => {
      socket.off("admin");
    };
  }, [
    selectedCompany,
    AiData[messageType]?.selectedChat,
    messageType,
    searchWord,
  ]);

  useEffect(() => {
    if (
      loadMore &&
      AiData[messageType]?.chat?.length < AiData[messageType]?.totalCount &&
      !paginationLoader &&
      !loading
    ) {
      setPage(page + 1);
      handleList("", page + 1);
    }
  }, [loadMore]);

  useEffect(() => {
    dispatch(resetChat());
    handleList("", 1, true, "chatwidget", false);
    handleList("", 1, true, "sms", false);
    handleList("", 1, true, "yelp", false);
    handleList("", 1, true, "lsa", false);
  }, [selectedCompany, AiStartDate, AiEndDate]);

  useEffect(() => {
    setPage(AiData[messageType]?.fetchedCount);
    if (AiData[messageType]?.chat?.length === 0) {
      handleList("", 1, true, messageType, false);
    } else {
      handleList("", 1, false);
    }
    dispatch(updatedSelectedChats({ type: messageType, selectedChat: null }));
    setSearchWord("");
  }, [messageType]);

  return (
    <>
      <ConversationGroundWrapper
        height={messageType === "booking" || messageType === "scheduled"}
      >
        <Header
          title="Conversations"
          clientInfo={aiSelectedCompany}
          isFilter={true}
          openFilter={() => setFilterDrawers(true)}
          isNotification={true}
        />

        <TabComponent
          index={findActiveTab}
          tabs={tabs}
          selectedIndex={handleAction}
          // disableBtnsOnLoading={switchLoading}
          isIndicator={isIndicatorTab}
        />
        {messageType === "booking" ? (
          <BookingList />
        )
          // : messageType === "scheduled" ? (
          //   <ScheduledBooking />
          // ) 
          : (
            <AIBookingConversation
              cancelToken={cancelToken}
              messagesList={AiData[messageType]?.chat}
              loading={loading}
              paginationLoader={paginationLoader}
              selectedChat={AiData[messageType]?.selectedChat || null}
              setTabCount={setTabCount}
              tabCount={tabCount}
              setSearchWord={setSearchWord}
              searchWord={searchWord}
            />
          )}

        {filterDrawers && (
          <AiFilter
            filterDrawers={filterDrawers}
            setFilterDrawers={setFilterDrawers}
            setCStatsData={null}
            companyList={companyList}
            initialLoading={false}
            stopApi={true}
            showUi={messageType === "booking" || messageType === "scheduled"}
          />
        )}
      </ConversationGroundWrapper>
      {messageType !== "booking" && messageType !== "scheduled" && (
        <Watermark
          style={{
            position: "absolute",
            bottom: "1px",
            right: "10px",
            background: "#fff",
          }}
          background="rgb(255 255 255)"
        />
      )}
    </>
  );
}

const ConversationGroundWrapper = styled.div`
  width: 100%;
  min-height: ${({ height }) => (height ? "100vh" : "calc(100vh - 19px)")};
  max-height: 100%;
  background: #ffffff;
  padding: 20px 20px 0;
  position: relative;
`;
