import React from "react";
import styled from "styled-components";

const NoDataUI = ({ icon, heading, para }) => {
  return (
    <MessageContainer>
      <IconWrapper>{icon ? icon : ""}</IconWrapper>
      {heading && <MessageTitle>{heading}</MessageTitle>}
      {para && <MessageText>{para}</MessageText>}
    </MessageContainer>
  );
};

export default NoDataUI;

const MessageContainer = styled.div`
  height: calc(100vh - 150px);
  text-align: center;
  padding: 2rem;
  // background-color: #f9faf6;
  border-radius: 0.5rem;
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MessageTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 0.5rem;
`;

const MessageText = styled.p`
  color: #6b7280;
  font-size: 1rem;
`;

const IconWrapper = styled.div`
  width: 100%;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;
