import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { GraphWrapper, MainWrapper } from "./style";

const Histogram = ({ data }) => {
  const [histogram, setHistogram] = useState(null);

  const manipulateData = (arr) => {
    const rangeSize = 100;
    let dataArray = arr?.map((ele) => ele?.avg_ticket);

    const min = Math.min(...dataArray);
    const max = Math.max(...dataArray);

    const histogramData = [];
    for (
      let start = Math.floor(min / rangeSize) * rangeSize;
      start < max;
      start += rangeSize
    ) {
      const range = `${start}-${start + rangeSize}`;
      histogramData.push({ range, count: 0 });
    }

    dataArray.forEach((value) => {
      const rangeIndex = Math.floor((value - min) / rangeSize);
      if(histogramData?.length > 0) histogramData[rangeIndex].count += 1;
    });

    setHistogram(histogramData);
  };

  useEffect(() => {
    manipulateData(data);
  }, [data]);

  return (
    <MainWrapper>
      <div className="sub-header">
        <sub>Distribution of Avg Ticket</sub>
      </div>
      <GraphWrapper className="graph-section">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={histogram}>
            <CartesianGrid horizontal={false} strokeDasharray="3 3" />
            <XAxis dataKey="range" />
            <YAxis
              label={{ value: "Frequency", angle: -90, position: "insideLeft" }}
            />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#673bb7" />
          </BarChart>
        </ResponsiveContainer>
      </GraphWrapper>
    </MainWrapper>
  );
};

export default Histogram;
