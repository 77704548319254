/* eslint-disable react/jsx-key */
import React from "react";
import { useSelector } from "react-redux";
import { PrivateLayoutWrapper } from "../layouts/privateLayouts/PrivateLayout";
import NotFound from "../components/NotFound";

export const PublicWrapper = ({children,path}) => {
  const sidebar = useSelector((state) => state?.LoginSlice?.sideNav);
  const userDetails = useSelector(
    (state) => state?.loginAuth?.adleverageUserId
  );

  return userDetails?.permissions?.includes('settings') ? (
    <PrivateLayoutWrapper sidebar={sidebar}>
      <div>
        <div>
          <section className="outlet-section">
            <div className="outlet-section-inner">
              {children}
            </div>
          </section>
        </div>
      </div>
    </PrivateLayoutWrapper>
  ) : (
    <NotFound />
  );
};
