import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";

const HeatMapChart = ({ data }) => {
  const [series, setSeries] = useState([]);

  const options = {
    chart: {
      height: 350,
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 25.99,
              name: "low",
              color: "#00A100", // Green
            },
            {
              from: 26,
              to: 50.99,
              name: "medium",
              color: "#128FD9", //Blue
            },
            {
              from: 51,
              to: 75.99,
              name: "high",
              color: "#FFB200", // yellow
            },
            {
              from: 76,
              to: 100,
              name: "extreme",
              color: "#FF0000", // red
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      categories: [
        "Lead %",
        "Booking %",
        "Closing %",
        "Unbooked %",
        "Excused %",
        "Abandon %",
        "Cancellation %",
        // "Avg Ticket",
      ],
    },
  };

  // Function to generate random data
  function generateData(count, yrange) {
    let data = [];
    for (let i = 0; i < count; i++) {
      data.push({
        x: `W${i + 1}`,
        y:
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
          yrange.min,
      });
    }
    return data;
  }

  const manipulateData = (arr) => {
    let graphData = arr?.map((el) => {
      let name = moment()
        .month(el?.month - 1)
        .format("MMMM");
      let data = [
        el?.lead_calls_perc,
        el?.booking_rate,
        el?.closing_perc,
        el?.unbooked_rate,
        el?.excused_rate,
        el?.abandon_rate,
        el?.cancellation_rate,
        // el?.avg_ticket > 100 ? 100 : el?.avg_ticket,
      ];
      return { name, data };
    });
    setSeries(graphData);
  };

  let legendsArray = [
    {
      name: "low",
      color: "#00A100", // Green
    },
    {
      name: "medium",
      color: "#128FD9", //Blue
    },
    {
      name: "high",
      color: "#FFB200", // yellow
    },
    {
      name: "extreme",
      color: "#FF0000", // red
    },
  ];

  useEffect(() => {
    manipulateData(data);
  }, [data]);

  return (
    <HeatMapChartStyle>
      <div className="sub-header">
        <sub>Correlation Between KIPS</sub>
      </div>
      <div className="chartWrapper">
        <ReactApexChart
          options={options}
          series={series}
          type="heatmap"
          height={450}
        />
        {/* Gradient Legend */}
        {/* <div style={{ marginTop: "20px", textAlign: "center" }}>
        <div style={{ display: "inline-block", width: "80%", height: "20px", background: "linear-gradient(to right, #00A100, #128FD9, #FFB200, #FF0000)" }}></div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5px",
            fontSize: "14px",
            fontWeight: "bold",
            width: "80%",
            margin:"auto",
          }}
        >
          <span>0</span>
          <span>25</span>
          <span>75</span>
          <span>100</span>
        </div>
      </div> */}
        <CustomLegends>
          {legendsArray?.map((el) => (
            <div>
              <span style={{ background: el?.color }} />
              <label>{el?.name}</label>
            </div>
          ))}
        </CustomLegends>
      </div>
    </HeatMapChartStyle>
  );
};

export default HeatMapChart;

const HeatMapChartStyle = styled.div`
  width: 50%;
  height: 100%;
  background: #fff;
  margin: 20px 0;
  border-radius: 5px;
  padding: 20px;

  .sub-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    sub {
      font-size: 20px;
      font-weight: 600;
    }
  }
`;
const CustomLegends = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  span {
    display: block;
    width: 16px;
    height: 16px;
  }
  label {
    text-transform: capitalize;
  }
`;
