import { Drawer, Input, message, Modal, Select, Table } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { updateDynamicSettingTab } from "../../app/Auth/signIn/LogInSlice";
import { BackBtn } from "../../utils/Images/Images";
import { useNavigate } from "react-router-dom";
import {
  addCategories,
  getallbugetsInput,
  getBudgetCategories,
  getBusinessUnitsCategoriesOptions,
  getBusinessUnitsList,
  getBusinessUnitsSubCategoriesOptions,
  getBussinessUnitsListing,
  getCampaignCategoriesList,
  getCampaignList,
  updateBusinessUnits,
  updateCampaignCategories,
} from "../../services/Collections";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { toast } from "react-toastify";
import { Flashlight } from "lucide-react";
import Addbudgetmodal from "./Addbudgetmodal";
import { ModalLoader } from "../../components/Loader";
import { MdModeEdit } from "react-icons/md";
import { formatNumberDecimal } from "./Year/commonFuc";
import { DatePicker } from "antd";
import Header from "../../components/Header";
import TabComponent from "../../components/newComponent/TabComponent";
import {
  EditNewicon,
  TickEditIcon,
  CrossEditIcon,
} from "../../utils/Icons/SvgIcons";
import LoadingBlock from "../../components/LoadingBlock";
import Watermark from "../../components/Watermark";

export const SettingDataModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const activeTab = useSelector((state) => state?.loginAuth?.dynamicSettingTab);
  const [active, setActive] = useState(activeTab);
  const [tableData, setTableData] = useState([]);
  const [campaignTableData, setCampaignTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [campaignFilterTableData, setCampaignFilterTableData] = useState([]);
  const [activeCampaignsTab, setActiveCampaignsTab] = useState("all");
  const clientkeyPrefilled = useSelector(
    (state) => state?.loginAuth?.prefilledFilters?.selectedClient?.key
  );
  const summaryFilter = useSelector(
    (state) => state?.loginAuth?.summarySectionFilter?.selectedClient
  );
  const loginAuth = useSelector((state) => state?.loginAuth);

  let clientkeyId = summaryFilter?.key
    ? summaryFilter?.key
    : clientkeyPrefilled;

  const [selecteCategories, setSelecteCategories] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [campaignCategoriesOptions, setCampaignCategoriesOptions] = useState(
    []
  );
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
  const [addbudget, setAddbudget] = useState(false);
  const [budgetmodal, setbudgetmodal] = useState(false);
  const [dataall_budget, setDataall_budget] = useState([]);
  const [editActive, setEditActive] = useState({});

  const [editingKey, setEditingKey] = useState("");
  const [editedRowData, setEditedRowData] = useState({});

  const isEditing = (record) => record?.id === editingKey;

  const handleEdit = (record) => {
    if (editingKey !== "") {
      handleCancel();
    }
    setEditingKey(record?.id);
    setEditedRowData({ ...record });
  };

  const handleCancel = () => {
    setEditingKey("");
    setEditedRowData({});
  };

  const handleSave = (key) => {
    if (editedRowData?.paid_search_name?.trim()?.length <= 0) {
      message.error(
        "The category name cannot be left empty. Please fill out this field."
      );
      return;
    }
    const newData = [...dataall_budget];
    const index = newData.findIndex((item) => item.id === key);
    let obj = {
      budget: editedRowData?.oldBudget,
      budget_category: editedRowData?.budget_category,
      id: editedRowData?.id,
      label: editedRowData?.label,
      lead_name: editedRowData?.lead_name,
      month: editedRowData?.monthNumber,
      value: editedRowData?.value,
      year: editedRowData?.year,
    };
    if (index > -1) {
      handleEditSubmit(obj);
      newData[index] = obj;
      setDataall_budget(newData);
      setEditingKey("");
      setEditedRowData({});
    }
  };

  const handleInputChange = (e, data) => {
    if (e.target.value == "" || Number(e?.target?.value)) {
      setEditedRowData((prev) => ({
        ...prev,
        budget: e.target.value,
        oldBudget: Number(e.target.value),
      }));
    }
  };

  const handleEditSubmit = async (data) => {
    const addCategoriepayload = [
      {
        user_id: loginAuth?.data?.id,
        year: data?.year,
        month: data?.month,
        lead_name: data?.lead_name || "",
        budget_category: data?.budget_category,
        budget: data?.budget,
      },
    ];

    const res = await addCategories(clientkeyId, addCategoriepayload);
    if (res?.status == 200) {
      //   toast?.success(res?.message || "Budget edited successfully");
    } else {
      toast?.error(res?.message || "Something went wrong");
    }
  };

  const openModal = () => {
    setbudgetmodal(true);
  };

  const closeModal = () => {
    setbudgetmodal(false);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 40,
      }}
      spin
    />
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [budgetFilter, setBudgetFilter] = useState({
    year: "",
    month: "",
  });

  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys?.length <= 0) {
      setSelectedCategory(null);
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const campaignCategoriesSelectedata = campaignCategoriesOptions?.map(
    (ele) => {
      return { ...ele, value: ele?.listofsource, label: ele?.listofsource };
    }
  );

  const handleSelectedCategories = (obj) => {
    setSelecteCategories((prevCategories) => {
      const updatedCategories = prevCategories.map((ele) =>
        ele.id === obj.id ? { ...ele, ...obj } : ele
      );
      const categoryExists = prevCategories.some((ele) => ele.id === obj.id);

      if (!categoryExists) {
        updatedCategories.push(obj);
      }
      return updatedCategories;
    });
  };

  const handleModifiedData = (obj) => {
    setCampaignFilterTableData((pre) =>
      pre?.map((ele) => (ele?.id == obj?.id ? { ...ele, ...obj } : ele))
    );
  };

  const handleSelectedSubCategories = (obj) => {
    setSelecteCategories((prevCategories) => {
      const updatedCategories = prevCategories.map((ele) =>
        ele.id === obj.id ? { ...ele, sub_category: obj.sub_category } : ele
      );
      const subCategoryExists = prevCategories.some((ele) => ele.id === obj.id);

      if (!subCategoryExists) {
        updatedCategories.push(obj);
      }
      return updatedCategories;
    });
  };

  const columns = [
    {
      title: "Business Unit",
      dataIndex: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      render: (record, alldata) => {
        const data = categoriesOptions?.map((ele) => {
          return { ...ele, value: ele?.type, label: ele?.type };
        });
        return (
          <Select
            defaultValue={record}
            size={"large"}
            prefixCls="inner-select2"
            showSearch
            onChange={(_, val) =>
              handleSelectedCategories({
                id: alldata?.id,
                category: val?.type,
              })
            }
            options={data}
            style={{
              width: "100%",
            }}
            maxTagCount={1}
            placeholder="Please select"
            dropdownStyle={{
              maxHeight: "300px",
            }}
          />
        );
      },
    },
    {
      title: "Sub Category",
      dataIndex: "subcategory",
      render: (record, alldata) => {
        const data = subCategoriesOptions?.map((ele) => {
          return { ...ele, value: ele?.subtype, label: ele?.subtype };
        });
        return (
          <Select
            defaultValue={record}
            size={"large"}
            prefixCls="inner-select2"
            showSearch
            onChange={(_, val) =>
              handleSelectedSubCategories({
                id: alldata?.id,
                sub_category: val?.subtype,
              })
            }
            options={data}
            style={{
              width: "100%",
            }}
            maxTagCount={1}
            placeholder="Please select"
            dropdownStyle={{
              maxHeight: "300px",
            }}
          />
        );
      },
    },
  ];

  const budgetColumns = [
    {
      title: "Year",
      dataIndex: "year",
      sorter: (a, b) => a.year - b.year,
    },
    {
      title: "Month",
      dataIndex: "month",
      className: "odd-row",
    },
    {
      title: "Budget Category",
      dataIndex: "budget_category",
    },
    {
      title: "Budget",
      dataIndex: "budget",
      render: (text, data) =>
        isEditing(data) ? (
          <Input
            value={editedRowData.oldBudget}
            onChange={(e) => handleInputChange(e, data)}
          />
        ) : (
          text
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, data) => {
        const editable = isEditing(data);
        return (
          <>
            {editable ? (
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  justifyContent: "center",
                }}
              >
                <i onClick={() => handleSave(data?.id)}>
                  <TickEditIcon />
                </i>
                <i onClick={handleCancel}>
                  <CrossEditIcon />
                </i>
              </div>
            ) : (
              <i
                onClick={() => handleEdit(data)}
                style={{ marginRight: "10px" }}
              >
                <EditNewicon />
              </i>
            )}
          </>
        );
      },
    },
  ];

  const campaignColumns = [
    {
      title: "Campaign Name",
      dataIndex: "name",
      width: "65%",
      align: "left",
    },
    {
      title: "Category",
      dataIndex: "category",
      align: "left",
      render: (record, alldata) => {
        // const data = campaignCategoriesOptions?.map((ele) => {
        //   return { ...ele, value: ele?.listofsource, label: ele?.listofsource };
        // });
        return (
          <>
            {selectedRowKeys?.includes(alldata?.key) ? (
              <Select
                value={selectedCategory || record}
                size={"large"}
                prefixCls="inner-select2"
                showSearch
                onChange={(_, val) => {
                  handleSelectedCategories({
                    id: alldata?.id,
                    category: val?.listofsource,
                  });
                }}
                options={campaignCategoriesSelectedata}
                style={{
                  width: "100%",
                }}
                maxTagCount={1}
                placeholder="Please select"
                dropdownStyle={{
                  maxHeight: "300px",
                }}
              />
            ) : (
              <Select
                defaultValue={record}
                value={record}
                size={"large"}
                prefixCls="inner-select2"
                showSearch
                onChange={(_, val) => {
                  handleSelectedCategories({
                    id: alldata?.id,
                    category: val?.listofsource,
                  });
                  handleModifiedData({
                    id: alldata?.id,
                    category: val?.listofsource,
                  });
                }}
                options={campaignCategoriesSelectedata}
                style={{
                  width: "100%",
                }}
                maxTagCount={1}
                placeholder="Please select"
                dropdownStyle={{
                  maxHeight: "300px",
                }}
              />
            )}
          </>
        );
      },
    },
  ];

  const categoriesType = [
    {
      label: "All Categories",
      value: "all",
    },
    {
      label: "Missing Categories",
      value: "missing",
    },
  ];

  const marketingGuideColoumns = [
    {
      title: "SBA Average Guide",
      dataIndex: "averageGuide",
      width: "70%",
      align: "left",
    },
    {
      title: "Percent Of Gross Revenue",
      dataIndex: "grossRevenue",
      width: "30%",
    },
  ];

  const handleUpdateBudgetCategories = async () => {
    setUpdate(true);
    const req = {
      items: selecteCategories
        .map((selected) => {
          const original = tableData.find((item) => item.id === selected.id);
          if (
            original &&
            (selected.category !== original.category ||
              selected.sub_category !== original.subcategory)
          ) {
            return {
              id: selected.id,
              category: selected.category || original.category,
              subcategory: selected.sub_category || original.subcategory,
            };
          }
          return null;
        })
        .filter((item) => item !== null),
    };

    const res = await updateBusinessUnits(clientkeyId, req?.items);

    if (res?.status === 200) {
      toast.success(res?.message || "Categories Updated Successfully");
      setSelecteCategories([]);
    } else {
      toast.error(res?.message || "Something went wrong");
    }

    setUpdate(false);
  };

  const handleUpdateCampaignCategories = async () => {
    setUpdate(true);

    let slectedCheckData = selectedRowKeys?.map((id) => ({
      id: id,
      category: selectedCategory,
    }));

    let finalData = [...slectedCheckData, ...selecteCategories];

    const req = {
      items: finalData
        .map((selected) => {
          const original = campaignTableData?.find(
            (item) => item.id === selected.id
          );
          if (original && selected.category !== original.category) {
            return {
              id: selected.id,
              category: selected.category || original.category,
            };
          }
          return null;
        })
        .filter((item) => item !== null),
    };
    const res = await updateCampaignCategories(clientkeyId, req?.items);

    if (res?.status == 200) {
      toast.success(res?.message || "Categories Updated Successfully");
      setSelecteCategories([]);
      setSelectedRowKeys([]);
      setSelectedCategory(null);
      handleCampaignsList();
      // handleCampaignsList();
      // handleCampaignCategoriesOptions();
      setUpdate(false);
    } else {
      // toast.error(res?.message || "something went wrong");
    }
    setUpdate(false);
  };

  const handleBussinessUnits = async () => {
    setTableLoading(true);
    const res = await getBusinessUnitsList(clientkeyId);
    if (res?.status === 200) {
      if (res?.data?.length > 0) {
        setTableData(res?.data);
      }
    } else {
      message.error(res?.message || "something went wrong");
    }
    setTableLoading(false);
  };

  const handleCampaignsList = async () => {
    setTableLoading(true);
    const res = await getCampaignList(clientkeyId);
    if (res?.status === 200) {
      if (res?.data?.length > 0) {
        let modifyData = res?.data?.map((ele) => ({ ...ele, key: ele?.id }));
        setCampaignTableData(modifyData);
        setCampaignFilterTableData(modifyData);
      }
      setActiveCampaignsTab("all");
    } else {
      message.error(res?.message || "something went wrong");
    }
    setTableLoading(false);
  };

  const handleCategoriesOptions = async () => {
    let res = await getBusinessUnitsCategoriesOptions(clientkeyId);
    if (res.status === 200) {
      setCategoriesOptions(res?.data);
    }
  };

  const handleCampaignCategoriesOptions = async () => {
    let res = await getCampaignCategoriesList(clientkeyId);
    if (res.status === 200) {
      setCampaignCategoriesOptions(res?.data);
    }
  };

  const handleSubCategoriesOptions = async () => {
    let res = await getBusinessUnitsSubCategoriesOptions(clientkeyId);
    if (res.status === 200) {
      setSubCategoriesOptions(res?.data);
    }
  };

  // Get_all_budget_api_data

  const prefilledFilters = useSelector(
    (state) => state?.loginAuth?.summarySectionFilter
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );

  const activeClient =
    prefilledFilters == null
      ? prefilledData?.selectedClient?.key
      : prefilledFilters?.selectedClient?.key;

  const handleAddBudget = async () => {
    setTableLoading(true);
    let params = new URLSearchParams();
    params.append("client_key", activeClient);
    let res = await getallbugetsInput(activeClient, params);
    if (res?.status === 200) {
      const getdata = res?.data?.map((el, index) => {
        return {
          ...el,
          value: el?.budget_category,
          label: el?.budget_category,
          budget: el?.budget,
          month: el?.month,
          year: el?.year,
          id: index,
        };
      });
      setDataall_budget(getdata);
      setTableLoading(false);
    } else {
      message.error("Something went wrong");
      setTableLoading(false);
    }
  };

  const getMonthName = (month) => {
    return month === 1
      ? "January"
      : month === 2
        ? "February"
        : month === 3
          ? "March"
          : month === 4
            ? "April"
            : month === 5
              ? "May"
              : month === 6
                ? "June"
                : month === 7
                  ? "July"
                  : month === 8
                    ? "August"
                    : month === 9
                      ? "September"
                      : month === 10
                        ? "October"
                        : month === 11
                          ? "November"
                          : month === 12
                            ? "December"
                            : "";
  };

  const months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const handleCategoriesTypes = (val) => {
    if (val !== "all") {
      let type = val == "missing" ? null : val;
      let filterArray = campaignTableData?.filter((el) => el?.category == type);
      setCampaignFilterTableData(filterArray);
    } else {
      setCampaignFilterTableData(campaignTableData);
    }
    setActiveCampaignsTab(val);
  };

  const processedData = useMemo(() => {
    let data = [];

    if (budgetFilter?.year || budgetFilter?.month) {
      if (budgetFilter?.year) {
        data = dataall_budget?.filter((ele) => budgetFilter?.year == ele?.year);
      }
      if (budgetFilter?.month) {
        data = dataall_budget?.filter(
          (ele) => budgetFilter?.month == ele?.month
        );
      }
      if (budgetFilter?.year && budgetFilter?.month) {
        data = dataall_budget?.filter(
          (ele) =>
            budgetFilter?.year == ele?.year && budgetFilter?.month == ele?.month
        );
      }
    } else {
      data = [...dataall_budget];
    }

    return data?.map((item) => ({
      ...item,
      month: getMonthName(item.month),
      monthNumber: item.month,
      budget: `$${formatNumberDecimal(item.budget)}`,
      oldBudget: item.budget,
    }));
  }, [dataall_budget, budgetFilter?.year, budgetFilter?.month]);

  useEffect(() => {
    if (active == "businessUnits") {
      handleBussinessUnits();
      handleCategoriesOptions();
      handleSubCategoriesOptions();
    } else if (active == "campaigns") {
      handleCampaignsList();
      handleCampaignCategoriesOptions();
    } else if (active == "addbudget") {
      handleAddBudget();
    } else {
      setTableData2([
        {
          averageGuide: "Low",
          grossRevenue: "2%",
        },
        {
          averageGuide: "Medium / Low",
          grossRevenue: "4%",
        },
        {
          averageGuide: "Medium 1",
          grossRevenue: "12%",
        },
        {
          averageGuide: "Medium 2",
          grossRevenue: "19%",
        },
        {
          averageGuide: "Medium / Hight",
          grossRevenue: "6%",
        },
        {
          averageGuide: "High",
          grossRevenue: "15%",
        },
        {
          averageGuide: "Growth / Aggressive",
          grossRevenue: "17%",
        },
        {
          averageGuide: "Startup / Aggressive",
          grossRevenue: "16%",
        },
      ]);
    }
  }, [active, activeClient]);

  const tabs = [
    "Business Units",
    "Campaigns",
    "SBA Marketing % Guide",
    "Budget",
  ];

  const handleAction = (val) => {
    if (tabs[val] == "Business Units") {
      setActive("businessUnits");
      dispatch(updateDynamicSettingTab("businessUnits"));
      setSelectedRowKeys([]);
      return;
    }
    if (tabs[val] == "Campaigns") {
      setActive("campaigns");
      dispatch(updateDynamicSettingTab("campaigns"));
      return;
    }
    if (tabs[val] == "SBA Marketing % Guide") {
      setActive("marketingGuide");
      dispatch(updateDynamicSettingTab("marketingGuide"));
      setSelectedRowKeys([]);
      return;
    }
    if (tabs[val] == "Budget") {
      setActive("addbudget");
      dispatch(updateDynamicSettingTab("addbudget"));
      setSelectedRowKeys([]);
      setAddbudget(!addbudget);
      return;
    }
  };

  const getActiveTab = () => {
    let arr = ["businessUnits", "campaigns", "marketingGuide", "addbudget"];
    if (arr?.includes(active)) {
      return arr?.indexOf(active);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    handleAction(active);
  }, []);

  return (
    <>
      <Header
        // clientInfo={activeClient}
        // openFilter={() => setOpenDrawer(true)}
        title="Dynamic Budget Settings"
      />

      <DeleteModalWrapper
        selectedThemeColors={selectedThemeColors}
        active={active == "addbudget" || active == "campaigns"}
      >
        <div className="wrapper_botton">
          <div className="setting-tabs">

            <div className="main-content">
              <TabComponent
                index={getActiveTab}
                tabs={tabs}
                selectedIndex={handleAction}
                disableBtnsOnLoading={tableLoading}
              />
              <div className="all-tabs">
                {/* {!selectedCategory && selecteCategories?.length <= 0 && (
                  <>
                    <button
                      className={
                        active == "businessUnits"
                          ? "active"
                          : tableLoading && "disable"
                      }
                      onClick={() => {
                        if (!tableLoading) {
                          setActive("businessUnits");
                          dispatch(updateDynamicSettingTab("businessUnits"));
                          setSelectedRowKeys([]);
                        }
                      }}
                    >
                      Business Units
                    </button>
                    <button
                      className={
                        active == "campaigns"
                          ? "active"
                          : tableLoading && "disable"
                      }
                      onClick={() => {
                        if (!tableLoading) {
                          setActive("campaigns");
                          dispatch(updateDynamicSettingTab("campaigns"));
                        }
                      }}
                    >
                      Campaigns
                    </button>
                    <button
                      className={
                        active == "marketingGuide"
                          ? "active"
                          : tableLoading && "disable"
                      }
                      onClick={() => {
                        if (!tableLoading) {
                          setActive("marketingGuide");
                          dispatch(updateDynamicSettingTab("marketingGuide"));
                          setSelectedRowKeys([]);
                        }
                      }}
                    >
                      SBA Marketing % Guide
                    </button>
                    <button
                      className={
                        active == "addbudget"
                          ? "active"
                          : tableLoading && "disable"
                      }
                      onClick={() => {
                        if (!tableLoading) {
                          setActive("addbudget");
                          dispatch(updateDynamicSettingTab("addbudget"));
                          setSelectedRowKeys([]);
                        }
                        setAddbudget(!addbudget);
                      }}
                    >
                      Budget
                    </button>
                  </>
                )} */}
                {(selectedCategory || selecteCategories?.length > 0) &&
                  (active == "businessUnits" || active == "campaigns") && (
                    <>
                      <button
                        style={{ backgroundColor: "#22a122" }}
                        onClick={
                          active == "businessUnits"
                            ? handleUpdateBudgetCategories
                            : handleUpdateCampaignCategories
                        }
                      >
                        {update ? (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                className="spinner"
                                style={{
                                  fontSize: 22,
                                  color: "white",
                                }}
                                spin
                              />
                            }
                          />
                        ) : (
                          "Update"
                        )}
                      </button>
                      <button
                        style={{ backgroundColor: "#252525" }}
                        onClick={() => {
                          setSelecteCategories([]);
                          setSelectedRowKeys([]);
                          setSelectedCategory(null);
                          handleCampaignsList();
                          handleBussinessUnits();
                        }}
                      >
                        {tableLoading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                className="spinner"
                                style={{
                                  fontSize: 22,
                                  color: "white",
                                }}
                                spin
                              />
                            }
                          />
                        ) : (
                          "Cancel"
                        )}
                      </button>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>

        {tableLoading ? (
          <LoadingBlock height={"calc(100vh - 220px)"} size={28} />
        ) : (
          <div className="table-data">
            {active == "businessUnits" ? (
              <Table
                prefixCls="custom-ui-table"
                columns={columns}
                dataSource={tableData}
                pagination={false}
                scroll={{ x: "100%", y: "calc(100vh - 298px)" }}
              />
            ) : active == "campaigns" ? (
              <TableFilterWrapper>
                {!tableLoading && active == "campaigns" && (
                  <div className="selectWrapperFor">
                    <Select
                      size={"large"}
                      prefixCls="inner-select2"
                      showSearch
                      value={activeCampaignsTab}
                      onChange={(_, val) => handleCategoriesTypes(val?.value)}
                      options={[
                        ...categoriesType,
                        ...campaignCategoriesSelectedata,
                      ]}
                      style={{
                        width: "100%",
                        background: "white",
                      }}
                      maxTagCount={1}
                      placeholder="Please select categories"
                      dropdownStyle={{
                        maxHeight: "300px",
                      }}
                    />

                    {selectedRowKeys?.length > 1 && (
                      <Select
                        size={"large"}
                        prefixCls="inner-select2"
                        showSearch
                        onChange={(_, val) =>
                          setSelectedCategory(val?.listofsource)
                        }
                        options={campaignCategoriesSelectedata}
                        style={{
                          width: "100%",
                          background: "white",
                        }}
                        maxTagCount={1}
                        placeholder="Please select"
                        dropdownStyle={{
                          maxHeight: "300px",
                        }}
                      />
                    )}
                  </div>
                )}
                <Table
                  rowSelection={rowSelection}
                  prefixCls="custom-ui-table"
                  columns={campaignColumns}
                  dataSource={campaignFilterTableData}
                />
              </TableFilterWrapper>
            ) : active == "addbudget" ? (
              <TableFilterWrapper>
                {active == "addbudget" && (
                  <div className="filterrow">
                    <div>
                      <Select
                        size={"large"}
                        className="monthSelect"
                        prefixCls="inner-select2"
                        allowClear
                        onChange={(d) =>
                          setBudgetFilter((pre) => ({ ...pre, month: d }))
                        }
                        options={months}
                        style={{
                          width: "200px",
                          background: "white",
                        }}
                        maxTagCount={1}
                        placeholder="Select month"
                        dropdownStyle={{
                          maxHeight: "300px",
                        }}
                      />
                      <DatePicker
                        onChange={(_, d) =>
                          setBudgetFilter((pre) => ({ ...pre, year: d }))
                        }
                        picker="year"
                        style={{ width: "200px" }}
                      />
                    </div>

                    <div>
                      <button
                        className="add_budget_button"
                        onClick={() => {
                          !tableLoading && dataall_budget && openModal();
                        }}
                        style={{
                          cursor: !tableLoading ? "pointer" : "not-allowed",
                        }}
                      >
                        Add Budget
                      </button>
                    </div>
                  </div>
                )}
                <Table
                  prefixCls="custom-ui-table"
                  columns={budgetColumns}
                  dataSource={processedData}
                  pagination={{
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                  }}
                />
              </TableFilterWrapper>
            ) : (
              <Table
                prefixCls="custom-ui-table"
                columns={marketingGuideColoumns}
                dataSource={tableData2}
                pagination={false}
                scroll={{ x: "100%", y: "calc(100vh - 298px)" }}
              />
            )}
            {active == "campaigns" || active == "addbudget" ? <Watermark
              style={{ position: "relative", bottom: 0, width: "fit-content", justifyContent: "start" }}
              background={"transparent"}
            /> :
              <Watermark
                style={{ position: "relative", bottom: 0 }}
                background={"transparent"}
              />
            }
          </div>
        )}

        <Modal
          closable={false}
          visible={budgetmodal}
          onCancel={closeModal}
          key="bottom"
          footer={null}
          prefixCls="addBudgetModal"
          destroyOnClose={true}
        >
          <Addbudgetmodal
            tableLoading={tableLoading}
            setTableLoading={setTableLoading}
            dataall_budget={dataall_budget}
            closeModal={closeModal}
            handleAddBudget={handleAddBudget}
            setbudgetmodal={setbudgetmodal}
          />
        </Modal>
      </DeleteModalWrapper>
    </>
  );
};

const DeleteModalWrapper = styled.div`
  width: 100%;
  padding: 20px 20px 0px 20px;
  height: calc(100vh - 58px);
  overflow: auto;

  .wrapper_botton {
    position: relative;
  }

  .spinner {
    svg {
      fill: white;
    }
  }

  .selectWrapperFor {
    width: 50%;
    margin-bottom: 10px;
    margin-left: 16px;
    display: grid;
    grid-template-columns: 300px 300px;
    gap: 20px;
  }

  .disable {
    cursor: not-allowed;
    background: rgb(94 93 93) !important;
  }

  .setting-tabs {
    margin-bottom: ${({ active }) => (active ? "20px" : "16px")};
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: center; */
    width: 100%;
    gap: 24px;

    p {
        width: fit-content;
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;

      img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
    .main-content {
      width: max-content;
      display: flex;
      gap: 10px;
      width: 100%;
      justify-content: space-between;
      .all-tabs {
        button {
          background: #242424;
          width: fit-content;
          text-align: center;
          padding: 5px 20px;
          color: white;
          border: none;
          border-left: 2px solid white;
          height: 33px;
          font-size: 13px;
          font-family: Poppins;
          font-style: normal;

          font-style: normal;

          :first-child {
            border: none;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
          }
          :last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }
        }

        .active {
          background: rgb(203 203 203 / 67%);
          color: #000;
        }
      }
      .filter-btn {
        button {
          background: rgb(36, 36, 36);
          color: rgb(255, 255, 255);
          border-style: none;
          padding: 10px 15px;
          border-radius: 10px;
          cursor: pointer;
          width: 100px;
          font-family: Poppins;
        }
      }
    }
  }

  .table-data {
    min-height: calc(100vh - 186px);
    max-height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .campaignTable {
      .campaignTable-thead {
        .campaignTable-cell {
          background: ${({ selectedThemeColors }) =>
    selectedThemeColors?.headerColor} !important;
        }
      }
      .campaignTable-tbody {
        .campaignTable-cell {
          background: ${({ selectedThemeColors }) =>
    selectedThemeColors?.CardsColor};
          color: ${({ selectedThemeColors }) =>
    selectedThemeColors?.textPrimaryColor};
          border-bottom: 1px solid rgba(232, 232, 237, 1) !important;
        }
      }
      .campaignTable-container {
        height: calc(100vh - 220px) !important;
        overflow: auto !important;
      }
      .campaignTable-thead {
        position: sticky !important;
        top: 0 !important;
        z-index: 99 !important;
      }
    }
    .ant-select-selection-item {
      color: black !important;
    }
  }
  .add_budget_button {
    background: rgb(36, 36, 36);
    width: fit-content;
    text-align: center;
    padding: 5px 20px;
    color: white;
    /* border-top: none;
    border-right: none;
    border-bottom: none; */
    border: transparent;
    border-image: initial;
    height: 33px;
    font-size: 13px;
    font-family: Poppins;
    font-style: normal;
    border-radius: 10px;
    /* position: absolute; */
    /* right: 0; */
    /* top: 47px; */
  }

  .filterrow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 16px;

    div {
      display: flex;
      gap: 10px;
    }
  }
  .monthSelect {
    .ant-select-single.ant-select-lg {
      width: 150px !important;
    }
    .ant-select-selector {
      width: 100% !important;
      padding: 0 11px !important;
    }
    .ant-select-selection-placeholder {
      font-size: 16px !important;
      font-weight: 400 !important;
      color: rgb(0 0 0 / 25%) !important;
    }
    .ant-select-selection-item {
      color: black !important;
    }
  }
`;

const CampaignTabWrapper = styled.div`
  .all-tabs {
    width: fit-content;
    border: 1px solid #d2d4de;
    border-radius: 6px;
    padding: 2px;
    display: flex;
    gap: 5px;
    background: white;
    margin-bottom: 10px;

    button {
      background: #f6f9fc;
      width: fit-content;
      text-align: center;
      padding: 5px 20px;
      color: #000;
      border: none;
      border-left: 2px solid white;
      height: 33px;
      font-size: 13px;
      font-family: Poppins;
      font-style: normal;
      border-radius: 6px;
      // transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease;

      &:hover {
        transform: scale(1.05);
      }
    }

    .active {
      background: #242424;
      color: white;
      transition: background 0.3s ease, color 0.3s ease;
    }
  }
`;

const TableFilterWrapper = styled.div`
  background: white;
  border-radius: 12px;
  padding-top: 14px;
`;
