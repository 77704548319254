import React, { useEffect, useRef, useState } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";
import styled from "styled-components";
import useWindowWidth from "../customHook/GetWindowWidth";

const ComposedGraphComponent = ({
  data,
  graphListing,
  yAxis_left_label,
  yAxis_right_label,
  label_prefix,
}) => {
  const [width] = useWindowWidth();
  const hasPainted = useRef(false);
  const [opacity, setOpacity] = useState({});
  const [activeKeys, setActiveKeys] = useState([]);

  const getKeyListing = () => {
    const opacityList = {};
    const keyList = graphListing
      ?.filter((obj) => (opacityList[obj?.name] = 1 && obj?.disable !== true))
      ?.map((list) => {
        // opacityList[list?.name] = 1;
        return list.name;
      });
    setActiveKeys(keyList);
    setOpacity(opacityList);
  };

  useEffect(() => {
    getKeyListing();
  }, [data]);

  const handleMouseEnter = (datalist) => {
    const { dataKey, inactive } = datalist;
    if (hasPainted.current === dataKey) return;
    hasPainted.current = dataKey;
    if (!inactive) {
      let final = Object.entries(opacity).reduce((op, [key]) => {
        if (key === dataKey) op[key] = 1;
        else op[key] = 0.1;
        return op;
      }, {});
      setOpacity(final);
    }
  };

  const handleMouseLeave = () => {
    if (!hasPainted.current) return;
    hasPainted.current = false;
    let final = Object.entries(opacity).reduce((op, [key]) => {
      op[key] = 1;
      return op;
    }, {});
    setOpacity(final);
  };

  const handleLegendClick = (e) => {
    const key = e.dataKey;
    setActiveKeys((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
    );
  };

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <LegendWrapper
      // onMouseEnter={() => handleMouseLeave()}
      // onMouseLeave={() => handleMouseLeave()}
      // onMouseOver={(e) => {
      //   handleMouseLeave();
      // }}
      >
        {payload.map((entry, index) => (
          <button
            key={`item-${index}`}
            className="flex-wraps"
            onMouseEnter={(e) => {
              if (e) {
                e.preventDefault();
                e.stopPropagation();
              }
              // handleMouseEnter(entry);
            }}
            onMouseLeave={() => handleMouseLeave()}
            // onMouseOver={(e) => {
            //   e.preventDefault();
            //   e.stopPropagation();
            //   handleMouseEnter(entry);
            // }}
            onClick={() => handleLegendClick(entry)}
            // onMouseLeave={() => handleMouseLeave(entry)}
            style={{
              maxWidth: "max-content",
              margin: "10px 0px",
              height: "fit-content",
            }}
          >
            <div className="circle-wrapper">
              <Circle fill={entry?.color} size="15px" />
            </div>
            <Content className="content" inactive={entry?.inactive}>
              {entry.value}
            </Content>
          </button>
        ))}
      </LegendWrapper>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipWrapper className="custom-tooltip">
          <div className="label">{label}</div>
          {payload?.map((list, index) => (
            <div className="flex-wraps" key={index}>
              <Circle fill={list.color} size="8px" />
              <div>{list?.dataKey}</div>
              <div>{valueFormatter(list?.value)}</div>
            </div>
          ))}
        </TooltipWrapper>
      );
    }
    return null;
  };
  const valueFormatter = (num, digits) => {
    digits = digits ?? 2;
    if (num > 999) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
          return num >= item.value;
        });
      return item
        ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
        : "0";
    } else {
      return Math.floor(num);
    }
  };
  const tickFormatter = (value, prefix = "") => {
    return `${prefix}${valueFormatter(value, 3)}`;
  };

  console.log(data, "datadata")

  return (
    <GraphWrapper className="graph-section">
      <ResponsiveContainer width="100%" height={500}>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 10,
            bottom: 20,
            left: 10,
          }}
          style={{
            // width:"calc(100% - 40px)",
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            padding: "4px 0",
          }}
        >
          <CartesianGrid
            stroke="rgba(232, 232, 237, 1)"
            strokeDasharray="5 5"
          />
          <XAxis dataKey="name" />
          <YAxis
            tickCount={7}
            yAxisId="left"
            orientation="left"
            axisLine={false}
            tickFormatter={(e) => tickFormatter(e, label_prefix)}
          >
            <Label
              style={{ textAnchor: "middle" }}
              value={yAxis_left_label ?? "left label"}
              position="left"
              angle={-90}
            />
          </YAxis>

          <YAxis
            tickCount={7}
            yAxisId="right"
            orientation="right"
            axisLine={false}
            tickFormatter={(e) => tickFormatter(e, label_prefix)}
          >
            <Label
              style={{ textAnchor: "middle" }}
              value={yAxis_right_label ?? "Right Label"}
              position="right"
              angle={90}
            />
          </YAxis>

          <Tooltip content={<CustomTooltip />} />

          <Legend content={renderLegend} />
          {graphListing?.map((list, index) =>
            list?.type === "line" ? (
              <Line
                key={index}
                type="monotone"
                dataKey={list?.name}
                stroke={activeKeys.includes(list.name) ? list?.color : "grey"}
                strokeOpacity={opacity[list.name]}
                yAxisId={list?.yAxis ?? "left"}
                hide={!activeKeys.includes(list.name)}
                activeDot={{ r: 8 }}
              />
            ) : (
              <Bar
                key={index}
                dataKey={list.name}
                barSize={data?.length > 15 ? 6 : 14}
                // barSize={width > 1440 ? (activeKeys && activeKeys?.length) > 4 ? 2 : 6 : (activeKeys && activeKeys?.length) > 4 ? 8 : 20}
                fill={activeKeys.includes(list.name) ? list?.color : "grey"}
                opacity={opacity[list.name]}
                // yAxisId={list?.yAxis ?? "left"}
                yAxisId={"left"}
                hide={!activeKeys.includes(list.name)}
              // minPointSize={4}
              />
            )
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </GraphWrapper>
  );
};

export default ComposedGraphComponent;

const GraphWrapper = styled.div`
  height: 500px;
  width: 100%;
  .recharts-legend-wrapper {
    height: auto !important;
  }
  @media (max-width: 570px) {
    height: 90vh;
  }
  @media (max-width: 430px) {
    height: 100vh;
  }
`;

const LegendWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-transform: Capitalize;
  padding: 15px 40px;
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 15px;
  }
  @media (max-width: 430px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .flex-wraps {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    color: white;
    background: white;
    border: none;
  }
  .circle-wrapper {
    margin-top: 5px;
  }
`;
const Content = styled.div`
  // max-width: 80%;
  color: ${({ inactive }) => (inactive ? "grey" : "#000")};
  text-decoration: ${({ inactive }) => (inactive ? "line-through" : "none")};
`;

const Circle = styled.div`
  background: ${({ fill }) => fill};
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  border-radius: 10px;
`;

const TooltipWrapper = styled.div`
  background: rgba(243, 243, 243, 1);
  border-radius: 3px;
  min-width: 150px;
  text-transform: Capitalize;
  padding: 8px;
  cursor: pointer;
  .label {
    font-weight: 800;
  }
  .flex-wraps {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;
