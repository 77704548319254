import { Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ConfigProvider, DatePicker, Switch, TimePicker } from "antd";
import dayjs from "dayjs";

import InputField from "../../../components/company/InputField";
import ErrorMessage from "../../../components/company/ErrorMessage";
import Header from "../../../components/Header";
import { connectCrm, updateCrmCompany } from "../../../services/Collections";
import { toast } from "react-toastify";

function ConnectCrm() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();

  const editDetails = () => {
    if (location?.pathname?.includes("edit-crm")) {
      return location?.state;
    }
    if (location?.state?.isDuplicate) {
      return location?.state;
    }
    return null;
  };

  const initialValues = {
    business_unit_id: editDetails()?.business_unit_id ?? "",
    job_type_id: editDetails()?.job_type_id ?? "",
    tenant_id: editDetails()?.tenant_id ?? "",
    app_key: editDetails()?.app_key ?? "",
    booking_provider: editDetails()?.booking_provider ?? "",
    integration_type: editDetails()?.integration_type ?? "",
    campaign_id: editDetails()?.campaign_id ?? "",
    priority: editDetails()?.priority ?? "",
    technician_id: editDetails()?.technician_id ?? "",
    tag_id: editDetails()?.tag_id ?? "",
    business_unit_name: editDetails()?.business_unit_name ?? "",
    job_type_name: editDetails()?.job_type_name ?? "",
    campaign_name: editDetails()?.campaign_name ?? "",
    technician_name: editDetails()?.technician_name ?? "",
    tag_name: editDetails()?.tag_name ?? "",
    book_or_job: editDetails()?.book_or_job ?? "book",
    max_jobs: editDetails()?.max_jobs ?? "",
    auto_capacity: !!editDetails()?.auto_capacity,
    city: editDetails()?.city ?? "",
    state: editDetails()?.state ?? "",
    country: editDetails()?.country ?? "",
    assign_tech: editDetails()?.assign_tech ?? "",
    arrival_window_settings: editDetails()?.arrival_window_settings
      ? JSON.stringify(editDetails()?.arrival_window_settings)
      : editDetails()?.arrival_window_settings,
    arrival_windows:
      editDetails()?.arrival_windows?.length > 0
        ? JSON.stringify(editDetails()?.arrival_windows)
        : "",
    first_appt_time: editDetails()?.first_appt_time ?? null,
    first_appt_date: editDetails()?.first_appt_date ?? null,
  };

  const FormikFieldValues = [
    {
      name: "business_unit_name",
      label: "Business Unit Name",
      type: "text",
      placeholder: "Enter business unit name",
      component: InputField,
    },
    {
      name: "business_unit_id",
      label: "Business Unit ID",
      type: "text",
      placeholder: "Enter business unit id",
      component: InputField,
    },
    {
      name: "job_type_name",
      label: "Job Type Name",
      type: "text",
      placeholder: "Enter job type name",
      component: InputField,
    },
    {
      name: "job_type_id",
      label: "Job Type ID",
      type: "text",
      placeholder: "Enter job type id",
      component: InputField,
    },
    {
      name: "tenant_id",
      label: "Tenant ID",
      type: "text",
      placeholder: "Enter tenant id",
      component: InputField,
    },
    {
      name: "app_key",
      label: "App Key",
      type: "text",
      placeholder: "Enter App Key",
      component: InputField,
    },
    {
      name: "booking_provider",
      label: "Booking Provider",
      type: "text",
      placeholder: "Enter booking provider",
      component: InputField,
    },
    {
      name: "integration_type",
      label: "Integration Type",
      type: "text",
      placeholder: "Enter integration type",
      component: InputField,
    },
    {
      name: "campaign_name",
      label: "Campaign Name",
      type: "text",
      placeholder: "Enter campaign name",
      component: InputField,
    },
    {
      name: "campaign_id",
      label: "Campaign ID",
      type: "text",
      placeholder: "Enter campaign id",
      component: InputField,
    },
    {
      name: "priority",
      label: "Priority",
      type: "text",
      placeholder: "Enter priority",
      component: InputField,
    },
    {
      name: "technician_name",
      label: "Technician Name",
      type: "text",
      placeholder: "Enter technician name",
      component: InputField,
    },
    {
      name: "technician_id",
      label: "Technician ID",
      type: "text",
      placeholder: "Enter technician id",
      component: InputField,
    },
    {
      name: "tag_name",
      label: "Tag Name",
      type: "text",
      placeholder: "Enter tag name",
      component: InputField,
    },
    {
      name: "tag_id",
      label: "Tag ID",
      type: "text",
      placeholder: "Enter tag id",
      component: InputField,
    },
    {
      name: "max_jobs",
      label: "Max Jobs",
      type: "number",
      placeholder: "Enter max jobs",
      component: InputField,
    },
    {
      name: "assign_tech",
      label: "Assign Tech",
      type: "text",
      placeholder: "Assign Tech",
      component: InputField,
      notRequired: true,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Enter City",
      component: InputField,
      notRequired: true,
    },
    {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "Enter State",
      component: InputField,
      notRequired: true,
    },
    {
      name: "country",
      label: "Country",
      type: "text",
      placeholder: "Enter Country",
      component: InputField,
      notRequired: true,
    },
  ];

  const validationSchema = yup.object().shape({
    business_unit_id: yup.string().required("Business Unit ID is required"),
    job_type_id: yup.string().required("Job Type ID is required"),
    tenant_id: yup.string().required("Tenant ID is required"),
    app_key: yup.string().required("App Key is required"),
    booking_provider: yup.string().required("Booking Provider is required"),
    integration_type: yup.string().required("Integration Type is required"),
    campaign_id: yup.string().required("Campaign ID is required"),
    priority: yup.string().required("Priority is required"),
    technician_id: yup.string().required("Technician ID is required"),
    tag_id: yup.string().required("Tag ID is required"),
    business_unit_name: yup.string().required("Business Unit Name is required"),
    job_type_name: yup.string().required("Job Type Name  is required"),
    campaign_name: yup.string().required("Campaign Name  is required"),
    technician_name: yup.string().required("Technician Name is required"),
    tag_name: yup.string().required("Tag Name is required"),
    max_jobs: yup.string().required("Max jobs is required"),
    arrival_windows: yup
      .string()
      .nullable()
      .notRequired()
      .test(
        "arrival_windows",
        "Array field must be a valid JSON array",
        (value) => {
          if (!value) return true;
          try {
            const parsedValue = JSON.parse(value);
            return Array.isArray(parsedValue);
          } catch (e) {
            return false;
          }
        }
      ),
    // .optional("Array field is required"),

    arrival_window_settings: yup
      .string()
      .nullable()
      .notRequired()
      .test(
        "arrival_window_settings",
        "Object field must be a valid JSON object",
        (value) => {
          if (!value) return true;
          try {
            const parsedValue = JSON.parse(value);
            return (
              typeof parsedValue === "object" && !Array.isArray(parsedValue)
            );
          } catch (e) {
            return false;
          }
        }
      ),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    const requestPayload = {
      ...values,
      company_id: location?.state?.isDuplicate
        ? location?.state?.company_id
        : location?.state?.id,
      arrival_windows: values?.arrival_windows
        ? JSON.parse(values?.arrival_windows)
        : [],
      arrival_window_settings: values?.arrival_window_settings
        ? JSON.parse(values?.arrival_window_settings)
        : null,
    };
    const res = await connectCrm(requestPayload);
    if (res?.status === 200) {
      toast.success("CRM Connected Successfully");
      setLoading(false);
      navigate(-1);
    } else {
      const message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      toast.error(message);
      setLoading(false);
    }
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    const requestPayload = {
      ...values,
      company_id: location?.state?.company_id,
      arrival_windows: values?.arrival_windows
        ? JSON.parse(values?.arrival_windows)
        : [],
      arrival_window_settings: values?.arrival_window_settings
        ? JSON.parse(values?.arrival_window_settings)
        : null,
    };
    const res = await updateCrmCompany(location?.state?.id, requestPayload);
    if (res?.status === 200) {
      toast.success("CRM Updated Successfully");
      setLoading(false);
      navigate(-1);
    } else {
      const message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      toast.error(message);
      setLoading(false);
    }
  };

  return (
    <>
      <Header
        title="Connect CRM"
        clientInfo={{ name: location?.state?.name || location?.state?.companyData?.name }}
      />
      <Container location={location?.pathname?.includes("private-layout")}>
        {/* <div className="title">
          <h2>
            {" "}
            {!loc?.pathname?.includes("edit-crm")
              ? `Connect CRM ${location?.state?.name ? "for" : ""} ${
                  location?.state?.name ? location?.state?.name : ""
                }`
              : "Edit CRM"}{" "}
          </h2>
        </div> */}
        <div className="box">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={
              location?.pathname?.includes("edit-crm")
                ? handleUpdate
                : handleSubmit
            }
            render={({ setFieldValue, values, errors, touched }) => (
              <Form>
                <div className="content">
                  {FormikFieldValues?.map((field, idx) => (
                    <div key={idx} className="fields">
                      <label>
                        {field?.label}
                        {!field?.notRequired && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "15px",
                              marginLeft: "2px",
                            }}
                          >
                            *
                          </span>
                        )}
                      </label>
                      <Field
                        className="input"
                        name={field?.name}
                        type={field?.type}
                        placeholder={field?.placeholder}
                        component={field?.component}
                      />
                    </div>
                  ))}

                  <div className="fields">
                    <label>
                      First Appt Date{" "}
                      {/* <span style={{ color: "red", fontSize: "15px" }}>*</span> */}
                    </label>
                    <DatePicker
                      separator={<span>--</span>}
                      defaultValue={
                        editDetails()?.first_appt_date &&
                        dayjs(editDetails()?.first_appt_date)
                      }
                      prefixCls="date-picker"
                      className="time-picker"
                      onChange={(_, date) =>
                        setFieldValue("first_appt_date", date)
                      }
                    />
                    {errors?.first_appt_date && touched?.first_appt_date && (
                      <ErrorMessage message={errors?.first_appt_date} />
                    )}
                  </div>
                  <div className="fields">
                    <label>
                      First Appt Time{" "}
                      {/* <span style={{ color: "red", fontSize: "15px" }}>*</span> */}
                    </label>
                    <TimePicker
                      separator={<span>--</span>}
                      defaultValue={
                        editDetails()?.first_appt_time &&
                        dayjs(`2022-09-12 ${editDetails()?.first_appt_time}`)
                      }
                      needConfirm={false}
                      format={"HH:mm"}
                      prefixCls="date-picker"
                      className="time-picker"
                      onChange={(_, date) =>
                        setFieldValue("first_appt_time", date)
                      }
                    />
                    {errors?.first_appt_time && touched?.first_appt_time && (
                      <ErrorMessage message={errors?.first_appt_time} />
                    )}
                  </div>

                  <div className="fields">
                    <label>
                      Book or Job{" "}
                      <span style={{ color: "red", fontSize: "15px" }}>*</span>
                    </label>
                    <div className="radio-section">
                      <label
                        style={{ width: "100%", height: "100%" }}
                        htmlFor="book"
                      >
                        <div className="radio-button">
                          <input
                            type="radio"
                            id="book"
                            name="book_or_job"
                            value="book"
                            checked={values?.book_or_job === "book"}
                            onChange={(e) =>
                              setFieldValue("book_or_job", e?.target?.value)
                            }
                          />
                          <label htmlFor="book">Book</label>
                        </div>
                      </label>
                      <label
                        style={{ width: "100%", height: "100%" }}
                        htmlFor="job"
                      >
                        <div className="radio-button">
                          <input
                            type="radio"
                            id="job"
                            name="book_or_job"
                            checked={values?.book_or_job === "job"}
                            value="job"
                            onChange={(e) =>
                              setFieldValue("book_or_job", e?.target?.value)
                            }
                          />
                          <label htmlFor="job">Job</label>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="fields">
                    <label>
                      Auto Capacity{" "}
                      <span style={{ color: "red", fontSize: "15px" }} />{" "}
                    </label>
                    <div
                      style={{
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <ConfigProvider
                        theme={{
                          components: {
                            Switch: {
                              colorPrimary: "#296eff",
                              handleBg: "#fff",
                              colorPrimaryHover: "#296eff",
                              colorTextQuaternary: "#2c2c2c",
                              colorTextTertiary: "#2c2c2c",
                            },
                          },
                        }}
                      >
                        <Switch
                          prefixCls="custom-switch"
                          value={values?.auto_capacity}
                          onChange={(e) => setFieldValue("auto_capacity", e)}
                        />
                      </ConfigProvider>
                    </div>
                  </div>
                  <div className="fields">
                    <label>Arrival Windows </label>
                    {/* <RangePicker
                    showTime
                    use12Hours
                    format="hh:mma"
                    prefixCls="date-picker"
                    className="time-picker"
                    defaultValue={
                      values?.arrival_windows?.length > 0 && [
                        dayjs(values?.arrival_windows?.[0], "hh:mma"),
                        dayjs(values?.arrival_windows?.[1], "hh:mma"),
                      ]
                    }
                    onChange={(_, date) => {
                      setFieldValue("arrival_windows", date);
                    }}
                  /> */}

                    <Field
                      className="textarea"
                      as="textarea"
                      name="arrival_windows"
                      placeholder="Enter Arrival Windows"
                    />

                    {errors?.arrival_windows && touched?.arrival_windows && (
                      <ErrorMessage message={errors?.arrival_windows} />
                    )}
                  </div>

                  <div className="fields">
                    <label>
                      Arrival Windows Settings{" "}
                      {/* <span style={{ color: "red", fontSize: "15px" }}>*</span> */}
                    </label>
                    <Field
                      className="textarea"
                      as="textarea"
                      name="arrival_window_settings"
                      placeholder="Enter Arrival Windows Settings"
                    />
                    {errors?.arrival_window_settings &&
                      touched?.arrival_window_settings && (
                        <ErrorMessage
                          message={errors?.arrival_window_settings}
                        />
                      )}
                  </div>
                  {/* <div className="fields">
                  <label>Arrival Windows Settings </label>
                  <SelectWeekDayTime
                    weekDayTimes={weekDayTimes}
                    setWeekDayTimes={setWeekDayTimes}
                    selectedDays={selectedDays}
                    setSelectedDays={setSelectedDays}
                  />
                </div> */}
                </div>
                <div className="btn-wrapper">
                  <span onClick={() => navigate(-1)}>BACK</span>
                  {loading ? (
                    <button className="btn-save">Loading...</button>
                  ) : (
                    <button className="btn-save" type="submit">
                      {location?.pathname?.includes("edit-crm")
                        ? "UPDATE"
                        : "CONNECT"}
                    </button>
                  )}
                </div>
              </Form>
            )}
          />
        </div>
      </Container>
    </>
  );
}

export default ConnectCrm;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px !important;
  /* margin-top: ${({ location }) => (location ? 0 : "56px !important")}; */

  .radio-section {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;

    .radio-button {
      border: 1px solid #cecece;
      padding: 10px 5px;
      height: 50px;
      border-radius: 7px;
      display: flex;
      ${"" /* justify-content: space-around; */}
      align-items: center;
      gap: 20px;
      padding-left: 20px;
      width: 100%;
      cursor: pointer;

      input {
        width: 14px;
        height: 20px;
        ${"" /* accent-color: #f98229; */}
        cursor: pointer;
      }
      label {
        cursor: pointer;
      }
    }
  }

  .title {
    color: #000;
    font-family: "Poppins";
  }

  .box {
    border: 1px solid #cecece;
    min-height: 200px;
    border-radius: 7px;
    margin-top: 10px;
    padding-bottom: 15px;
    background: #fff;

    .content {
      width: 100%;
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 14px;
      padding: 0 15px;

      @media (max-width: 1025px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
      }

      .fields {
        display: flex;
        flex-direction: column;
        gap: 6px;
        position: relative;

        .img-icon {
          position: absolute;
          right: 20px;
          top: 45px;
          cursor: pointer;
          ${
            "" /* svg {
            filter: ${({ themeValue }) =>
              themeValue == "day" ? "invert(0)" : "invert(1)"};
          } */
          }
        }

        label {
          color: #2c2c2cbd;
          font-family: "Poppins";
          font-size: 13px;
        }
        .input {
          background: transparent;
          border: 1px solid #cecece;
          border-radius: 10px;
          padding: 16px !important;
          color: #000;
          &:focus {
            outline: none;
          }
        }

        .textarea {
          background: transparent;
          border: 1px solid #cecece;
          border-radius: 10px;
          color: #000;
          resize: none;
          padding: 10px;
          font-family: "Poppins" !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: #535e6d !important;
            font-family: "Poppins" !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
        }

        .time-picker {
          ${"" /* color: #535e6d; */}
          font-family: "Poppins" !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          height: 50px;
          width: 100%;
          padding: 0px 0px;
          border-radius: 10px;
          border: 1px solid #cecece !important;
          display: flex !important;
          justify-content: space-around !important;
          ${"" /* background-color: #00050b; */}
        }
      }
    }

    .btn-wrapper {
      width: 100%;
      display: flex;
      gap: 10px;
      margin-top: 26px;
      justify-content: end;
      padding: 0 15px;

      @media (max-width: 480px) {
        flex-direction: column-reverse;
        gap: 10px;
      }

      button,
      span {
        ${"" /* width: 100%; */}
        display: flex;
        height: 48px;
        padding: 21px 24px;
        justify-content: center;
        align-items: center;
        background: transparent;
        gap: 8px;
        border-radius: 10px;
        border: 1px solid #000;
        color: #000;
        text-align: center;
        cursor: pointer;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: 0.2px;
      }

      .btn-save {
        background: red;
        color: #fff;
        border: none;
      }
    }
  }
`;
