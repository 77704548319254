import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterClientsBySection } from "../../utils/common/commonFunction";
import { FilterWrapper } from "../NotificationCenter/NotificationFilter";
import { updateBudgetTrckerClient } from "./BudgetTrckerSlice";
import LoadingBlock from "../../components/LoadingBlock";

const TrackerFilter = ({ loader }) => {
  const { loginAuth, budgetTrcker } = useSelector((state) => state);
  const clientOptionsData = loginAuth?.clientsList;
  const selectedThemeColors = loginAuth?.themeColors;
  const activeClient = budgetTrcker?.selectedClient
    ? budgetTrcker?.selectedClient
    : loginAuth?.prefilledFilters?.selectedClient;

  const [clientOptions, setclientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(activeClient || null);
  const dispatch = useDispatch();

  const getClientOption = () => {
    let arr = [];
    filterClientsBySection(clientOptionsData?.clients, "budget-tracker")
      ?.length > 0 &&
      filterClientsBySection(clientOptionsData?.clients, "budget-tracker")?.map(
        (ele) => {
          let obj = {
            ...ele,
            label: ele?.name,
            value: ele?.name,
          };
          arr.push(obj);
        }
      );
    setclientOptions(arr);
  };

  const handleSubmit = () => {
    dispatch(updateBudgetTrckerClient(selectedClient));
  };

  useEffect(() => {
    getClientOption();
  }, []);

  return (
    <FilterWrapper selectedThemeColors={selectedThemeColors}>
      <div className="inner-part">
        <div className="content-box">
          <div className="filters">
            <h4>Filter</h4>
            <div>
              <label>
                <strong>Client</strong>
              </label>
              <Select
                size={"large"}
                name="clientKey"
                prefixCls="inner-select2"
                defaultValue={selectedClient}
                value={selectedClient}
                onChange={(_, data) => setSelectedClient(data)}
                style={{
                  width: "100%",
                  height: "48px",
                }}
                maxTagCount={1}
                placeholder="Please select"
                options={clientOptions}
                dropdownStyle={{
                  maxHeight: "300px",
                }}
              />
            </div>
            {loader ? (
              <button type="button">
                {" "}
                <LoadingBlock
                  height="fit-content"
                  size={28}
                  onlySpinner={false}
                  color={"#fff"}
                />
              </button>
            ) : (
              <button type="submit" onClick={handleSubmit}>
                Apply
              </button>
            )}
          </div>
        </div>
      </div>
    </FilterWrapper>
  );
};

export default TrackerFilter;
