import axios from "axios";
import { SignInStep } from "../../app/Auth/signIn/SignInSlice";
import { store } from "../../app/store/store";
import { reset_AiBookingSlice } from "../../module/AIBooking/AiBookingSlice";
import { reset_loginAuth } from "../../app/Auth/signIn/LogInSlice";
import { resetCampaignslice } from "../../module/campaignTable/CampaignSlice";
import { resetLsaPerformance } from "../../module/PaidSearch/LsaPerformanceStore";
import { resetDynamicBudgetSlice } from "../../module/exceltable/ExcelStore";
import { resetCapacitySlice } from "../../module/capacityMarketing/CapacitySlice";
import { resetQualitySlice } from "../../module/qualityAssurance/QualityAssSlice";
import { resetBudgetTrckerSlice } from "../../module/BudgetTracker/BudgetTrckerSlice";
import { resetNotificationSlice } from "../../module/NotificationCenter/NotificationSlice";

// Environment and API setup
const BaseURL = process.env.REACT_APP_EXTERNAL_BASEURL;
const ApiPassword = process.env.REACT_APP_API_AUTH;
const Version = "api/v1/";
const EndPoint = `${BaseURL}${Version}`;

const ExternalApi = axios.create({
  baseURL: EndPoint,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});

// Function to get the token
const getAuthHeader = () => {
  const username = "apis";
  const password = ApiPassword;

  const btoaToken = btoa(`${username}:${password}`);
  return `Basic ${btoaToken}`;
};

// Function to get X-Token from store
const getXToken = () => store.getState()?.loginAuth?.data?.token ?? null;

// Clear user-related store data
const clearStoreData = () => {
  store.dispatch(SignInStep(null));
  store.dispatch(resetDynamicBudgetSlice());
  store.dispatch(resetLsaPerformance());
  store.dispatch(resetCampaignslice());
  store.dispatch(reset_AiBookingSlice());
  store.dispatch(reset_loginAuth());
  store.dispatch(resetCapacitySlice());
  store.dispatch(resetQualitySlice());
  store.dispatch(resetBudgetTrckerSlice());
  store.dispatch(resetNotificationSlice());
};

// Axios Request Interceptor
ExternalApi.interceptors.request.use(
  (config) => {
    const authHeader = getAuthHeader();
    const xToken = getXToken();
    // console.log(xToken, "xToken", store.getState());

    if (xToken) {
      config.headers = {
        Authorization: authHeader,
        "X-Token": xToken,
      };
    } else {
      config.headers = {
        Authorization: authHeader,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Axios Response Interceptor
ExternalApi.interceptors.response.use(
  (response) => {
    if (response.data?.status === 401) {
      clearStoreData();
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      clearStoreData();
    }

    return Promise.reject(error);
  }
);

export default ExternalApi;
