export const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const TableText = ({ val, weight }) => (
  <p style={{ margin: 0, fontWeight: weight || 400 }}>{val}</p>
);

export const TableValuesKeys = [
  "budget",
  "sales",
  "roas",
  "leads",
  "cpl",
  "appts",
  "cpa",
  "con_per",
  "jobs",
  "cpj",
  "close_per",
  "avg_ticket",
  "new_customer",
];

export const TableValIni = {
  category_name: "Total",
  budget: 0,
  sales: 0,
  roas: 0,
  leads: 0,
  cpl: 0,
  appts: 0,
  cpa: 0,
  con_per: 0,
  jobs: 0,
  cpj: 0,
  close_per: 0,
  avg_ticket: 0,
  new_customer: 0,
};
