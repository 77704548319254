import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { Arrowiconleft, Arrowiconright } from "../../utils/Icons/SvgIcons";

export default function SimpleDoubleBarChart({
    payload,
    title,
    firstLineChartName,
    firstLineChartLabel,
    firstLineChartColor,
    secondLineChartName,
    secondLineChartLabel,
    secondLineChartColor,
}) {

    const [graphPage, setGraphPage] = useState(1);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      if (payload && payload.length > 0) {
        const startIndex = (graphPage - 1) * 15;
        const endIndex = startIndex + 15;
        const paginatedData = payload.slice(startIndex, endIndex);
        const updatedData = paginatedData?.map((el) => ({
          ...el,
          newField: el[`${firstLineChartLabel}`] - el[`${secondLineChartLabel}`],
        }));
        setData(updatedData);
      }
    }, [graphPage, payload]);
  
    const CustomizedTick = (props) => {
      const { x, y, payload } = props;
      const sourceName = payload.value;
      const maxLength = 8; // Limit the number of characters
      const truncatedName =
        sourceName.length > maxLength
          ? sourceName.slice(0, maxLength) + "..."
          : sourceName;
  
      return (
        <text
          x={x - 10}
          y={y + 20}
          textAnchor="middle"
          // transform={`rotate(-45, ${x}, ${y + 10})`} // Apply rotation at the correct x, y position
          style={{
            fontSize: 12, // Set font size to 12px
            cursor: "pointer",
          }}
          title={sourceName} // Title will show the full name on hover
        >
          {truncatedName}
        </text>
      );
    };
  
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        let temp = 0;
        payload?.map((el) => (temp = temp + el?.value));
        return (
          <CustomTooltipWrapper>
            <h2>{payload?.[0]?.payload?.source}</h2>
            <div>
              <label>{firstLineChartName} - </label>
              <span>{temp}</span>
            </div>
            <div>
              <label>{secondLineChartName} - </label>
              <span>{payload?.[0]?.value}</span>
            </div>
          </CustomTooltipWrapper>
        );
      }
      return null;
    };
  
    if (Array.isArray(payload) && payload?.length === 0) {
      return (
        <MainWrapper>
          <div className="sub-header">
            <sub>{title}</sub>
          </div>
          <GraphWrapper className="nodata-section" style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "400px", fontSize: "22px" }}>
            NO DATA FOUND
          </GraphWrapper>
        </MainWrapper>
      );
    }

  return (
     <MainWrapper>
      <div className="sub-header">
        <sub>{title}</sub>
        <div className="prev-next">
          <Previous set={setGraphPage} value={graphPage} />
          <Next set={setGraphPage} value={graphPage} payload={payload} />
        </div>
      </div>

      <GraphWrapper className="graph-section">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 30,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey="source"
              name="source"
              angle={-45}
              height={50}
              textAnchor="end"
              tick={<CustomizedTick />}
            />
            <YAxis label={{ value: "Count", angle: -90, position: "left" }} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar
              dataKey={secondLineChartLabel}
              name={secondLineChartName}
              stackId="a"
              fill={secondLineChartColor}
              barSize={60}
            />
            <Bar
              dataKey={'newField'}
              name={firstLineChartName}
              stackId="a"
              fill={firstLineChartColor}
              barSize={60}
            />
          </BarChart>
        </ResponsiveContainer>
      </GraphWrapper>
    </MainWrapper>
  )
}

const Previous = ({ set, value }) => {
    return (
      <button
        style={{ cursor: value == 1 && "not-allowed" }}
        onClick={() => set((prev) => (prev > 1 ? prev - 1 : 1))}
      >
        <Arrowiconleft />
      </button>
    );
  };
  
  const Next = ({ set, value, payload }) => {
    const totalPages = Math.ceil(payload.length / 15);
  
    return (
      <button
        style={{
          cursor: value >= totalPages ? "not-allowed" : "pointer",
        }}
        onClick={() => (value < totalPages ? set((prev) => prev + 1) : null)}
        disabled={value >= totalPages}
      >
        <Arrowiconright />
      </button>
    );
  };
  
  const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    margin: 40px 0;
  
    .sub-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
  
      sub {
        font-size: 20px;
        font-weight: 600;
      }
    }
  `;
  
  const GraphWrapper = styled.div`
    height: 500px;
    width: 100%;
    background: #ffffff;
    margin: 20px 0;
    padding: 20px 0;
    .recharts-legend-wrapper {
      height: auto !important;
    }
  `;
  
  const CustomTooltipWrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 10px 15px;
    border-radius: 6px;
    font-family: Poppins, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    min-width: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  
    h2 {
      font-size: 18px;
      margin: 0;
      padding-bottom: 5px;
      font-weight: 600;
      border-bottom: 1px solid #444;
      width: 100%;
    }
  
    div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 5px 0;
    }
  
    label {
      font-weight: 500;
    }
  
    span {
      font-weight: 400;
      color: #dcdcdc;
    }
  
    animation: fadeIn 0.3s ease-in-out;
  
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `;
