import React, { useEffect, useState } from "react";
import { Avatar, Select } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { filterClientsBySection } from "../utils/common/commonFunction";
import { summaryFilterDetails } from "../app/Auth/signIn/LogInSlice";
import {
  activeHistorySheet,
  activeHistorySheetData,
  finalReportData,
  finalReportFilter,
  updateGoalFilters,
  updateYearData,
  updateYearSheetFilters,
} from "../module/exceltable/ExcelStore";

const { Option } = Select;

const BudgetSheetHeaderFilter = ({setYear}) => {
  const dispatch = useDispatch();
  const newFilters = useSelector(
    (state) => state?.loginAuth?.summarySectionFilter
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const [clientOptions, setclientOptions] = useState([]);

  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );

  const clientname = newFilters?.selectedClient
    ? newFilters?.selectedClient
    : prefilledData?.selectedClient;

  const dynamicBudgetSheet = useSelector((state) => state?.dynamicBudgetSheet);
  const { yearSheetFilters } = dynamicBudgetSheet;

  const getClientOption = () => {
    let arr = [];
    filterClientsBySection(clientOptionsData?.clients, "dynamic-budget-sheet")
      ?.length > 0 &&
      // eslint-disable-next-line array-callback-return
      filterClientsBySection(
        clientOptionsData?.clients,
        "dynamic-budget-sheet"
      )?.map((ele) => {
        let obj = {
          ...ele,
          label: ele?.name,
          value: ele?.name,
        };
        arr.push(obj);
      });
    setclientOptions(arr);
  };

  const submitHandler = (selectedClient) => {
    dispatch(activeHistorySheet(null));
    dispatch(activeHistorySheetData(null));
    dispatch(updateYearData());
    dispatch(finalReportData());
    dispatch(updateGoalFilters({}));
    dispatch(
      updateYearSheetFilters({
        ...yearSheetFilters,
        category: [],
        subCategory: [],
      })
    );
    let reauestedPayload = {
      selectedClient: selectedClient,
    };
    dispatch(summaryFilterDetails(reauestedPayload));
    dispatch(finalReportFilter());
    setYear &&
     setYear(null)
  };

  useEffect(() => {
    getClientOption();
  }, []);

  return (
    <StyledContainer>
      <Avatar style={{ backgroundColor: "#1890ff" }}>
        {clientname?.name?.slice(0, 2)}
      </Avatar>
      <StyledSelect
        dropdownStyle={{ backgroundColor: "#2b2b2b" }}
        style={{ marginLeft: "10px" }}
        bordered={false}
        prefixCls="headerSelect"
        className="style"
        defaultValue={
          newFilters?.selectedClient
            ? newFilters?.selectedClient
            : prefilledData?.selectedClient
        }
        onChange={(_, data) => submitHandler(data)}
        maxTagCount={1}
        placeholder="Please select"
        options={clientOptions}
      />
    </StyledContainer>
  );
};

export default BudgetSheetHeaderFilter;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #2b2b2b;
  padding: 4px;
  border-radius: 20px;
  max-width: fit-content;

  .style {
    .ant-select-selector {
      background: #2b2b2b !important;
    }
  }
`;

const StyledSelect = styled(Select)`
  min-width: 159px;
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    color: #fff !important;
    box-shadow: none !important;
    padding: 0 !important;
  }

  .ant-select-arrow {
    color: #fff !important;
  }
`;
