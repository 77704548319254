import React, { useState } from "react";
import { DbsWrap, FilterButtonWrap, FlexBox, SubHeader } from "./styledbs";
import Header from "../../components/Header";
import TabComponent from "../../components/newComponent/TabComponent";
import Goal from "./goal";
import Year from "./year";
import Final from "./final";
import { FiltersOfSheetIcon } from "../../utils/Icons/SvgIcons";

const tabs = ["Goals", "Year Sheet", "Final Report"];
const finaltabs = ["Predicted", "Current"];

export default function index() {
  const [active, setActive] = useState(0);
  const [reportActive, setReportActive] = useState(0);
  const [showFilter, setShowFilter] = useState(false);

  const handleTabAction = (index) => {
    setActive(index);
  };

  const handleReportTabAction = (index) => {
    setReportActive(index);
  };

  return (
    <DbsWrap>
      <Header
        isSetting={true}
        openSetting={() => setOpenPop(true)}
        isNotification={true}
        title="Dynamic Budget Sheet"
      />

      <SubHeader>
        <TabComponent
          index={active}
          tabs={tabs}
          selectedIndex={handleTabAction}
        />
        <FlexBox>
          {active === 2 && (
            <TabComponent
              index={reportActive}
              tabs={finaltabs}
              selectedIndex={handleReportTabAction}
            />
          )}
          <FilterButtonWrap>
            <p onClick={() => setShowFilter(!showFilter)}> <FiltersOfSheetIcon /> Filters </p>
            
          </FilterButtonWrap>
        </FlexBox>
      </SubHeader>

      {active === 0 && <Goal />}
      {active === 1 && <Year />}
      {active === 2 && <Final />}
    </DbsWrap>
  );
}
