import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { kpisVisualization } from "../../services/Collections";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import StackedAreaChart from "./charts/StackedAreaChart";
import moment from "moment";
import { Skeleton } from "antd";
import { GraphLoader } from "../../utils/Icons/SvgIcons";
import MultiLineChart from "./charts/MultiLineChart";
import LineBarChart from "./charts/LineBarChart";
import BoxPlot from "./charts/BoxPlot";
import HeatMapChart from "./charts/HeatMapChart";
import Histogram from "./charts/HistogramChart";
import TempHeatmapChart from "./charts/TempHeatmapChart";
import CorrelationGraph from "./charts/CorrelationGraph";
import { distribution, distribution2, titles } from "./graphData";

export default function KPIs({ setFilterLoading, setOpenDrawer }) {
  const { visualizationData } = useSelector((e) => e.loginAuth);

  const [kpisData, setKpisData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchkpis = async () => {
    setLoading(true);
    setFilterLoading(true);
    let res = await kpisVisualization(visualizationData?.selectedClient?.key);
    if (res?.status === 200) {
      setKpisData(
        res?.data?.map((el) => {
          const uniqueInboundCalls = el?.unique_inbound_calls || 0;
          const onlineForms = el?.online_forms || 0;
          const totalJobsBooked = el?.total_jobs_booked || 0;
          const unbookedCalls = el?.unbooked_calls || 0;
          const cancelledCalls = el?.cancelled_calls || 0;
          const excusedCalls = el?.excused_calls || 0;

          const total =
            uniqueInboundCalls +
            onlineForms -
            (totalJobsBooked + unbookedCalls + cancelledCalls + excusedCalls);

          const totalPer =
            uniqueInboundCalls === 0 ? 0 : (total / uniqueInboundCalls) * 100;

          const booking_rate = (
            (totalJobsBooked / (uniqueInboundCalls + onlineForms)) *
            100
          ).toFixed(2);

          return {
            ...el,
            abandon_rate: totalPer > 0 ? totalPer.toFixed(2) : 0,
            monthName: moment()
              ?.month(el?.month - 1)
              ?.format("MMMM"),
            booking_rate: Number(booking_rate) ? booking_rate : 0,
          };
        })
      );
      setLoading(false);
      setFilterLoading(false);
      setOpenDrawer(false);
    } else {
      toast.error(res?.message || res?.error || "Something went wrong");
      setLoading(false);
      setFilterLoading(false);
    }
  };

  useEffect(() => {
    fetchkpis();
  }, [visualizationData]);

  if (loading || kpisData?.length <= 0) {
    return (
      <LoaderWrapper>
        {titles?.map((el, i) => (
          <MapsLoaderWrapper key={i} loading={loading}>
            {!loading && <div className="title">{el}</div>}
            <Skeleton.Node
              active={loading}
              style={{
                width: "100%",
                height: "400px",
                margin: "auto",
                background: !loading && "#fff",
              }}
            >
              {loading ? (
                <GraphLoader />
              ) : (
                <div className="noData">NO DATA</div>
              )}
            </Skeleton.Node>
          </MapsLoaderWrapper>
        ))}
      </LoaderWrapper>
    );
  }
  return (
    <>
      <KPIsWrapper>
        <StackedAreaChart
          title="Distribution Over Time"
          data={kpisData}
          areaData={distribution}
          xAxisDataKey="monthName"
        />
      </KPIsWrapper>
      <KPIsWrapper>
        {/* <MultiLineChart data={kpisData} /> */}
        <StackedAreaChart
          title="Trend of Lead %, Booking % and Closing %"
          data={kpisData}
          areaData={distribution2}
          xAxisDataKey="monthName"
        />
      </KPIsWrapper>
      <KPIsWrapper>
        <LineBarChart data={kpisData} />
      </KPIsWrapper>
      <KPIsWrapper>
        <BoxPlot data={kpisData?.map((el) => el?.avg_ticket)} />
      </KPIsWrapper>
      {/* <KPIsWrapper>
        <HeatMapChart data={kpisData} />
      </KPIsWrapper> */}
      <KPIsWrapper>
        <CorrelationGraph data={kpisData} />
      </KPIsWrapper>
      <KPIsWrapper>
        <Histogram data={kpisData} />
      </KPIsWrapper>
    </>
  );
}

const KPIsWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
  border-radius: 5px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const MapsLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  background-color: ${({ loading }) => !loading && "#fff"};
  border-radius: 5px;
  overflow: hidden;

  .title {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0 0 20px;
  }

  .noData {
    font-size: 22px;
  }
`;
