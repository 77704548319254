import { Button, Checkbox, DatePicker, Select, Tooltip } from "antd";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  addCampaignAndBusinessUnit,
  getBusinessUnitListFromST,
  getCampaignByPlatform,
  getCampaignDataFromST,
  getSourceListForSetting,
} from "../../services/Collections";
import { toast } from "react-toastify";
import moment from "moment";
import dayjs from "dayjs";
import { debounce } from "../../utils/common/commonFunction";
import { NoDataDropdown } from "../../components/common/NoDataDropdown";

const clientOptions = [
  { label: "Campaign", value: "campaign" },
  { label: "Business Unit", value: "businessunit" },
];

const platformType = [
  {
    label: "Google",
    value: "google",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "LSA",
    value: "lsa",
  },
  {
    label: "Yelp",
    value: "yelp",
  },
  {
    label: "Custom",
    value: "custom",
  },
  {
    label: "Bing",
    value: "bing",
  },
];
const platformType2 = [
  {
    label: "Google",
    value: "google",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "LSA",
    value: "lsa",
  },
  {
    label: "Yelp",
    value: "yelp",
  },
  {
    label: "Bing",
    value: "bing",
  },
];

const EditSettings = ({ handleListing, setFilterDrawer, editData }) => {
  const [scrollPage, setScrollPage] = useState(1);
  let cost_year = editData?.prefilledData?.custom || [];
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(
    editData?.prefilledData?.st_type
  );
  const [sourceLoading, setsourceLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  const [campaignSearchId, setCampaignSearchId] = useState(null);

  const [sourceOption, setSourceOption] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );

  const [platformLoading, setPlatformLoading] = useState(false);
  const [platformCampaignList, setPlatformCampaignList] = useState([]);
  const [selectedSource, setSelectedSource] = useState(
    editData?.prefilledData?.campaign_source
  );
  const [platformTypeValue, setPlatFormType] = useState(editData?.platform);
  const [selectData, setSelectData] = useState([]);
  const [selectData2, setSelectData2] = useState([]);
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const [sourceLoader, setsourceLoader] = useState(false);
  const [sourceOptionSetting, setSourceOptionSetting] = useState([]);
  const filterData = useSelector((state) => state?.loginAuth?.settingData);

  let arr = [];
  let arr2 = [];
  const [selectedplatformByCampaign, setSelectedplatformByCampaign] =
    useState(null);

  const [
    selectedIgnoreplatformByCampaign,
    setSelectedIgnoreplatformByCampaign,
  ] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  let editDataArray = editData?.prefilledData?.custom || [];
  console.log(editDataArray, "editDataArray");

  const initialValues = {
    custom_costs:
      editData?.prefilledData?.custom_cost?.length > 0
        ? editData?.prefilledData?.custom_cost
        : [
            {
              cost: "",
              year: "",
              month: "",
            },
          ],
  };

  const handleAllClear = () => {
    setIsSelectAll(false);
    setSelectedValues([]);
    setSelectData([]);
  };
  const handleIgnoreAllClear = () => {
    setIsSelectAll(false);
    setSelectedIgnoreplatformByCampaign([]);
    setSelectData2([]);
  };
  const handleAllClearCampaignSearch = () => {
    setIsSelectAll(false);
  };

  const getCampaignData = async (type, initial) => {
    if (initial) {
    } else {
      setCampaignSearchId(null);
    }
    console.log(type, "typeeeeeee");
    if (type == "campaign") {
      setsourceLoading(true);
      let res = await getCampaignDataFromST(
        filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db
      );
      if (res.status === 200) {
        console.log(res);

        let arr = [];

        if (res.data.length > 0) {
          for (let i = 0; i < res?.data.length; i++) {
            let obj = {
              value: res?.data[i]?.id,
              label: res?.data[i]?.name,
            };
            arr.push(obj);
          }
        }
        const filteredByVal = Array.isArray(arr)
          ? [
              ...arr
                .reduce((a, c) => {
                  a?.set(c?.value, c);
                  return a;
                }, new Map())
                .values(),
            ]
          : [];
        setsourceLoading(false);
        setSourceOption(filteredByVal);
      } else {
        setsourceLoading(false);
        // toast.error(res?.message || res?.error || "Something went wrong");
      }
    } else {
      setsourceLoading(true);
      let res = await getBusinessUnitListFromST(
        filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db
      );
      if (res.status === 200) {
        console.log(res);

        let arr = [];

        if (res.data.length > 0) {
          for (let i = 0; i < res?.data.length; i++) {
            let obj = {
              value: res?.data[i]?.id,
              label: res?.data[i]?.name,
            };
            arr.push(obj);
          }
        }
        const filteredByVal = Array.isArray(arr)
          ? [
              ...arr
                .reduce((a, c) => {
                  a?.set(c?.value, c);
                  return a;
                }, new Map())
                .values(),
            ]
          : [];
        setsourceLoading(false);
        setSourceOption(filteredByVal);
      } else {
        setsourceLoading(false);
        // toast.error(res?.message || res?.error || "Something went wrong");
      }
    }
  };

  const handleAddValues = (val) => {
    console.log(val, "values-----");
    if (!arr.includes(val)) {
      arr = [...arr, val];
      setSelectedValues(arr);
    } else {
      let filteredArr = [];
      arr.map((values) => {
        if (values != val) {
          filteredArr.push(val);
        }
      });
      arr = filteredArr;
      setSelectedValues(arr);
    }
    console.log(arr, "aar1");
  };
  const handleAddIgnoreValues = (val) => {
    if (!arr2.includes(val)) {
      arr2 = [...arr2, val];
      setSelectedIgnoreplatformByCampaign(arr2);
    } else {
      let filteredArr = [];
      arr2.map((values) => {
        if (values != val) {
          filteredArr.push(val);
        }
      });
      arr2 = filteredArr;
      setSelectedIgnoreplatformByCampaign(arr2);
    }
  };

  const handlePlatformList = async (platformName, initial) => {
    if (platformName != "custom") {
      setPlatformLoading(true);
      if (initial) {
      } else {
        setSelectedplatformByCampaign(null);
      }

      let params = new URLSearchParams();

      params.append(
        "db",
        filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db
      );
      params.append("name", platformName);
      let res = await getCampaignByPlatform(params.toString());
      if (res?.status === 200) {
        console.log(res);

        let arr = [];

        if (res.data.length > 0) {
          for (let i = 0; i < res?.data.length; i++) {
            let obj = {
              value: res?.data[i]?.id,
              label: res?.data[i]?.name,
            };
            arr.push(obj);
          }
        }
        const filteredByVal = Array.isArray(arr)
          ? [
              ...arr
                .reduce((a, c) => {
                  a?.set(c?.value, c);
                  return a;
                }, new Map())
                .values(),
            ]
          : [];
        setPlatformLoading(false);
        setPlatformCampaignList(filteredByVal);
      } else {
        // toast.error(res.message || res?.error || "Something went wrong");
      }
    }
  };

  const filterPlatformList = () => {
    setCampaignSearchId(
      editData?.prefilledData?.campaign?.campaignid ||
        editData?.prefilledData?.businessunit?.id
    );
  };

  const handleMultiSelectPrefilledData = () => {
    console.log(editData, "editDataeditData");
    let arr = [];
    if (editData?.prefilledData?.st_type == "businessunit") {
      editData?.prefilledData?.ad_campaigns?.length > 0 &&
        editData?.prefilledData?.ad_campaigns?.map((el) => {
          // let obj = {
          //   value: el?.id,
          //   label: el?.name,
          // };
          arr.push(el?.id);
        });
    } else {
      editData?.prefilledData?.ad_campaigns?.length > 0 &&
        editData?.prefilledData?.ad_campaigns?.map((el) => {
          //   let obj = {
          //     value: el?.campaignid,
          //     label: el?.campaignname,
          //   };
          arr.push(el?.id);
        });
    }
    setSelectedValues(arr);
  };

  const handleIgnoreMultiSelectPrefilledData = () => {
    let arr = [];
    if (editData?.prefilledData?.st_type == "businessunit") {
      editData?.prefilledData?.ignored_ad_campaigns?.length > 0 &&
        editData?.prefilledData?.ignored_ad_campaigns?.map((el) => {
          // let obj = {
          //   value: el?.id,
          //   label: el?.name,
          // };
          arr.push(el?.id);
        });
    } else {
      editData?.prefilledData?.ignored_ad_campaigns?.length > 0 &&
        editData?.prefilledData?.ignored_ad_campaigns?.map((el) => {
          //   let obj = {
          //     value: el?.campaignid,
          //     label: el?.campaignname,
          //   };
          arr.push(el?.id);
        });
    }
    setSelectedIgnoreplatformByCampaign(arr);
  };

  const handleSourceList = async (search, page) => {
    setsourceLoader(true);
    let params = new URLSearchParams();
    params.append(
      "db",
      filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db
    );
    params.append("page_size", 5000);
    params.append("page", page ?? scrollPage);
    params.append("interval", 90);
    search && params.append("search", search);
    let res = await getSourceListForSetting(params?.toString());
    if (res?.status == 200) {
      let arr = [];
      res?.data?.items?.length > 0 &&
        res?.data?.items?.map((el) => {
          arr.push({
            label: el,
            value: el,
          });
        });

      if (page == 1 || scrollPage == 1) {
        setSourceOptionSetting(arr);
      } else {
        setSourceOptionSetting([...sourceOptionSetting, ...arr]);
      }
      setsourceLoader(false);
    } else {
      setsourceLoader(false);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      // User has scrolled to the bottom

      setScrollPage(scrollPage + 1);
    }
  };

  const handleSearchSource = useCallback(debounce(handleSourceList), []);

  const handleSearchOptions = (e) => {
    handleSearchSource(e, 1);
  };

  const submitHandler = async (val) => {
    if (
      selectedValues &&
      // costValue &&
      val?.custom_costs?.length > 0 &&
      val?.custom_costs?.[0]?.cost !== "" &&
      val?.custom_costs?.[0]?.year !== "" &&
      val?.custom_costs?.[0]?.month !== "" &&
      platformTypeValue == "custom" &&
      campaignSearchId
    ) {
      setLoading(true);
      let requestPayload;
      let arr = [];
      val?.custom_costs?.map((el, index) => {
        if (el?.cost) {
          arr.push(el);
        }
        console.log(arr, "firstArr");
      });
      if (selectedClient == "campaign") {
        requestPayload = {
          id: editData?.prefilledData?.id,
          st_type: selectedClient,
          campaignid:
            campaignSearchId?.toString() ||
            editData?.prefilledData?.campaign?.campaignid ||
            editData?.prefilledData?.businessunit?.id,
          platform_type: platformTypeValue,
          custom_costs: arr,

          ad_campaignids: selectedValues
            ? JSON.stringify(selectedValues)
            : null,
          ignored_ad_campaignids: selectedIgnoreplatformByCampaign
            ? JSON.stringify(selectedIgnoreplatformByCampaign)
            : null,

          campaign_source: JSON.stringify(selectedSource),
        };
        if (platformTypeValue == "custom") {
          delete requestPayload.ad_campaignids;
        }
      } else {
        requestPayload = {
          id: editData?.prefilledData?.id,
          st_type: selectedClient,
          businessunitid:
            campaignSearchId?.toString() ||
            editData?.prefilledData?.campaign?.campaignid ||
            editData?.prefilledData?.businessunit?.id,
          platform_type: platformTypeValue,

          ad_campaignids: selectedValues
            ? JSON.stringify(selectedValues)
            : null,
          ignored_ad_campaignids: selectedIgnoreplatformByCampaign
            ? JSON.stringify(selectedIgnoreplatformByCampaign)
            : null,

          campaign_source: JSON.stringify(selectedSource),
        };
        if (platformTypeValue == "custom") {
          delete requestPayload.ad_campaignids;
        }
      }
      let res = await addCampaignAndBusinessUnit(
        filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db,
        requestPayload
      );
      if (res.status == 200 || res.status == 201) {
        console.log(res);
        toast.success("Edit successfully");
        setLoading(false);
        handleListing();
        setFilterDrawer(false);
      } else {
        setLoading(false);
        // toast.error(
        //   res?.response?.data?.message ||
        //     res?.message ||
        //     res?.error ||
        //     "Something went wrong"
        // );
      }
    } else if (
      selectedClient &&
      campaignSearchId &&
      (selectedValues ||
        selectedIgnoreplatformByCampaign ||
        isSelectAll == true) &&
      (selectedValues?.length > 0 ||
        selectedIgnoreplatformByCampaign?.length > 0 ||
        selectData?.length > 0 ||
        selectData2?.length > 0) &&
      (platformTypeValue == "google" ||
        platformTypeValue == "facebook" ||
        platformTypeValue == "lsa" ||
        platformTypeValue == "yelp" ||
        platformTypeValue == "bing")
    ) {
      setLoading(true);
      let requestPayload;
      if (selectedClient == "campaign") {
        requestPayload = {
          id: editData?.prefilledData?.id,
          st_type: selectedClient,
          campaignid:
            campaignSearchId?.toString() ||
            editData?.prefilledData?.campaign?.campaignid ||
            editData?.prefilledData?.businessunit?.id,
          platform_type: platformTypeValue,

          ad_campaignids: selectedValues
            ? JSON.stringify(selectedValues)
            : null,
          ignored_ad_campaignids: selectedIgnoreplatformByCampaign
            ? JSON.stringify(selectedIgnoreplatformByCampaign)
            : null,

          campaign_source: JSON.stringify(selectedSource),
        };
        if (platformTypeValue == "custom") {
          delete requestPayload.ad_campaignids;
        }
      } else {
        requestPayload = {
          id: editData?.prefilledData?.id,
          st_type: selectedClient,
          businessunitid:
            campaignSearchId?.toString() ||
            editData?.prefilledData?.campaign?.campaignid ||
            editData?.prefilledData?.businessunit?.id,
          platform_type: platformTypeValue,

          ad_campaignids: selectedValues
            ? JSON.stringify(selectedValues)
            : null,
          ignored_ad_campaignids: selectedIgnoreplatformByCampaign
            ? JSON.stringify(selectedIgnoreplatformByCampaign)
            : null,

          campaign_source: JSON.stringify(selectedSource),
        };
        if (platformTypeValue == "custom") {
          delete requestPayload.ad_campaignids;
        }
      }
      let res = await addCampaignAndBusinessUnit(
        filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db,
        requestPayload
      );
      if (res.status == 200 || res.status == 201) {
        console.log(res);
        toast.success("Edit successfully");
        setLoading(false);
        handleListing();
        setFilterDrawer(false);
      } else {
        setLoading(false);
        // toast.error(
        //   res?.response?.data?.message ||
        //     res?.message ||
        //     res?.error ||
        //     "Something went wrong"
        // );
      }
    } else {
      toast.warn("Please fill required fields");
    }
  };

  const handleAllClearSources = () => {
    setSelectedSource([]);
  };

  const handleSelectAll = (data) => {
    let arr = [];
    data?.length > 0 &&
      data?.map((el) => {
        arr.push(el?.value);
      });

    setSelectedValues(arr);
  };
  const handleIgnoreSelectAll = (data) => {
    let arr = [];
    data?.length > 0 &&
      data?.map((el) => {
        arr.push(el?.value);
      });
    setSelectedIgnoreplatformByCampaign(arr);
  };

  useEffect(() => {
    handleMultiSelectPrefilledData();
    handleIgnoreMultiSelectPrefilledData();
    getCampaignData(editData?.prefilledData?.st_type, true);
    handlePlatformList(editData?.platform);
    filterPlatformList();
  }, []);

  useEffect(() => {
    handleSourceList();
  }, [scrollPage]);

  return (
    <AddSectionWrapper selectedThemeColors={selectedThemeColors}>
      <div className="title">
        <h3 style={{ color: "rgba(25, 25, 25, 1)" }}>Edit Campaigns</h3>
      </div>

      <div className="content">
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={submitHandler}
          render={({ values, setFieldValue }) => (
            <Form>
              <div>
                <label>
                  <strong>Select Type</strong>
                </label>
                <div className="" style={{ marginTop: "4px" }}>
                  <Select
                    size={"large"}
                    // className="custom-select add-select"
                    prefixCls="inner-select2"
                    onChange={(val) => {
                      getCampaignData(val);
                      setSelectedClient(val);
                      setSourceOption([]);
                      setPlatFormType(null);
                    }}
                    style={{
                      width: "100%",
                      height: "48px",
                      // backgroundColor: "#05031a",
                    }}
                    maxTagCount={1}
                    value={selectedClient}
                    placeholder="Please select"
                    options={clientOptions}
                  />
                </div>
              </div>

              <div className="select-wrap">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "transparent",
                    height: "100%",
                    marginBottom: "0px",
                  }}
                >
                  <label
                    className="mb-1  "
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong>Campaign Search</strong>
                  </label>
                </div>
                <Tooltip>
                  <Select
                    showSearch
                    prefixCls="inner-select2"
                    allowClear
                    loading={sourceLoading}
                    style={{
                      width: "100%",
                      height: "48px",
                    }}
                    maxTagCount={1}
                    value={sourceLoading ? null : campaignSearchId}
                    placeholder="Please select"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onClear={handleAllClearCampaignSearch}
                    onChange={setCampaignSearchId}
                    options={sourceOption}
                    notFoundContent={
                      sourceLoading ? (
                        <NoDataDropdown text={"Processing..."} />
                      ) : (
                        <NoDataDropdown text={"No data"} />
                      )
                    }
                  />
                </Tooltip>
              </div>

              <div className="input-box" style={{ marginTop: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "transparent",
                    height: "100%",
                    marginBottom: "0px",
                  }}
                >
                  <label
                    className="mb-1  "
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong
                      style={{
                        fontWeight: 700,
                        fontSize: "15px",
                        color: "rgb(146, 146, 165)",
                        fontStyle: "normal",
                      }}
                    >
                      Campaign Sources
                    </strong>
                  </label>
                </div>
                <Tooltip>
                  <Select
                    mode="multiple"
                    prefixCls="inner-select2"
                    allowClear
                    loading={sourceLoader}
                    onPopupScroll={handleScroll}
                    onSearch={(e) => handleSearchOptions(e)}
                    onBlur={(e) =>
                      scrollPage == 1 ? handleSourceList() : setScrollPage(1)
                    }
                    maxTagCount={1}
                    style={{
                      width: "100%",
                      height: "48px",
                    }}
                    value={selectedSource}
                    onChange={(el) => setSelectedSource(el)}
                    // onChange={(el) => console.log(el)}

                    placeholder="Please select"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onClear={handleAllClearSources}
                    options={sourceOptionSetting}
                    notFoundContent={
                      sourceLoader ? (
                        <NoDataDropdown text={"Processing..."} />
                      ) : (
                        <NoDataDropdown text={"No data"} />
                      )
                    }
                  />
                </Tooltip>
              </div>

              <div className="select-wrap">
                <label style={{ marginTop: "10px" }}>
                  <strong>Platform Type</strong>
                </label>
                <div className="" style={{ marginTop: "4px" }}>
                  <Select
                    size={"large"}
                    // className="custom-select add-select"
                    prefixCls="inner-select2"
                    onChange={(val) => {
                      if (!platformLoading) {
                        val != "custom" && handlePlatformList(val);
                        setPlatFormType(val);
                        setPlatformCampaignList([]);
                        handleAllClear();
                        setSelectedIgnoreplatformByCampaign([]);
                        setSelectedValues([]);
                      }
                    }}
                    style={{
                      width: "100%",
                      height: "48px",
                      // backgroundColor: "#05031a",
                    }}
                    value={platformTypeValue}
                    maxTagCount={1}
                    placeholder="Please select"
                    options={
                      selectedClient == "businessunit"
                        ? platformType2
                        : platformType
                    }
                  />
                </div>
              </div>

              {platformTypeValue == "custom" && (
                <>
                  <FieldArray name="custom_costs">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.custom_costs.length > 0 &&
                          values.custom_costs.map((friend, index) => (
                            <div key={index}>
                              <div className="col">
                                <label
                                  htmlFor={`custom_costs.${index}.cost`}
                                  className="new-add-label-2"
                                >
                                  Cost
                                </label>
                                <Field
                                  name={`custom_costs.${index}.cost`}
                                  type="number"
                                  min="0"
                                  className="new-add-input"
                                  placeholder="Please Enter"
                                  // onChange={(e) => setCostValue(e.target.value)}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `custom_costs.${index}.cost`,
                                      e?.target?.value
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name={`custom_costs.${index}.cost`}
                                  component="div"
                                  className="field-error"
                                />
                              </div>
                              <div className="col">
                                <label
                                  htmlFor={`custom_costs.${index}.month`}
                                  className="new-add-label-2"
                                >
                                  Select month/year
                                </label>
                                {/* <Field
                                  name={`custom_costs.${index}.monthyear`}
                                  placeholder="jane@acme.com"
                                  type="text"
                                /> */}
                                <DatePicker
                                  name={`custom_costs.${index}.month`}
                                  allowClear={false}
                                  defaultValue={
                                    values?.custom_costs?.[index]?.year &&
                                    values?.custom_costs?.[index]?.month
                                      ? dayjs(
                                          `${values?.custom_costs?.[index]?.year}-${values?.custom_costs?.[index]?.month}`
                                        )
                                      : ""
                                  }
                                  picker="month"
                                  style={{ color: "#fff" }}
                                  placeholder="Please Select"
                                  className="form-control input custom-year-picker"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `custom_costs.${index}.month`,
                                      e.month() + 1
                                    );
                                    setFieldValue(
                                      `custom_costs.${index}.year`,
                                      e.year()
                                    );
                                  }}
                                  // onChange={(e, fullDate) => {
                                  //   setMonth(e.month());
                                  //   setYear(e.year());
                                  //   setYearMonth(fullDate);
                                  // }}
                                />
                                <ErrorMessage
                                  name={`custom_costs.${index}.month`}
                                  component="div"
                                  className="field-error"
                                />
                              </div>
                              <div className="add-more">
                                {index >= 1 ? (
                                  <button
                                    type="button"
                                    className="secondary"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ))}
                        <div className="add-more" style={{ marginTop: "20px" }}>
                          <button
                            type="button"
                            className="secondary"
                            onClick={() =>
                              push({
                                cost: "",
                                month: "",
                                year: "",
                              })
                            }
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                  {/* <FieldArray
                      name="values"
                      render={({ push, remove }) => (
                        <div>
                          {cost_year
                            ?.slice(1, cost_year?.length)
                            .map((value, index) => (
                              <div key={index}>
                                <label className="new-add-label-2">
                                  <strong>Cost</strong>
                                </label>
                                <input
                                  type="number"
                                  className="new-add-input"
                                  placeholder="Please Enter"
                                  defaultValue={value?.cost}
                                  onChange={(e) => setCostValue(e.target.value)}
                                />
                                <label className="new-add-label">
                                  <strong>Select month/year</strong>
                                </label>
                                <DatePicker
                                  picker="month"
                                  style={{ color: "#fff" }}
                                  placeholder="Please Select"
                                  className="form-control input custom-year-picker"
                                  defaultValue={
                                    value?.year && value?.month
                                      ? dayjs(
                                          `${value?.year}-${value?.month}`,
                                          "YYYY-MM"
                                        )
                                      : ""
                                  }
                                  onChange={(e, fullDate) => {
                                    console.log(value, "heheheh");
                                    setMonth(e.month());
                                    setYear(e.year());
                                    setYearMonth(fullDate);
                                  }}
                                />
                                <div className="add-more">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      remove(value);
                                      removeObj(index + 1);
                                    }}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                          <div
                            className="add-more"
                            style={{ marginTop: "20px" }}
                          >
                            <button
                              type="button"
                              onClick={() => {
                                push("");
                                addMore();
                              }} // Push an empty string to add another input field
                            >
                              Add More
                            </button>
                          </div>
                        </div>
                      )}
                    /> */}
                  {/* <Form.List name="names">
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              {...(index === 0
                                ? formItemLayout
                                : formItemLayoutWithOutLabel)}
                              // label={index === 0 ? "Passengers" : ""}
                              required={false}
                              key={field.key}
                              isListField
                            >
                              <Form.Item
                                {...field}
                                isListField
                                validateTrigger={["onChange", "onBlur"]}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message:
                                      "Please input passenger's name or delete this field.",
                                  },
                                ]}
                                noStyle
                              >
                                {cost_year?.length > 0 &&
                                  cost_year?.map((val) => {
                                    return (
                                      <>
                                        {" "}
                                        <label className="new-add-label">
                                          <strong>Cost</strong>
                                        </label>
                                        <input
                                          type="number"
                                          placeholder="Please Enter"
                                          className="new-add-input"
                                          defaultValue={val?.cost}
                                          onChange={(e) =>
                                            setCostValue(e.target.value)
                                          }
                                        />
                                        <label className="new-add-label">
                                          <strong>Select month/year</strong>
                                        </label>
                                        <DatePicker
                                          picker="month"
                                          style={{ color: "#fff" }}
                                          placeholder="Please Select"
                                          defaultValue={dayjs(
                                            `${val?.year}-${val?.month}`,
                                            "YYYY-MM"
                                          )}
                                          className="form-control input custom-year-picker"
                                          onChange={(e, fullDate) => {
                                            setMonth(e.month());
                                            setYear(e.year());
                                            setYearMonth(fullDate);
                                          }}
                                        />
                                        {fields.length > 0 ? (
                                          <div className="remove-icon">
                                            <MinusCircleOutlined
                                              className="dynamic-delete-button"
                                              onClick={(e) => {
                                                remove(field.name);
                                                removeObj(field.name);
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </>
                                    );
                                  })}
                              </Form.Item>
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <div className="add-more">
                              <Button
                                type="dashed"
                                onClick={(e) => {
                                  add();
                                  addMore();
                                  console.log(e, "addeddata");
                                }}
                                icon={<PlusOutlined />}
                              >
                                Add More
                              </Button>
                            </div>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List> */}
                  {/* <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item> */}
                  {/* </Form> */}
                </>
              )}

              {platformTypeValue != "custom" && (
                <>
                  <div className="select-wrap" style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "transparent",
                        height: "100%",
                        marginBottom: "0px",
                      }}
                    >
                      <label>
                        <strong>Campaigns by Platform</strong>
                      </label>
                      <>
                        {selectedValues?.length < 1 ||
                        selectedValues?.length !==
                          platformCampaignList?.length ? (
                          <span
                            className="ml-2"
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              height: "20px",
                            }}
                          >
                            <span className="mr-2" style={{ color: "#828690" }}>
                              Select All
                            </span>
                            <span aria-disabled>
                              <Checkbox
                                disabled={platformLoading}
                                checked={
                                  selectedValues?.length > 0 &&
                                  selectedValues?.length ==
                                    platformCampaignList?.length
                                }
                                onChange={(e) => {
                                  setIsSelectAll(e?.target?.checked);
                                  setSelectData(platformCampaignList);
                                  if (e.target.checked) {
                                    handleSelectAll(platformCampaignList);
                                  }
                                }}
                              />
                            </span>
                          </span>
                        ) : (
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignContent: "center",
                              height: "20px",
                            }}
                            className="ml-2"
                          >
                            <span
                              className="mr-2"
                              style={{ color: "#828690", marginTop: "4px" }}
                            >
                              {" "}
                              Clear All
                            </span>
                            <span aria-disabled>
                              <Checkbox
                                disabled={platformLoading}
                                checked={
                                  platformCampaignList?.length > 0 &&
                                  selectedValues?.length ==
                                    platformCampaignList?.length
                                }
                                onChange={(e) => {
                                  setIsSelectAll(e?.target?.checked);
                                  setSelectedplatformByCampaign([]);
                                  handleAllClear();
                                }}
                              />
                            </span>
                          </span>
                        )}
                      </>
                    </div>
                    <div className="" style={{ marginTop: "4px" }}>
                      <Select
                        size={"large"}
                        loading={platformLoading}
                        mode="multiple"
                        // className="custom-select-2 inner-multi-select add-multi-select"
                        prefixCls="inner-select2"
                        onChange={(val) => {
                          handleAddValues(val);
                          setSelectedValues(val);
                        }}
                        searchValue={searchValue}
                        onBlur={() => setSearchValue("")}
                        onSearch={(e) => setSearchValue(e)}
                        style={{
                          width: "100%",
                          height: "48px",
                          // backgroundColor: "#05031a",
                        }}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={!platformLoading ? selectedValues : []}
                        maxTagCount={1}
                        placeholder="Please select"
                        options={platformCampaignList}
                        notFoundContent={
                          platformLoading ? (
                            <NoDataDropdown text={"Processing..."} />
                          ) : (
                            <NoDataDropdown text={"No data"} />
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="select-wrap" style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "transparent",
                        height: "100%",
                        marginBottom: "0px",
                      }}
                    >
                      <label>
                        <strong>Ignored Campaign By Platform</strong>
                      </label>
                      <>
                        {selectedIgnoreplatformByCampaign?.length < 1 ||
                        selectedIgnoreplatformByCampaign?.length !==
                          platformCampaignList?.length ? (
                          <span
                            className="ml-2"
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              height: "20px",
                            }}
                          >
                            <span className="mr-2" style={{ color: "#828690" }}>
                              Select All
                            </span>
                            <span aria-disabled>
                              <Checkbox
                                disabled={platformLoading}
                                checked={
                                  selectedIgnoreplatformByCampaign?.length >
                                    0 &&
                                  selectedIgnoreplatformByCampaign?.length ==
                                    platformCampaignList?.length
                                }
                                onChange={(e) => {
                                  setIsSelectAll(e?.target?.checked);
                                  setSelectData2(platformCampaignList);
                                  if (e.target.checked) {
                                    handleIgnoreSelectAll(platformCampaignList);
                                  }
                                }}
                              />
                            </span>
                          </span>
                        ) : (
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignContent: "center",
                              height: "20px",
                            }}
                            className="ml-2"
                          >
                            <span
                              className="mr-2"
                              style={{ color: "#828690", marginTop: "4px" }}
                            >
                              {" "}
                              Clear All
                            </span>
                            <span aria-disabled>
                              <Checkbox
                                disabled={platformLoading}
                                checked={
                                  platformCampaignList?.length > 0 &&
                                  selectedIgnoreplatformByCampaign?.length ==
                                    platformCampaignList?.length
                                }
                                onChange={(e) => {
                                  setIsSelectAll(e?.target?.checked);
                                  setSelectedplatformByCampaign([]);
                                  handleIgnoreAllClear();
                                }}
                              />
                            </span>
                          </span>
                        )}
                      </>
                    </div>
                    <div className="" style={{ marginTop: "4px" }}>
                      <Select
                        size={"large"}
                        loading={platformLoading}
                        mode="multiple"
                        // className="custom-select-2 inner-multi-select add-multi-select"
                        prefixCls="inner-select2"
                        onChange={(val) => {
                          handleAddIgnoreValues(val);
                          setSelectedIgnoreplatformByCampaign(val);
                        }}
                        style={{
                          width: "100%",
                          height: "48px",
                          // backgroundColor: "#05031a",
                        }}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={
                          !platformLoading
                            ? selectedIgnoreplatformByCampaign
                            : []
                        }
                        maxTagCount={1}
                        placeholder="Please select"
                        options={platformCampaignList}
                        notFoundContent={
                          platformLoading ? (
                            <NoDataDropdown text={"Processing..."} />
                          ) : (
                            <NoDataDropdown text={"No data"} />
                          )
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              {!loading && !platformLoading ? (
                <button
                  className="btn-wrap"
                  type="submit"
                  onClick={() => {
                    // submitHandler();
                  }}
                >
                  <b>Submit</b>
                </button>
              ) : (
                <div
                  style={{
                    marginTop: "17px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LoadingButton selectedThemeColors={selectedThemeColors}>
                    Loading...
                  </LoadingButton>
                </div>
              )}
            </Form>
          )}
        ></Formik>
      </div>
    </AddSectionWrapper>
  );
};

export default EditSettings;

const AddSectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px 0;

  .btn-wrap {
    margin-top: 30px;
    width: 100%;
    border-style: none;
    background-color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.buttonColor};
    border-radius: 7px;
    padding: 10px 15px;
    color: #fff;
  }

  .title {
    width: 100%;
    margin-bottom: 20px;

    h3 {
      color: #fff;
    }
  }
  .content {
    width: 100%;
    padding-bottom: 20px;

    label {
      color: ${({ selectedThemeColors }) => selectedThemeColors?.lightText};
      font-size: 15px;
    }
    .select-wrap {
      margin-top: 10px;
    }
  }
  .add-more {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 10px;

    Button {
      border: none;
      padding: 0px 7px;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      background-color: rgba(36, 36, 36, 1);
    }
  }
`;
const LoadingButton = styled.p`
  margin-top: 13px;
  width: 100%;
  border-style: none;
  background-color: ${({ selectedThemeColors }) =>
    selectedThemeColors?.buttonColor};
  border-radius: 7px;
  padding: 10px 15px;
  color: #fff;
`;
