import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import { Drawer, Modal, Table } from "antd";
import { DeleteNewIcon, EditNewicon } from "../../utils/Icons/SvgIcons";
import AddBudget from "./AddBudget";
import { useSelector } from "react-redux";
import { ModalWrapper } from "../dynamicBudgetSheet/Goals/GoalsHistory";
import {
  deleteTrackerReason,
  getBudgetTrackerListing,
} from "../../services/Collections";
import { toast } from "react-toastify";
import LoadingBlock from "../../components/LoadingBlock";
import TrackerFilter from "./TrackerFilter";
import { numberWithCommas } from "../../utils/common/commonFunction";
import Watermark from "../../components/Watermark";

export default function BudgetTracker() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAddBudget, setOpenAddBudget] = useState(false);
  const [editModal, setEditModal] = useState(null);
  const [deletModal, setDeleteModal] = useState(null);
  const [loader, setLoader] = useState(false);
  const [trackerList, setTrackerList] = useState(null);
  const [count, setCount] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalData, settotalData] = useState(0);

  const { loginAuth, budgetTrcker } = useSelector((state) => state);
  const activeClient = budgetTrcker?.selectedClient
    ? budgetTrcker?.selectedClient
    : loginAuth?.prefilledFilters?.selectedClient;

  const trackingListing = async (page, size) => {
    setLoader(true);
    let key = activeClient?.key;
    let param = new URLSearchParams();
    param.append("page", page);
    param.append("page_size", size);

    let res = await getBudgetTrackerListing(key, param);

    if (res?.status == 200) {
      setTrackerList(res?.data);
      settotalData(res?.total);
      setOpenDrawer(false);
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(res?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    setCount(1);
    trackingListing(1, limit);
  }, [activeClient?.key]);

  const modalSubmit = () => {
    if (openAddBudget) {
      setOpenAddBudget(false);
    } else {
      setEditModal(null);
    }
    setCount(1);
    trackingListing(1, limit);
  };

  const handlePagination = (page, size) => {
    setCount(page);
    setLimit(size);
    trackingListing(page, size);
  };

  const handleDelete = async () => {
    setLoader(true);
    let param = new URLSearchParams();
    param.append("budget_tracker", deletModal?.id);
    let res = await deleteTrackerReason(activeClient?.key, param);

    if (res?.status == 200) {
      toast.success(res?.message || "Budget deleted successfully");
      if (trackerList?.length <= 1) {
        setCount(1);
        trackingListing(1, limit);
      } else {
        trackingListing(count, limit);
      }
    } else {
      setLoader(false);
      toast.error("Something went wrong");
    }
    setDeleteModal(null);
  };

  const budgetColumns = [
    {
      title: "Reason",
      align: "left",
      dataIndex: "reason",
      width: "50%",
    },
    {
      title: "Amount",
      dataIndex: "total",
      render: (total, data) => <div>${numberWithCommas(total)}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, data) => (
        <>
          <i
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => setEditModal(data)}
          >
            <EditNewicon />
          </i>
          <i
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => setDeleteModal(data)}
          >
            <DeleteNewIcon />
          </i>
        </>
      ),
    },
  ];

  return (
    <BudgetTrackerWrapper>
      <Drawer
        className="filter-drawer"
        placement="right"
        closable={false}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        width={"400px"}
      >
        <TrackerFilter loader={loader} />
      </Drawer>

      <Header
        clientInfo={activeClient}
        title="Budget Tracker"
        openFilter={() => setOpenDrawer(true)}
        isFilter={true}
        isNotification={true}
      />

      <main>
        <div className="sub-header">
          <p>Budget Tracker</p>
          <button onClick={() => setOpenAddBudget(true)}>+ Add Budget </button>
        </div>
        {loader ? (
          <LoadingBlock height={"calc(100vh - 160px)"} size={28} />
        ) : (
          <Table
            prefixCls="custom-ui-table"
            columns={budgetColumns}
            dataSource={trackerList}
            pagination={{
              hideOnSinglePage: true,
              current: count,
              pageSize: limit,
              showSizeChanger: false,
              onChange: handlePagination,
              total: totalData,
              defaultPageSize: limit,
            }}
            scroll={{
              x: "100%",
              y: "calc(100vh - 262px)",
            }}
          />
        )}
      </main>

      <Modal
        width={475}
        closable={false}
        open={openAddBudget || editModal}
        onCancel={() =>
          openAddBudget ? setOpenAddBudget(false) : setEditModal(null)
        }
        key="bottom"
        footer={null}
        prefixCls="paidAddCategory"
        destroyOnClose={true}
      >
        <AddBudget
          title={editModal ? "Edit Budget" : "Add Budget"}
          initialData={editModal}
          handleSubmit={modalSubmit}
        />
      </Modal>
      {deletModal && (
        <Modal
          open={deletModal !== null}
          footer={false}
          prefixCls="goalHistory"
          closable={false}
          width="350px"
        >
          <ModalWrapper>
            <p>Are you sure you want to delete this budget ?</p>
            <div className="btnWrapper">
              <button className="cancel" onClick={() => setDeleteModal(null)}>
                No
              </button>
              <button className="submit" onClick={handleDelete}>
                {loader ? "Loading..." : "Yes"}
              </button>
            </div>
          </ModalWrapper>
        </Modal>
      )}
      {!loader && (
        <Watermark
          style={{
            width: "fit-content",
            position: "absolute",
            bottom: "30px",
            paddingLeft: "20px",
            justifyContent: "start",
            background: "transparent"
          }}
        />
      )}
    </BudgetTrackerWrapper>
  );
}

const BudgetTrackerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  main {
    height: calc(100vh - 56px);
    padding: 16px;

    .sub-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;

      p {
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 29.05px;
        letter-spacing: 0.005em;
        margin: 0;
      }

      button {
        padding: 6px 12px;
        border-radius: 6px;
        background: #0f1011;
        border: none;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }
`;
