import React, { useEffect, useRef } from "react";
import { MainWrapper } from "./style";

const CorrelationGraph = ({ data }) => {
  // Reference to the DOM element for rendering the graph
  const graphRef = useRef(null);

  function calculateCorrelation(arrayX, arrayY) {
    const n = arrayX.length;

    const meanX = arrayX.reduce((sum, x) => sum + x, 0) / n;
    const meanY = arrayY.reduce((sum, y) => sum + y, 0) / n;

    const covariance =
      arrayX.reduce((sum, x, i) => sum + (x - meanX) * (arrayY[i] - meanY), 0) /
      n;

    const stdDevX = Math.sqrt(
      arrayX.reduce((sum, x) => sum + Math.pow(x - meanX, 2), 0) / n
    );
    const stdDevY = Math.sqrt(
      arrayY.reduce((sum, y) => sum + Math.pow(y - meanY, 2), 0) / n
    );

    return covariance / (stdDevX * stdDevY);
  }

  const initGraph = () => {
    let newData = data?.map((el) => {
      return {
        lead_calls_perc: Number(el?.lead_calls_perc),
        booking_rate: Number(el?.booking_rate),
        closing_perc: Number(el?.closing_perc),
        unbooked_rate: Number(el?.unbooked_rate),
        excused_rate: Number(el?.excused_rate),
        abandon_rate: Number(el?.abandon_rate),
        cancellation_rate: Number(el?.cancellation_rate),
      };
    });

    const features = Object.keys(newData[0]);
    const featureData = features.map((feature) =>
      newData.map((row) => row[feature])
    );

    const correlationMatrix = features.map((_, i) =>
      features.map((_, j) =>
        calculateCorrelation(featureData[i], featureData[j])
      )
    );

    // Labels for the axes
    const labels = [
      "Lead %",
      "Booking %",
      "Closing %",
      "Unbooked %",
      "Excused %",
      "Abandon %",
      "Cancellation %",
    ];

    // Create a heatmap trace
    const trace = {
      z: correlationMatrix,
      x: labels,
      y: labels,
      type: "heatmap",
      colorscale: "RdBu", // Red to blue color scale
      zmin: -1,
      zmax: 1,
    };

    // Layout configuration
    const layout = {
      // title: "Correlation Graph",
      xaxis: { fixedrange: true }, // Disable zoom on x-axis
      yaxis: { fixedrange: true }, // Disable zoom on y-axis
      margin: { l: 100, r: 50, t: 50, b: 100 },
    };

    // Configuration to disable top-right menu and mouse events
    const config = {
      displayModeBar: false,
      scrollZoom: false,
      responsive: true,
    };

    // Render the plot
    if (graphRef.current) {
      Plotly.newPlot(graphRef.current, [trace], layout, config);
    }
  };

  useEffect(() => {
    data?.length > 0 && initGraph();
  }, [data]);
  return (
    <MainWrapper>
      <div className="sub-header">
        <sub>Correlation Between KPI's</sub>
      </div>
      <div
        ref={graphRef}
        style={{ width: "700px", height: "500px", margin: "0 auto" }}
      ></div>
    </MainWrapper>
  );
};

export default CorrelationGraph;
