import { Drawer, Modal, Select, Table, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getreportdata } from "../../services/Collections";
import { useSelector } from "react-redux";
import NoDataFound from "../../components/NoDataFound";
import LoadingBlock from "../../components/LoadingBlock";
import Watermark from "../../components/Watermark";
import { SearchComponent } from "../../components/SearchComponent";
import { formatUSPhoneNumber } from "../../utils/common/commonFunction";
import QualityFilter from "./QualityFilter";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { FaPlayCircle } from "react-icons/fa";

function Report({ openDrawer, setOpenDrawer }) {
  const preClient = useSelector(
    (state) => state?.loginAuth?.prefilledFilters?.selectedClient
  );
  const qualityClient = useSelector(
    (state) => state?.qualityAssurance?.selectedClient
  );
  const client = qualityClient || preClient;

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [pagesize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [totalPagereport, setTotalPagereport] = useState(0);
  const [selectDuration, setSelectDuration] = useState("");
  const ApiUrl = process.env.REACT_APP_EXTERNAL_BASEURL;

  console.log(selectDuration, "selectDuration");

  const statusOptions = [
    { value: "queued", label: "Queued" },
    { value: "ringing", label: "Ringing" },
    { value: "in-progress", label: "In Progress" },
    { value: "completed", label: "Completed" },
    { value: "busy", label: "Busy" },
    { value: "no-answer", label: "No Answer" },
    { value: "canceled", label: "Canceled" },
    { value: "failed", label: "Failed" },
  ];

  const Durationselectdata = [
    { value: "0,29", label: "Less than 30 seconds", min: 0, max: 29 },
    { value: "30,60", label: "30 to 60 seconds", min: 30, max: 60 },
    { value: "61,90", label: "61 to 90 seconds", min: 61, max: 90 },
    { value: "91, 120", label: "91 to 120 seconds", min: 91, max: 120 },
    { value: "121,150", label: "121 to 150 seconds", min: 121, max: 150 },
    { value: "151,180", label: "151 to 180 seconds", min: 151, max: 180 },
    {
      value: "181,3600",
      label: "Greater than 180 seconds",
      min: 181,
      max: 3600,
    },
  ];
  const [modal, setModal] = useState(null);
  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial_number",
      width: 50,
      render: (text, record, index) => index + 1 + (currentPage - 1) * pagesize,
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
      width: 100,
      fixed: "left",
      render: (text) => formatUSPhoneNumber(text),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: 100,
    },
    {
      title: "Response",
      dataIndex: "response",
      width: 100,
      render: (text) => (
        <Tooltip placement="top" title={text}>
          <p className="toottip">{text}</p>
        </Tooltip>
      ),
    },
    {
      title: "Audio",
      dataIndex: "",
      width: 60,
      align: "center",
      render: (payload) => (
        <>
          {payload?.audio_link ? (
            <i onClick={() => setModal(payload)}>
              <FaPlayCircle size={25} />
            </i>
          ) : (
            <div>N/A</div>
          )}
        </>
      ),
    },
    {
      title: "Tag",
      dataIndex: "ai_tag",
      width: 100,
      render: (text) => (text ? text : "-"),
    },
  ];

  const CustomAudioPlayer = ({ payload }) => {
    console.log(payload, "payload");
    const audioRef = useRef();
    const clientid = client?.key;
    const id = payload?.id;
    const audioURL = `${ApiUrl}api/v1/clients/${clientid}/qa/phone-autocheck/logs/download?id=${id}`;

    // const audioURL = 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3';
    return (
      <AudioWrapper>
        <p style={{ margin: 0, fontWeight: 500 }}>Phone Number</p>
        <p>{formatUSPhoneNumber(payload?.phone_number)}</p>
        {payload?.audio_link ? (
          <AudioPlayer
            ref={audioRef}
            src={audioURL}
            showJumpControls={false}
            showSkipControls={false}
            showVolumeControls={false}
            customAdditionalControls={[]}
            layout="horizontal"
            customVolumeControls={[]}
            autoPlayAfterSrcChange={false}
            autoPlay
          />
        ) : (
          <div>N/A</div>
        )}
      </AudioWrapper>
    );
  };

  // export default AudioPlayer;

  const audioPlayer = (payload) => {
    const clientid = "65a8777c314f083e0527578d0a737ee4";
    const id = "CAf8f60c7b570b0db92f2c25acf9d68099";
    const a = `https://7ynq2yi5oangxfamqhu62xm6k40dnehg.lambda-url.us-east-2.on.aws/api/v1/clients/${clientid}/qa/phone-autocheck/logs/download?id=${id}`;
    const audio = new Audio(a);
    audio.play();
  };

  const handleReportTable = async (page, page_size) => {
    let pageNo = page || currentPage;
    let limit = page_size || pagesize;
    setLoading(true);
    let params = new URLSearchParams();
    params.append("page", Number(pageNo));
    params.append("page_size", Number(limit));
    searchWord && params.append("search_phone", searchWord);
    selectedStatus && params.append("status", selectedStatus);
    selectDuration &&
      params.append("duration_min", selectDuration?.split(",")?.[0]);
    selectDuration &&
      params.append("duration_max", selectDuration?.split(",")?.[1]);

    const res = await getreportdata(client?.key, params);
    if (res?.status === 200 && res?.data?.length > 0) {
      setDataSource(res.data);
      setTotalPagereport(res?.total);
      setOpenDrawer(false);
      setLoading(false);
    } else {
      setDataSource([]);
      setTotalPagereport(0);
      setOpenDrawer(false);
      setLoading(false);
    }
  };

  const onChangePagination = (e, limit) => {
    setCurrentPage(e);
    setPageSize(limit);
    handleReportTable(e, limit);
  };

  const handledurationselect = async (text) => {
    setSelectDuration(text);
  };

  const handleSearchReport = async (text) => {
    setSearchWord(text);
  };

  const handleChange = (value) => {
    setSelectedStatus(value);
  };

  useEffect(() => {
    setCurrentPage(1);
    handleReportTable(1, pagesize);
  }, [searchWord, selectedStatus, client, selectDuration]);

  return (
    <>
      <WrapperReport>
        <div className="search-select-wrap">
          {openDrawer && (
            <Drawer
              className="filter-drawer"
              placement="right"
              closable={false}
              onClose={() => setOpenDrawer(false)}
              open={openDrawer}
              width={"400px"}
              key="bottom"
            >
              <QualityFilter paidLoading={loading} />
            </Drawer>
          )}

          <SearchComponent
            onChange={handleSearchReport}
            delayTime={1000}
            value={searchWord}
            style={{ padding: "5px" }}
          />
          <div className="select-wrap-div">
            <Select
              placeholder="Select Duration"
              style={{ width: 200, background: "#fff", borderRadius: "8px" }}
              onChange={handledurationselect}
              allowClear
              disabled={loading}
              prefixCls="inner-select2"
            >
              {Durationselectdata.map((status) => (
                <Option key={status.value} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Select Status"
              style={{ width: 200, background: "#fff", borderRadius: "8px" }}
              onChange={handleChange}
              allowClear
              disabled={loading}
              prefixCls="inner-select2"
            >
              {statusOptions.map((status) => (
                <Option key={status.value} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        {loading ? (
          <LoadingBlock height={"calc(100vh - 217px)"} size={28} />
        ) : dataSource.length === 0 ? (
          <NoDataFound
            height={"calc(100vh - 222px)"}
            imageSize={150}
            message={"NO DATA FOUND"}
          />
        ) : (
          <div style={{ height: "calc(100vh - 217px)" }}>
            <Table
              prefixCls="custom-ui-table"
              columns={columns}
              dataSource={dataSource}
              pagination={{
                hideOnSinglePage: true,
                current: currentPage,
                pageSize: pagesize,
                total: totalPagereport,
                onChange: onChangePagination,
                // showSizeChanger: true,
                defaultPageSize: pagesize,
                className: "custom-ui-pagination",
              }}
              scroll={{
                x: "100%",
                y: "calc(100vh - 320px)",
              }}
            />
          </div>
        )}
        <Modal
          width={300}
          prefixCls="custom"
          centered
          open={modal}
          onCancel={() => setModal(null)}
          destroyOnClose
          footer={false}
          closable={false}
        >
          <CustomAudioPlayer payload={modal} />
        </Modal>
        {!loading && (
          <Watermark
            style={{
              width: "fit-content",
              position: "absolute",
              left: "10px",
              bottom: "-12px",
              justifyContent: "start",
            }}
          />
        )}
      </WrapperReport>
    </>
  );
}

export default Report;

const WrapperReport = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 20px;
  position: relative;

  .search-select-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .toottip {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .select-wrap-div {
    display: flex;
    gap: 8px;
  }
`;

const AudioWrapper = styled.div`
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 18px;
    color: #000;
    text-align: center;
  }
  .rhap_container {
    background-color: #e3e2e2;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    padding: 5px;
    margin-top: 20px;
  }
  .rhap_progress-bar {
    cursor: not-allowed;
    background-color: #dcdcdc;
  }
  .rhap_progress-filled {
    background-color: #1890ff;
  }
  .rhap_download-progress {
    background-color: #000000;
  }
  .rhap_time {
    color: #666;
  }
`;
