import { ConfigProvider, DatePicker } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
const { RangePicker } = DatePicker;

const theme = {
  backgroundGray: "black",
  lightWhite: "#000",
  primaryColor: "#fff",
  lightText: "#000",
  greyText: "#a4a4a4",
};

const RangeSelector = ({
  defaultDate = [],
  handleChange,
  disabled = false,
  bgColor,
  allowClear = false,
  style = {},
  picker
}) => {
  const pathname = window && window.location.pathname;
  console.log(pathname, "pathname")
  const [startDate, setStartDate] = useState(defaultDate[0] ? dayjs(defaultDate[0]) : null);
  const [endDate, setEndDate] = useState(defaultDate[1] ? dayjs(defaultDate[1]) : null);
  const dateFormat = picker ? 'YYYY-MM' : "YYYY-MM-DD";
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );

  // const handleDateChangeVisualization = (e, value) => {
  //   console.log(value)
  //   handleChange(value);
  //   setStartDate(value[0]);
  //   setEndDate(value[1]);
  // };

  const handleDateChangeVisualization = (e, value) => {
    console.log(value, 'valuevaluevaluevaluevalue')

    const [newStartDate, newEndDate] = value;

    // If startDate is updated, ensure endDate is within 13 months of startDate
    if (newStartDate) {
      const maxEndDate = dayjs(newStartDate).add(14, "month");

      // If endDate is beyond the range, adjust it
      if (newEndDate && dayjs(newEndDate).isAfter(maxEndDate, "day")) {
        setEndDate(dayjs(maxEndDate).format(dateFormat));
        handleChange([dayjs(newStartDate).format(dateFormat), dayjs(maxEndDate).format(dateFormat)]); // Trigger the change handler
      } else {
        setEndDate(dayjs(newEndDate).format(dateFormat));
        handleChange([dayjs(newStartDate).format(dateFormat), dayjs(newEndDate).format(dateFormat)]); // Trigger the change handler

      }

      setStartDate(dayjs(newStartDate).format(dateFormat)); // Update startDate
    } else {
      setEndDate(dayjs(newEndDate).format(dateFormat)); // Update endDate if only it changes
      handleChange(value);
    }
  };

  //  Function to disable dates more than 13 months after the start date
  const disabledDate = (current) => {
    // Ensure startDate is a dayjs object
    if (!startDate) return false;

    const maxDate = dayjs(startDate).add(14, 'month'); // Add 13 months to the start date
    // Disable dates that are more than 13 months after the start date
    return current.isAfter(maxDate, 'day');
  };

  const handleDateChange = (e, value) => handleChange(value);
  return (
    // pathname == "/private-layout/visualization" ? <ConfigProvider
    //   selectedThemeColors={selectedThemeColors}
    //   theme={{
    //     token: {
    //       colorBgElevated: "#fff", // container background
    //       colorSplit: theme.lightWhite,
    //       colorTextQuaternary: theme.lightText, // previous/next month date color
    //     },
    //     components: {
    //       DatePicker: {
    //         colorBgContainer: bgColor ?? theme.primaryColor, // used to change the bg color of datepicker-field
    //         colorText: theme.lightWhite,
    //         colorTextPlaceholder: theme.lightText,
    //         colorBorder: theme.primaryColor,
    //         cellActiveWithRangeBg: theme.primaryColor, // background for selected range
    //         colorTextHeading: theme.greyText, // heading color in calendar
    //         colorPrimary: "#000",
    //         colorPrimaryHover: "#000",
    //         colorIcon: theme.lightWhite,
    //         colorIconHover: theme.lightWhite,
    //       },
    //     },
    //   }}
    // >
    //   <DatePickerWrapper
    //     selectedThemeColors={selectedThemeColors}
    //     format={dateFormat}
    //     value={[
    //       startDate ? dayjs(startDate, dateFormat) : null,
    //       endDate ? dayjs(endDate, dateFormat) : null,
    //     ]}
    //     allowClear={false}
    //     suffixIcon={null}
    //     onChange={handleDateChangeVisualization}
    //     onCalendarChange={(value) => {
    //       if (value && value[0]) {
    //         setStartDate(dayjs(value[0])); // Ensure we set startDate as a dayjs object
    //       }
    //     }} // Ensure the startDate is updated on calendar change
    //     disabled={disabled}
    //     popupClassName="range-picker-popup"
    //     style={style}
    //     picker={picker}
    //     disabledDate={disabledDate} // Apply the disabledDate logic
    //   />
    // </ConfigProvider> :
    <ConfigProvider
      selectedThemeColors={selectedThemeColors}
      theme={{
        token: {
          colorBgElevated: "#fff", // container background
          colorSplit: theme.lightWhite,
          colorTextQuaternary: theme.lightText, // previous/next month date color
        },
        components: {
          DatePicker: {
            colorBgContainer: bgColor ?? theme.primaryColor, // used to change the bg color of datepicker-field
            // colorBgContainerDisabled: bgColor ?? theme.primaryColor, // used to change the bg color of datepicker-field
            colorText: theme.lightWhite,
            colorTextPlaceholder: theme.lightText,
            colorBorder: theme.primaryColor,
            cellActiveWithRangeBg: theme.primaryColor, //"#ebf4f8", // used to show the selected range background
            colorTextHeading: theme.greyText, // used to change the heading color in calender
            colorPrimary: "#000",
            colorPrimaryHover: "#000",
            colorIcon: theme.lightWhite,
            colorIconHover: theme.lightWhite,
          },
        },
      }}
    >
      <DatePickerWrapper
        selectedThemeColors={selectedThemeColors}
        format={dateFormat}
        defaultValue={[
          defaultDate[0] ? dayjs(defaultDate[0], dateFormat) : null,
          defaultDate[1] ? dayjs(defaultDate[1], dateFormat) : null,
        ]}
        allowClear={allowClear}
        suffixIcon={null}
        onChange={handleDateChange}
        disabled={disabled}
        popupClassName="range-picker-popup"
        style={style}
        picker={picker}
      />
    </ConfigProvider>
  );
};

export default RangeSelector;

const DatePickerWrapper = styled(RangePicker)`
  width: 100%;
  height: 48px;
  padding: 8px !important;
  background: #fff !important;
  border: 1px solid rgba(206, 206, 206, 1) !important;
  .ant-empty-description {
    color: #000 !important;
  }
  .ant-picker-input {
    width: 40%;
    margin-bottom: 0px !important;
  }
  .ant-picker-separator {
    color: #fff !important;
  }
  .ant-picker-range-separator {
    width: 20%;
    display: flex;
    justify-content: center;
    filter: invert(1) !important;
  }
  .ant-picker-input > input {
    color: #0f1011 !important;

    text-align: center;
  }
  .ant-picker .ant-picker-input > input-disabled:hover {
    border: none !important;
    background-color: none !important;
  }
  .ant-picker .ant-picker-input > input[disabled]:hover {
    border: none !important;
    background-color: none !important;
  }

  .ant-picker-clear {
    background-color: transparent !important;
  }
`;
