import { Modal } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

export function ScriptModal({ open, handleClose, editDetails }) {
  const [copied, setCopied] = useState(false);
  const colorCode = editDetails?.color ? `"${editDetails?.color}"` : null;
  const code = `<script>
      var chatBoxTitle = "${editDetails?.name}"
      var key = "${editDetails?.uuid}"
      var chatBoxPrimaryColor = ${colorCode}
      var chatLauncherName = "Chat with Us"
</script>
<script src="https://d19knm1tav3rtt.cloudfront.net"></script>
`;
  const handleCopy = () => {
    navigator?.clipboard?.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };
  return (
    <Modal
      open={open}
      onCancel={handleClose}
      prefixCls="code-modal"
      width={490}
      centered
      footer={false}
    >
      <Container>
        <div className="embedded-code">
          <div
            style={{
              backgroundColor: "#fff",
              padding: "10px",
              borderRadius: "10px",
              marginBottom: "5px",
              border: "1px solid #cecece",
              //   position: "relative",
            }}
          >
            <pre
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                margin: "0",
                color: "#000",
              }}
            >
              {code}
            </pre>
          </div>
        </div>
        <div className="button-box">
          <button
            onClick={handleCopy}
            className="copy-button"
            style={{ alignSelf: "flex-end" }}
          >
            {copied ? "Copied!" : "Copy"}
          </button>
        </div>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 10px;
  background: #f2f5fd;
  border-radius: 8px;
  border: 1px solid #cecece;

  .embedded-code {
    width: 95%;
    margin: auto;
    margin-top: 30px !important;
    margin-bottom: 2px !important;
  }
  .button-box {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 10px;
  }
  .copy-button {
    background-color: red;
    color: #ffffff;
    border: none;
    font-family: "Poppins";
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .copy-button:hover {
    background-color: #ff0000d1;
  }
`;
