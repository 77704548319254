import React from "react";
import {
  CartesianGrid,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  ComposedChart,
} from "recharts";
import { GraphWrapper, MainWrapper } from "./style";

const LineBarChart = ({ data }) => {
  return (
    <MainWrapper>
      <div className="sub-header">
        <sub>Avg Ticket and Cancellation %</sub>
      </div>
      <GraphWrapper className="graph-section">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={data}
            margin={{ top: 20, right: 30, left: 40, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="2 2"/>
            <YAxis
              label={{
                value: "Value",
                angle: -90,
                position: "insideLeft",
                offset: -10,
              }}
            />
            <XAxis
              dataKey="monthName"
            //   label={{
            //     value: "Month",
            //     position: "insideBottom",
            //     offset: -20,
            //   }}
            />
            <Tooltip />
            <Legend
              layout="horizontal"
              verticalAlign="bottom"
              align="center"
              wrapperStyle={{ paddingTop: 20 }}
            />
            <Line
              name="Avg Ticket"
              type="linear"
              dataKey="avg_ticket"
              stroke="#198754"
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
            <Bar
              name="Cancellation %"
              dataKey="cancellation_rate"
              barSize={60}
              fill="#f8a4b3"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </GraphWrapper>
    </MainWrapper>
  );
};

export default LineBarChart;
