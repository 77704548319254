import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Checkbox, DatePicker, message, Select } from "antd";
import {
  businessUnitCategories,
  businessUnitSubCategories,
  getListingGoalPredictionApi,
  predictionDefaultValue,
  proposalsHistoryApi,
  proposalsListingApi,
} from "../../services/Collections";
import { useDispatch, useSelector } from "react-redux";
import { getRandomColor } from "../../utils/common/commonFunction";
import moment from "moment";
import { CalendarDays } from "lucide-react";
import YearComponent from "./Year/YearComponent";
import {
  calculateTotals,
  predictionCalculator,
  previousYearGoalCalculator,
  reCalculatingCurrent,
} from "./Year/commonFuc";
import {
  activeHistorySheet,
  activeHistorySheetData,
  updateGoalPrediction,
  updatePredicationLabelValue,
  updateYearData,
  updateYearSheetFilters,
} from "../exceltable/ExcelStore";
import dayjs from "dayjs";
import LoadingBlock, { LoaderIcon } from "../../components/LoadingBlock";
import { months } from "./finalReport/FinalReport";
const { Option } = Select;
export const PickerWithType = ({ type, onChange, value }) => {
  return <DatePicker picker={type} value={value} onChange={onChange} />;
};

const sortByFilter = [
  // {
  //   label: "Budget  A-Z",
  //   value: "budget-asc",
  //   option: { name: "budget", type: "asc" },
  // },
  {
    label: "Budget",
    value: "budget-desc",
    option: { name: "budget", type: "desc" },
  },

  // {
  //   label: "Sales  A-Z",
  //   value: "sales-asc",
  //   option: { name: "sales", type: "asc" },
  // },
  {
    label: "Revenue",
    value: "sales-desc",
    option: { name: "sales", type: "desc" },
  },

  // {
  //   label: "Roas  A-Z",
  //   value: "roas-asc",
  //   option: { name: "roas", type: "asc" },
  // },
  {
    label: "Roas",
    value: "roas-desc",
    option: { name: "roas", type: "desc" },
  },

  // {
  //   label: "Leads  A-Z",
  //   value: "leads-asc",
  //   option: { name: "leads", type: "asc" },
  // },
  {
    label: "Leads",
    value: "leads-desc",
    option: { name: "leads", type: "desc" },
  },

  // {
  //   label: "Appts  A-Z",
  //   value: "appts-asc",
  //   option: { name: "appts", type: "asc" },
  // },
  {
    label: "Appts",
    value: "appts-desc",
    option: { name: "appts", type: "desc" },
  },

  {
    label: "Cost per Appts",
    value: "costappts-asc",
    option: { name: "cpa", type: "asc" },
  },
  // {
  //   label: "Cost per Appts",
  //   value: "costappts-desc",
  //   option: { name: "cpa", type: "desc" },
  // },

  // {
  //   label: "Sold Jobs  A-Z",
  //   value: "soldjobs-asc",
  //   option: { name: "jobs", type: "asc" },
  // },
  {
    label: "Sold Jobs",
    value: "soldjobs-desc",
    option: { name: "jobs", type: "desc" },
  },

  {
    label: "Cost per Sold Jobs",
    value: "costsoldjob-asc",
    option: { name: "cpj", type: "asc" },
  },
  // {
  //   label: "Cost per Sold Jobs",
  //   value: "costsoldjob-desc",
  //   option: { name: "cpj", type: "desc" },
  // },

  {
    label: "CPL",
    value: "cpl-asc",
    option: { name: "cpl", type: "asc" },
  },
  // {
  //   label: "CPL",
  //   value: "cpl-desc",
  //   option: { name: "cpl", type: "desc" },
  // },

  // {
  //   label: "Conv%  A-Z",
  //   value: "conv-asc",
  //   option: { name: "con_per", type: "asc" },
  // },
  {
    label: "Conv%",
    value: "conv-desc",
    option: { name: "con_per", type: "desc" },
  },

  // {
  //   label: "Close%  A-Z",
  //   value: "close-asc",
  //   option: { name: "close_per", type: "asc" },
  // },
  {
    label: "Close%",
    value: "close-desc",
    option: { name: "close_per", type: "desc" },
  },

  // {
  //   label: "Avg Ticket  A-Z",
  //   value: "avgticket-asc",
  //   option: { name: "avg_ticket", type: "asc" },
  // },
  {
    label: "Avg Ticket",
    value: "avgticket-desc",
    option: { name: "avg_ticket", type: "desc" },
  },
];
let monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function YearSheet({
  handleUnique,
  yearsheetData,
  setYearsheetData,
  yearSheetFilter,
}) {
  const formRef = useRef(null);
  const prefilledFilters = useSelector(
    (state) => state?.loginAuth?.summarySectionFilter
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const yearSheetFilters = useSelector(
    (state) => state?.dynamicBudgetSheet?.yearSheetFilters
  );
  const activeClient =
    prefilledFilters == null
      ? prefilledData?.selectedClient?.key
      : prefilledFilters?.selectedClient?.key;
  const loginAuth = useSelector((state) => state?.loginAuth);
  const currentDateTime = moment().toISOString();
  const [categoriesListing, setCategoriesListing] = useState([]);
  const [subCategoriesListing, setSubCategoriesListing] = useState([]);
  const [catLoading, setCatLoading] = useState(false);
  const [subCatLoading, setSubCatLoading] = useState(false);
  const [graphsLoading, setGraphsLoading] = useState(false);
  const [graphsData, setGraphsData] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [activeBudgetSheet, setActiveBudgetSheet] = useState("current");
  const [submitData, setSubmitData] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [yearTotal, setYearTotal] = useState([]);
  const [falseLoading, setFalseLoading] = useState(false);
  const [goalsCal, setGoalsCal] = useState({ budget: 0, sales: 0 });
  const [sortBy, setSortBy] = useState(null);
  const dispatch = useDispatch();
  const persistYearData = useSelector(
    (state) => state?.dynamicBudgetSheet?.yearData
  );
  const currentYear = dayjs().year();
  const selectedYear = yearSheetFilters?.year || null;
  const uniqueSelectedMonths = yearSheetFilters?.uniqueMonths;
  const [noDataFound, setNoDataFound] = useState(false);

  const onYearChange = (year) => {
    if (!year) {
      dispatch(updateYearSheetFilters({ ...yearSheetFilters, year: null }));
      return;
    }
    dispatch(
      updateYearSheetFilters({ ...yearSheetFilters, year: Number(year) })
    );
    dispatch(updateYearData());
  };

  const onMonthChange = (date) => {
    if (date == null || date == undefined) {
      dispatch(updateYearSheetFilters({ ...yearSheetFilters, month: null }));
      return;
    }
    dispatch(updateYearSheetFilters({ ...yearSheetFilters, month: date }));
    dispatch(updateYearData());
  };

  const handleChangeCategory = (value) => {
    dispatch(updateYearSheetFilters({ ...yearSheetFilters, category: value }));
    dispatch(updateYearData());
  };

  const handleChangeSubCategory = (value) => {
    dispatch(
      updateYearSheetFilters({ ...yearSheetFilters, subCategory: value })
    );
    dispatch(updateYearData());
  };

  const handleNewCustomer = (value) => {
    dispatch(
      updateYearSheetFilters({
        ...yearSheetFilters,
        newCustomer: value.target.checked ? "new" : "all",
      })
    );
    dispatch(updateYearData());
  };

  const getCategoriesListing = async () => {
    try {
      setCatLoading(true);
      let res = await businessUnitCategories(activeClient);
      if (res?.status === 200) {
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.name,
          label: ele?.name,
        }));
        setCategoriesListing(updateData);
        setCatLoading(false);
      } else {
        message.error("Something went wrong");
        setCatLoading(false);
      }
    } catch (error) {
      setCatLoading(false);
      console.log("error in businessUnitCategories", error);
    }
  };

  const getSubCategoriesListing = async () => {
    try {
      setSubCatLoading(true);
      let res = await businessUnitSubCategories(activeClient);
      if (res?.status === 200) {
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.name,
          label: ele?.name,
        }));
        setSubCategoriesListing(updateData);
        setSubCatLoading(false);
      } else {
        message.error("Something went wrong");
        setSubCatLoading(false);
      }
    } catch (error) {
      setSubCatLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  const applyFilters = async () => {
    dispatch(updateGoalPrediction(null));
    dispatch(updateYearData(null));

    setYearsheetData([]);
    let result = false;
    let totalBudget = 0;
    let totalSales = 0;

    if (selectedYear) {
      setGraphsLoading(true);
      let params = new URLSearchParams();
      params.append("user_id", loginAuth?.data?.id);
      params.append("client_key", activeClient);
      params.append("year", selectedYear);
      params.append("customer_type", yearSheetFilters?.newCustomer ?? "all");
      if (
        yearSheetFilters?.category &&
        yearSheetFilters?.category?.length > 0
      ) {
        params.append("bu_category", yearSheetFilters?.category);
      }
      if (
        yearSheetFilters?.subCategory &&
        yearSheetFilters?.subCategory?.length > 0
      ) {
        params.append("bu_subcategory", yearSheetFilters?.subCategory);
      }
      if (yearSheetFilters?.month) {
        params.append("month", yearSheetFilters?.month);
      }

      // let res = await getListingGoalPredictionApi(activeClient, params);
      let res = await proposalsListingApi(activeClient, params);

      if (res?.status === 200) {
        if (res?.data?.projection?.length > 0) {
          let reCalculatingResult = null;
          // Get future year or month data
          // ***** To Calculate the current - pacing data *****
          if (yearSheetFilters?.year > dayjs().year()) {
            reCalculatingResult = await reCalculatingCurrent(
              res?.data?.last14day,
              yearSheetFilters?.month,
              selectedYear,
              undefined
            );
          }

          // Get current year data
          else if (
            yearSheetFilters?.year === dayjs().year() &&
            !yearSheetFilters?.month
          ) {
            reCalculatingResult = await reCalculatingCurrent(
              res?.data?.last14day,
              undefined,
              selectedYear,
              res?.data?.current
            );
          }
          // Get current month data of current year
          else if (
            yearSheetFilters?.year === dayjs().year() &&
            yearSheetFilters?.month >= dayjs().month() + 1
          ) {
            reCalculatingResult = await reCalculatingCurrent(
              res?.data?.last14day,
              yearSheetFilters?.month,
              selectedYear,
              res?.data?.current
            );
          }
          let currentData = reCalculatingResult ?? res?.data?.current;
          // ***** END *****

          // ###### To Calculate the GOAL Data ######
          let goalData = res?.data?.projection;
          let isFlag = true;
          // isFlag true means goal data will be calculate from goal table! ELSE It will be calculate from the projection table
          if (Array.isArray(goalData?.[0])) {
            // To calculate the goal data from newly saved projection!
            isFlag = false;
            dispatch(
              updateYearData({ categories: goalData?.[0], name: "prev" })
            );
          }

          let monthKeys = yearSheetFilters?.month
            ? monthNames?.filter(
                (el, index) => index == yearSheetFilters?.month - 1
              )
            : monthNames;

          if (isFlag) {
            totalBudget = goalData[0]?.expected_budget_sheet?.nextData?.reduce(
              (acc, el) => {
                return monthKeys?.includes(el?.month) ? acc + el?.budget : acc;
              },
              0
            );
            totalSales = goalData[0]?.expected_budget_sheet?.nextData?.reduce(
              (acc, el) => {
                return monthKeys?.includes(el?.month) ? acc + el?.revenue : acc;
              },
              0
            );
          } else {
            totalBudget = goalData[0]?.reduce((acc, el) => acc + el?.budget, 0);
            totalSales = goalData[0]?.reduce((acc, el) => acc + el?.sales, 0);
          }

          let payload = {
            budget: totalBudget,
            sales: totalSales,
            year: selectedYear,
          };
          dispatch(updateGoalPrediction(payload));

          // Current Handling
          // if (currentData === 0) {
          //   setNoDataFound(true);
          //   setGraphsLoading(false);
          //   return;
          // }

          let willAddedKey = currentData?.map((cat) => {
            const baseValues = {
              isChecked: false,
              isParent: true,
              showUi: false,
            };
            return {
              ...cat,
              ...baseValues,
            };
          });

          if (isFlag) {
            // Here we are fetching data for the previous year,It means user saved predetation data!
            let previousYearData = await fetchingPrevyearData();
            console.log(previousYearData, "previous");
            const goalprev = previousYearGoalCalculator(
              totalBudget,
              totalSales,
              previousYearData
            );

            // for (let i = 0; i < willAddedKey.length; i++) {
            //   const currentObject = willAddedKey[i];
            //   let item = {};
            //   let result = predictionCalculator(
            //     totalBudget,
            //     totalSales,
            //     categorySum,
            //     currentObject,
            //     previousYearData
            //   );
            //   item.budget = result.budget;
            //   item.sales = result.sales;
            //   item.roas = result.roas;
            //   item.jobs = result.jobs;
            //   item.appts = result.appts;
            //   item.leads = result.leads;
            //   item.cpa = result.cpa;
            //   item.cpa = result.cpa;
            //   item.cpj = result.cpj;
            //   item.cpl = result.cpl;
            //   item.isChecked = true;
            //   item.isParent = false;
            //   item.isDynaicBudget = true;
            //   arr.push({ ...currentObject, ...item });
            // }
            dispatch(updateYearData({ categories: [goalprev], name: "prev" }));
          }

          dispatch(
            updateYearData({ categories: willAddedKey, name: "current" })
          );
          setYearsheetData(willAddedKey);
          setGraphsLoading(false);
        } else {
          message.error(
            "Please define the goal before proceeding with the dynamic budget sheet."
          );
          setGraphsLoading(false);
        }
      } else {
        setGraphsLoading(false);
        message.error("Something went wrong");
      }
    }

    if (result) {
      dispatch(activeHistorySheetData(null));
      dispatch(activeHistorySheet(null));
    }
  };

  const fetchingPrevyearData = async () => {
    let params = new URLSearchParams();
    params.append("user_id", loginAuth?.data?.id);
    params.append("client_key", activeClient);
    params.append("year", currentYear - 1);
    params.append("customer_type", yearSheetFilters?.newCustomer ?? "all");
    if (yearSheetFilters?.category && yearSheetFilters?.category?.length > 0) {
      params.append("bu_category", yearSheetFilters?.category);
    }
    if (
      yearSheetFilters?.subCategory &&
      yearSheetFilters?.subCategory?.length > 0
    ) {
      params.append("bu_subcategory", yearSheetFilters?.subCategory);
    }
    if (yearSheetFilters?.month) {
      params.append("month", yearSheetFilters?.month);
    }
    let res = await proposalsListingApi(activeClient, params);
    if (res.status === 200) {
      if (res?.data?.current) {
        const data = calculateTotals(res?.data?.current);
        return data;
      }
    }
  };

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  useEffect(() => {
    setCategoriesListing([]);
    setSubCategoriesListing([]);

    if (activeClient) {
      getCategoriesListing();
      getSubCategoriesListing();
    }
  }, [activeClient]);

  return (
    <YearSheetWrapper>
      {/* <button onClick={testing} >TESTING</button> */}
      <FilterTabsWrapper position={yearSheetFilter}>
        {yearSheetFilter && (
          <div className="wrap">
            <section>
              <div className="label">
                Select Year & Month <span>*</span>
              </div>
              <div className="flex">
                <DatePicker
                  picker="year"
                  value={selectedYear ? dayjs().year(selectedYear) : null}
                  onChange={(_, dateString) => onYearChange(dateString)}
                  disabledDate={(date) =>
                    date.year() > currentYear + 1 ||
                    date.year() < currentYear - 1
                  }
                />
                <Select
                  placeholder="Select month"
                  prefixCls="inner-select2"
                  className="select"
                  onChange={onMonthChange}
                  value={yearSheetFilters?.month || null}
                  size="middle"
                  allowClear
                >
                  {months?.map((item, i) => (
                    <Option key={item?.value} value={item?.value}>
                      <span
                        style={{
                          color:
                            uniqueSelectedMonths?.includes(item?.value) &&
                            item?.value != yearSheetFilters?.month &&
                            "green",
                        }}
                      >
                        {item?.label}
                      </span>
                    </Option>
                  ))}
                </Select>
              </div>
            </section>
            <section>
              <div className="label">Category</div>
              <Select
                placeholder="Please select"
                prefixCls="inner-select2"
                className="select"
                loading={catLoading}
                onChange={handleChangeCategory}
                options={categoriesListing}
                value={yearSheetFilters?.category || []}
                mode="multiple"
                size="middle"
                maxTagCount={2}
              />
            </section>
            <section>
              <div className="label">Sub-Category</div>
              <Select
                placeholder="Please select"
                prefixCls="inner-select2"
                className="select"
                loading={subCatLoading}
                onChange={handleChangeSubCategory}
                options={subCategoriesListing}
                value={yearSheetFilters?.subCategory || []}
                mode="multiple"
                maxTagCount={2}
              />
            </section>

            <section className="new-customers">
              <div className="label">New Customers</div>
              <Checkbox
                style={{ height: "32px" }}
                checked={yearSheetFilters?.newCustomer == "new" ? true : false}
                onChange={(e) => handleNewCustomer(e)}
              />
            </section>

            <button
              className="saveBtn submit"
              onClick={() => !graphsLoading && applyFilters()}
            >
              {graphsLoading ? <LoaderIcon size="14" /> : "Submit"}
            </button>
          </div>
        )}
      </FilterTabsWrapper>
      {graphsLoading ? (
        <LoadingBlock
          height={
            yearSheetFilter ? "calc(100vh - 216px)" : "calc(100vh - 130px)"
          }
          size={28}
        />
      ) : (
        <>
          <div className="historyWrapper">
            {(persistYearData?.current?.length > 0 &&
              persistYearData?.prediction?.length > 0) ||
            (persistYearData?.current?.length > 0 &&
              yearsheetData &&
              yearsheetData?.length > 0) ? (
              <>
                <MainWrapperYear>
                  <section className="left">
                    {/* <div className="all-tabs">
                      <button
                        className={activeBudgetSheet == "current" && "active"}
                        onClick={() => {
                          setActiveBudgetSheet("current");
                          setSortBy(null);
                        }}
                      >
                        Budget Planning
                      </button>
                    </div> */}

                    <div className="main-title">
                      <span>Budget Planning</span>
                    </div>

                    <div style={{ display: "flex", gap: "10px" }}>
                      <div className="orderby-wrap">
                        <span className="title">Order By </span>
                        <Select
                          prefixCls="inner-select2"
                          placeholder="Select"
                          size={"large"}
                          allowClear
                          style={{ minWidth: "180px" }}
                          options={sortByFilter}
                          onChange={(_, data) => setSortBy(data)}
                        />
                      </div>

                      <div className="all-tabs" style={{ marginRight: "10px" }}>
                        <button
                          className="active"
                          onClick={!formLoading && handleButtonClick}
                        >
                          {formLoading ? <LoaderIcon size="14" /> : "Save Projection"}
                        </button>
                      </div>
                    </div>
                  </section>
                </MainWrapperYear>

                <YearComponent
                  name={activeBudgetSheet}
                  ref={formRef}
                  setLoading={setFormLoading}
                  data={yearsheetData}
                  setYearTotal={setYearTotal}
                  category={yearSheetFilters?.category || []}
                  subCategory={yearSheetFilters?.subCategory || []}
                  goalsCal={goalsCal}
                  setGraphsData={setGraphsData}
                  graphsData={graphsData}
                  yearSheetFilter={yearSheetFilter}
                  sortBy={sortBy}
                  handleUnique={handleUnique}
                />
              </>
            ) : (
              <>
                {!graphsLoading && (
                  <ApplyFilter
                    yearSheetFilter={yearSheetFilter}
                    message={noDataFound && "No data found!"}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </YearSheetWrapper>
  );
}

export const ApplyFilter = ({ title = null, yearSheetFilter, message }) => {
  return (
    <MessageContainer yearSheetFilter={yearSheetFilter}>
      <StyledCalendarDays />
      {message ? (
        <>
          <MessageTitle>Missing Data for Specified Period!</MessageTitle>
          <MessageText>{message}</MessageText>
        </>
      ) : (
        <>
          <MessageTitle>
            {" "}
            Please select the {title || "date range and categories"}{" "}
          </MessageTitle>
          <MessageText>
            {" "}
            Use the fields above to specify your desired{" "}
            {title || "data range and categories"}.{" "}
          </MessageText>
        </>
      )}
    </MessageContainer>
  );
};

const MainWrapperYear = styled.div`
  width: 100%;
  display: flex;
  // margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .left {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right {
    width: 30%;
    min-width: 400px;
    display: flex;
    justify-content: end;
  }

  .orderby-wrap {
    .title {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgb(123, 127, 145);
      padding-right: 10px;
    }
  }

  .main-title {
    span {
      color: rgb(36, 36, 36);
      margin: 0px;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .all-tabs {
    border: 1px solid #d2d4de;
    border-radius: 6px;
    padding: 2px;
    button {
      background: #f6f9fc;
      width: fit-content;
      text-align: center;
      padding: 5px 20px;
      color: #000;
      border: none;
      border-left: 2px solid white;
      height: 33px;
      font-size: 13px;
      font-family: Poppins;
      font-style: normal;
      border-radius: 6px;
    }

    .active {
      background: #242424;
      color: white;
    }
  }
`;

const YearSheetWrapper = styled.div`
  .historyWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .saveBtn {
    background: rgb(36, 36, 36);
    color: rgb(255, 255, 255);
    border-style: none;
    height: 33px;
    font-size: 13px;
    padding-right: 12px;
    padding-left: 12px;
    cursor: pointer;
    font-family: Poppins;
    height: 34px;
    min-width: 68px;
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
  }

  .submit {
    background: #d80c0c;
  }

  .select {
    .inner-select2-selector {
      min-width: 250px !important;
      background: white !important;
    }
  }
`;

const FilterTabsWrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: ${({ position }) => (position ? "space-between" : "right")};
  align-items: end;
  background: ${({ position }) => (position ? "#fff" : "transparent")};
  padding: ${({ position }) => (position ? "10px" : "0px")};
  border-radius: 9px;
  border: ${({ position }) => (position ? "1px solid rgb(210, 212, 222)" : "")};
  margin: ${({ position }) => (position ? "10px 0" : "0 0 10px 0")};

  .flex {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #7b7f91;
    span {
      color: red;
    }
  }
  .wrap {
    display: flex;
    align-items: end;
    gap: 15px;
  }

  .new-customers {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row-reverse;
  }
`;

const MessageContainer = styled.div`
  height: ${({ yearSheetFilter }) =>
    yearSheetFilter ? "calc(100vh - 210px)" : "calc(100vh - 130px)"};
  text-align: center;
  padding: 2rem;
  background-color: #f9faf6;
  border-radius: 0.5rem;
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledCalendarDays = styled(CalendarDays)`
  width: 3rem;
  height: 3rem;
  color: rgb(216, 12, 12);
  margin: 0 auto 1rem;
`;

const MessageTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 0.5rem;
`;

const MessageText = styled.p`
  color: #6b7280;
  font-size: 1rem;
`;
