import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  adminLogin,
} from "../../../services/Collections";
import {
  HideEye,
  LoginBg,
  MainAppIcon,
} from "../../../utils/Images/Images";
import { getErrorMessage } from "../../store/Constants";
import { loginSuccess, loginUserDetail } from "./LogInSlice";
import { toast } from "react-toastify";
import { LoginEye } from "../../../utils/Icons/SvgIcons";
import SignInWithGoogle from "../../../components/SignInWithGoogle";
import { useGoogleLogin } from "@react-oauth/google";
import { verifyGoogleToken } from "../../../components/VerifyGoogleToken";
import { Spin } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { LoaderIcon } from "../../../components/LoadingBlock";

const antIcon = (
  <SyncOutlined
    style={{
      fontSize: 26,
      color: "#fff",
    }}
    spin
  />
);

const Login = () => {
  const [auth, setAuth] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState("password");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const showPassToggle = () => {
    if (showPassword == "password") {
      setShowPassword("text");
      return;
    }
    setShowPassword("password");
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      if (auth.email && auth.password) {
        let req = {
          email: auth.email.trim(),
          password: auth.password.trim(),
          device: "web",
        };
        let res = await adminLogin(req);
        if (res.status === 200) {
          let filteredObject = {
            ...res?.data,
            permissions: res?.data?.boards,
          };

          console.log(filteredObject, "filteredObject");

          dispatch(loginSuccess(filteredObject));
          dispatch(loginUserDetail(filteredObject));
          // await requestNotificationPermission(res?.data);
          setLoading(false);
          navigate("/select-module");
        } else {
          const message = getErrorMessage(res, "Failed to connection");
          toast.error(message);
          setLoading(false);
        }
      } else {
        toast.error("Please enter a credentials");
        setLoading(false);
      }
    }
  };

  const handleLoginWithGoogle = async (payload) => {
    setLoading(true);
    let filteredPayload = {
      ...payload,
      device: "web",
    };
    let res = await adminLogin(filteredPayload);
    if (res.status === 200) {
      let filteredObject = {
        ...res?.data,
        permissions: res?.data?.boards,
      };
      dispatch(loginSuccess(filteredObject));
      dispatch(loginUserDetail(filteredObject));
      setLoading(false);
      // await requestNotificationPermission(res?.data);
      navigate("/select-module");
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      verifyGoogleToken(tokenResponse, handleLoginWithGoogle);
    },
  });

  useEffect(() => {
    dispatch(loginSuccess(null));
    dispatch(loginUserDetail(null));
  }, []);

  return (
    <LoginWrapper>
      <div className="flex-div">
        <div className="gradient-bg">
          <img src={LoginBg} alt="" />
        </div>
        <div className="inner-part">
          <div className="main-box">
            <div className="logo-div">
              <img src={MainAppIcon} alt="" />
              <h4>Sign in your account</h4>
            </div>
            <form onSubmit={(e) => submitHandler(e)}>
              <div className="content">
                <div>
                  <label>
                    <strong>Email</strong>
                  </label>
                  <input
                    font1="font1"
                    type="email"
                    placeholder="Enter your email"
                    onChange={(e) =>
                      setAuth({
                        email: e.target.value,
                        password: auth.password,
                      })
                    }
                  />
                </div>
                <div>
                  <label>
                    <strong>Password</strong>
                  </label>
                  <div className="password-div">
                    <input
                      font1="font1"
                      type={showPassword}
                      placeholder="Enter your password"
                      onChange={(e) =>
                        setAuth({
                          email: auth.email,
                          password: e.target.value,
                        })
                      }
                    />
                    {showPassword == "password" ? (
                      <div onClick={showPassToggle}>
                        <img src={HideEye} alt="" />
                      </div>
                    ) : (
                      <div onClick={showPassToggle}>
                        <LoginEye />
                      </div>
                    )}
                  </div>
                </div>



                <div className="forgt-password">
                  <h1 onClick={() => navigate("/forgot-password")}>
                    Forgot Password
                  </h1>
                </div>
                {!loading ? (
                  <button style={{ fontFamily: "Poppins" }}>Sign Me In</button>
                ) : (
                  <LoadingButton>
                    <LoaderIcon />
                  </LoadingButton>
                )}

                <div onClick={() => loginWithGoogle()}>
                  <SignInWithGoogle text={"Sign in with Google"} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LoginWrapper>
  );
};

export default Login;

const LoginWrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  width: 100%;
  height: 100vh;
  background: #fff;
  overflow: hidden;

  .flex-div {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .gradient-bg {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .inner-part {
      background: #fff;
      width: 90%;
      max-width: 600px;
      border-radius: 24px;
      box-shadow: 0px 16px 32px 0px #0000001a;
      ${"" /* border-radius: 8px; */}
      position: absolute;

      .main-box {
        padding: 44px;
        .logo-div {
          text-align: center;
          img {
            width: 419.55px;
            height: 92.7px;

            @media (max-width: 1370px) {
              width: 203px;
            }
            @media (max-width: 530px) {
              width: 100%;
              object-fit: contain;
            }
          }
          h4 {
            font-size: 25px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: center;
            font-family: "Poppins";
            color: #242424;
            margin-top: 20px;
          }
        }
        .content {
          margin-top: 15px;
          div {
            ${"" /* margin-bottom: 1rem; */}
            label {
              color: #242424;
              margin-bottom: 0.25rem;
              display: block;
              font-family: "Poppins";
              font-size: 16px;
            }
            .password-div {
              display: flex;
              position: relative;
              input {
                background-color: #ffff;
                border: 1px solid #afafaf;
                color: #000;
                position: relative;
                padding: 0rem 0.75rem;
                width: 100%;
                ${"" /* height: 41px; */}
                height: 56px;
                border-radius: 12px;

                font-family: "Poppins" !important;
                font-size: 14px;

                :focus {
                  outline: none;
                }
                ::placeholder {
                  color: rgba(0, 0, 0, 0.39) !important;
                }
              }
              svg {
                position: absolute;
                right: 12px;
                top: 20px;
                cursor: pointer;
              }
              img {
                position: absolute;
                right: 12px;
                top: 15px;
                cursor: pointer;
              }
            }
            input {
              background-color: #ffff;
              border: 1px solid #afafaf;
              color: #000;
              position: relative;
              padding: 0rem 0.75rem;
              width: 100%;
              ${"" /* height: 41px; */}
              height: 56px;
              border-radius: 12px;

              font-family: "Poppins" !important;
              font-size: 14px;

              :focus {
                outline: none;
              }
              ::placeholder {
                color: rgba(0, 0, 0, 0.39) !important;
              }
            }
          }
          .forgt-password {
            display: flex;
            justify-content: end;
            h1 {
              width: fit-content;
              color: #00a1e6;
              text-align: right;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              letter-spacing: 0.2px;
              cursor: pointer;
              text-decoration-line: underline;
            }
          }
          button {
            font-weight: 500;
            height: 50px;
            cursor: pointer;
            border-radius: 0.75rem;
            width: 100%;
            background-color: #d80c0c;
            border: 1px solid #d80c0c;
            font-size: 14px;
            color: #fff;
            text-align: center;
            margin-top: 10px;

            ${"" /* :hover {
              background-color: #282348;
            } */
  }
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .inner-part {
      width: 95% !important;
    }
  }
`;

export const LoadingButton = styled.span`
  width: 100%;
  font-weight: 700;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.75rem;
  width: 100%;
  background-color: #d80c0c !important;
  border: 1px solid #d80c0c !important;
  color: #fff;
  text-align: center;
`;
