import { DatePicker, InputNumber, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { addCategories, campaign_categaries } from "../../services/Collections";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import dayjs from "dayjs";
import { CalenderIcon, GoalHistoryCrossIcon } from "../../utils/Icons/SvgIcons";
import LoadingBlock from "../../components/LoadingBlock";

function Addbudgetmodal({ closeModal, handleAddBudget }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputValue, setInputValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [compaign_data_state, setCompaign_data_state] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const prefilledFilters = useSelector(
    (state) => state?.loginAuth?.summarySectionFilter
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const loginAuth = useSelector((state) => state?.loginAuth);

  const handleDateChange = (value) => {
    if (value) {
      const year = value.year();
      const month = value.month() + 1;
      const dateObj = {
        date: value,
        year: year,
        month: month,
      };
      setSelectedDate(dateObj);
    }
  };

  const activeClient =
    prefilledFilters == null
      ? prefilledData?.selectedClient?.key
      : prefilledFilters?.selectedClient?.key;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleInput = (val) => {
    if (val?.budget == null) {
      setInputValue((pre) =>
        pre?.filter((ele) => ele?.listofsource != val?.listofsource)
      );
      return;
    }
    if (
      inputValue?.filter((ele) => ele?.listofsource === val?.listofsource)
        ?.length > 0
    ) {
      setInputValue((pre) =>
        pre?.map((ele) => (ele?.listofsource == val?.listofsource ? val : ele))
      );
    } else {
      setInputValue((pre) => [...pre, val]);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);

    if (!selectedDate?.year || !selectedDate?.month) {
      setSubmitLoading(false);
      message?.warning(
        "Kindly select the month and year before adding the budget."
      );
      return;
    }
    if (selectedRowKeys?.length <= 0) {
      message?.warning(
        "Kindly select the budget category before adding the budget."
      );
      setSubmitLoading(false);
      return;
    }
    if (inputValue?.length <= 0) {
      message?.warning(
        "Kindly enter the budget category before adding the budget."
      );
      setSubmitLoading(false);
      return;
    }

    const addCategoriepayload = inputValue
      ?.filter((ele) => selectedRowKeys?.includes(ele?.listofsource))
      ?.map((el) => ({
        user_id: loginAuth?.data?.id,
        year: selectedDate?.year,
        month: selectedDate?.month,
        lead_name: "",
        budget_category: el?.listofsource,
        budget: el?.budget,
      }));

    if (
      addCategoriepayload?.length <= 0 ||
      addCategoriepayload?.length !== selectedRowKeys?.length
    ) {
      message?.warning(
        "Kindly enter the budget category before adding the budget."
      );
      setSubmitLoading(false);
      return;
    }

    const res = await addCategories(activeClient, addCategoriepayload);
    if (res?.status == 200) {
      toast?.success(res?.message || "Budget added successfully");
      handleAddBudget();
    } else {
      toast?.error(res?.message || "Something went wrong");
    }
    resetForm();
    setSubmitLoading(false);
  };

  const resetForm = () => {
    setSelectedRowKeys([]);
    setInputValue([]);
    setSelectedDate(null);
    closeModal();
  };

  const handleget_campaign_categaries = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("client_key", activeClient);
    let res = await campaign_categaries(activeClient, params);
    if (res?.status === 200) {
      const compaign_data = res?.data?.map((el, index) => ({
        ...el,
        key: el?.listofsource,
      }));
      setCompaign_data_state(compaign_data);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleget_campaign_categaries();
  }, [activeClient]);

  const budgetColumns = [
    {
      title: "Category",
      dataIndex: "listofsource",
      sorter: (a, b) => a?.listofsource.localeCompare(b?.listofsource),
    },
    {
      title: "Budget",
      render: (_, val, index) => (
        <InputNumber
          key={index}
          style={{
            width: "100%",
            // height: "42px",
            fontSize: "15px",
            fontFamily: "Poppins",
          }}
          type="text"
          min={0}
          placeholder="Enter Budget Ammount"
          onChange={(e) => handleInput({ ...val, budget: e })}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        />
      ),
    },
  ];

  return (
    <Wrapperbudetmodal>
      <div className="header">
        <h4 className="title">Add Budget</h4>
        <i onClick={closeModal}>
          <GoalHistoryCrossIcon />
        </i>
      </div>
      <DatePicker
        onChange={(value, dateString) =>
          handleDateChange(value, dateString, "month")
        }
        value={selectedDate ? dayjs(selectedDate?.date) : ""}
        picker="month"
        style={{
          height: "40px",
          width: "427px",
          fontSize: "15px",
          borderRadius: "10px",
          padding: "12px 10px",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16px",
          marginBottom: "16px",
        }}
        suffixIcon={<CalenderIcon />}
        placeholder="Select year & month"
        hoverBorderColor={"#ccd2d9"}
        className="datepicker"
      />
      {loading ? (
        <LoadingBlock height={"240px"} size={28} />
      ) : (
        <Table
          rowSelection={rowSelection}
          prefixCls="addBudgetTable"
          columns={budgetColumns}
          dataSource={compaign_data_state}
          pagination={false}
          scroll={{ y: 240 }}
          rowClassName={(_, index) =>
            index % 2 === 0 ? "even-row" : "odd-row"
          }
        />
      )}
      <div className="submitBtn">
        {submitLoading ? (
          <button>
            <LoadingBlock
              height="fit-content"
              size={28}
              onlySpinner={false}
              color={"#fff"}
            />
          </button>
        ) : (
          <button onClick={handleSubmit}>
            <b>Submit</b>
          </button>
        )}
      </div>
    </Wrapperbudetmodal>
  );
}

export default Addbudgetmodal;

const Wrapperbudetmodal = styled.div`
  width: 100%;

  .even-row {
    background-color: #ffffff;
  }

  .odd-row {
    background-color: #f2f2f2;
  }

  .submitBtn {
    display: flex;
    justify-content: end;
    margin-top: 24px;
    button {
      width: 210px;
      border-style: none;
      background-color: #ed0101;
      border-radius: 10px;
      padding: 10px 12px;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
      text-align: center;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 24px;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    letter-spacing: 0.005em;
    color: #242424;
    margin: 0;
  }

  i {
    cursor: pointer;
  }

  .datepicker {
    input::placeholder {
      color: #c2c2c2 !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
`;
