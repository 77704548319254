import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import styled from "styled-components";

export default function TempPlayGroundGraph() {
    let firstcolor = '#ffc04d'; // unbooked
    let firstkey = 'unbooked';
    let firstName = 'UnBooked'

    let secondcolor = '#4ca64c'; // totalbooking 
    let secondkey = 'booked';
    let secondName = 'Total Booking'

  const [gdata, setGdata] = useState([]);
  const data = [
    {
      source: "Google Ads",
      booked: 12,
      unbooked: 8,
    },
    {
      source: "Bing",
      booked: 45,
      unbooked: 9,
    },
    {
      source: "Yahoo",
      booked: 38,
      unbooked: 7,
    },
  ];

  useEffect(() => {
    let updated = data?.map((el) => {
        let newFie = Math.abs(el[`${secondkey}`] - el[`${firstkey}`]);
        if(el[`${secondkey}`] > el[`${firstkey}`]){
            return { ...el, [secondkey]: el[`${firstkey}`], 'newField': newFie, reverse :false };
        }else{
            return { ...el, 'newField': newFie, reverse :true };
        }
    });

    console.log(updated, "updated")
    setGdata(updated);
  }, []);

  const getBarColor = (a,b)=>{
    if(a>b) return firstcolor // ;
    else return secondcolor ;
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let temp = 0;
      payload?.map((el) => (temp = temp + el?.value));
      return (
        <CustomTooltipWrapper>
          <h2>{payload?.[0]?.payload?.source}</h2>
          <div>
            <label>{payload?.[0]?.payload?.reverse ? firstName : secondName} - </label>
            <span>{temp}</span>
          </div>
          <div>
          <label>{payload?.[0]?.payload?.reverse ? secondName : firstName} - </label>
            <span>{payload?.[0]?.value}</span>
          </div>
        </CustomTooltipWrapper>
      );
    }
    return null;
  };


  return (
    <GraphWrapper className="graph-section">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={gdata}
          margin={{
            top: 20,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis
            dataKey="source"
            name="source"
            angle={-45}
            height={50}
            textAnchor="end"
          />
          <YAxis label={{ value: "Count", angle: -90, position: "left" }} />

          <Legend />
        <Tooltip content={<CustomTooltip />} />


          <Bar
            dataKey={secondkey}
            name={secondName}
            stackId="a"
            fill={secondcolor}
            barSize={60}
            
          >
            {data.map((entry, index, arr) => (
              <>
                <Cell key={`cell-${index}`} fill={getBarColor(arr[index][`${secondkey}`], arr[index][`${firstkey}`])} />
              </>
            ))}
          </Bar>

          <Bar
            dataKey={"newField"}
            name={firstName}
            stackId="a"
            fill={firstcolor}
            barSize={60}
          >
            {data.map((entry, index, arr) => (
              <>
                <Cell key={`cell-${index}`} fill={getBarColor(arr[index][`${firstkey}`], arr[index][`${secondkey}`])} />
              </>
            ))}
          </Bar>

        </BarChart>
      </ResponsiveContainer>
    </GraphWrapper>
  );
}

const GraphWrapper = styled.div`
  height: 500px;
  width: 100%;
  background: #ffffff;
  margin: 20px 0;
  padding: 20px 0;
  .recharts-legend-wrapper {
    height: auto !important;
  }
`;

const CustomTooltipWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 10px 15px;
  border-radius: 6px;
  font-family: Poppins, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;

  h2 {
    font-size: 18px;
    margin: 0;
    padding-bottom: 5px;
    font-weight: 600;
    border-bottom: 1px solid #444;
    width: 100%;
  }

  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
  }

  label {
    font-weight: 500;
  }

  span {
    font-weight: 400;
    color: #dcdcdc;
  }

  animation: fadeIn 0.3s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;