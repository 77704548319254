import ForgotPassword from "../app/Auth/signIn/ForgotPassword";
import Login from "../app/Auth/signIn/Login";
import { AdminUserPublic } from "../components/AdminUserPublic";
import { AIBookingPublic } from "../components/AIBookingPublic";
import Filter from "../components/Filter";
import SelectModule from "../components/SelectModule";
import SetNewPassForget from "../components/SetNewPassForget";
import { BookingListPublic } from "../module/AIBooking/BookingListPublic";
import CompanyComp from "../module/company/CompanyComp";
import ConnectCrm from "../module/company/crm/ConnectCrm";
import ConnectedCrmList from "../module/company/crm/ConnectedCrmList";
import CompanyBookingList from "../module/company/booking/BookingList";
import { SettingDataModal } from "../module/dynamicBudgetSheet/SettingDataModal";
import { PublicWrapper } from "./PublicWrapper";

export const Paths = [
  {
    path: "/",
    component: <Login />,
  },
  {
    path: "/select-module",
    component: <SelectModule />,
  },
  {
    path: "/filter",
    component: <Filter />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/set-new-password",
    component: <SetNewPassForget />,
  },
  {
    path: "/booking-buddy-performance",
    component: <AIBookingPublic />,
  },
  {
    path: "admin-users",
    component: <AdminUserPublic />,
    sectionName: "settings",
  },
  {
    path: "/budget-setting",
    component: (
      <PublicWrapper path="dynamic-budget-sheet">
        <SettingDataModal />
      </PublicWrapper>
    ),
    sectionName: "dynamic-budget-sheet",
  },
  { path: "/booking", component: <BookingListPublic /> },
  // { path: "/scheduled-booking", component: <BookingListPublic /> },

  {
    path: "/booking_buddy_settings",
    component: (
      <PublicWrapper path="booking_buddy_settings">
        <CompanyComp />
      </PublicWrapper>
    ),
  },
  {
    path: "/booking_buddy_settings/:id/crm",
    component: (
      <PublicWrapper path="booking_buddy_settings">
        <ConnectedCrmList />
      </PublicWrapper>
    ),
  },
  {
    path: "/booking_buddy_settings/:id/add-crm",
    component: (
      <PublicWrapper path="booking_buddy_settings">
        <ConnectCrm />
      </PublicWrapper>
    ),
  },
  {
    path: "/booking_buddy_settings/:id/edit-crm",
    component: (
      <PublicWrapper path="booking_buddy_settings">
        <ConnectCrm />
      </PublicWrapper>
    ),
  },
  {
    path: "/booking_buddy_settings/:id/bookings",
    component: (
      <PublicWrapper path="booking_buddy_settings">
        <CompanyBookingList />
      </PublicWrapper>
    ),
  },
];
