import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { Search } from "lucide-react";

const SearchComponent = ({width, onChange, delayTime = 500, style={}, value="" }) => {
  const debounceTimer = useRef(null);

  const handleInputChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(() => {
        onChange(value);
      }, delayTime);
    },
    [onChange]
  );

  return (
    <SearchComponentWrapper width={width} style={style}>
      <input placeholder="Search..." onChange={(e)=> onChange(e.target.value)} value={value} />
      <Search />
    </SearchComponentWrapper>
  );
};

const SearchComponentWrapper = styled.div`
  width: ${({width})=> width ? width : '300px'};
  padding: 10px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  color: #7a7b7a;
  display: flex;
  justify-content: space-between;

  input {
    font-family: Inter;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    color: #696969;
    border: none;
    outline: none;
    width: 100%;

    ::placeholder {
      color: #b9b6b6c7 !important;
    }
  }
`;

const ButtonComponent = ({title, onClick}) => {
  return <ButtonComponentWrapper onClick={onClick} >{title}</ButtonComponentWrapper>;
};

const ButtonComponentWrapper = styled.div`
  padding: 4px 11px;
  border-radius: 6px;
  background: #0f1011;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
`;

export { SearchComponent, ButtonComponent };
