import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { GraphWrapper, MainWrapper } from "./style";

export default function StackedAreaChart({
  title,
  data,
  areaData,
  xAxisDataKey,
}) {
  console.log(data, "dattatatattatatta");

  return (
    <MainWrapper>
      <div className="sub-header">
        <sub>{title}</sub>
      </div>
      <GraphWrapper className="graph-section">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            // width={500}
            // height={400}
            data={data}
            margin={{ top: 20, right: 30, left: 40, bottom: 10 }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey={xAxisDataKey} />
            <YAxis
              label={{
                value: "Percentage",
                angle: -90,
                position: "insideLeft",
                offset: -10,
              }}
            />

            <Tooltip />
            <Legend
              layout="horizontal"
              verticalAlign="bottom"
              align="center"
              wrapperStyle={{ paddingTop: 20 }}
            />

            {areaData?.map((el) => {
              return (
                <Area
                  name={el?.name}
                  type={el?.type}
                  dataKey={el?.dataKey}
                  stackId="1"
                  stroke={el?.color}
                  fill={el?.color}
                  fillOpacity={1}
                />
              );
            })}
          </AreaChart>
        </ResponsiveContainer>
      </GraphWrapper>
    </MainWrapper>
  );
}
