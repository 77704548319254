import styled from "styled-components";

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const DbsWrap = styled.div`
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  padding: 10px;
`;

export const SubHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const FilterButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  p {
    position: relative;
    display: flex;
    width: 100px;
    height: 39px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    border-radius: 10px;
    cursor: pointer;
    font-family: Poppins;
    background: white;
    gap: 8px;
    color: black;
    border: 1px solid #d2d4de;
  }
`;
