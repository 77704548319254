import React, { useState } from "react";
import styled from "styled-components";

export default function ImageModal({ files }) {
  const [currentPreview, setCurrentPreview] = useState(0);

  const handleNavigate = (type) => {
    if (type === "next") {
      ;
    }
    if (type === "pre") {
      setCurrentPreview((prev) => prev - 1);
    }
  };

  return (
    <ImageModalStyle className="imgContainer" bgimg={files[0]}>
      <div className="backgroundimg"></div>
      <header>
        <div className="userImg">
          {files[0] ? (
            <img src={files[0]} alt="" />
          ) : (
            <p style={{ textTransform: "capitalize", fontSize: "19px" }}>
              {GenerateAvatarName("Images")}
            </p>
          )}
        </div>
        <div>
          <div className="titlename">{"Images"}</div>
        </div>
      </header>
      {files?.length > 1 && (
        <div className="navigateIcons">
          <button
            onClick={() => setCurrentPreview((prev) => prev - 1)}
            style={{ visibility: currentPreview == 0 ? "hidden" : "unset" }}
          >
            PREV
          </button>
          <button
            onClick={() => setCurrentPreview((prev) => prev + 1)}
            style={{
       
              visibility:
                currentPreview == files?.length - 1 ? "hidden" : "unset",
            }}
          >
            NEXT
          </button>
        </div>
      )}
      <img src={files[currentPreview]} alt="img" className="images" />
    </ImageModalStyle>
  );
}

const ImageModalStyle = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  .backgroundimg {
    background-image: url(${({ bgimg }) => bgimg});
    background-size: cover;
    background-position: center;
    filter: blur(6px);
    margin: 0;
    position: absolute;
    inset: 0;
    padding:0 2px;
  }

  .images {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
    padding:0 2px;
  }
  :hover .navigateIcons button {
    display: flex;
  }

  .navigateIcons {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    z-index: 2;

    button {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid transparent;
      overflow: hidden;
      display: none;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0 0 5px black;
      border: 1px solid gray;
      color: gray;
      background: rgb(0 0 0 / 60%);
    }
  }

  //header
  header {
    position: absolute;
    width: 100%;
    top: 0px;
    overflow: hidden;
    display: none;
    gap: 10px;
    z-index: 3;
    background: linear-gradient(180deg, #100f0fc9, transparent);
    padding: 10px;
  }
  :hover header {
    display: flex;
  }
  .userImg {
    width: 44px;
    max-height: 44px;
    border-radius: 4px;
    p {
      width: 44px;
      height: 44px;
      object-fit: cover;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(9, 179, 243, 0.14);
      color: rgb(0, 173, 255);
      font-family: Lato, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
    }

    img {
      border-radius: 6px;
      width: 100%;
      height: 100%;
    }
  }
  .titlename {
    text-transform: capitalize;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    border-radius: 0px 8px 8px;
    // color: #1d1c1d;
    color: white;
  }
`;
