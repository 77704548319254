import React from "react";
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

// Example correlation matrix data
const correlationData = [
    { variable: "Lead %", Lead: 100, Booking: 0.8, Closing: 0.5, Abandon: -0.6 },
    { variable: "Booking %", Lead: 0.8, Booking: 1, Closing: 0.7, Abandon: -0.4 },
    { variable: "Closing %", Lead: 0.5, Booking: 0.7, Closing: 1, Abandon: -0.2 },
    { variable: "Abandon %", Lead: -0.6, Booking: -0.4, Closing: -0.2, Abandon: 1 },
];

const variables = ["Lead %", "Booking %", "Closing %", "Abandon %"];

// Color scale function
const getColor = (value) => {
    const hue = ((1 - value) * 240).toString(10); // Map values (-1 to 1) to colors (blue to red)
    return `hsl(${hue}, 70%, 50%)`;
};

// Transform correlation matrix into usable data for the ScatterChart
const transformedData = [];
correlationData.forEach((row, yIndex) => {
    variables.forEach((colName, xIndex) => {
        transformedData.push({
            x: xIndex,
            y: yIndex,
            value: row[colName],
        });
    });
});

export default function Heatmap() {
    const cellSize = 40; // Define the size of each cell (adjust for responsiveness)

    return (
        <div style={{ width: "40%", height: "400px" }}>
            <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
                Heatmap: Correlation Between KPIs
            </h3>
            <ResponsiveContainer width="100%" height="100%">
                <ScatterChart
                    margin={{
                        top: 20,
                        right: 40,
                        bottom: 40,
                        left: 40,
                    }}
                >
                    {/* XAxis */}
                    <XAxis
                        type="number"
                        dataKey="x"
                        domain={[0, variables.length - 1]}
                        tickFormatter={(value) => variables[value]}
                        tick={{ fontSize: 12 }}
                        interval={0}
                        allowDecimals={false}
                        angle={-45}
                        textAnchor="end"
                    />

                    {/* YAxis */}
                    <YAxis
                        type="number"
                        dataKey="y"
                        domain={[0, variables.length - 1]}
                        tickFormatter={(value) => variables[value]}
                        tick={{ fontSize: 12 }}
                        interval={0}
                        allowDecimals={false}
                        reversed
                    />

                    {/* Tooltip */}
                    <Tooltip
                        cursor={{ strokeDasharray: "3 3" }}
                        content={({ payload }) => {
                            if (payload && payload.length > 0) {
                                const data = payload[0].payload;
                                return (
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            padding: "10px",
                                            border: "1px solid gray",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        <p>
                                            {variables[data.y]} - {variables[data.x]}
                                        </p>
                                        <p>Correlation: {data?.value?.toFixed(2)}</p>
                                    </div>
                                );
                            }
                            return null;
                        }}
                    />

                    {/* Scatter (Cells) */}
                    <Scatter
                        data={transformedData}
                        shape={(props) => {
                            const { cx, cy, value } = props;
                            console.log(cx, cy, value, 'cx, cy, value')
                            return (
                                <rect
                                    x={cx - cellSize / 2}
                                    y={cy - cellSize / 2}
                                    width={cellSize}
                                    height={cellSize}
                                    fill={getColor(cx)}
                                    stroke="#000"
                                    strokeWidth={0.5}
                                />
                            );
                        }}
                    />
                </ScatterChart>
            </ResponsiveContainer>
        </div>
    );
}
