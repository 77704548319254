import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { GraphWrapper, MainWrapper } from "./style";

const MultiLineChart = ({ data }) => {
  return (
    <MainWrapper>
      <div className="sub-header">
        <sub>Trend of Lead %, Booking % and Closing %</sub>
      </div>
      <GraphWrapper className="graph-section">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, left: 40, bottom: 10 }}
          >
            <YAxis
              label={{
                value: "Percentage",
                angle: -90,
                position: "insideLeft",
                offset: -10,
              }}
            />
            <XAxis
              dataKey="monthName"
              // label={{
              //   value: "Month",
              //   position: "insideBottom",
              //   offset: -20,
              // }}
            />
            <Tooltip />
            <Legend
              layout="horizontal"
              verticalAlign="bottom"
              align="center"
              wrapperStyle={{ paddingTop: 20 }}
            />
            <Line
              name="Lead %"
              type="linear"
              dataKey="lead_calls_perc"
              stroke="#ff5700fa"
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
            <Line
              name="Booking %"
              type="linear"
              strokeWidth={2}
              dataKey="unique_call_booking_perc"
              stroke="#ffc107"
            />
            <Line
              name="Closing %"
              type="linear"
              strokeWidth={2}
              dataKey="closing_perc"
              stroke="#dc3545"
            />
          </LineChart>
        </ResponsiveContainer>
      </GraphWrapper>
    </MainWrapper>
  );
};

export default MultiLineChart;

