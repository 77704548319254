import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  selectedHistory: null,
  selectedGoalHistory: null,
  selectedHistoryData: null,
  yearSheetFilters: {},
  goalFilters: {},
  yearData: {
    prev: [],
    current: [],
    prediction: [],
  },
  GoalPrediction: null,
  predicationLabelValue: [],
  finalReport: {
    filters: null,
    data: null,
    predictedData: null,
    predictedFilter: null,
    currentData: null,
    currentFilter: null,
  },
};

export const DynamicBudgetModuleSlice = createSlice({
  name: "DynamicBudgetModuleSlice",
  initialState,
  reducers: {
    updateExcelDataBudget: (state, action) => {
      state.data = action?.payload;
    },
    activeHistorySheet: (state, action) => {
      state.selectedHistory = action?.payload;
    },
    activeGoalHistory: (state, action) => {
      state.selectedGoalHistory = action?.payload;
    },
    activeHistorySheetData: (state, action) => {
      state.selectedHistoryData = action?.payload;
    },
    updateYearSheetFilters: (state, action) => {
      state.yearSheetFilters = action?.payload;
    },
    updateGoalFilters: (state, action) => {
      state.goalFilters = action?.payload;
    },
    updateYearData: (state, action) => {
      if (action?.payload) {
        if (action?.payload?.name === "prev") {
          state.yearData.prev = action?.payload?.categories;
        }
        if (action?.payload?.name === "current") {
          state.yearData.current = action?.payload?.categories;
        }
        if (action?.payload?.name === "prediction") {
          state.yearData.prediction = action?.payload?.categories;
        }
        if (action?.payload?.current && action?.payload?.prediction) {
          state.yearData = action?.payload;
        }
      } else {
        state.yearData = { prev: [], current: [], prediction: [] };
      }
    },
    updateGoalPrediction: (state, action) => {
      if (action?.payload) {
        state.GoalPrediction = action.payload;
      } else {
        state.GoalPrediction = null;
      }
    },
    updatePredicationLabelValue: (state, action) => {
      if (action?.payload) {
        state.predicationLabelValue = action.payload;
      }
    },
    finalReportFilter: (state, action) => {
      if (action?.payload) {
        if (action.payload.type == "pre") {
          state.finalReport.predictedFilter = action.payload.filter;
        } else {
          state.finalReport.currentFilter = action.payload.filter;
        }
      } else {
        state.finalReport.predictedFilter = null;
        state.finalReport.currentFilter = null;
      }
    },
    finalReportData: (state, action) => {
      if (action?.payload) {
        if (action.payload.type == "pre") {
          state.finalReport.predictedData = action.payload.data;
        } else {
          state.finalReport.currentData = action.payload.data;
        }
      } else {
        state.finalReport.predictedData = null;
        state.finalReport.currentData = null;
      }
    },
    resetDynamicBudgetSlice: () => initialState,
  },
});

export const {
  updateExcelDataBudget,
  activeHistorySheet,
  activeHistorySheetData,
  activeGoalHistory,
  updateYearSheetFilters,
  updateGoalFilters,
  updateYearData,
  updateGoalPrediction,
  updatePredicationLabelValue,
  resetDynamicBudgetSlice,
  finalReportData,
  finalReportFilter,
} = DynamicBudgetModuleSlice.actions;
export default DynamicBudgetModuleSlice.reducer;
