import { DatePicker, message, Modal } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ModalLoader } from "../../../components/Loader";
import {
  getListingGoalHistoryApi,
  goalHistoryApi,
  goalPredictionApi,
} from "../../../services/Collections";
import {
  activeGoalHistory,
  updateGoalFilters,
} from "../../exceltable/ExcelStore";
import ExcelGoalSheet from "./ExcelGoalSheet";
import { DatePickerIcon } from "../../../utils/Icons/SvgIcons";
import LoadingBlock from "../../../components/LoadingBlock";

const datePickerStyle = {
  width: "291px",
  height: "40px",
  padding: "10px",
  borderRadius: "10px",
  border: "1px solid #E1E1E1",
};

export default function Goals({ selectedHistory, year, setYear }) {
  const { goalFilters } = useSelector((state) => state?.dynamicBudgetSheet);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentData, setCurrentData] = useState(
    goalFilters?.currentData ?? []
  );
  const [nextData, setNextData] = useState([]);
  const [budgetPercentageText, setBudgetPercentageText] = useState(
    goalFilters?.budgetPercentageText ?? 0
  );
  const [revenueGoalText, setRevenueGoalText] = useState(
    goalFilters?.revenueGoalText ?? 0
  );
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitTiggered, setSubmitTiggered] = useState(false);
  const currentYear = dayjs().year();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (userName?.trim()?.length > 0) {
      setIsModalOpen(false);
      handleSaveSheet();
      setUserName("");
    } else {
      message.success("Please enter name");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const prefilledFilters = useSelector(
    (state) => state?.loginAuth?.summarySectionFilter
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );

  const activeClient =
    prefilledFilters == null
      ? prefilledData?.selectedClient?.key
      : prefilledFilters?.selectedClient?.key;

  const loginAuth = useSelector((state) => state?.loginAuth);

  const getYear = (date) => {
    if (date) {
      setYear(date?.$y);
      dispatch(updateGoalFilters({ year: date?.$y }));
      dispatch(activeGoalHistory(null));
      setBudgetPercentageText(0);
      setRevenueGoalText(0);
    } else {
      setYear(null);
      dispatch(updateGoalFilters({}));
    }
  };

  const handleSaveSheet = async () => {
    try {
      setSubmitLoading(true);
      const currentDateTime = moment().toISOString();

      let payload = {
        user_id: loginAuth?.data?.id,
        current_budget_sheet: {
          currentData: currentData,
          year: year,
          budgetPercentageText: budgetPercentageText,
          revenueGoalText: revenueGoalText,
        },
        current_budget_per: 0,
        current_budget: 0,
        current_revenue: 0,
        expected_budget_sheet: { nextData },
        expected_budget_per: 0,
        expected_budget: 0,
        expected_revenue_goal: 0,
        expected_increase_revenue: 0,
      };

      let goalHistoryPayload = {
        save_date: currentDateTime,
        mark_as_default: 1,
        name: userName,
        ...payload,
      };

      let preDictionPayload = {
        year: year,
        ...payload,
      };

      let params = new URLSearchParams();
      params.append("client_key", activeClient);
      let predictionRes = await goalPredictionApi(
        activeClient,
        params,
        preDictionPayload
      );
      await goalHistoryApi(activeClient, params, goalHistoryPayload);
      if (predictionRes?.status === 200) {
        dispatch(
          updateGoalFilters({
            currentData: currentData,
            year: year,
            budgetPercentageText: budgetPercentageText,
            revenueGoalText: revenueGoalText,
          })
        );
        message.success("Saved successfully");
        handleSheetListing();
        setSubmitLoading(false);
      } else {
        message.error("Something went wrong");
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  const getDefaultHistoryData = async () => {
    try {
      setLoading(true);
      let params = new URLSearchParams();
      params.append("user_id", loginAuth?.data?.id);
      params.append("default", 1);
      params.append("client_key", activeClient);
      let res = await getListingGoalHistoryApi(activeClient, params);
      if (res?.status === 200) {
        if (res?.data?.length > 0) {
          let history = res?.data?.[0];
          let year = history?.current_budget_sheet?.year;
          setYear(year);
          setCurrentData(history.current_budget_sheet?.currentData);
          setBudgetPercentageText(
            history.current_budget_sheet?.budgetPercentageText
          );
          setRevenueGoalText(history.current_budget_sheet?.revenueGoalText);
          dispatch(updateGoalFilters(history?.current_budget_sheet));
          dispatch(activeGoalHistory(history?.id));
        } else {
          setYear(null);
          dispatch(updateGoalFilters({}));
          dispatch(activeGoalHistory(null));
        }
        setLoading(false);
      } else {
        setLoading(false);
        message.error("Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  useEffect(() => {
    if (selectedHistory && selectedHistory?.currentData?.length > 0) {
      const { year, currentData, budgetPercentageText, revenueGoalText } =
        selectedHistory;
      setYear(year);
      setCurrentData(currentData || []);
      setBudgetPercentageText(budgetPercentageText || 0);
      setRevenueGoalText(revenueGoalText || 0);
    }
    // else {
    //   setYear(null);
    //   setCurrentData([]);
    //   setBudgetPercentageText(0);
    //   setRevenueGoalText(0);
    // }
  }, [selectedHistory]);

  useEffect(() => {
    !goalFilters?.currentData && getDefaultHistoryData();
  }, [activeClient]);

  return (
    <GoalsWrapper>
      {
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          prefixCls="goal"
        >
          <ModalWrapper>
            <p>History Title</p>
            <input
              value={userName}
              type="text"
              placeholder="Please enter the history title"
              onChange={(e) => setUserName(e?.target?.value)}
            />
          </ModalWrapper>
        </Modal>
      }
      <FilterTabsWrapper>
        <div>
          <DatePicker
            value={year ? dayjs(year?.toString(), "YYYY") : null}
            onChange={getYear}
            picker="year"
            suffixIcon={<DatePickerIcon />}
            allowClear={false}
            style={datePickerStyle}
            disabledDate={(date) =>
              date.year() > currentYear + 1 || date.year() < currentYear - 1
            }
          />
          <button className="saveBtn" onClick={() => setSubmitTiggered(!submitTiggered)} >Submit</button>
        </div>
      </FilterTabsWrapper>

      {loading ? (
        <LoadingBlock height={"calc(100vh - 216px)"} size={28} />
      ) : (
        <ExcelGoalSheet
          currentData={currentData}
          setCurrentData={setCurrentData}
          nextData={nextData}
          setNextData={setNextData}
          year={year}
          budgetPercentageText={budgetPercentageText}
          setBudgetPercentageText={setBudgetPercentageText}
          revenueGoalText={revenueGoalText}
          setRevenueGoalText={setRevenueGoalText}
          showModal={showModal}
          submitLoading={submitLoading}
          submitTiggered={submitTiggered}
        />
      )}
    </GoalsWrapper>
  );
}

const GoalsWrapper = styled.div`
  margin-top: 16px;
  overflow: scroll;
  scrollbar-width: none;
`;

export const ModalWrapper = styled.div`
  margin: 10px 0;

  input {
    width: 300px;
    height: 35px;
    border-radius: 8px;
    border: 1px solid gray;
    padding: 4px 10px;
    color: black;

    &::placeholder {
      color: #979797 !important;
    }
  }

  p {
    margin: 0 0 5px 0;
    color: black;
    font-weight: 600;
  }
`;

const FilterTabsWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  margin-bottom: 25px;

  div {
    display: flex;
    gap: 20px;
  }
  .saveBtn {
    border-style: none;
    height: 40px;
    font-size: 13px;
    padding: 0 16px;
    border-radius: 10px;
    cursor: pointer;
    font-family: Poppins;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background: #00a1e6;
    color: rgb(255, 255, 255);
  }
`;
