import { DatePicker, Drawer, Modal, Select, Spin, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import AddSettings from "./AddSettings";
import {
  deleteSettingsMapping,
  getCampaignMapping,
  MediumTransformationApi,
  MediumTransformationPutApi,
  SettingCampaingsNewApi,
} from "../../services/Collections";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  BillingEditIcon,
  DeleteIcon,
  DeleteNewIcon,
  EditIcon,
  EditNewicon,
} from "../../utils/Icons/SvgIcons";
import EditSettings from "./EditSettings";
import { DeleteModal } from "../../components/DeleteModal";
import { updateSettingData } from "../../app/Auth/signIn/LogInSlice";
import { filterClientsBySection } from "../../utils/common/commonFunction";
import Header from "../../components/Header";
import TabComponent from "../../components/newComponent/TabComponent";
import { FaPlus } from "react-icons/fa6";
import LoadingBlock from "../../components/LoadingBlock";
import Watermark from "../../components/Watermark";

const SettingsTable = () => {
  const [openDrawer, setFilterDrawer] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [toggle, setToggle] = useState();
  const dispatch = useDispatch();
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );
  const [editData, setEditdata] = useState(null);
  const [editDrawer, setEditDrawer] = useState(false);
  const filterData = useSelector((state) => state?.loginAuth?.settingData);
  const adleverageUserId = useSelector(
    (state) => state?.loginAuth?.adleverageUserId?._id
  );
  const [defaultValue, setDefaultValue] = useState(
    filterData ? filterData : prefilledData?.selectedClient
  );
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [clientOptions, setclientOptions] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState([]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 40,
      }}
      spin
    />
  );

  const [mapping, setMapping] = useState(false);
  const [campaignListing, setCampaignListing] = useState([]);
  const [mediumListing, setMediumLisnting] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);

  const [missingCampaign, setMissingCampaign] = useState(false);
  const [missingLoading, setMissingLoading] = useState(false);

  const handlePageChange = (page, newPageSize) => {
    setLoadingTable(true);
    setCurrentPage(page);
    setPageSize(newPageSize);
    handleMediumTransformation(page, newPageSize);
  };

  const campaignColumnsSett = [
    {
      title: "Medium Transformed",
      dataIndex: "transformed_medium",
    },
    {
      title: "Campaign",
      dataIndex: "campaign",
      render: (record, alldata) => {
        let value = alldata?.campaign?.id || null;
        return (
          <>
            <Select
              key={alldata?.id}
              defaultValue={value}
              size={"large"}
              prefixCls="inner-select2"
              showSearch
              allowClear
              onChange={(_, val) => {
                updateMediumTransformation({
                  id: alldata?.id,
                  campaign: val ? val?.id : null,
                });
              }}
              options={campaignListing}
              style={{
                width: "500px",
              }}
              maxTagCount={1}
              placeholder="Please select"
              dropdownStyle={{
                maxHeight: "300px",
              }}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </>
        );
      },
    },
  ];

  const handleCloseDrawer = () => {
    setOpenFilterDrawer(false);
    setDefaultValue(filterData ? filterData : prefilledData?.selectedClient);
  };

  const columns = [
    {
      title: "ST Type",
      dataIndex: "st_type",
      width: 100,
      fixed: "left",
      render: (val) => (
        <p style={{ margin: 0, textTransform: "capitalize" }}>{val}</p>
      ),
    },
    {
      title: "Platform type",
      dataIndex: "platform",
      width: 100,
    },
    {
      title: "Ad Campaign(Name)",
      dataIndex: "ad_campaigns",
      width: 100,
      render: (val) => (
        <p
          onClick={() => {
            setViewModal(true);
            setViewData(val);
          }}
          style={{ margin: 0, cursor: "pointer" }}
        >{`${val?.[0] ? val?.[0] : ""} ${val?.[1] ? "," + val?.[1] : ""} ${
          val?.length > 2 ? `, +${val?.length - 2}` : ""
        }`}</p>
      ),
    },
    {
      title: "Cost",
      dataIndex: "cost",
      width: 100,
      render: (val) => (
        <p
          onClick={() => {
            val && setViewModal(true);
            setViewData(val);
          }}
          style={{ margin: 0, cursor: "pointer" }}
        >
          {val?.length
            ? `${val[0] ? val[0] : ""} ${val[1] ? "," + val[1] : ""} ${
                val.length > 2 ? `, +${val.length - 2}` : ""
              }`
            : "-"}
        </p>
      ),
    },
    {
      title: "Campaigns",
      dataIndex: "campaigns_businessunit",
      width: 100,
    },

    {
      title: "Campaign Sources",
      dataIndex: "prefilledData",
      width: 100,
      render: (val, data) => (
        <p
          onClick={() => {
            setViewModal(true);
            setViewData(data?.prefilledData?.campaign_source);
          }}
          style={{ margin: 0, cursor: "pointer" }}
        >
          {`${
            data?.prefilledData?.campaign_source?.[0]
              ? data?.prefilledData?.campaign_source?.[0]
              : ""
          } ${
            data?.prefilledData?.campaign_source?.[1]
              ? "," + data?.prefilledData?.campaign_source?.[1]
              : ""
          } ${
            data?.prefilledData?.campaign_source?.length > 2
              ? `, +${data?.prefilledData?.campaign_source?.length - 2}`
              : ""
          }`}
        </p>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, data) => (
        <div style={{ whiteSpace: "nowrap" }}>
          <span
            onClick={() => {
              setEditDrawer(true);
              setEditdata(data);
            }}
            style={{ marginLeft: "10px", cursor: "pointer" }}
          >
            <EditNewicon />
          </span>
          <span
            onClick={() => {
              setDeleteModal(true);
              setDeleteId(data?.prefilledData?.id);
            }}
            style={{ marginLeft: "10px", cursor: "pointer" }}
          >
            <DeleteNewIcon />
          </span>
        </div>
      ),
    },
  ];

  const getClientOption = () => {
    let arr = [];
    filterClientsBySection(clientOptionsData?.clients, "settings")?.length >
      0 &&
      // eslint-disable-next-line array-callback-return
      filterClientsBySection(clientOptionsData?.clients, "settings")?.map(
        (ele) => {
          let obj = {
            ...ele,
            label: ele?.name,
            value: ele?.name,
          };
          arr.push(obj);
        }
      );
    setclientOptions(arr);
  };

  const FilterComponent = () => {
    return (
      <div className="campaign-filters">
        <div className="title">
          <h4 style={{ color: "rgba(25, 25, 25, 1)" }}>Filters</h4>
        </div>

        <div className="content">
          <div className="datepicker-box">
            <div style={{ marginTop: "20px" }} className="input-box">
              <label>
                <strong>Client</strong>
              </label>
              <br />
              <Select
                size={"large"}
                prefixCls="inner-select2"
                value={defaultValue}
                onChange={(_, data) => setDefaultValue(data)}
                style={{
                  width: "100%",
                  height: "48px",
                  marginTop: "6px",
                }}
                maxTagCount={1}
                placeholder="Please select"
                options={clientOptions}
              />
            </div>

            <div className="button-wrap">
              {loading || missingLoading ? (
                <button>
                  <b>Loading...</b>
                </button>
              ) : (
                <button
                  onClick={() => {
                    dispatch(updateSettingData(defaultValue));
                    handleApiOnAppyClient();
                    setOpenFilterDrawer(false);
                  }}
                >
                  <b>Apply</b>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleApiOnAppyClient = () => {
    handleList();
    handleCampaignListing();``
    handleMediumTransformation(1, pageSize);
    setCurrentPage(1);
  };

  const handleList = async () => {
    setLoading(true);
    let res = await getCampaignMapping(
      defaultValue?.db ? defaultValue?.db : prefilledData?.selectedClient?.db
    );
    if (res.status == 200) {
      setLoading(false);
      let arr = [];

      res?.data?.length > 0 &&
        res?.data?.map((el) => {
          let obj = {
            st_type: el?.st_type,
            platform: el?.platform_type,
            ad_campaigns: el?.ad_campaigns?.map((val) => val?.name),
            cost: el?.custom_cost?.map((val) => val?.cost),
            campaigns_businessunit: el?.campaign?.campaignname
              ? el?.campaign?.campaignname
              : el?.businessunit?.name,
            prefilledData: {
              ...el,
              campaign_source:
                el?.campaign_source?.[0] == "[" &&
                el?.campaign_source?.[el?.campaign_source?.length - 1] == "]"
                  ? JSON.parse(el?.campaign_source)
                  : [el?.campaign_source],
            },
          };
          arr.push(obj);
        });
      setTableData(arr);
    } else {
      setLoading(false);
      // toast.error(
      //   res?.response?.data?.message ||
      //     res?.message ||
      //     res?.error ||
      //     "Something went wrong"
      // );
    }
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    let res = await deleteSettingsMapping(
      deleteId,
      filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db
    );
    if (res?.status == 200) {
      toast.success("Deleted Successfully");
      setDeleteLoading(false);
      setDeleteModal(false);
      handleList();
    } else {
      setDeleteLoading(false);
      // toast.error(
      //   res?.response?.data?.message ||
      //     res?.message ||
      //     res?.error ||
      //     "Something went wrong"
      // );
    }
  };

  const handleCampaignListing = async () => {
    let params = new URLSearchParams();
    params.append("db", defaultValue?.db);
    params.append("include_inactive", true);

    let res = await SettingCampaingsNewApi(params);

    if (res?.status === 200) {
      const modify = res?.data?.map((ele) => ({
        ...ele,
        label: ele?.name,
        value: ele?.id,
      }));
      setCampaignListing(modify);
    } else {
      toast.error(res?.message || res?.error || "Something went wrong");
    }
  };

  const handleMediumTransformation = async (page, limit) => {
    let params = new URLSearchParams();
    params.append("client", defaultValue?.key);
    params.append("page", page || currentPage);
    params.append("page_size", limit || pageSize);
    missingCampaign && params.append("campaign", null);

    let res = await MediumTransformationApi(params);

    if (res?.status === 200) {
      let p = page || currentPage;
      if (p > 1 && res?.data?.length == 0) {
        handleApiOnAppyClient();
        return
      }
      setMediumLisnting(res?.data);
      setTotalData(res?.total);
      setLoadingTable(false);
      setMissingLoading(false);
      
    } else {
      toast.error(res?.message || res?.error || "Something went wrong");
      setMissingLoading(false);
      setLoadingTable(false);
    }
  };

  const updateMediumTransformation = async (payload) => {
    let params = new URLSearchParams();
    params.append("client", defaultValue?.key);

    let res = await MediumTransformationPutApi(params, payload);

    if (res?.status !== 200) {
      toast.error(res?.message || res?.error || "Something went wrong");
    }
  };

  const handleAction = (index) => {
    if (index === 0) {
      setMapping(false);
    } else if (index === 1) {
      setMapping(true);
    }
    {
      setToggle(false);
    }
  };

  useEffect(() => {
    handleList();
    getClientOption();
    handleCampaignListing();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setMissingLoading(true);
    handleMediumTransformation(1, pageSize);
  }, [missingCampaign]);

  return (
    <>
      <Header
        title="Client Settings"
        clientInfo={defaultValue}
        isFilter={true}
        openFilter={() => setOpenFilterDrawer(true)}
        isNotification={true}
      />

      <TableWrapper selectedThemeColors={selectedThemeColors}>
        {deleteModal && (
          <DeleteModal
            handleClose={() => setDeleteModal(false)}
            open={deleteModal}
            title="Are you sure you want to Delete ?"
            description=""
            button="Delete"
            btnColor="red"
            handleSubmit={handleDelete}
            isLoading={deleteLoading}
          />
        )}
        {editDrawer && (
          <Drawer
            className="filter-drawer"
            placement="right"
            closable={false}
            onClose={() => setEditDrawer(false)}
            open={editDrawer}
            width={"600px"}
            key="bottom"
          >
            <EditSettings
              handleListing={handleList}
              setFilterDrawer={setEditDrawer}
              editData={editData}
            />
          </Drawer>
        )}

        {openFilterDrawer && (
          <Drawer
            className="filter-drawer"
            placement="right"
            closable={false}
            onClose={() => handleCloseDrawer()}
            open={openFilterDrawer}
            width={"400px"}
            key="bottom"
          >
            <FilterComponent />
          </Drawer>
        )}

        {viewModal && (
          <Modal
            open={viewModal}
            centered
            width={448}
            footer={false}
            onCancel={() => setViewModal(false)}
          >
            <div className="viewModal">
              {viewData?.length > 0 &&
                viewData.map((el, index) => (
                  <div>
                    <span>
                      {index + 1}. {el}
                    </span>
                    <br />
                  </div>
                ))}
            </div>
          </Modal>
        )}

        {loading || missingLoading ? (
          <LoadingBlock height={"calc(100vh - 160px)"} size={28} />
        ) : (
          <div className="">
            {openDrawer && (
              <Drawer
                className="filter-drawer"
                placement="right"
                closable={false}
                onClose={() => setFilterDrawer(false)}
                open={openDrawer}
                width={"600px"}
                key="bottom"
              >
                <AddSettings
                  handleListing={handleList}
                  setFilterDrawer={setFilterDrawer}
                />
              </Drawer>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TabComponent
                index={mapping ? 1 : 0}
                tabs={["Clients", "Mapping Campaigns"]}
                selectedIndex={handleAction}
              />
              <div className="filterBtn-Box">
                {mapping ? (
                  <ActiveButton
                    active={missingCampaign}
                    onClick={() => setMissingCampaign(!missingCampaign)}
                  >
                    Missing Campaign
                  </ActiveButton>
                ) : (
                  <button onClick={() => setFilterDrawer(true)}>
                    <FaPlus style={{ color: "#ffffff" }} /> Add Campaign
                  </button>
                )}
              </div>
            </div>

            {mapping ? (
              <>
                {loadingTable ? (
                  <LoadingBlock height={"calc(100vh - 180px)"} size={28} />
                ) : (
                  <Table
                    prefixCls="custom-ui-table"
                    columns={campaignColumnsSett}
                    dataSource={mediumListing}
                    pagination={{
                      hideOnSinglePage: true,
                      current: currentPage,
                      pageSize: pageSize,
                      onChange: handlePageChange,
                      showSizeChanger: false,
                      total: totalData,
                    }}
                    scroll={{
                      x: "100%",
                    }}
                  />
                )}
              </>
            ) : (
              <Table
                prefixCls="custom-ui-table"
                columns={columns}
                dataSource={tableData}
                pagination={false}
                scroll={{
                  x: "100%",
                }}
              />
            )}
          </div>
        )}
      </TableWrapper>
      {!loading && <Watermark style={{ padding: "0 10px 6px" }} />}
    </>
  );
};

export default SettingsTable;

const TableWrapper = styled.div`
  background: ${({ selectedThemeColors }) =>
    selectedThemeColors?.bgMainColor} !important;
  width: 100%;
  min-height: 80vh;
  min-height: calc(100vh - 80px);
  max-height: 100%;
  padding: 20px;

  .client-name {
    color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.textPrimaryColor};
    font-size: 16px;
  }

  .filterBtn-Box {
    margin: 0 !important;
    button {
      height: 33px;
      padding: 4px 11px;
      border-style: none;
      border-radius: 6px;
      font-weight: 400;
      color: white;
      font-size: 12px;
      background: ${({ selectedThemeColors }) =>
        selectedThemeColors?.headerColor};
    }
  }

  .campaignTable {
    .campaignTable-thead {
      .campaignTable-cell {
        background: ${({ selectedThemeColors }) =>
          selectedThemeColors?.headerColor} !important;
      }
    }
    .campaignTable-tbody {
      .campaignTable-cell {
        border-bottom: 1px solid rgba(232, 232, 237, 1) !important;
        background: ${({ selectedThemeColors }) =>
          selectedThemeColors?.CardsColor} !important;
        color: ${({ selectedThemeColors }) =>
          selectedThemeColors?.textPrimaryColor} !important;
      }
      .campaignTable-cell-row-hover {
        background: rgba(241, 241, 241, 1) !important;
      }
    }
    .campaignTable-container {
      max-height: calc(100vh - 160px) !important;
      overflow: auto !important;
    }
    .campaignTable-thead {
      position: sticky !important;
      top: 0 !important;
      z-index: 99 !important;
    }
  }
  .settinglogoWrap {
    display: flex;
    gap: 10px;
    align-items: center;
    img {
      height: 47px;
      object-fit: cover;
    }
  }
`;

const ActiveButton = styled.button`
  background: ${({ active }) =>
    active ? "#dbebf2" : "transparent"} !important;
  border: ${({ active }) =>
    active ? "1px solid #00a1e6" : "1px solid #7A7B7A"} !important;
  color: ${({ active }) => (active ? "#00a1e6" : "#7A7B7A")} !important;
  height: 32px !important;
  padding: 6px 13px !important;
  border-radius: 6px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
`;
