import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Progress, Skeleton, Tooltip } from "antd";
import styled from "styled-components";
import Speedometer from "../../components/Speedometer";
import FilterComponent from "../../components/FilterComponent";
import ApexBar3 from "../../components/Graph";
import { RxReload } from "react-icons/rx";
import {
  getAbandonedRate,
  getAVGTickets,
  getBindData,
  getCancelation,
  getClosing,
  getCostPerJob,
  getCostPerLead,
  getDashBoardContent,
  getExcusedRate,
  getFacebookData,
  getGoogleData,
  getIndicator,
  getIsaData,
  getLeadCalls,
  getTotalBooking,
  getUnbookedCalls,
  getYelpData,
} from "../../services/Collections";
import { toast } from "react-toastify";

import useWindowWidth from "../../customHook/GetWindowWidth";
import Header from "../../components/Header";
import { PoweredBy } from "../../layouts/privateLayouts/PrivateLayout";
import Watermark from "../../components/Watermark";
import { set } from "lodash";

const Home2 = () => {
  const [totalBooking, setTotalBooking] = useState(null);
  const [channelCheckActive, setChannelCheckActive] = useState(true);
  const [ignoreActive, setIgnoreActive] = useState(false);
  const [isCompareDisabled, setIsCompareDisabled] = useState(true);
  const [totalBookingLoader, setTotalBookingLoader] = useState(true);
  const [indicatorData, setIndicatorData] = useState(null);
  const [indicatorLoader, setIndicatorLoader] = useState(true);
  const [unbookedData, setUnbookedData] = useState(null);
  const [unbookedLoader, setUnbookedLoader] = useState(true);
  const [excusedData, setExcusedData] = useState(null);
  const [excusedLoader, setExcusedLoader] = useState(true);
  const [abandonedData, setAbandonedData] = useState(null);
  const [abandonedLoader, setAbandonedLoader] = useState(true);
  const [leadCallsData, setLeadCallsData] = useState(null);
  const [inboundCalls, setIboundCallData] = useState(null);
  const [leadCallsLoader, setLeadCallsLoader] = useState(true);
  const [cancelationData, setCancelationData] = useState(null);
  const [cancelationLoader, setCancelationLoader] = useState(true);
  const [closingData, setClosingData] = useState(null);
  const [closingLoader, setClosingLoader] = useState(true);
  const [costPerLeadData, setCostPerLeadData] = useState(null);
  const [costPerLeadLoader, setCostPerLeadLoader] = useState(true);
  const [costPerJobData, setCostPerJobData] = useState(null);
  const [costPerJobLoader, setCostPerJobLoader] = useState(true);
  const [googleData, setGoogleData] = useState(null);
  const [bingData, setBingData] = useState(null);
  const [avgTicketsData, setAvgTicketsData] = useState(null);
  const [facebookData, setFacebookData] = useState(null);
  const [yelpData, setYelpData] = useState(null);
  const [isaData, setIsaData] = useState(null);
  const [googleLoader, setGoogleLoader] = useState(false);
  const [bingLoader, setBingLoader] = useState(false);
  const [fbLoader, setFbLoader] = useState(false);
  const [yelpLoader, setYelpLoader] = useState(false);
  const [isaLoader, setIsaLoader] = useState(false);
  const [avgTicketsLoader, setAvgTicketsLoader] = useState(true);
  const [refreshPayload, setRefreshPayload] = useState(null);
  const [inboundCallsLoader, setInboundCallsLoader] = useState(false);
  const [companyRevenue, setCompanyRevenue] = useState(null);
  const [companyRevLoader, setCompanyRevLoader] = useState(false);
  const [companySales, setCompanySales] = useState(null);
  const [companySalesLoader, setCompanySalesLoader] = useState(false);
  const alreadyApliedMargin = useSelector(
    (state) => state?.loginAuth?.alreadyAppliedMargin
  );
  const [initialApplyMargin, setInitialApplyMargin] =
    useState(alreadyApliedMargin);
  const prefillMargin = useSelector(
    (state) => state?.loginAuth?.prefilledApplyMargin
  );
  const [applyMarginData, setApplyMarginData] = useState(prefillMargin || []);
  const [openDrawer, setFilterDrawer] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [apiCount, setApiCount] = useState(0);
  const [width] = useWindowWidth();
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );

  const [aiSummaryDrawer, setAiSummaryDrawer] = useState(false);

  const marginOptions = [
    {
      label: "Google",
      value: "Google",
    },
    {
      label: "LSA",
      value: "LSA",
    },
    {
      label: "Facebook",
      value: "Facebook",
    },
    {
      label: "Yelp",
      value: "Yelp",
    },
    {
      label: "Bing",
      value: "Bing",
    },
  ];

  const valueFormatter = (num, digits) => {
    if (num > 999) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
          console.log(item, "valueFormatter if 1");
          return item ? num >= item.value : "0";
        });
      console.log(item, "valueFormatter if 2");
      return item
        ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
        : "0";
    } else {
      console.log(num, "valueFormatter else");
      return num ? Math.floor(num) : "0";
    }
  };

  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );

  const [graphData, setGraphData] = useState([]);

  const manupilateDataForGraph = () => {
    setGraphData([]);
    let array = [];
    if (indicatorData?.current) {
      let tempObject = {
        name: "Current",
        forms: indicatorData?.current?.online_forms ?? 0,
        unique_inbound_calls: indicatorData?.current?.unique_inbound_calls ?? 0,
        inbound_calls: indicatorData?.current?.total_inbound_calls ?? 0,
        data: [
          indicatorData?.current?.online_forms ?? 0,
          indicatorData?.current?.unique_inbound_calls ?? 0,
          indicatorData?.current?.total_inbound_calls ?? 0,
        ],
      };
      array.push(tempObject);
    }
    if (indicatorData?.compare && isCompareDisabled == false) {
      let tempObject = {
        name: "Compare",
        forms: indicatorData?.compare?.online_forms ?? 0,
        unique_inbound_calls: indicatorData?.compare?.unique_inbound_calls ?? 0,
        inbound_calls: indicatorData?.compare?.total_inbound_calls ?? 0,
        data: [
          indicatorData?.compare?.online_forms ?? 0,
          indicatorData?.compare?.unique_inbound_calls ?? 0,
          indicatorData?.compare?.total_inbound_calls ?? 0,
        ],
      };
      array.push(tempObject);
    }
    setGraphData(array);
  };

  const MyGraph = useCallback(() => {
    return (
      <div>
        <ApexBar3 data={graphData} indicatorData={indicatorData} />
        <IndicatorSign>
          {graphData && graphData?.length > 1 && (
            <>
              <div>
                <label className="label-values">Current (C)</label>
                <span className="current-span" />
              </div>
              <div>
                <label className="label-values">Compared (CT)</label>
                <span className="compared-span" />
              </div>
            </>
          )}
        </IndicatorSign>
      </div>
    );
  }, [graphData]);

  const handleTotalBooking = async (id, req) => {
    setTotalBookingLoader(true);
    let res = await getTotalBooking(id, req);
    if (res?.status === 200) {
      setTotalBooking(res?.data);
      setTotalBookingLoader(false);
    } else {
      setTotalBooking(null);
      setTotalBookingLoader(false);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleIndicator = async (id, req) => {
    setIndicatorLoader(true);
    let res = await getIndicator(id, req);
    if (res?.status === 200) {
      setIndicatorData(res?.data);
      setIndicatorLoader(false);
    } else {
      setIndicatorData(null);
      setIndicatorLoader(false);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleUnbooked = async (id, req) => {
    setUnbookedLoader(true);
    let res = await getUnbookedCalls(id, req);
    if (res?.status === 200) {
      setUnbookedData(res?.data);
      setUnbookedLoader(false);
    } else {
      setUnbookedLoader(false);
      setUnbookedData(null);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleExcusedRate = async (id, req) => {
    setExcusedLoader(true);
    let res = await getExcusedRate(id, req);
    if (res?.status === 200) {
      setExcusedData(res?.data);
      setExcusedLoader(false);
    } else {
      setExcusedData(null);
      setExcusedLoader(false);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleAbandonedRate = async (id, req) => {
    setAbandonedLoader(true);
    let res = await getAbandonedRate(id, req);
    if (res?.status === 200) {
      setAbandonedData(res?.data);
      setAbandonedLoader(false);
    } else {
      setAbandonedData(null);
      setAbandonedLoader(false);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleLeadCalls = async (id, req) => {
    setLeadCallsLoader(true);
    let res = await getLeadCalls(id, req);
    if (res?.status === 200) {
      setLeadCallsData(res?.data);
      setLeadCallsLoader(false);
    } else {
      setLeadCallsData(null);
      setLeadCallsLoader(false);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleCancelation = async (id, req) => {
    setCancelationLoader(true);
    let res = await getCancelation(id, req);
    if (res?.status === 200) {
      setCancelationData(res?.data);
      setCancelationLoader(false);
    } else {
      setCancelationData(null);
      setCancelationLoader(false);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleClosing = async (id, req) => {
    setClosingLoader(true);
    let res = await getClosing(id, req);
    if (res?.status === 200) {
      setClosingData(res?.data);
      setClosingLoader(false);
    } else {
      setClosingData(null);
      setClosingLoader(false);
    }

    setApiCount((pre) => pre + 1);
  };

  const handleCostPerLead = async (id, req) => {
    setCostPerLeadLoader(true);
    let res = await getCostPerLead(id, req);
    if (res?.status === 200) {
      setCostPerLeadData(res?.data);
      setCostPerLeadLoader(false);
    } else {
      setCostPerLeadData(null);
      setCostPerLeadLoader(false);
    }

    setApiCount((pre) => pre + 1);
  };

  const handleCostPerJob = async (id, req) => {
    setCostPerJobLoader(true);
    let res = await getCostPerJob(id, req);
    if (res?.status === 200) {
      setCostPerJobData(res?.data);
      setCostPerJobLoader(false);
    } else {
      setCostPerJobData(null);
      setCostPerJobLoader(false);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleAvgTickets = async (id, req) => {
    setAvgTicketsLoader(true);
    let res = await getAVGTickets(id, req);
    if (res?.status === 200) {
      setAvgTicketsData(res?.data);
      setAvgTicketsLoader(false);
    } else {
      setAvgTicketsData(null);
      setAvgTicketsLoader(false);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleGetGooglData = async (id, req) => {
    setGoogleLoader(true);
    let res = await getGoogleData(id, req);
    if (res.status === 200) {
      setGoogleLoader(false);
      setGoogleData(res?.data);
    } else {
      setGoogleLoader(false);
      // toast.error(res.message);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleGetBingData = async (id, req) => {
    setBingLoader(true);
    let res = await getBindData(id, req);
    if (res.status === 200) {
      setBingLoader(false);
      setBingData(res?.data);
    } else {
      setBingLoader(false);
      // toast.error(res.message);
    }
    setApiCount((pre) => pre + 1);
  };

  const handlegetFacebookData = async (id, req) => {
    setFbLoader(true);
    let res = await getFacebookData(id, req);
    if (res.status === 200) {
      setFbLoader(false);
      setFacebookData(res?.data);
    } else {
      setFbLoader(false);
      // toast.error(res.message);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleYelpData = async (id, req) => {
    setYelpLoader(true);
    let res = await getYelpData(id, req);
    if (res.status === 200) {
      setYelpLoader(false);
      setYelpData(res?.data);
    } else {
      setYelpLoader(false);
      // toast.error(res.message);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleGetIsaData = async (id, req) => {
    setIsaLoader(true);
    let res = await getIsaData(id, req);
    if (res.status === 200) {
      setIsaLoader(false);
      setIsaData(res?.data);
    } else {
      setIsaLoader(false);
      // toast.error(res.message);
    }
    setApiCount((pre) => pre + 1);
  };

  const handleCompanyRevenue = async (id, req) => {
    setCompanyRevLoader(true);
    let res = await getDashBoardContent(id, "revenue", req);
    if (res.status === 200) {
      setCompanyRevenue(res?.data);
    }
    setCompanyRevLoader(false);
    setApiCount((pre) => pre + 1);
  };

  const handleCompanySales = async (id, req) => {
    setCompanySalesLoader(true);
    let res = await getDashBoardContent(id, "sales", req);
    if (res.status === 200) {
      setCompanySales(res?.data);
    }
    setCompanySalesLoader(false);
    setApiCount((pre) => pre + 1);
  };

  const handleDashboardApis = (id, req, db) => {
    setFilterLoader(true);
    setApiCount(0);
    handleTotalBooking(id, req);
    handleClosing(id, req);
    handleIndicator(id, req);
    handleUnbooked(id, req);
    handleExcusedRate(id, req);
    handleLeadCalls(id, req);
    handleCancelation(id, req);
    // handleAbandonedRate(id, req);
    // handleCostPerJob(id, req);
    // handleCostPerLead(id, req);
    handleAvgTickets(id, req);
    handleGetGooglData(id, req);
    handleGetBingData(id, req);
    handlegetFacebookData(id, req);
    handleYelpData(id, req);
    handleGetIsaData(id, req);
    handleCompanyRevenue(id, req);
    handleCompanySales(id, req);
  };

  const calculateAbandonedRate = useMemo(() => {
    try {
      const uniqueInboundCalls =
        indicatorData?.current?.unique_inbound_calls || 0;
      const onlineForms = indicatorData?.current?.online_forms || 0;
      const totalJobsBooked = totalBooking?.current?.total_jobs_booked || 0;
      const unbookedCalls = unbookedData?.current?.unbooked_calls || 0;
      const cancelledCalls = cancelationData?.current?.cancelled_calls || 0;
      const excusedCalls = excusedData?.current?.excused_calls || 0;

      const total =
        uniqueInboundCalls +
        onlineForms -
        (totalJobsBooked + unbookedCalls + cancelledCalls + excusedCalls);

      const totalPer =
        uniqueInboundCalls === 0 ? 0 : (total / uniqueInboundCalls) * 100;

      return {
        abandoned_calls: total > 0 ? total : 0,
        abandoned_rate: totalPer > 0 ? totalPer : 0,
      };
    } catch (error) {
      console.error("Error abandoned rate:", error);
      return 0;
    }
  }, [indicatorData, totalBooking, unbookedData, cancelationData, excusedData]);

  const calculateAbandonedRateCompare = useMemo(() => {
    try {
      const uniqueInboundCalls =
        indicatorData?.compare?.unique_inbound_calls || 0;
      const onlineForms = indicatorData?.compare?.online_forms || 0;
      const totalJobsBooked = totalBooking?.compare?.total_jobs_booked || 0;
      const unbookedCalls = unbookedData?.compare?.unbooked_calls || 0;
      const cancelledCalls = cancelationData?.compare?.cancelled_calls || 0;
      const excusedCalls = excusedData?.compare?.excused_calls || 0;

      const total =
        uniqueInboundCalls +
        onlineForms -
        (totalJobsBooked + unbookedCalls + cancelledCalls + excusedCalls);

      const totalPer =
        uniqueInboundCalls === 0 ? 0 : (total / uniqueInboundCalls) * 100;

      return {
        abandoned_calls: total > 0 ? total : 0,
        abandoned_rate: totalPer > 0 ? totalPer : 0,
      };
    } catch (error) {
      console.error("Error abandoned rate:", error);
      return 0;
    }
  }, [indicatorData, totalBooking, unbookedData, cancelationData, excusedData]);

  const isNotEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (apiCount > 14) setFilterLoader(false);
  }, [[apiCount]]);

  useEffect(() => {
    let requestPayload = {
      campaigns: prefilledData?.campaigns,
      compare_enable: prefilledData?.compare_enable,
      date_compare_end: prefilledData?.date_compare_end,
      date_compare_start: prefilledData?.date_compare_start,
      date_end: prefilledData?.date_end,
      date_start: prefilledData?.date_start,
      use_ignore: ignoreActive ? 1 : 0,
    };
    handleDashboardApis(
      prefilledData?.selectedClient?.key,
      requestPayload,
      prefilledData?.selectedClient
    );
    setRefreshPayload(requestPayload);
  }, []);

  useEffect(() => {
    manupilateDataForGraph();
  }, [indicatorData]);

  useEffect(() => {
    if (!inboundCallsLoader) {
      setIboundCallData(inboundCalls);
    }
  }, [inboundCallsLoader]);

  const reloadMainDivStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "136px",
  };

  const RxReloadStyle = {
    fontSize: "30px",
    color: "#000",
    cursor: "pointer",
    marginBottom: "6px",
  };

  return (
    <>
      <Header
        title="Dashboard"
        clientInfo={prefilledData?.selectedClient}
        isNotification={true}
        isAI={false}
        openAiSummary={() => setAiSummaryDrawer(true)}
      />

      {aiSummaryDrawer && (
        <Drawer
          closable
          destroyOnClose
          title={'Ai Summary'}
          placement="bottom"
          open={aiSummaryDrawer}
          loading={true}
          size="large"
          onClose={() => setAiSummaryDrawer(false)}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Drawer>
      )}
      <HomeWrapper
        selectedThemeColors={selectedThemeColors}
        indicatorLoader={indicatorLoader}
      >
        <div style={{ width: "100%", height: "100%" }}>
          {openDrawer && (
            <Drawer
              className="filter-drawer"
              placement="right"
              closable={false}
              onClose={() => setFilterDrawer(false)}
              open={openDrawer}
              width={"500px"}
              key="bottom"
            >
              <FilterComponent
                drawer={true}
                setFilterDrawer={setFilterDrawer}
                handleDashboardApis={handleDashboardApis}
                setChannelCheckActive={setChannelCheckActive}
                channelCheckActive={channelCheckActive}
                setIgnoreActive={setIgnoreActive}
                ignoreActive={ignoreActive}
                setApplyMarginData={setApplyMarginData}
                applyMarginData={applyMarginData}
                marginOptions={marginOptions}
                setInitialApplyMargin={setInitialApplyMargin}
                initialApplyMargin={initialApplyMargin}
                setIsCompareDisabled={setIsCompareDisabled}
                isCompareDisabled={isCompareDisabled}
                filterLoader={filterLoader}
                width={width}
              />
            </Drawer>
          )}

          <FilterDrawerWrapper>
            <button onClick={() => setFilterDrawer(true)} className="button">
              Filters
            </button>
          </FilterDrawerWrapper>
          <div className="dashboardWrapper">
            <GraphSection selectedThemeColors={selectedThemeColors}>
              <div className="first-row">
                {totalBookingLoader ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "60%",
                    }}
                    className="total-bookings"
                  >
                    <Skeleton active prefixCls="custom-skeleton-booking" />
                  </div>
                ) : totalBooking != null ? (
                  <div
                    className="total-bookings"
                    style={{ width: "60%", position: "relative" }}
                  >
                    <h1
                      style={{
                        display: "flex",
                        alignItems: "end",
                        gap: "5px",
                      }}
                    >
                      {valueFormatter(
                        totalBooking?.current?.unique_call_booking_perc,
                        1
                      )}
                      %{" "}
                      <span
                        style={{
                          fontSize: "13px",
                          fontWeight: 500,
                          color: "rgb(146, 146, 165)",
                          marginBottom: "9px",
                        }}
                      >
                        (C)
                      </span>
                    </h1>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <p>Total Bookings</p>
                      <InfoIconComp title={"Client Avg: 60%"} />
                    </div>
                    <Progress
                      percent={totalBooking?.current?.unique_call_booking_perc}
                      size="50"
                      status="active"
                      showInfo={false}
                      strokeColor={selectedThemeColors?.progressColor}
                      trailColor={selectedThemeColors?.progressBgColor}
                    />
                    <div className="progress-text">
                      <span>{totalBooking?.current?.total_jobs_booked}</span>
                    </div>
                    {(totalBooking?.compare?.unique_call_booking_perc ||
                      totalBooking?.compare?.total_jobs_booked) && (
                      <Comparedetails
                        style={{ position: "absolute", bottom: "15px" }}
                        // style={{ padding: "0px" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            color: "#9292a5",
                          }}
                        >
                          <span className="percentage">
                            {valueFormatter(
                              totalBooking?.compare?.unique_call_booking_perc,
                              1
                            )}
                            %
                          </span>
                          <span className="values">
                            ({totalBooking?.compare?.total_jobs_booked})
                          </span>
                          Vs Compared (CT)
                        </p>
                      </Comparedetails>
                    )}
                  </div>
                ) : (
                  <div
                    className="total-bookings"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="try-again-div">
                      <RxReload
                        className="reload-icon"
                        onClick={() =>
                          handleTotalBooking(
                            prefilledData?.selectedClient?.key,
                            refreshPayload
                          )
                        }
                      />
                      <h6>Try again</h6>
                    </div>
                  </div>
                )}
                {closingLoader ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "60%",
                    }}
                    className="total-bookings"
                  >
                    <Skeleton prefixCls="custom-skeleton-booking" active />
                  </div>
                ) : closingData !== null ? (
                  <div
                    style={{ position: "relative", width: "60%" }}
                    className="total-bookings"
                  >
                    <div>
                      <h1
                        style={{
                          display: "flex",
                          alignItems: "end",
                          gap: "5px",
                        }}
                      >
                        {valueFormatter(closingData?.current?.closing_perc, 1)}%{" "}
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: 500,
                            color: "rgb(146, 146, 165)",
                            marginBottom: "9px",
                          }}
                        >
                          (C)
                        </span>
                      </h1>
                      <InfoWrapper>
                        <span className="closing-percnt">
                          CLOSING{" "}
                          <h5>({closingData?.current?.number_closed})</h5>
                        </span>
                        <p className="information-icon-closing">
                          <Tooltip
                            title={
                              <span>
                                Number of Sales / Number of jobs booked <br />{" "}
                                Client Avg: 55%
                              </span>
                            }
                            color={"rgb(216, 12, 12)"}
                          >
                            <i
                              style={{ marginTop: "-3px" }}
                              className="card-info-icon"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ width: "17px", height: "17px" }}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-info"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                              </svg>
                            </i>
                          </Tooltip>
                        </p>
                      </InfoWrapper>
                      {(closingData?.compare?.closing_perc ||
                        closingData?.compare?.number_closed) &&
                        closingData?.compare && (
                          <Comparedetails
                            style={{ position: "absolute", bottom: "15px" }}
                          >
                            <p
                              className="compared-details"
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                                color: "#9292a5",
                              }}
                            >
                              <span className="percentage">
                                {valueFormatter(
                                  closingData?.compare?.closing_perc,
                                  1
                                )}
                                %
                              </span>
                              <span className="values">
                                ({closingData?.compare?.number_closed})
                              </span>
                              Vs Compared (CT)
                            </p>
                          </Comparedetails>
                        )}
                    </div>
                  </div>
                ) : (
                  <div
                    className="total-bookings"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="try-again-div">
                      <RxReload
                        className="reload-icon"
                        onClick={() =>
                          handleClosing(
                            prefilledData?.selectedClient?.key,
                            refreshPayload
                          )
                        }
                      />
                      <h6>Try again</h6>
                    </div>
                  </div>
                )}

                <div className="indicator" style={{ width: "100%" }}>
                  {indicatorLoader ? (
                    <Skeleton
                      active
                      prefixCls="indicator-loading"
                      style={{ marginTop: "44px", padding: "0px 30px" }}
                    />
                  ) : indicatorData !== null ? (
                    graphData?.length > 0 && (
                      <>
                        <h1>Indicator</h1>
                        {graphData && <MyGraph />}
                      </>
                    )
                  ) : (
                    <div
                      className="total-bookings"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <div className="try-again-div">
                        <RxReload
                          className="reload-icon"
                          onClick={() =>
                            handleIndicator(
                              prefilledData?.selectedClient?.key,
                              refreshPayload
                            )
                          }
                        />
                        <h6>Try again</h6>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="secondRowWrapper">
                <div
                  className="second-row"
                  style={{ padding: "30px 22px", minHeight: "235px" }}
                >
                  <div
                    className="inner-content"
                    style={{
                      gap: "8px",
                      gridTemplateColumns: "repeat(3, 1fr)",
                    }}
                  >
                    {unbookedLoader ? (
                      <Skeleton active />
                    ) : unbookedData == null ? (
                      <div
                        className="total-bookings"
                        style={reloadMainDivStyle}
                      >
                        <div
                          className="try-again-div"
                          style={{ textAlign: "center", color: "#000" }}
                        >
                          <RxReload
                            style={RxReloadStyle}
                            onClick={() => {
                              handleUnbooked(
                                prefilledData?.selectedClient?.key,
                                refreshPayload
                              );
                            }}
                          />
                          <h6> Try again </h6>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h1
                          style={{
                            display: "flex",
                            alignItems: "end",
                            gap: "5px",
                          }}
                        >
                          {valueFormatter(
                            unbookedData?.current?.unbooked_rate,
                            1
                          )}
                          %{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: 500,
                              marginBottom: "9px",
                            }}
                          >
                            (C)
                          </span>
                        </h1>
                        <InfoWrapper style={{ marginTop: "12px" }}>
                          <span>
                            UNBOOKED{" "}
                            <h5 className="infoInner">
                              ({unbookedData?.current?.unbooked_calls})
                            </h5>
                          </span>
                          <InfoIconComp title={"Client Avg: 2%"} />
                        </InfoWrapper>
                        {unbookedData?.compare &&
                          unbookedData.compare.unbooked_rate != null &&
                          unbookedData.compare.unbooked_calls != null && (
                            <Comparedetails
                              style={{ position: "absolute", bottom: "-8px" }}
                            >
                              <p>
                                <span className="percentage">
                                  {valueFormatter(
                                    unbookedData.compare.unbooked_rate,
                                    1
                                  )}
                                  %
                                </span>
                                <span className="values">
                                  ({unbookedData.compare.unbooked_calls})
                                </span>
                                Vs Compared (CT)
                              </p>
                            </Comparedetails>
                          )}
                      </div>
                    )}

                    {excusedLoader ? (
                      <Skeleton active />
                    ) : excusedData == null ? (
                      <div
                        className="total-bookings"
                        style={reloadMainDivStyle}
                      >
                        <div
                          className="try-again-div"
                          style={{ textAlign: "center", color: "#000" }}
                        >
                          <RxReload
                            style={RxReloadStyle}
                            onClick={() => {
                              handleExcusedRate(
                                prefilledData?.selectedClient?.key,
                                refreshPayload
                              );
                            }}
                          />
                          <h6> Try again </h6>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h1
                          style={{
                            display: "flex",
                            alignItems: "end",
                            gap: "5px",
                          }}
                        >
                          {valueFormatter(
                            excusedData?.current?.excused_rate,
                            1
                          )}
                          %{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: 500,
                              marginBottom: "9px",
                            }}
                          >
                            (C)
                          </span>
                        </h1>
                        <InfoWrapper>
                          <span>
                            EXCUSED RATE{" "}
                            <h5 className="infoInner">
                              ({excusedData?.current?.excused_calls})
                            </h5>
                          </span>
                          <p className="information-icon">
                            {" "}
                            <Tooltip
                              title={
                                <span>
                                  Percentage of incoming call excused. (Any call
                                  answered that lasts less than 60 seconds will
                                  automatically be classified as an excused call
                                  or else if a call lasts over 60 seconds, CSR
                                  can manually excuses the call) <br /> Client
                                  Avg: 5%
                                </span>
                              }
                              color={"rgb(216, 12, 12)"}
                            >
                              <i
                                style={{
                                  width: "10px",
                                  height: "10px",
                                }}
                                className="card-info-icon"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ width: "18px", height: "18px" }}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-info"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="12" y1="16" x2="12" y2="12"></line>
                                  <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                </svg>
                              </i>
                            </Tooltip>
                          </p>
                        </InfoWrapper>

                        {excusedData?.compare &&
                          excusedData?.compare?.excused_rate != null &&
                          excusedData?.compare?.excused_calls != null && (
                            <Comparedetails
                              style={{ position: "absolute", bottom: "-8px" }}
                            >
                              <p>
                                <span className="percentage">
                                  {valueFormatter(
                                    excusedData?.compare?.excused_rate,
                                    1
                                  )}
                                  %
                                </span>
                                <span className="values">
                                  ({excusedData?.compare?.excused_calls})
                                </span>
                                Vs Compared (CT)
                              </p>
                            </Comparedetails>
                          )}
                      </div>
                    )}

                    {excusedLoader ||
                    totalBookingLoader ||
                    indicatorLoader ||
                    unbookedLoader ||
                    cancelationLoader ? (
                      <Skeleton active />
                    ) : excusedData == null ||
                      totalBooking == null ||
                      indicatorData == null ||
                      unbookedData == null ||
                      cancelationData == null ? (
                      <div
                        className="total-bookings"
                        style={reloadMainDivStyle}
                      >
                        <div
                          className="try-again-div"
                          style={{ textAlign: "center", color: "#000" }}
                        >
                          <RxReload
                            style={RxReloadStyle}
                            onClick={() => {
                              handleAbandonedRate(
                                prefilledData?.selectedClient?.key,
                                refreshPayload
                              );
                            }}
                          />
                          <h6> Try again </h6>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h1
                          style={{
                            display: "flex",
                            alignItems: "end",
                            gap: "5px",
                          }}
                        >
                          {valueFormatter(
                            calculateAbandonedRate?.abandoned_rate,
                            1
                          )}
                          %
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: 500,
                              marginBottom: "9px",
                            }}
                          >
                            (C)
                          </span>
                        </h1>
                        <InfoWrapper>
                          <span>
                            ABANDONED RATE{" "}
                            <h5 className="infoInner">
                              {/* ({abandonedData?.current?.abandoned_calls}) */}
                              ({calculateAbandonedRate?.abandoned_calls})
                            </h5>
                          </span>
                          <p className="information-icon">
                            {" "}
                            <Tooltip
                              title={
                                <span>
                                  Percentage of incoming call abandoned <br />{" "}
                                  Client Avg: 5%
                                </span>
                              }
                              color={"rgb(216, 12, 12)"}
                            >
                              <i
                                style={{
                                  width: "10px",
                                  height: "10px",
                                }}
                                className="card-info-icon"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ width: "18px", height: "18px" }}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-info"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="12" y1="16" x2="12" y2="12"></line>
                                  <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                </svg>
                              </i>
                            </Tooltip>
                          </p>
                        </InfoWrapper>
                        {prefilledData?.compare_enable === 1 &&
                          (calculateAbandonedRateCompare?.abandoned_calls !=
                            null ||
                            calculateAbandonedRateCompare?.abandoned_rate !=
                              null) && (
                            <Comparedetails
                              style={{ position: "absolute", bottom: "-8px" }}
                            >
                              <p>
                                <span className="percentage">
                                  {valueFormatter(
                                    calculateAbandonedRateCompare?.abandoned_rate,
                                    1
                                  )}
                                  %
                                </span>
                                <span className="values">
                                  (
                                  {
                                    calculateAbandonedRateCompare?.abandoned_calls
                                  }
                                  )
                                </span>
                                Vs Compared (CT)
                              </p>
                            </Comparedetails>
                          )}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="second-row"
                  style={{ padding: "30px 22px", minHeight: "235px" }}
                >
                  <div
                    className={!leadCallsLoader && `inner-content`}
                    style={{
                      gap: "8px",
                      gridTemplateColumns: "auto",
                      justifyContent: "center",
                    }}
                  >
                    {leadCallsLoader ? (
                      <Skeleton active />
                    ) : leadCallsData == null ? (
                      <div
                        className="total-bookings"
                        style={reloadMainDivStyle}
                      >
                        <div
                          className="try-again-div"
                          style={{ textAlign: "center", color: "#000" }}
                        >
                          <RxReload
                            style={RxReloadStyle}
                            onClick={() => {
                              handleLeadCalls(
                                prefilledData?.selectedClient?.key,
                                refreshPayload
                              );
                            }}
                          />
                          <h6> Try again </h6>
                        </div>
                      </div>
                    ) : (
                      <div className="speedometer-section">
                        <div className="title">
                          <InfoWrapper
                            style={{
                              marginTop: "10px",
                              justifyContent: "center",
                            }}
                          >
                            <span style={{ flexDirection: "row" }}>
                              LEAD CALLS{" "}
                              <p>({leadCallsData?.current?.lead_calls})</p>{" "}
                            </span>
                            <p className="information-icon-lead-calls">
                              {" "}
                              <Tooltip
                                title={
                                  <span>
                                    (Number of inbound calls &ge; 60 seconds or
                                    a job was booked) / Total Inbound Calls{" "}
                                    <br /> Client Avg: 80%
                                  </span>
                                }
                                color={"rgb(216, 12, 12)"}
                              >
                                <i className="card-info-icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ width: "21px", height: "21px" }}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-info"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line
                                      x1="12"
                                      y1="16"
                                      x2="12"
                                      y2="12"
                                    ></line>
                                    <line
                                      x1="12"
                                      y1="8"
                                      x2="12.01"
                                      y2="8"
                                    ></line>
                                  </svg>
                                </i>
                              </Tooltip>
                            </p>
                          </InfoWrapper>
                        </div>
                        <div className="speedometer">
                          <Speedometer
                            value={leadCallsData?.current?.lead_calls_perc}
                          />
                        </div>
                        <div className="speedo-inner-text">
                          <h3>
                            {leadCallsData?.current?.lead_calls_perc.toFixed(2)}
                            %{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                paddingTop: "5px",
                                fontWeight: 500,
                                color: "rgb(146, 146, 165)",
                              }}
                            >
                              (C)
                            </span>
                          </h3>

                          {(leadCallsData?.compare?.lead_calls_perc ||
                            leadCallsData?.compare?.lead_calls) && (
                            <Comparedetails>
                              <p className="speedometer-compare">
                                <span className="percentage">
                                  {valueFormatter(
                                    leadCallsData?.compare?.lead_calls_perc,
                                    1
                                  )}
                                  %
                                </span>
                                <span className="values">
                                  ({leadCallsData?.compare?.lead_calls})
                                </span>
                                Vs Compared (CT)
                              </p>
                            </Comparedetails>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="second-row"
                style={{
                  padding: "30px 22px",
                }}
              >
                <div
                  className="inner-content"
                  style={{ paddingBottom: "26px", height: "100%", gap: "8px" }}
                >
                  {cancelationLoader ? (
                    <Skeleton active />
                  ) : cancelationData === null ? (
                    <div className="total-bookings" style={reloadMainDivStyle}>
                      <div
                        className="try-again-div"
                        style={{ textAlign: "center", color: "#000" }}
                      >
                        <RxReload
                          style={RxReloadStyle}
                          onClick={() => {
                            handleCancelation(
                              prefilledData?.selectedClient?.key,
                              refreshPayload
                            );
                          }}
                        />
                        <h6> Try again </h6>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h1
                        style={{
                          display: "flex",
                          alignItems: "end",
                          gap: "5px",
                        }}
                      >
                        {valueFormatter(
                          cancelationData?.current?.cancellation_rate,
                          1
                        )}
                        %
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: 500,
                            marginBottom: "9px",
                          }}
                        >
                          (C)
                        </span>
                      </h1>
                      <InfoWrapper style={{ paddingTop: "2px" }}>
                        <span>
                          CANCELLATION{" "}
                          <h5>({cancelationData?.current?.cancelled_calls})</h5>
                        </span>
                        <InfoIconComp title={"Client Avg: 2%"} />
                      </InfoWrapper>

                      {(cancelationData?.compare?.cancellation_rate ||
                        cancelationData?.compare?.cancelled_calls) && (
                        <Comparedetails
                          style={{ position: "absolute", bottom: "-8px" }}
                        >
                          <p>
                            <span className="percentage">
                              {valueFormatter(
                                cancelationData?.compare?.cancellation_rate,
                                1
                              )}
                              %
                            </span>
                            <span className="values">
                              ({cancelationData?.compare?.cancelled_calls})
                            </span>
                            Vs Compared (CT)
                          </p>
                        </Comparedetails>
                      )}
                    </div>
                  )}

                  {avgTicketsLoader ? (
                    <Skeleton active />
                  ) : avgTicketsData === null ? (
                    <div className="total-bookings" style={reloadMainDivStyle}>
                      <div
                        className="try-again-div"
                        style={{ textAlign: "center", color: "#000" }}
                      >
                        <RxReload
                          style={RxReloadStyle}
                          onClick={() => {
                            handleAvgTickets(
                              prefilledData?.selectedClient?.key,
                              refreshPayload
                            );
                          }}
                        />
                        <h6> Try again </h6>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h1
                        style={{
                          display: "flex",
                          alignItems: "end",
                          gap: "5px",
                        }}
                      >
                        $
                        {valueFormatter(avgTicketsData?.current?.avg_ticket, 1)}{" "}
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: 500,
                            marginBottom: "9px",
                          }}
                        >
                          (C)
                        </span>
                      </h1>
                      <InfoWrapper style={{ paddingTop: "2px" }}>
                        <span>AVG TICKET </span>
                        <InfoIconComp
                          style={{ margin: 0 }}
                          title={"Client Avg: 1.5k"}
                        />
                      </InfoWrapper>
                      {avgTicketsData?.compare?.avg_ticket && (
                        <Comparedetails
                          style={{ position: "absolute", bottom: "-3px" }}
                        >
                          <p>
                            <span className="values">
                              {/* {avgTicketsData?.compare?.avg_ticket?.toFixed(1)} */}
                              $
                              {valueFormatter(
                                avgTicketsData?.compare?.avg_ticket,
                                1
                              )}{" "}
                            </span>
                            Vs Compared (CT)
                          </p>
                        </Comparedetails>
                      )}
                    </div>
                  )}

                  {companyRevLoader ? (
                    <Skeleton active />
                  ) : companyRevenue === null ? (
                    <div className="total-bookings" style={reloadMainDivStyle}>
                      <div
                        className="try-again-div"
                        style={{ textAlign: "center", color: "#000" }}
                      >
                        <RxReload
                          style={RxReloadStyle}
                          onClick={() => {
                            handleCompanyRevenue(
                              prefilledData?.selectedClient?.key,
                              refreshPayload
                            );
                          }}
                        />
                        <h6> Try again </h6>
                      </div>
                    </div>
                  ) : (
                    <div style={{ margin: "0 15px" }}>
                      <h1
                        style={{
                          display: "flex",
                          alignItems: "end",
                          gap: "5px",
                        }}
                      >
                        ${valueFormatter(companyRevenue?.current?.revenue, 1)}
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: 500,
                            marginBottom: "9px",
                          }}
                        >
                          (C)
                        </span>
                      </h1>
                      <InfoWrapper style={{ paddingTop: "2px" }}>
                        <span>INVOICE TOTAL</span>
                        {/* <span>COMPANY REVENUE </span> */}
                        {/* <InfoIconComp title={"Client Avg: 2%"} /> */}
                      </InfoWrapper>

                      {companyRevenue?.compare?.revenue && (
                        <Comparedetails
                          style={{ position: "absolute", bottom: "-3px" }}
                        >
                          <p>
                            <span className="values">
                              $
                              {valueFormatter(
                                companyRevenue?.compare?.revenue,
                                1
                              )}
                            </span>
                            Vs Compared (CT)
                          </p>
                        </Comparedetails>
                      )}
                    </div>
                  )}

                  {companySalesLoader ? (
                    <Skeleton active />
                  ) : companySales === null ? (
                    <div className="total-bookings" style={reloadMainDivStyle}>
                      <div
                        className="try-again-div"
                        style={{ textAlign: "center", color: "#000" }}
                      >
                        <RxReload
                          style={RxReloadStyle}
                          onClick={() => {
                            handleCompanySales(
                              prefilledData?.selectedClient?.key,
                              refreshPayload
                            );
                          }}
                        />
                        <h6> Try again </h6>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h1
                        style={{
                          display: "flex",
                          alignItems: "end",
                          gap: "5px",
                        }}
                      >
                        ${valueFormatter(companySales?.current?.sales, 1)}
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: 500,
                            marginBottom: "9px",
                          }}
                        >
                          (C)
                        </span>
                      </h1>
                      <InfoWrapper style={{ paddingTop: "2px" }}>
                        <span>COMPANY SALES </span>
                        {/* <InfoIconComp title={"Client Avg: 2%"} /> */}
                      </InfoWrapper>

                      {companySales?.compare?.sales && (
                        <Comparedetails
                          style={{ position: "absolute", bottom: "-3px" }}
                        >
                          <p>
                            <span className="values">
                              ${valueFormatter(companySales?.compare?.sales, 1)}
                            </span>
                            Vs Compared (CT)
                          </p>
                        </Comparedetails>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {!avgTicketsLoader && (
                <Watermark sub="ServiceTitan" style={{ paddingTop: "8px" }} />
              )}
            </GraphSection>
            {width > 500 && (
              <div className="filters-section">
                <FilterComponent
                  handleDashboardApis={handleDashboardApis}
                  setChannelCheckActive={setChannelCheckActive}
                  channelCheckActive={channelCheckActive}
                  setIgnoreActive={setIgnoreActive}
                  ignoreActive={ignoreActive}
                  setApplyMarginData={setApplyMarginData}
                  applyMarginData={applyMarginData}
                  marginOptions={marginOptions}
                  setInitialApplyMargin={setInitialApplyMargin}
                  initialApplyMargin={initialApplyMargin}
                  setIsCompareDisabled={setIsCompareDisabled}
                  isCompareDisabled={isCompareDisabled}
                  filterLoader={filterLoader}
                  width={width}
                />
              </div>
            )}
          </div>
          <>
            <div className="channel-campaign-row">
              <div className="channel-campaign-inner">
                {!googleLoader ? (
                  <>
                    <h1 className="channel-iner-heading">GOOGLE</h1>
                    <h5 className="channel-iner-heading2">CURRENT (C)</h5>
                    <p>
                      Revenue{" "}
                      <span>
                        ${valueFormatter(googleData?.current?.revenue, 1)}
                      </span>
                    </p>
                    <p>
                      Cost/Job{" "}
                      <span>
                        ${valueFormatter(googleData?.current?.cost_per_job, 1)}
                      </span>
                    </p>
                    {/* <p>
                      Cost/Lead{" "}
                      <span>
                        ${valueFormatter(googleData?.current?.cost_per_lead, 1)}
                      </span>
                    </p> */}
                    <p>
                      ROAS <span>{googleData?.current?.roas?.toFixed(1)}</span>
                    </p>
                    <p>
                      Total Spend{" "}
                      <span>
                        ${valueFormatter(googleData?.current?.total_spend, 1)}
                      </span>
                    </p>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Skeleton active />
                  </div>
                )}
              </div>

              <div className="channel-campaign-inner">
                {!fbLoader ? (
                  <>
                    <h1 className="channel-iner-heading">FACEBOOK</h1>
                    <h5 className="channel-iner-heading2">CURRENT (C)</h5>
                    <p>
                      Revenue{" "}
                      <span>
                        ${valueFormatter(facebookData?.current?.revenue, 1)}
                      </span>
                    </p>
                    <p>
                      Cost/Job{" "}
                      <span>
                        $
                        {valueFormatter(facebookData?.current?.cost_per_job, 1)}
                      </span>
                    </p>
                    {/* <p>
                      Cost/Lead{" "}
                      <span>
                        $
                        {valueFormatter(
                          facebookData?.current?.cost_per_lead,
                          1
                        )}
                      </span>
                    </p> */}
                    <p>
                      ROAS{" "}
                      <span>{facebookData?.current?.roas?.toFixed(1)}</span>
                    </p>
                    <p>
                      Total Spend{" "}
                      <span>
                        ${valueFormatter(facebookData?.current?.total_spend, 1)}
                      </span>
                    </p>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Skeleton active />
                  </div>
                )}
              </div>

              <div className="channel-campaign-inner">
                {!yelpLoader ? (
                  <>
                    <h1 className="channel-iner-heading">YELP</h1>
                    <h5 className="channel-iner-heading2">CURRENT (C)</h5>
                    <p>
                      Revenue{" "}
                      <span>
                        ${valueFormatter(yelpData?.current?.revenue, 1)}
                      </span>
                    </p>
                    <p>
                      Cost/Job{" "}
                      <span>
                        ${valueFormatter(yelpData?.current?.cost_per_job, 1)}
                      </span>
                    </p>
                    {/* <p>
                      Cost/Lead{" "}
                      <span>
                        ${valueFormatter(yelpData?.current?.cost_per_lead, 1)}
                      </span>
                    </p> */}
                    <p>
                      ROAS <span>{yelpData?.current?.roas?.toFixed(1)}</span>
                    </p>
                    <p>
                      Total Spend{" "}
                      <span>
                        ${valueFormatter(yelpData?.current?.total_spend, 1)}
                      </span>
                    </p>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Skeleton active />
                  </div>
                )}
              </div>

              <div className="channel-campaign-inner">
                {!isaLoader ? (
                  <>
                    {" "}
                    <h1 className="channel-iner-heading">LSA</h1>
                    <h5 className="channel-iner-heading2">CURRENT (C)</h5>
                    <p>
                      Revenue{" "}
                      <span>
                        ${valueFormatter(isaData?.current?.revenue, 1)}
                      </span>
                    </p>
                    <p>
                      Cost/Job{" "}
                      <span>
                        ${valueFormatter(isaData?.current?.cost_per_job, 1)}
                      </span>
                    </p>
                    {/* <p>
                      Cost/Lead{" "}
                      <span>
                        ${valueFormatter(isaData?.current?.cost_per_lead, 1)}
                      </span>
                    </p> */}
                    <p>
                      ROAS <span>{isaData?.current?.roas?.toFixed(1)}</span>
                    </p>
                    <p>
                      Total Spend
                      <span>
                        ${valueFormatter(isaData?.current?.total_spend, 1)}
                      </span>{" "}
                    </p>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Skeleton active />
                  </div>
                )}
              </div>
              <div className="channel-campaign-inner">
                {!bingLoader ? (
                  <>
                    {" "}
                    <h1 className="channel-iner-heading">BING</h1>
                    <h5 className="channel-iner-heading2">CURRENT (C)</h5>
                    <p>
                      Revenue{" "}
                      <span>
                        ${valueFormatter(bingData?.current?.revenue, 1)}
                      </span>
                    </p>
                    <p>
                      Cost/Job{" "}
                      <span>
                        ${valueFormatter(bingData?.current?.cost_per_job, 1)}
                      </span>
                    </p>
                    {/* <p>
                      Cost/Lead{" "}
                      <span>
                        ${valueFormatter(bingData?.current?.cost_per_lead, 1)}
                      </span>
                    </p> */}
                    <p>
                      ROAS <span>{bingData?.current?.roas?.toFixed(1)}</span>
                    </p>
                    <p>
                      Total Spend
                      <span>
                        ${valueFormatter(bingData?.current?.total_spend, 1)}
                      </span>{" "}
                    </p>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Skeleton active />
                  </div>
                )}
              </div>
            </div>

            {isNotEmpty(googleData?.compare) &&
            isNotEmpty(facebookData?.compare) &&
            isNotEmpty(bingData?.compare) &&
            isNotEmpty(yelpData?.compare) &&
            isNotEmpty(isaData?.compare) &&
            isCompareDisabled == false ? (
              <div className="channel-campaign-row">
                {googleData?.compare && (
                  <div className="channel-campaign-inner">
                    {!googleLoader ? (
                      <>
                        <h1 className="channel-iner-heading">GOOGLE</h1>
                        <h6 className="channel-iner-heading2">
                          VS COMPARED (CT)
                        </h6>
                        <p>
                          Revenue{" "}
                          <span>
                            ${valueFormatter(googleData?.compare?.revenue, 1)}
                          </span>
                        </p>
                        <p>
                          Cost/Job{" "}
                          <span>
                            $
                            {valueFormatter(
                              googleData?.compare?.cost_per_job,
                              1
                            )}
                          </span>
                        </p>
                        {/* <p>
                          Cost/Lead{" "}
                          <span>
                            $
                            {valueFormatter(
                              googleData?.compare?.cost_per_lead,
                              1
                            )}
                          </span>
                        </p> */}
                        <p>
                          ROAS{" "}
                          <span>{googleData?.compare?.roas?.toFixed(1)}</span>
                        </p>
                        <p>
                          Total Spend{" "}
                          <span>
                            $
                            {valueFormatter(
                              googleData?.compare?.total_spend,
                              1
                            )}
                          </span>
                        </p>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Skeleton active />
                      </div>
                    )}
                  </div>
                )}
                {facebookData?.compare && (
                  <div className="channel-campaign-inner">
                    {!fbLoader ? (
                      <>
                        {" "}
                        <h1 className="channel-iner-heading">FACEBOOK</h1>
                        <h6 className="channel-iner-heading2">
                          VS COMPARED (CT)
                        </h6>
                        <p>
                          Revenue{" "}
                          <span>
                            ${valueFormatter(facebookData?.compare?.revenue, 1)}
                          </span>
                        </p>
                        <p>
                          Cost/Job{" "}
                          <span>
                            $
                            {valueFormatter(
                              facebookData?.compare?.cost_per_job,
                              1
                            )}
                          </span>
                        </p>
                        {/* <p>
                          Cost/Lead{" "}
                          <span>
                            $
                            {valueFormatter(
                              facebookData?.compare?.cost_per_lead,
                              1
                            )}
                          </span>
                        </p> */}
                        <p>
                          ROAS{" "}
                          <span>{facebookData?.compare?.roas?.toFixed(1)}</span>
                        </p>
                        <p>
                          Total Spend{" "}
                          <span>
                            $
                            {valueFormatter(
                              facebookData?.compare?.total_spend,
                              1
                            )}
                          </span>
                        </p>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Skeleton active />
                      </div>
                    )}
                  </div>
                )}
                {yelpData?.compare && (
                  <div className="channel-campaign-inner">
                    {!yelpLoader ? (
                      <>
                        <h1 className="channel-iner-heading">YELP</h1>
                        <h6 className="channel-iner-heading2">
                          VS COMPARED (CT)
                        </h6>
                        <p>
                          Revenue{" "}
                          <span>
                            ${valueFormatter(yelpData?.compare?.revenue, 1)}
                          </span>
                        </p>
                        <p>
                          Cost/Job{" "}
                          <span>
                            $
                            {valueFormatter(yelpData?.compare?.cost_per_job, 1)}
                          </span>
                        </p>
                        {/* <p>
                          Cost/Lead{" "}
                          <span>
                            $
                            {valueFormatter(
                              yelpData?.compare?.cost_per_lead,
                              1
                            )}
                          </span>
                        </p> */}
                        <p>
                          ROAS{" "}
                          <span>{yelpData?.compare?.roas?.toFixed(1)}</span>
                        </p>
                        <p>
                          Total Spend{" "}
                          <span>
                            ${valueFormatter(yelpData?.compare?.total_spend, 1)}
                          </span>
                        </p>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Skeleton active />
                      </div>
                    )}
                  </div>
                )}
                {isaData?.compare && (
                  <div className="channel-campaign-inner">
                    {!isaLoader ? (
                      <>
                        <h1 className="channel-iner-heading">LSA</h1>
                        <h6 className="channel-iner-heading2">
                          VS COMPARED (CT)
                        </h6>
                        <p>
                          Revenue{" "}
                          <span>
                            ${valueFormatter(isaData?.compare?.revenue, 1)}
                          </span>
                        </p>
                        <p>
                          Cost/Job{" "}
                          <span>
                            ${valueFormatter(isaData?.compare?.cost_per_job, 1)}
                          </span>
                        </p>
                        {/* <p>
                          Cost/Lead{" "}
                          <span>
                            $
                            {valueFormatter(isaData?.compare?.cost_per_lead, 1)}
                          </span>
                        </p> */}
                        <p>
                          ROAS <span>{isaData?.compare?.roas?.toFixed(1)}</span>
                        </p>
                        <p>
                          Total Spend
                          <span>
                            ${valueFormatter(isaData?.compare?.total_spend, 1)}
                          </span>{" "}
                        </p>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Skeleton active />
                      </div>
                    )}
                  </div>
                )}
                {bingData?.compare && (
                  <div className="channel-campaign-inner">
                    {!isaLoader ? (
                      <>
                        <h1 className="channel-iner-heading">BING</h1>
                        <h6 className="channel-iner-heading2">
                          VS COMPARED (CT)
                        </h6>
                        <p>
                          Revenue{" "}
                          <span>
                            ${valueFormatter(bingData?.compare?.revenue, 1)}
                          </span>
                        </p>
                        <p>
                          Cost/Job{" "}
                          <span>
                            $
                            {valueFormatter(bingData?.compare?.cost_per_job, 1)}
                          </span>
                        </p>
                        {/* <p>
                          Cost/Lead{" "}
                          <span>
                            $
                            {valueFormatter(
                              bingData?.compare?.cost_per_lead,
                              1
                            )}
                          </span>
                        </p> */}
                        <p>
                          ROAS{" "}
                          <span>{bingData?.compare?.roas?.toFixed(1)}</span>
                        </p>
                        <p>
                          Total Spend
                          <span>
                            ${valueFormatter(bingData?.compare?.total_spend, 1)}
                          </span>{" "}
                        </p>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Skeleton active />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </>
        </div>
        {!isaLoader && <Watermark style={{ paddingTop: "4px" }} />}
      </HomeWrapper>
    </>
  );
};

export default Home2;

const Comparedetails = styled.div`
  padding: 10px 0 0 0;
  position: relative;

  .compared-details {
    @media (max-width: 1390px) {
      position: absolute;
      left: -17px;
      bottom: 2px;
    }
  }

  .speedometer-compare {
    position: absolute;
    bottom: "-8px";
    left: 50%;
    transform: translateX(-50%);
  }
  p {
    color: #9292a5;

    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    gap: 5px !important;
    .percentage {
      color: #25d052 !important;

      font-size: 16px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .values {
      color: #0f1011 !important;

      font-size: 12px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

const GraphSection = styled.div`
  width: 100%;

  @media (max-width: 1170px) {
    width: 100%;
  }

  .speedometer-section {
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .speedometer {
      position: relative;
      width: 200px;
    }

    .meter-values {
      padding: 0 16px 0 30px;
      display: flex;
      justify-content: space-between;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 15px;

      @media (min-width: 1600px) {
        bottom: 7px;
      }
    }

    .speedo-inner-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;

      h3 {
        color: #0f1011 !important;
        font-family: "Inter", sans-serif;
        font-size: 32px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
        text-align: center;

        @media (max-width: 1400px) {
          font-size: 25px !important;
        }
      }
    }

    .title {
      width: 100%;
      margin-top: -25px;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: #9292a5;

        font-size: 16px !important;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      p {
        margin: 0;
        color: #0f1011 !important;

        font-size: 16px !important;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .progress-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    span {
      color: #ed0101;

      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    p {
      margin: 0 !important;
      color: #9292a5 !important;
      text-align: right !important;

      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: normal !important;
    }
  }

  .first-row {
    display: flex;
    .total-bookings {
      margin-right: 10px;
      padding: 20px 30px;
      border-radius: 10px;
      background-color: ${({ selectedThemeColors }) =>
        selectedThemeColors?.CardsColor};
      height: 240px;

      @media (max-width: 1400px) {
        height: 220px;
      }

      .try-again-div {
        color: #000;
        text-align: center;
        .reload-icon {
          font-size: 30px;
          color: #000;
          cursor: pointer;
        }
        h6 {
          color: #000000;
          margin: 0px;
          margin-top: 6px;
        }
      }

      div {
        .closing-percnt {
          color: ${({ selectedThemeColors }) =>
            selectedThemeColors?.textPrimaryColor};
          display: flex;
          align-items: center;
          gap: 6px;
          white-space: nowrap;

          h5 {
            margin: 0px;
          }
        }
      }

      h1 {
        color: ${({ selectedThemeColors }) =>
          selectedThemeColors?.textPrimaryColor};
        font-family: "Inter", sans-serif;
        /* font-size: 60px; */
        font-weight: 700;
        margin-bottom: 5px;
        font-size: 75px;

        @media (max-width: 1600px) {
          font-size: 48px;
        }
      }
      p {
        color: ${({ selectedThemeColors }) =>
          selectedThemeColors?.textPrimaryColor};

        font-size: 24px;
        font-weight: 500;
        white-space: nowrap;
        margin: 0;
      }
      .progress-bar {
        .ant-progress-outer {
          background: #0f1011;
          border-radius: 10px;
        }
        .ant-progress-text {
          color: #fff;
          margin-top: 5px;
        }
      }
    }
    .indicator {
      border-radius: 10px;
      background-color: ${({ selectedThemeColors }) =>
        selectedThemeColors?.CardsColor};
      height: 240px;
      padding: ${({ indicatorLoader }) =>
        indicatorLoader ? "0px 10px 20px 10px" : "0px"};

      @media (max-width: 1400px) {
        height: 220px;
      }

      h1 {
        color: #0f1011;
        font-size: 22px;
        padding: 20px 22px 0px 22px;
        font-weight: 500;
        margin: 0;
      }
    }
  }
  .secondRowWrapper {
    display: grid;
    grid-template-columns: 74% 25%;
    gap: 10px;
    width: 100%;

    @media (min-width: 1600px) {
      .second-row .inner-content div h1 {
        font-size: 75px;
      }
    }
  }
  .second-row {
    border-radius: 10px;
    background-color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.CardsColor};
    margin-top: 30px;
    min-height: 204px;

    /* @media (min-width: 1600px) and (max-width: 1920px) {
      margin: 50px 0;
    } */

    @media (min-height: 700px) {
      height: 250px;
    }

    h4 {
      color: #0f1011;

      font-size: 22px;
      font-weight: 500;
    }
    .inner-content {
      position: relative;
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      height: 100%;

      div {
        h1 {
          color: ${({ selectedThemeColors }) =>
            selectedThemeColors?.textPrimaryColor};
          font-family: "Inter", sans-serif;
          font-size: 64px;
          font-weight: 700;
          @media (min-width: 1600px) and (max-width: 1920px) {
            font-size: 60px;
          }

          @media (max-width: 1590px) {
            font-size: 48px;
          }
        }
        span {
          color: ${({ selectedThemeColors }) => selectedThemeColors?.lightText};

          font-size: 18px;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 6px;
          white-space: nowrap;
          @media (min-width: 1600px) and (max-width: 1920px) {
            flex-direction: column;
          }
          @media (max-width: 1400px) {
            font-size: 12px;
          }

          h5 {
            color: ${({ selectedThemeColors }) =>
              selectedThemeColors?.textPrimaryColor};

            font-size: 16px;
            font-weight: 500;
            margin: 0px;
            @media (min-width: 1600px) and (max-width: 1920px) {
              font-size: 22px;
            }
            @media (max-width: 1400px) {
              font-size: 12px;
            }
          }
        }
        h3 {
          color: #f85062;

          font-size: 16px;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 6px;
          margin: 0px;
          @media (max-width: 1400px) {
            font-size: 12px;
          }

          h5 {
            color: #fff;

            font-size: 16px;
            font-weight: 500;
            margin: 0px;
            @media (max-width: 1400px) {
              font-size: 12px;
            }
          }
        }
        p {
          color: #9292a5;

          font-size: 18px;
          font-weight: 500;
          display: flex;
          gap: 10px;
          align-items: center;
          white-space: nowrap;
          @media (max-width: 1400px) {
            font-size: 12px;
          }

          svg {
            width: 24px;
            height: 24px;
          }
          span {
            color: #25d052;

            font-size: 18px;
            font-weight: 500;
            @media (max-width: 1400px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;

const FilterDrawerWrapper = styled.div`
  display: none;
  justify-content: end;
  align-items: center;
  margin-bottom: 15px;

  .button {
    background: rgb(36, 36, 36);
    color: #fff;
    border-style: none;

    border-radius: 10px;
    cursor: pointer;
    width: 84px;
    height: 36px;
    font-size: 14px;
    font-family: "Poppins";
  }

  @media (max-width: 1170px) {
    display: flex;
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  gap: 6px;

  .infoInner {
    margin-top: 2px !important;
    color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.textPrimaryColor};
  }

  .information-icon {
    margin-top: 0px;

    svg {
      margin-top: 3px;
    }

    @media (min-width: 1600px) and (max-width: 1920px) {
      margin-top: -40px;
    }
  }

  .information-icon-closing {
    margin: 0;

    @media (min-width: 1600px) and (max-width: 1920px) {
      margin-top: -20px;
      margin-bottom: 12px;
    }
  }

  .information-icon-lead-calls {
    margin: 0;

    @media (min-width: 1600px) and (max-width: 1920px) {
      margin-top: -29px;
      margin-bottom: 12px;
    }
  }
`;

const HomeWrapper = styled.div`
  background: ${({ selectedThemeColors }) => selectedThemeColors?.bgMainColor};
  width: 100%;
  height: 100%;
  padding: 20px;

  .dashboardWrapper {
    .filters-section {
      background: ${({ selectedThemeColors }) =>
        selectedThemeColors?.CardsColor} !important;
    }
  }

  .title {
    color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.textPrimaryColor};
  }

  .chart-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tooltip-box {
    width: max-content;
    padding: 15px;
    background: #fff;
    position: relative;

    h5,
    p {
      margin: 0;
    }

    .ant-popover-arrow {
      left: 50%;
      transform: translateX(-50%) translateY(100%) rotate(180deg);
      position: absolute;
      bottom: 0px;
      left: 100.5px;
    }
  }
  .loading-wrap {
    width: 100%;
    background: ${({ selectedThemeColors }) => selectedThemeColors?.CardsColor};
    padding: 15px;
    height: 200px;
    display: flex;
    border-radius: 7px;
    align-items: center;
  }

  .channel-campaign-row {
    display: flex;
    gap: 10px;
    margin-top: 10px;

    .channel-campaign-inner {
      width: 100%;
      padding: 20px;
      border-radius: 10px;
      background: ${({ selectedThemeColors }) =>
        selectedThemeColors?.CardsColor};
      min-height: 258px;
      line-height: 2;

      .channel-iner-heading {
        color: ${({ selectedThemeColors }) =>
          selectedThemeColors?.textPrimaryColor};
        font-size: 32px;
        margin-bottom: 20px;
      }
      .channel-iner-heading2 {
        color: ${({ selectedThemeColors }) => selectedThemeColors?.lightText};
        font-size: 15px;
      }

      p {
        color: ${({ selectedThemeColors }) => selectedThemeColors?.lightText};
        margin: 0px;
        margin: 0px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 13px;

        span {
          color: ${({ selectedThemeColors }) =>
            selectedThemeColors?.textPrimaryColor};
          font-size: 15px;
        }
      }
    }
  }
`;

const InfoIconComp = ({ style, title }) => {
  return (
    <p style={style} className="information-icon-closing">
      <Tooltip title={title} color={"rgb(216, 12, 12)"}>
        <i style={{ marginTop: "-3px" }} className="card-info-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: "17px", height: "17px" }}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-info"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="16" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12.01" y2="8"></line>
          </svg>
        </i>
      </Tooltip>
    </p>
  );
};

const IndicatorSign = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 8px;

  div {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .label-values {
    color: rgb(146, 146, 165);
    font-size: 12px;
    font-weight: 500;
    display: flex;
    gap: 10px;
    align-items: center;
    white-space: nowrap;
  }

  .current-span {
    display: block;
    width: 12px;
    height: 12px;
    background: #d80c0c;
    border-radius: 50%;
  }
  .compared-span {
    display: block;
    width: 12px;
    height: 12px;
    background: #25d052;
    border-radius: 50%;
  }
`;
