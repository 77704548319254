import React, { useEffect, useState } from "react";
import ComposedGraphComponent from "../../components/ComposedGraph";
import { LoadingOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
    getCampaignsCategories,
    getCampaignVisualizationData,
} from "../../services/Collections";
import LoadingBlock from "../../components/LoadingBlock";
import VisualizationCampaignUnit from "./VisualizationCampaignUnit";
import moment from "moment";

export const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 40,
        }}
        spin
    />
);

export const colrs = {
    skyBlue: "rgb(44, 175, 254)",
    navyBlue: "rgb(84, 79, 197)",
    green: "rgb(0, 226, 114)",
    orange: "rgb(254, 106, 53)",
    grey: "rgb(107, 138, 188)",
    pink: "rgb(213, 104, 251)",
    seaGreen: "rgb(46, 224, 202)",
    red: "rgb(250, 75, 66)",
    orangeYellow: "rgb(254, 181, 106)",
};

export const graphListing = [
    { name: "Existing Customer Rev", color: colrs.skyBlue }, //skyblue
    { name: "Existing Customer Job", color: colrs.skyBlue, disable: true },
    { name: "Existing Customer Avg Rev", type: "line", color: colrs.skyBlue, yAxis: "right", },
    { name: "New Customer Rev", color: colrs.seaGreen },
    { name: "New Customer Job", color: colrs.seaGreen, disable: true },
    { name: "New Customer Avg Rev", type: "line", color: colrs.seaGreen, yAxis: "right", },
];

const VisualizationCampaign = ({ setFilterLoading, setOpenDrawer }) => {
    const [loading, setLoading] = useState(true);
    const [defaultValue, setDefaultValue] = useState({});
    const prefilledData = useSelector(
        (state) => state?.loginAuth?.prefilledFilters
    );
    const [graphData, setGraphData] = useState([]);
    const { visualizationData } = useSelector((e) => e.loginAuth);
    const [unitLoading, setUnitLoading] = useState(false);
    const loadMore = 50;
    const [showData, setShowData] = useState([]);
    const [totalUnits, setTotalUnits] = useState([]);

    const getInitData = async () => {
        setLoading(true);
        setFilterLoading(true);
        const init = visualizationData && Object.keys(visualizationData)?.length > 1
            ? visualizationData
            : {
                date_start: prefilledData?.date_start,
                date_end: prefilledData?.date_end,
                selectedClient: prefilledData?.selectedClient,
                customer_type: "all",
                new_customer_nod: 30,
            };
        setDefaultValue(init);
        await getVisualizationDataListing(init);
        hanldeCampaignListing(init);
    };

    const getVisualizationDataListing = async (init) => {
        const {
            selectedClient,
            date_start,
            date_end,
            customer_type = "all",
            job_types = [],
            new_customer_nod,
            campaigns,
        } = init;
        let params = new URLSearchParams();
        params.append("date_start", moment(date_start).format("YYYY-MM-DD"));
        params.append("date_end", moment(date_end).endOf('month').format("YYYY-MM-DD"));
        // params.append("customer_type", customer_type);
        params.append("state", "NY");
        new_customer_nod && params.append("new_customer_nod", new_customer_nod);
        job_types?.map((list) => params.append("job_types", list));
        campaigns?.length > 0 &&
            campaigns?.map((list) => params.append("campaigns", list));

        let res = await getCampaignVisualizationData(selectedClient?.key, params);

        if (res?.status === 200) {
            const { categories, existing_cus, existing_cus_mkt, new_cus } =
                res?.data || {};
            let customize_data = categories?.map((element, index) => ({
                name: element,
                "Existing Customer Rev": existing_cus?.rev[index],
                "Existing Customer Job": existing_cus?.job[index],
                "Existing Customer Avg Rev": existing_cus?.avg_rev[index],

                "Existing Customer with Marketing Rev": existing_cus_mkt?.rev[index],
                "Existing Customer with Marketing Job": existing_cus_mkt?.job[index],
                "Existing Customer with Marketing Avg Rev":
                    existing_cus_mkt?.avg_rev[index],

                "New Customer Rev": new_cus?.rev[index],
                "New Customer Job": new_cus?.job[index],
                "New Customer Avg Rev": new_cus?.avg_rev[index],
            }));

            setGraphData(customize_data);
            setFilterLoading(false);
            setOpenDrawer(false);
        } else {
            setFilterLoading(false);
        }
    };

    const hanldeCampaignListing = async (init) => {
        setTotalUnits([]);
        setUnitLoading(true);
        let client =
            init?.selectedClient ||
            visualizationData?.selectedClient ||
            prefilledData?.selectedClient;

        let param = new URLSearchParams();
        param.append("client_key", client?.key);

        let res = await getCampaignsCategories(client?.key, param);

        if (res?.status === 200) {
            let resData;
            if (init?.campaigns?.length > 0) {
                resData = res?.data?.filter(
                    (ele) => init?.campaigns?.includes(ele?.listofsource) && ele
                );
                setTotalUnits(resData);
            } else {
                resData = res?.data;
                setTotalUnits(resData);
            }
            let show =
                resData?.length > loadMore ? resData?.slice(0, loadMore) : resData;
            setShowData(show);
            setLoading(false);
            setUnitLoading(false);
        } else {
            setLoading(false);
            setUnitLoading(false);
        }
    };

    const handleLoadMore = () => {
        let show =
            totalUnits?.length > showData?.length &&
            totalUnits?.slice(showData?.length, showData?.length + loadMore);
        setShowData((pre) => [...pre, ...show]);
    };

    useEffect(() => {
        setGraphData([]);
        setTotalUnits([]);
        setShowData([]);
        getInitData();
    }, [visualizationData]);

    return (
        <>
            {loading ? (
                <LoadingBlock height={"calc(100vh - 243px)"} size={28} />
            ) : (
                <>
                    <ComposedGraphComponent
                        data={graphData}
                        graphListing={graphListing}
                        yAxis_left_label="REV"
                        yAxis_right_label="AVG REV"
                        label_prefix="$"
                    />
                    {unitLoading ? (
                        ""
                    ) : (
                        <>
                            {showData?.map((ele, index) => {
                                return (
                                    <VisualizationCampaignUnit
                                        key={index}
                                        campaignData={ele}
                                        init={defaultValue}
                                    />
                                );
                            })}
                            {totalUnits?.length > showData?.length && (
                                <div className="loadmorewrap">
                                    <button className="loadmore" onClick={handleLoadMore}>
                                        Load More <DoubleRightOutlined className="icon" />
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default VisualizationCampaign;
