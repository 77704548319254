import React, { forwardRef, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Form,
  Button,
  InputNumber,
  message,
  Switch,
  Checkbox,
  Popover,
  Modal,
} from "antd";
import {
  formatNumber,
  formatToCurrencyWithDecimal,
} from "../../exceltable/commonFun";
import {
  calculateTotals,
  checkForInfinity,
  formatInteger,
  formatNumberDecimal,
  predictionCalculator,
} from "./commonFuc";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  budgetPredictedApi,
  getSocialAdsApi,
  proposalsHistoryApi,
} from "../../../services/Collections";
import { getRandomColor } from "../../../utils/common/commonFunction";
import {
  Arrowiconright,
  RemoveIcon,
  SheetPlusIcon,
} from "../../../utils/Icons/SvgIcons";
import TotalComponent from "./TotalComponent";
import DifferenceComponent from "./DifferenceComponent";
import BudgetSalesGraph from "../BudgetSalesGraph";
import {
  AdditionalMetrics,
  CategoryName,
  CategoryNameResponsive,
  CategoryWrap,
  Container,
  DynamicSheetWrapper,
  ExpendTag,
  FormButtonWrapper,
  Header,
  Label,
  ListContainer,
  Metric,
  MetricLabel,
  MetricsContainer,
  MetricValue,
  MinMetric,
  PopoverWrapper,
  StyledInputNumber,
  Tag,
} from "./styled";
import { updateYearData } from "../../exceltable/ExcelStore";
import { ModalWrapper } from "../Goals/Goals";
import { toast } from "react-toastify";
import Watermark from "../../../components/Watermark";
import { months } from "../finalReport/FinalReport";
import { retry } from "redux-saga/effects";
import { IoMdInformationCircleOutline } from "react-icons/io";
import PaidSearchModal from "./PaidSearchModal";

const formatterRegex = /\B(?=(\d{3})+(?!\d))/g;
const parseRegex = /\$\s?|(,*)/g;

const YearComponent = forwardRef(
  (
    {
      name,
      data,
      setYearTotal,
      category,
      subCategory,
      goalsCal,
      setGraphsData,
      graphsData,
      setLoading,
      yearSheetFilter,
      sortBy,
      handleUnique,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const persistYearData = useSelector(
      (state) => state?.dynamicBudgetSheet?.yearData
    );
    const goalPrediction = useSelector(
      (state) => state?.dynamicBudgetSheet?.GoalPrediction
    );
    const defaultValPrediction = useSelector(
      (state) => state?.dynamicBudgetSheet?.predicationLabelValue
    );
    const yearSheetFilters = useSelector(
      (state) => state?.dynamicBudgetSheet?.yearSheetFilters
    );

    let defaultName = yearSheetFilters?.month
      ? `${yearSheetFilters?.year} - ${
          months[yearSheetFilters?.month - 1]?.label
        }`
      : `${yearSheetFilters?.year}`;
    const [userName, setUserName] = useState(defaultName ?? "");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("current");
    const [loadBalancer, setLoadBalancer] = useState(true);
    const loginAuth = useSelector((state) => state?.loginAuth);
    const prefilledFilters = useSelector(
      (state) => state?.loginAuth?.summarySectionFilter
    );
    const prefilledData = useSelector(
      (state) => state?.loginAuth?.prefilledFilters
    );
    const userData = useSelector((state) => state?.loginAuth?.data);
    const activeClient =
      prefilledFilters == null
        ? prefilledData?.selectedClient?.key
        : prefilledFilters?.selectedClient?.key;

    const [isPaidModalOpen, setIsPaidModalOpen] = useState(false);
    const [impressionShare, setImpressionShare] = useState([]);
    const [isPaidLoading, setIsPaidLoading] = useState(false);

    const [form] = Form.useForm();

    const fieldForm = [
      {
        display: "Budget",
        name: "budget",
        signType: 1, // 1 - Dollar and 2 - Percentage
      },
      {
        display: "CPL",
        name: "cpl",
        signType: 1,
      },
      {
        display: "Conv%",
        name: "con_per",
        signType: 2,
      },
      {
        display: "Close%",
        name: "close_per",
        signType: 2,
      },
      {
        display: "Avg. Ticket",
        name: "avg_ticket",
        signType: 1,
      },
    ];

    const pushHistory = () => {
      if (userName) {
        setIsModalOpen(false);
        onFinish(false);
      } else {
        toast.error("Please enter the history title", { theme: "colored" });
      }
    };

    const onFinish = async (isModalOpen = true) => {
      if (isModalOpen) {
        setIsModalOpen(true);
        return;
      }
      if (persistYearData?.current?.length === 0) {
        alert("Before submitting, Please check Prediction sheet once!");
        return;
      }
      try {
        onFinishPrediction();
        // let payload = {
        //   save_date: moment().toISOString(),
        //   user_id: loginAuth?.data?.id,
        //   year: moment(new Date()).format("YYYY"),
        //   month: moment(new Date()).format("MM"),
        //   business_unit: category,
        //   service_type: subCategory,
        //   sheet: {
        //     historyTitle: userName,
        //     yearSheetFilters: yearSheetFilters,
        //     goalPrediction: goalPrediction,
        //     persistYearData: persistYearData,
        //   },
        //   total: {},
        //   budget_goal: 0,
        //   revenue_goal: 0,
        //   budget_per: 0,
        //   budget_goal_per: 0,
        //   budget_variance: 0,
        // };
        // let params = new URLSearchParams();
        // params.append("client_key", activeClient);
        // await proposalsHistoryApi(activeClient, params, payload);
      } catch (error) {
        // setLoading(false);
        console.log("error in businessUnitSubCategories", error);
      }
    };

    console.log(persistYearData?.current, "persistYearData");
    const onFinishPrediction = async () => {
      let data = form?.getFieldValue("categories");
      try {
        setLoading(true);

        let tempcategory = null;
        let duplicateArray = [];
        let AddCategories = {
          bu_category: category || [],
          bu_subcategory: subCategory || [],
        };
        let year = yearSheetFilters?.year;
        let month = yearSheetFilters?.month || 0;

        data?.forEach((cat, index) => {
          if (cat?.isChecked || cat?.isParent) {
            if (
              tempcategory &&
              cat?.category_name !== tempcategory?.category_name
            ) {
              duplicateArray.push({ ...tempcategory, ...AddCategories });
            }
            tempcategory = cat;
          }
          if (index === data.length - 1 && tempcategory) {
            duplicateArray.push({ ...tempcategory, ...AddCategories });
          }
        });

        let payload = {
          title: userName,
          user_id: loginAuth?.data?.id,
          items: duplicateArray,
          customer_type: yearSheetFilters?.newCustomer ?? "all",
          month: month,
          year: year,
          bu_category: category || [],
          bu_subcategory: subCategory || [],
          active: 1,
        };

        // category && category?.length > 0 && params.append("bu_category", category);
        // subCategory && subCategory?.length > 0 && params.append("bu_subcategory", subCategory);

        let res = await budgetPredictedApi(activeClient, payload);
        if (res?.status === 200) {
          message.success("Saved successfully");
          handleUnique();
          setLoading(false);
        } else {
          message.error("Something went wrong");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log("error in businessUnitSubCategories", error);
      }
    };

    function updateCategoryData(index, value, field) {
      if (value === null) return;
      const values = form.getFieldsValue();
      const category = values.categories[index];
      // Update the field with the passed numeric value
      category[field] = value;

      if (category?.isDynaicBudget) {
        // ROAS IS NOT EDITABLE
        category.leads = checkForInfinity(category.budget / category.cpl);
        category.appts = checkForInfinity(
          (category.leads * category.con_per) / 100
        );
        category.jobs = checkForInfinity(
          (category.appts * category.close_per) / 100
        );
        category.sales = checkForInfinity(category.avg_ticket * category.jobs);
        category.roas = checkForInfinity(category.sales / category.budget);
        category.cpa = checkForInfinity(category.budget / category.appts);
        category.cpj = checkForInfinity(category.budget / category.jobs);

        // category.jobs = checkForInfinity(category.sales / category.avg_ticket);
        // category.appts = checkForInfinity( (category.jobs / category.close_per) * 100 );
        // category.leads = checkForInfinity( (category.appts / category.con_per) * 100 );
        // // category.budget = checkForInfinity(category.leads * category.cpl);
        // category.sales = checkForInfinity(category.avg_ticket * category.jobs);
        // category.roas = checkForInfinity(category.sales / category.budget);
        // category.cpa = checkForInfinity(category.budget / category.appts);
        // category.cpj = checkForInfinity(category.budget / category.jobs);
      } else {
        category.jobs = checkForInfinity(category.sales / category.avg_ticket);
        category.appts = checkForInfinity(
          (category.jobs / category.close_per) * 100
        );
        category.leads = checkForInfinity(
          (category.appts / category.con_per) * 100
        );

        // category.budget = checkForInfinity(category.leads * category.cpl);
        // category.roas = checkForInfinity(category.sales / category.budget);
        // category.cpa = checkForInfinity(category.budget / category.appts);
        // category.cpj = checkForInfinity(category.budget / category.jobs);

        // if (field === "sales") {
        //   category.roas = checkForInfinity(category.sales / category.budget);
        // } else if (field === "roas") {
        //   category.sales = checkForInfinity(category.budget * category.roas);
        // } else {
        //   category.sales = checkForInfinity(category.budget * category.roas);
        //   category.roas = checkForInfinity(category.sales / category.budget);
        // }

        // category.appts = checkForInfinity( (category.jobs / category.close_per) * 100 );
        // category.leads = checkForInfinity( (category.appts / category.con_per) * 100 );
        // category.cpl = formatNumberDecimal( checkForInfinity(category.budget / category.leads) );
        // category.cpa = checkForInfinity(category.budget / category.appts);
        // category.cpj = checkForInfinity(category.budget / category.jobs);
      }
      // Calculate total for all categories
      dispatch(updateYearData({ categories: values?.categories, name }));
      form.setFieldsValue(values);
    }

    const handleCheckBox = (status, index, currentObj) => {
      let mainArr = form.getFieldValue().categories;
      mainArr.forEach((item) => {
        if (item.category_name === currentObj.category_name && item.isChecked) {
          item.isChecked = false;
        }
      });
      if (status) {
        mainArr[index] = { ...currentObj, isChecked: true };
      } else {
        const index = mainArr.findIndex(
          (item) =>
            item.category_name === currentObj.category_name && !item?.isParent
        );
        const object = mainArr.find(
          (item) =>
            item.category_name === currentObj.category_name && !item?.isParent
        );
        mainArr[index] = { ...object, isChecked: true };
      }
      dispatch(updateYearData({ categories: mainArr, name }));
      form.setFieldsValue({ categories: mainArr });
      setYearTotal(mainArr);
    };

    const updateExpend = (index, currentObj) => {
      let mainArr = form.getFieldValue().categories;
      mainArr.forEach((item) => {
        if (item.category_name === currentObj.category_name && item.isChecked) {
          item.isChecked = false;
        }
      });
      mainArr[index + 1].isChecked = true;
      setYearTotal(mainArr);
      dispatch(updateYearData({ categories: mainArr, name }));
      form.setFieldsValue({ categories: mainArr });
    };

    const removeExpend = () => {
      let mainArr = form.getFieldValue().categories;
      setYearTotal(mainArr);
      dispatch(updateYearData({ categories: mainArr, name }));
      // This state is only for the rending prepose!
      setLoadBalancer(!loadBalancer);
    };

    const handleMinimizeMaximize = (payload) => {
      let data = form.getFieldValue()?.categories;
      let updatedArray = data?.map((el) =>
        el?.category_name === payload?.category_name
          ? { ...el, showUi: !el?.showUi }
          : el
      );
      form.setFieldsValue({ categories: updatedArray });
    };

    function sortData(data, sortBy) {
      const isChildExist = data?.some((el) => !el?.isParent);
      const { name: key, type } = sortBy.option;

      const grouped = Object.values(
        data.reduce((acc, item) => {
          if (!acc[item.category_name]) acc[item.category_name] = [];
          acc[item.category_name].push(item);
          return acc;
        }, {})
      ).map((group) =>
        group.sort((a, b) => {
          if (a.isParent && !b.isParent) return -1;
          if (!a.isParent && b.isParent) return 1;
          return 0;
        })
      );

      grouped.sort((a, b) => {
        const parentA =
          a.find((item) => item.isChecked) ?? a.find((item) => item.isParent);
        const parentB =
          b.find((item) => item.isChecked) ?? b.find((item) => item.isParent);

        const valueA = parentA ? parentA[key] : 0;
        const valueB = parentB ? parentB[key] : 0;

        if (type === "asc") return valueA - valueB;
        if (type === "desc") return valueB - valueA;
        return 0;
      });
      return grouped.flat();
    }

    const handleSocialAds = async () => {
      setIsPaidLoading(true);
      const today = moment().format("YYYY-MM-DD");
      const thirtyDaysBack = moment().subtract(30, "days").format("YYYY-MM-DD");

      let param = new URLSearchParams();
      param.append("date_start", thirtyDaysBack);
      param.append("date_end", today);
      let res = await getSocialAdsApi(activeClient, param);

      if (res?.status === 200) {
        setImpressionShare(res?.data?.google?.impression_share);
      }
      setIsPaidLoading(false);
    };

    useEffect(() => {
      if (sortBy) {
        let data = form.getFieldValue()?.categories;
        const sorted = sortData(data, sortBy);
        form.setFieldsValue({ categories: sorted });
      } else {
        const initialPositions = [
          "Direct Mail",
          "Email Marketing",
          "Events",
          "Google LSA",
          "Misc Marketing",
          "Organic Social",
          "Outdoor",
          "Paid Search",
          "Paid Social",
          "Pay For Performance",
          "Print",
          "Production",
          "Radio",
          "Referral",
          "Repeat",
          "SEO",
          "Television",
          "Web",
          "Yelp",
        ];
        if (sortBy === undefined) {
          let data = form.getFieldValue()?.categories;
          const sorted = [];
          initialPositions?.forEach((val) =>
            data?.forEach(
              (ele) => ele?.category_name == val && sorted.push(ele)
            )
          );
          form.setFieldsValue({ categories: sorted });
        }
      }
    }, [sortBy]);

    useEffect(() => {
      if (data) {
        if (name === "current") {
          if (persistYearData?.current?.length) {
            form.setFieldsValue({ categories: persistYearData?.current });
          } else {
            form.setFieldsValue({ categories: data });
            dispatch(updateYearData({ categories: data, name: "current" }));
          }
        }
        // This state is only for the rending prepose!
        setLoadBalancer(!loadBalancer);
      }
    }, [data, name, persistYearData]);

    useEffect(() => {
      setImpressionShare([]);
      handleSocialAds();
    }, [activeClient]);

    return (
      <>
        <Modal
          open={isModalOpen}
          onOk={pushHistory}
          onCancel={() => setIsModalOpen(false)}
          prefixCls="goal"
        >
          <ModalWrapper>
            <p>Predicted Title</p>
            <input
              value={userName}
              type="text"
              placeholder="Please enter the predicted title"
              onChange={(e) => setUserName(e?.target?.value)}
            />
          </ModalWrapper>
        </Modal>

        <Form
          ref={ref}
          form={form}
          onFinish={onFinish}
          initialValues={{ categories: [] }}
        >
          <DynamicSheetWrapper active={yearSheetFilter}>
            <section className="right">
              <Form.List name="categories">
                {(fields, { add, remove }) => (
                  <ListContainer>
                    {fields.map((field, index) => {
                      let rowData = form.getFieldValue().categories[index];
                      return (
                        <>
                          {rowData?.showUi ? (
                            <Container
                              key={field.key}
                              style={{
                                margin: !rowData?.isParent ? "0 20px" : "0",
                                marginTop:
                                  index === 0
                                    ? "10px"
                                    : rowData?.isParent
                                    ? "20px"
                                    : "0",
                                padding: rowData?.isParent
                                  ? "10px 12px"
                                  : "20px 12px 10px 12px",
                              }}
                            >
                              {!rowData?.isParent && (
                                <Tag>
                                  {rowData?.isDynaicBudget
                                    ? "Budget"
                                    : "Revenue"}
                                </Tag>
                              )}

                              {rowData?.isParent && (
                                <ExpendTag
                                  rotation="90"
                                  onClick={() =>
                                    handleMinimizeMaximize(rowData)
                                  }
                                >
                                  <Arrowiconright />
                                </ExpendTag>
                              )}

                              <Header
                                style={{ paddingRight: "42px", gap: "4px" }}
                              >
                                <CategoryWrap>
                                  {rowData?.isParent ? (
                                    <CategoryName>
                                      {rowData.category_name}{" "}
                                      {rowData?.category_name ===
                                        "Paid Search" && (
                                        <i
                                          onClick={() =>
                                            setIsPaidModalOpen(true)
                                          }
                                        >
                                          <IoMdInformationCircleOutline
                                            size={15}
                                          />
                                        </i>
                                      )}
                                    </CategoryName>
                                  ) : (
                                    <Checkbox
                                      name="isChecked"
                                      checked={rowData?.isChecked}
                                      onChange={(e) =>
                                        handleCheckBox(
                                          e?.target?.checked,
                                          index,
                                          rowData
                                        )
                                      }
                                    >
                                      <CategoryName>
                                        {rowData.category_name}
                                      </CategoryName>
                                    </Checkbox>
                                  )}
                                  {rowData?.isParent && (
                                    <Popover
                                      content={
                                        <PopoverWrapper>
                                          <button
                                            onClick={() => {
                                              add(
                                                {
                                                  ...rowData,
                                                  isParent: false,
                                                  isChecked: true,
                                                  isDynaicBudget: true,
                                                },
                                                index + 1
                                              );
                                              updateExpend(index, rowData);
                                            }}
                                          >
                                            Dynamic Budget
                                          </button>
                                          <button
                                            onClick={() => {
                                              add(
                                                {
                                                  ...rowData,
                                                  isParent: false,
                                                  isChecked: true,
                                                  isDynaicBudget: false,
                                                },
                                                index + 1
                                              );
                                              updateExpend(index, rowData);
                                            }}
                                          >
                                            Dynamic Revenue
                                          </button>
                                        </PopoverWrapper>
                                      }
                                      trigger="hover"
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          border: "none",
                                          background: "transparent",
                                        }}
                                      >
                                        <SheetPlusIcon />
                                      </button>
                                    </Popover>

                                    // <button
                                    //   type="button"
                                    //   style={{
                                    //     border: "none",
                                    //     background: "transparent",
                                    //   }}
                                    //   onClick={() => {
                                    //     add(
                                    //       {
                                    //         ...rowData,
                                    //         isParent: false,
                                    //         isChecked: true,
                                    //         isDynaicBudget: true,
                                    //       },
                                    //       index + 1
                                    //     );
                                    //     updateExpend(index, rowData);
                                    //   }}
                                    // >
                                    //   <SheetPlusIcon />
                                    // </button>
                                  )}
                                  {!rowData?.isParent && (
                                    <i
                                      className="expend"
                                      onClick={() => {
                                        remove(index);
                                        removeExpend(index);
                                      }}
                                    >
                                      {" "}
                                      <RemoveIcon />{" "}
                                    </i>
                                  )}
                                </CategoryWrap>
                                <MetricsContainer style={{ height: "auto" }}>
                                  <>
                                    {fieldForm &&
                                      fieldForm?.map((ele) => {
                                        let el = null;
                                        if (
                                          !rowData?.isParent &&
                                          !rowData?.isDynaicBudget
                                        ) {
                                          el = {
                                            ...ele,
                                            display:
                                              ele.name === "budget"
                                                ? "Revenue"
                                                : ele.display,
                                            name:
                                              ele.name === "budget"
                                                ? "sales"
                                                : ele.name,
                                          };
                                        } else {
                                          el = ele;
                                        }
                                        return (
                                          <div>
                                            <Label>{el?.display}</Label>
                                            <StyledInputNumber
                                              prefixCls={
                                                rowData?.isParent
                                                  ? "disabled-custom-number-input"
                                                  : !rowData?.isDynaicBudget &&
                                                    (el?.name === "cpl" ||
                                                      el?.name === "con_per" ||
                                                      el?.name ===
                                                        "close_per" ||
                                                      el?.name === "avg_ticket")
                                                  ? "disabled-custom-number-input"
                                                  : "custom-number-input"
                                              }
                                              name={[field.name, el?.name]}
                                              value={formatInteger(
                                                rowData[el?.name]
                                              )}
                                              formatter={(value) =>
                                                el?.signType === 1
                                                  ? `$ ${value}`?.replace(
                                                      formatterRegex,
                                                      ","
                                                    )
                                                  : `${value}%`
                                              }
                                              parser={(value) =>
                                                el?.signType === 1
                                                  ? value?.replace(
                                                      parseRegex,
                                                      ""
                                                    )
                                                  : value?.replace("%", "")
                                              }
                                              onChange={(e) => {
                                                const regex = /^\d*\.?\d{0,2}$/;
                                                if (
                                                  regex?.test(e) ||
                                                  e === ""
                                                ) {
                                                  updateCategoryData(
                                                    index,
                                                    e,
                                                    el?.name
                                                  );
                                                }
                                              }}
                                              onKeyDown={(e) => {
                                                const currentValue =
                                                  e.target.value;
                                                const decimalIndex =
                                                  currentValue?.indexOf(".");
                                                if (decimalIndex !== -1) {
                                                  const decimals =
                                                    currentValue?.slice(
                                                      decimalIndex + 1
                                                    );
                                                  if (
                                                    decimals?.length >= 2 &&
                                                    ![
                                                      "Backspace",
                                                      "Delete",
                                                    ].includes(e.key)
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                }
                                              }}
                                              disabled={
                                                rowData?.isParent
                                                  ? true
                                                  : !rowData?.isDynaicBudget &&
                                                    (el?.name === "cpl" ||
                                                      el?.name === "con_per" ||
                                                      el?.name ===
                                                        "close_per" ||
                                                      el?.name === "avg_ticket")
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        );
                                      })}
                                  </>
                                </MetricsContainer>
                              </Header>

                              <AdditionalMetrics>
                                <Form.Item noStyle shouldUpdate>
                                  {({ getFieldValue }) => {
                                    const budget = getFieldValue([
                                      "categories",
                                      index,
                                      "budget",
                                    ]);

                                    const sales = getFieldValue([
                                      "categories",
                                      index,
                                      "sales",
                                    ]);
                                    const roas = getFieldValue([
                                      "categories",
                                      index,
                                      "roas",
                                    ]);
                                    const leads = getFieldValue([
                                      "categories",
                                      index,
                                      "leads",
                                    ]);
                                    const appts = getFieldValue([
                                      "categories",
                                      index,
                                      "appts",
                                    ]);
                                    const cpa = getFieldValue([
                                      "categories",
                                      index,
                                      "cpa",
                                    ]);
                                    const jobs = getFieldValue([
                                      "categories",
                                      index,
                                      "jobs",
                                    ]);
                                    const cpj = getFieldValue([
                                      "categories",
                                      index,
                                      "cpj",
                                    ]);

                                    return (
                                      <>
                                        <Metric>
                                          {!rowData?.isParent &&
                                          !rowData?.isDynaicBudget ? (
                                            <>
                                              <MetricLabel>Budget</MetricLabel>
                                              <MetricValue>
                                                ${formatInteger(budget)}
                                              </MetricValue>
                                            </>
                                          ) : (
                                            <>
                                              <MetricLabel>Revenue</MetricLabel>
                                              <MetricValue>
                                                ${formatInteger(sales)}
                                              </MetricValue>
                                            </>
                                          )}
                                        </Metric>
                                        <Metric>
                                          <MetricLabel>ROAS</MetricLabel>

                                          <MetricValue>
                                            {formatNumberDecimal(roas)}
                                          </MetricValue>
                                        </Metric>
                                        <Metric>
                                          <MetricLabel>Leads</MetricLabel>
                                          <MetricValue>
                                            {formatInteger(leads)}
                                          </MetricValue>
                                        </Metric>
                                        <Metric>
                                          <MetricLabel>Appts</MetricLabel>
                                          <MetricValue>
                                            {formatInteger(appts)}
                                          </MetricValue>
                                        </Metric>
                                        <Metric>
                                          <MetricLabel>
                                            Cost per Appts
                                          </MetricLabel>
                                          <MetricValue>
                                            ${formatInteger(cpa)}
                                          </MetricValue>
                                        </Metric>
                                        <Metric>
                                          <MetricLabel>Sold Jobs</MetricLabel>
                                          <MetricValue>
                                            {formatInteger(jobs)}
                                          </MetricValue>
                                        </Metric>
                                        <Metric>
                                          <MetricLabel>
                                            Cost per Sold Jobs
                                          </MetricLabel>
                                          <MetricValue>
                                            ${formatInteger(cpj)}
                                          </MetricValue>
                                        </Metric>
                                      </>
                                    );
                                  }}
                                </Form.Item>
                              </AdditionalMetrics>
                            </Container>
                          ) : (
                            <>
                              {/* This is the minimize block */}
                              {rowData?.isParent && (
                                <Container
                                  style={{
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: !rowData?.isParent ? "0 20px" : "0",
                                    marginTop:
                                      index === 0
                                        ? "10px"
                                        : rowData?.isParent
                                        ? "20px"
                                        : "0",
                                    padding: rowData?.isParent
                                      ? "10px 12px"
                                      : "20px 12px 10px 12px",
                                  }}
                                >
                                  <ExpendTag
                                    rotation="0"
                                    onClick={() =>
                                      handleMinimizeMaximize(rowData)
                                    }
                                  >
                                    <Arrowiconright />{" "}
                                  </ExpendTag>

                                  <CategoryNameResponsive>
                                    {rowData?.category_name}{" "}
                                    {rowData?.category_name ===
                                      "Paid Search" && (
                                      <i
                                        className="paid-search-icon"
                                        onClick={() => setIsPaidModalOpen(true)}
                                      >
                                        <IoMdInformationCircleOutline
                                          size={15}
                                        />
                                      </i>
                                    )}
                                  </CategoryNameResponsive>

                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                      const budget = getFieldValue([
                                        "categories",
                                        index,
                                        "budget",
                                      ]);
                                      const sales = getFieldValue([
                                        "categories",
                                        index,
                                        "sales",
                                      ]);
                                      const currentCell = getFieldValue([
                                        "categories",
                                        index,
                                      ]);
                                      const isCheckedCell = getFieldValue([
                                        "categories",
                                      ])?.find(
                                        (el) =>
                                          el?.category_name ===
                                            currentCell?.category_name &&
                                          el?.isChecked
                                      );

                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "10px",
                                          }}
                                        >
                                          <MinMetric flexDirection="row">
                                            <MetricLabel
                                              style={{
                                                marginBottom: 0,
                                              }}
                                            >
                                              Budget
                                            </MetricLabel>
                                            <MetricValue>
                                              ${formatInteger(budget)}
                                            </MetricValue>
                                          </MinMetric>

                                          <MinMetric flexDirection="row">
                                            <MetricLabel
                                              style={{
                                                marginBottom: 0,
                                              }}
                                            >
                                              Revenue
                                            </MetricLabel>
                                            <MetricValue>
                                              ${formatInteger(sales)}
                                            </MetricValue>
                                          </MinMetric>

                                          <CategoryName
                                            style={{ alignSelf: "center" }}
                                          >
                                            {" "}
                                            New{" "}
                                          </CategoryName>

                                          <MinMetric flexDirection="row">
                                            <MetricLabel
                                              style={{
                                                marginBottom: 0,
                                              }}
                                            >
                                              Budget
                                            </MetricLabel>
                                            <MetricValue>
                                              $
                                              {formatInteger(
                                                isCheckedCell?.budget
                                              )}
                                            </MetricValue>
                                          </MinMetric>

                                          <MinMetric flexDirection="row">
                                            <MetricLabel
                                              style={{
                                                marginBottom: 0,
                                              }}
                                            >
                                              Revenue
                                            </MetricLabel>
                                            <MetricValue>
                                              $
                                              {formatInteger(
                                                isCheckedCell?.sales
                                              )}
                                            </MetricValue>
                                          </MinMetric>
                                        </div>
                                      );
                                    }}
                                  </Form.Item>
                                </Container>
                              )}
                            </>
                          )}
                        </>
                      );
                    })}
                  </ListContainer>
                )}
              </Form.List>
            </section>

            <section className="left">
              <TotalComponent
                data={form.getFieldsValue()?.categories ?? data}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                name={name}
              />

              {name === "current" && (
                <DifferenceComponent
                  data={form.getFieldsValue()?.categories ?? data}
                  activeTab={activeTab}
                />
              )}

              <Watermark background={"transparent"} />
            </section>
          </DynamicSheetWrapper>
        </Form>
        {isPaidModalOpen && (
          <PaidSearchModal
            isModalOpen={isPaidModalOpen}
            setIsModalOpen={setIsPaidModalOpen}
            impressionShare={impressionShare}
            isPaidLoading={isPaidLoading}
          />
        )}
      </>
    );
  }
);

export default YearComponent;
